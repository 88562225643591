import { ApiDashboardModelPreviewTab, ApiPipelineType, ApiRenderState, ENDPOINT_PREFIX } from "core/common/types/api";
import { GenerateImageApiState, isCannyGenerateImageApiState, isDefaultGenerateImageApiState, isRefCannyGenerateImageApiState, isRefDefaultGenerateImageApiState } from "./generate-image-api-config";
import { StateUpdater } from "core/common/types";
import { editorContextStore } from "contexts/editor-context";
import { debugError, debugLog } from "core/utils/print-utilts";

export type SubmitApiRequestProps = {
    apiState: GenerateImageApiState,
    apiRenderState: ApiRenderState,
    setApiRenderState: (value: StateUpdater<ApiRenderState>) => void,
    setRenderResults: (value: StateUpdater<string[]>) => void,
    setRenderResultMessage: (value: StateUpdater<string>) => void,
    setPlaygroundTab: (value: StateUpdater<ApiDashboardModelPreviewTab>) => void,
}

const apiEndpointUrl = process.env.REACT_APP_RENDER_API_ENDPOINT_URL;

/** now that our jobs are async, you have to poll for them until something shows up. */

type ApiRequestParams = {
    apiState: GenerateImageApiState;
    userApiKey: string;
    endpoint: string;
};

/** polls the job status until it's done */
async function pollJobStatus(
  jobId: string,
  apiKey: string,
  maxAttempts: number = 20, // Adjust as needed
  pollingInterval: number = 3000 // 3 seconds
): Promise<string[]> {

  for (let attempt = 0; attempt < maxAttempts; attempt++) {
    try {
      const response = await fetch(
        `${apiEndpointUrl}/${ENDPOINT_PREFIX}/jobs/${jobId}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'api-key': apiKey,
          },
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();

      if (data.status === "Succeeded") {
        return data.image_urls;
      } else if (data.status === "Stopped") {
        throw new Error("Job was stopped");
      }

      // If job is still active, wait before next attempt
      await new Promise(resolve => setTimeout(resolve, pollingInterval));
    } catch (error) {
      console.error("Error polling job status:", error);
      throw error;
    }
  }

  throw new Error("Max polling attempts reached");
}

/** just creates a new api job */
async function makeApiRequest({ apiState, userApiKey, endpoint }: ApiRequestParams): Promise<Response> {
    return await fetch(endpoint, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'api-key': userApiKey,
        },
        body: JSON.stringify(apiState),
    });
}

/** polls all the jobIds together */
async function handleJobPolling(jobIds: string[], userApiKey: string): Promise<{ imageUrls: string[], anyFailed: boolean }> {
    let anyFailed = false;
    const imageUrls = await Promise.all(
        jobIds.map(async jobId => {
            try {
                return await pollJobStatus(jobId, userApiKey);
            } catch (error) {
                debugError(`Error polling job status for jobId ${jobId}:`, error);
                anyFailed = true;
                return [];
            }
        })
    );

    return { imageUrls: imageUrls.flat(), anyFailed };
}

async function submitApiRequest({
    apiState,
    apiRenderState,
    setApiRenderState,
    setRenderResults,
    setRenderResultMessage,
    setPlaygroundTab,
}: SubmitApiRequestProps): Promise<void> {
    if (apiRenderState !== ApiRenderState.Idle) {
        return;
    }

    const { userApiData } = editorContextStore.getState();

    setApiRenderState(ApiRenderState.Rendering);
    setPlaygroundTab(ApiDashboardModelPreviewTab.Results);

    const endpoint = `${apiEndpointUrl}/${ENDPOINT_PREFIX}`;

    // debugLog(`leon log just dumping all the args keys: ${Object.keys(apiState)}`);

    try {
        const response = await makeApiRequest({ apiState, userApiKey: userApiData.primaryKey, endpoint });

        if (response.ok) {
            const result = await response.json();
            const jobIds: string[] = result.jobIds;

            if (jobIds.length === 0) {
                setRenderResultMessage("Failed to start any jobs.");
                setRenderResults([]);
            } else {
                setRenderResultMessage("Successfully started jobs. Waiting for results...");
                const { imageUrls, anyFailed } = await handleJobPolling(jobIds, userApiData.primaryKey);

                if (anyFailed) {
                    setRenderResultMessage("Some jobs failed to complete.");
                } else {
                    setRenderResultMessage("All jobs completed successfully.");
                }
                setRenderResults(imageUrls);
            }
        } else {
            const message = (await response.json())?.message;
            setRenderResultMessage(message);
            setRenderResults([]);
        }
    } catch (error) {
        setRenderResultMessage(`An error occurred: `);
        debugError(error);
        setRenderResults([]);
    } finally {
        setApiRenderState(ApiRenderState.Idle);
    }
}

async function submitDefaultApiRequest(props: SubmitApiRequestProps): Promise<void> {
    // i don't totally understand why  you can't read props.apiState.pipelineType here but typescript is confident it's type never so i'm just going to trust it
    if (!isDefaultGenerateImageApiState(props.apiState)) {
        props.setRenderResultMessage(`Incorrect pipeline type: ${ApiPipelineType.Default} is required.`);
        props.setRenderResults([]);
        return;
    }

    await submitApiRequest(props);
}

async function submitCannyApiRequest(props: SubmitApiRequestProps): Promise<void> {
    if (!isCannyGenerateImageApiState(props.apiState)) {
        props.setRenderResultMessage(`Incorrect pipeline type: ${props.apiState.pipeline_type}; Need to set pipeline type to ${ApiPipelineType.Canny}.`);
        props.setRenderResults([]);
        return;
    }

    await submitApiRequest(props);
}

async function submitRefDefaultApiRequest(props: SubmitApiRequestProps): Promise<void> {
    if (!isRefDefaultGenerateImageApiState(props.apiState)) {
        props.setRenderResultMessage(`Incorrect pipeline type: ${props.apiState.pipeline_type}; Need to set pipeline type to ${ApiPipelineType.RefDefault}.`);
        props.setRenderResults([]);
        return;
    }

    await submitApiRequest(props);
}

async function submitRefCannyApiRequest(props: SubmitApiRequestProps): Promise<void> {
    if (!isRefCannyGenerateImageApiState(props.apiState)) {
        props.setRenderResultMessage(`Incorrect pipeline type: ${props.apiState.pipeline_type}; Need to set pipeline type to ${ApiPipelineType.RefCanny}.`);
        props.setRenderResults([]);
        return;
    }

    await submitApiRequest(props);
}

export async function submitGenerateImageApiRequest({
    apiState,
    apiRenderState,
    setApiRenderState,
    setRenderResults,
    setRenderResultMessage,
    setPlaygroundTab,
}: SubmitApiRequestProps) {
    try {
        const {
            userApiData,
        } = editorContextStore.getState();

        if (!userApiData?.primaryKey) {
            setRenderResultMessage("No API subscription found.");
            setApiRenderState(ApiRenderState.Idle);
            setRenderResults([]);
            return;
        }

        if (apiState.pipeline_type === ApiPipelineType.Default) {
            return await submitDefaultApiRequest({
                apiState,
                apiRenderState,
                setApiRenderState,
                setRenderResults,
                setRenderResultMessage,
                setPlaygroundTab,
            });
        } else if (apiState.pipeline_type === ApiPipelineType.Canny) {
            return await submitCannyApiRequest({
                apiState,
                apiRenderState,
                setApiRenderState,
                setRenderResults,
                setRenderResultMessage,
                setPlaygroundTab,
            });
        } else if (apiState.pipeline_type === ApiPipelineType.RefDefault) {
            return await submitRefDefaultApiRequest({
                apiState,
                apiRenderState,
                setApiRenderState,
                setRenderResults,
                setRenderResultMessage,
                setPlaygroundTab,
            });
        } else if (apiState.pipeline_type === ApiPipelineType.RefCanny) {
            return await submitRefCannyApiRequest({
                apiState,
                apiRenderState,
                setApiRenderState,
                setRenderResults,
                setRenderResultMessage,
                setPlaygroundTab,
            });
        }

    } catch (error) {

        console.error(error);

        setRenderResultMessage("Unknown error in the backend.");
        setRenderResults([]);
    } finally {
        setApiRenderState(ApiRenderState.Idle);
    }
}