import { OutpaintImageArgs, OutpaintImageResponse } from "backend/base";
import { debugError, debugLog } from "core/utils/print-utilts";

const backendOutpaintApiUrl = "https://us-central1-flair-ai.cloudfunctions.net/outpaint-image";

interface BackendOutpaintApiArgs {
    image: string;
    prompt: string;
    number_of_images: number;
}

interface BackendOutpaintApiResponse {
    image_urls: string[],
}

function isBackendOutpaintApiResponse(data: any): data is BackendOutpaintApiResponse {
    return (
        data &&
        Array.isArray(data.image_urls)
    );
}

export class BackendOutpaintManager {

    static async outpaintImage({
        imageDataURL,
        userId,
        prompt = "",
        numberOfImages = 1,
    }: OutpaintImageArgs & {
        userId: string,
    }): Promise<OutpaintImageResponse> {
        // if (process.env.NODE_ENV === 'development') {
        //     return {
        //         ok: true,
        //         imageDataUrls: [
        //             "https://imagedelivery.net/i1XPW6iC_chU01_6tBPo8Q/156af620-362a-4eb1-f27e-18b7496a3d00/public",
        //         ],
        //     };
        // }

        // Prepare the payload
        const payload: BackendOutpaintApiArgs = {
            image: imageDataURL,
            prompt: prompt,
            number_of_images: numberOfImages,
        };

        // Prepare the headers
        const headers = {
            "Content-Type": "application/json",
            UserId: userId,
        };

        // Send the request using fetch
        try {
            const response = await fetch(
                backendOutpaintApiUrl,
                {
                    method: "POST",
                    headers: headers,
                    body: JSON.stringify(payload),
                },
            );

            if (response.ok) {
                const responseData = await response.json();

                if (isBackendOutpaintApiResponse(responseData)) {
                    return {
                        ok: true,
                        imageDataUrls: responseData.image_urls,
                    };
                }

                debugLog(responseData.message);
                return {
                    ok: false,
                    message: "Cannot extend the image. Please try again later.",
                };
            } else {
                debugError(
                    `Failed to send images. Status code: ${response.status} and message: ${await response.text()}`
                );
                return {
                    ok: false,
                    message: "Failed to extend the images.",
                };
            }
        } catch (error) {
            debugError("Error sending images to the server:", error);
            return {
                ok: false,
                message: "Failed to extend the images.",
            };
        }
    }
}