import { CustomModelDatasetItem } from './../../core/common/types/custom-model-types';
import { CustomModelInfo } from 'core/common/types/custom-model-types';
import { getDataUrlFromBlob, getDataUrlFromString } from 'core/utils/asset-utils';
import { FirebaseApp, initializeApp } from 'firebase/app';
import { Database, getDatabase } from 'firebase/database';
import { FirebaseStorage, getStorage, ref as storageRef, getDownloadURL, deleteObject, uploadString, uploadBytes, StringFormat } from 'firebase/storage';
import { collection, doc, Firestore, getDoc, getDocs, getFirestore, limit, onSnapshot, query, runTransaction, setDoc, updateDoc, where, orderBy, startAfter, DocumentSnapshot, serverTimestamp, addDoc, connectFirestoreEmulator } from 'firebase/firestore';
import { Timestamp } from 'firebase/firestore';
import { FeatureFlags, FeatureFlagRolloutFunctions, DefaultFeatureFlags, BackendUserFeatureFlags } from 'core/controllers/featureFlags/featureFlags';

import {
    Auth,
    AuthProvider,
    browserLocalPersistence,
    getAuth,
    getRedirectResult,
    GoogleAuthProvider,
    isSignInWithEmailLink,
    onAuthStateChanged,
    sendSignInLinkToEmail,
    setPersistence,
    signInWithCustomToken,
    signInWithEmailAndPassword,
    signInWithEmailLink,
    signInWithPopup,
    signInWithRedirect,
    User,
} from 'firebase/auth';
import { Functions, getFunctions, HttpsCallable, httpsCallable, connectFunctionsEmulator } from 'firebase/functions';
import { Backend, AppEnvironmentVairables, CreateCheckoutSessionParams, CreateSubscriptionsPortalLinkArgs, CreateSubscriptionsPortalLinkCallable, DeleteUserAssetInfoArgs, GetApiUsageGeneratorArgs, GetUserAssetInfoArgs, ImageCaptionArgs, OnApiUsageUpdateArgs, OnUserAssetInfoUpdateArgs, RenderClothImageArgs, StartEraseProductJobArgs, StartEraseProductJobResponse, StartRegenerateProductJobArgs, StartRenderJobArgs, UpdateStripeSusbcriptionArgs, UpdateUserAssetInfoArgs, UploadUserAssetInfoArgs, UploadUserAssetInfoRespnose, UpscaleImageArgs, isParseClothImageResult, isRenderClothImageResult, isWarpParsedImageResult, OnColorCorrectV2UpdateArgs, CancelColorCorrectV2JobArgs, CreateCustomModelArgs, GetCustomModelTrainingArgs, OnCustomModelTrainingUpdateArgs, OnCustomModelTrainingCollectionUpdateArgs, StopCustomModelTrainingArgs, StartCustomModelTrainingArgs, StartCustomModelPredictionArgs, StopCustomModelPredictionArgs, OnCustomModelPredictionUpdateArgs, UpdateCustomModelInfoArgs, GetCustomModelTrainingsArgs, DeleteCustomModelArgs, UploadCustomModelDataItemToStorageArgs, OnCustomModelPredictionsUpdateArgs, DeleteCustomModelPredictionArgs, GetPublicCustomModelPredictionsArgs, StopRenderJobArgs, OutpaintImageArgs, OutpaintImageResponse, OnVideoGenerationDocUpdateArgs, OnUserVideoGenerationsUpdateArgs, UploadVideoKeyFrameToStorageArgs, GenerateVideoPromptArgs, OnPricingConfigUpdateArgs, CreateOneTimePaymentCheckoutSessionArgs, CreateOneTimePaymentCheckoutSessionResponse } from "backend/base";
import { AppRoleType, AppUserQuotas, BackendCallableResponseData, EditorAssetContentType, RENDER_JOBS_COLLECTION_V1, UserOnboardData, UserProject, CustomModelDataset, PastGeneration, UserAssetInfoType, isUserAssetInfo, UserAssetInfo, TryOnClothMaskTypeColorHex, isTryOnModelPreviewData, UserProjectType, AppUserSubscriptionTier, PublicUserId, isCustomUserClaims } from 'core/common/types';
import { BACKEND_API_KEY_STARTER, BACKEND_API_KEY_UNLIMITED, EMAIL_STORAGE_KEY } from 'components/constants/backend';
import { DEFAULT_ORIGIN_URL, EMAIL_LINK_SIGNIN } from 'components/constants/routes';
import { getScene, isSampleProjectScene, isUserProject } from 'core/utils/type-guards';
import { generateUUID } from 'core/utils/uuid-utils';
import { IScene, SampleProjectScene } from 'core/common/scene';
import { removeUndefinedFromObject } from 'core/utils/object-utils';
import { getEditorAssetExtension } from 'core/utils/asset-utils';
import { isBigIntLessThanEqual } from 'core/utils/number-utils';
import { defaultUserOnboardData } from 'core/common/constants';
import { FirebaseUserAssetInfoGenerator, getAssetInfoFromFirestoreDoc } from './asset-generator';
import { throttle, debounce } from 'lodash';
import { TryOnPreviewGenerator } from './tryon-preview-generator';
import { editorContextStore } from 'contexts/editor-context';
import { ApiModelType, UserApiDataDoc, isApiUsageDoc, isEmailApiDataDoc, isUserApiDataDoc } from 'core/common/types/api';
import { TypesenseGenerateTemplateManager } from './generate-template-manager';
import { UpscaleModelType } from 'core/common/types/upscale';
import { isActiveStripeSubscriptionStatus, isStripeSubscriptionFirestoreDoc, StripeCheckoutSessionData, StripeCheckoutSessionLineItem, StripeListInvoicesParams, StripeListInvoicesResponse, StripeSubscriptionFirestoreDoc } from 'core/common/types/stripe';
import { ElementsSearchManager } from './elements-manager';
import { displayUiMessage } from 'components/utils/display-message';
import { debugError, debugLog } from 'core/utils/print-utilts';
import { isUpscaleJobState, UpscaleJobState, UpscaleJobStatus } from 'core/common/types/upscale-job';
import { ColorCorrectV2Args, ColorCorrectV2Response, ColorCorrectV2ResponseStatus, ColorCorrectV2Stage, isColorCorrectV2RenderJobDoc } from 'core/common/types/color-correct-v2';
import { RenderProcessController } from 'core/common/interfaces';
import { UserActivitiesDoc } from 'core/common/types/user-activities';
import { CustomModelManager } from './custom-model-manager';
import { RenderStateManager } from './render-state-manager';
import { UserRenderJobStatus } from 'core/common/types/render-job';
import { BackendOutpaintManager } from './outpaint-manager';
import { FirebaseVideoManager } from './video-manager';
import { VideoGenerationRequest } from 'core/common/types/video';
import { isPricingConfig, PricingConfigVersion } from 'core/common/types/pricing-config';

// const RenderApiUrl = process.env.REACT_APP_RENDER_API_URL;

const RemoveBackgroundApiUrl = process.env.REACT_APP_REMOVE_BACKGROUND_API_URL;
const UpscaleApiUrl = process.env.REACT_APP_UPSCALE_API_URL;

const ParseClothApiUrl = process.env.REACT_APP_TRYON_PARSE_CLOTH_API_URL;
const WarpClothApiUrl = process.env.REACT_APP_TRYON_WARP_CLOTH_API_URL;
const RenderClothApiUrl = process.env.REACT_APP_TRYON_RENDER_CLOTH_API_URL;
const CaptionApiUrl = process.env.REACT_APP_CAPTION_API_URL;
const OnUserLoginApiUrl = process.env.REACT_APP_ON_USER_LOGIN_API_URL;

const CallExperimentalApi = true;

const flairAiFirebaseConfig = {
    apiKey: "AIzaSyDIqNvWJqGGIOUebiYyNzyGhYtoe5pxAL8",
    authDomain: "flair-ai.firebaseapp.com",
    projectId: "flair-ai",
    storageBucket: "flair-ai.appspot.com",
    messagingSenderId: "414302882980",
    appId: "1:414302882980:web:d84e81b4258ed502ba6a31"
};

const firebaseConfig = flairAiFirebaseConfig;

const PROJECTS = 'projects';
const PROJECT_DOCS = 'projectDocs';
const USER_QUOTAS = 'userQuotas';
const CREATE_NEW_PROJECT = 'createNewProject';
const DELETE_USER_PROJECT = 'deleteUserProject';
const REMOVE_BACKGROUND = 'callRemoveBackgroundApi';
const USE_INVITE_CODE = 'useInviteCode';
const UPSCALE_IMAGE = 'upscaleImageApi';
const TRAIN_CUSTOM_MODEL = 'customModelTrain';
const USER_HAS_INVITE_CODE = 'doesUserHaveInviteCode';
const SEND_EMAIL_LOGIN_LINK = "sendEmailLoginLink";
const SEND_MOBILE_REDIRECT_EMAIL = 'sendMobileRedirectEmail';
const CREATE_CUSTOM_MODEL = "createCustomModel";
const USER_ASSETS = 'userAssets';
const USER_API_DATA = 'userApiData';
const APP_ENVIRONMENT_VARIABLES = "appEnvironmentVairables";
const USER_FEATUREFLAGS = 'userFeatureFlags';
const USER_ACTIVITIES = "userActivitiesV1";

const COLOR_CORRECT_V2_RENDER_STATE = "userObjectDropState";
const COLOR_CORRECT_V2_JOB_STATE = "objectDropJobs";

let firebaseApp: FirebaseApp | null = null;
let firebaseAuth: Auth | null = null;
let firebaseDatabase: Database | null = null;
let firebaseStorage: FirebaseStorage | null = null;
let firestore: Firestore | null = null;
let firebaseFunctions: Functions | null = null;
const firebaseRegion = process.env.firebaseRegion || 'us-central1';
let googleProvider: GoogleAuthProvider;
let createNewProject: HttpsCallable<{ displayName?: string | null }, UserProject> | null = null;
let createCustomModel: HttpsCallable<{ displayName?: string }, CustomModelInfo> | null = null;
let deleteUserProject: HttpsCallable<{ projectId: string }, void> | null = null;
let callRemoveBackgroundApi: HttpsCallable<{ imageUrl: string }, BackendCallableResponseData> | null = null;
let upscaleImageApi: HttpsCallable<{ imageUrl: string, upscale?: 2 | 4 }, BackendCallableResponseData> | null = null;
let setInviteCodeUsed: HttpsCallable<{ inviteCode: string, email: string, version?: 'v1' | 'v2' }, BackendCallableResponseData> | null = null;
let doesUserHaveInviteCode: HttpsCallable<void, BackendCallableResponseData & { inviteCode?: string }> | null = null;
let trainCustomModelApi: HttpsCallable<{ modelId: string }, BackendCallableResponseData> | null = null;
let createPortalLink: CreateSubscriptionsPortalLinkCallable | null = null;
let createSubscriptionsPortalLink: CreateSubscriptionsPortalLinkCallable | null = null;
let sendMobileRedirectEmail: HttpsCallable<{ email: string, name?: string, noAuthToken?: boolean }, void> | null = null;
let sendEmailLoginLink: HttpsCallable<{ email: string, name?: string, redirectUrl?: string }, void> | null = null;
let getUserInvoices: HttpsCallable<StripeListInvoicesParams, StripeListInvoicesResponse> | null = null;
let downloadAndUploadInvoice: HttpsCallable<{ invoiceId: string }, { filePath?: string }> | null = null;
let getPublicUserId: HttpsCallable<
    void,
    {
        publicUserId: PublicUserId,
    }
> | null = null;
let createOneTimePaymentCheckoutSession: HttpsCallable<
    CreateOneTimePaymentCheckoutSessionArgs,
    CreateOneTimePaymentCheckoutSessionResponse
> | null = null;

type UpdateStripeSusbcriptionCallable = HttpsCallable<
    {
        fromProductId: string,
        toProductId: string,
        toPriceId?: string,
    },
    {
        code: number,
        updated: boolean,
        message: string,
    }
>

let updateStripeSusbcription: UpdateStripeSusbcriptionCallable | null = null;

type DisconnectRealTimeStateCallable = HttpsCallable<
    {
        connectionId: string,
    },
    {
        code: number,
        updated: boolean,
        message: string,
    }
>;

let disconnectRealTimeState: DisconnectRealTimeStateCallable | null = null;

let firebaseInitialized = false;
let firestoreInitialized = false;
let functionsInitialized = false;


function connectToEmulators({
    firestore,
    firebaseFunctions,
}: {
    firestore?: Firestore,
    firebaseFunctions?: Functions,
}) {
    if (process.env.NODE_ENV === 'development') {
        if (firestore && !firestoreInitialized) {
            firestoreInitialized = true;
            connectFirestoreEmulator(firestore, 'localhost', 8080);
        }
        if (firebaseFunctions && !functionsInitialized) {
            functionsInitialized = true;
            connectFunctionsEmulator(firebaseFunctions, 'localhost', 5001);
        }
        console.log('Connect to local firestore and functions emulator');
    }
}

export const MAX_NUMBER_ASSET_UPLOADS = 10;

export function getFirebaseApp() {
    firebaseApp = firebaseApp || initializeApp(firebaseConfig);
    firestore = firestore || getFirestore(firebaseApp);
    firebaseFunctions = firebaseFunctions || getFunctions(firebaseApp, firebaseRegion);

    // connectToEmulators({ firebaseFunctions });

    firebaseDatabase = firebaseDatabase || getDatabase(firebaseApp);
    firebaseAuth = firebaseAuth || getAuth(firebaseApp);
    firebaseStorage = firebaseStorage || getStorage(firebaseApp);
    googleProvider = new GoogleAuthProvider();
    googleProvider?.addScope('email');
    createNewProject = createNewProject || httpsCallable<{ displayName?: string | null }, UserProject>(firebaseFunctions, CREATE_NEW_PROJECT);
    createCustomModel = createCustomModel || httpsCallable<{ displayName?: string }, CustomModelInfo>(firebaseFunctions, CREATE_CUSTOM_MODEL);
    deleteUserProject = deleteUserProject || httpsCallable<{ projectId: string }, void>(firebaseFunctions, DELETE_USER_PROJECT);
    callRemoveBackgroundApi = callRemoveBackgroundApi || httpsCallable<{ imageUrl: string }, BackendCallableResponseData>(firebaseFunctions, REMOVE_BACKGROUND);
    upscaleImageApi = upscaleImageApi || httpsCallable<{ imageUrl: string, upscale?: 2 | 4 }, BackendCallableResponseData>(firebaseFunctions, UPSCALE_IMAGE);
    setInviteCodeUsed = setInviteCodeUsed || httpsCallable(firebaseFunctions, USE_INVITE_CODE);
    doesUserHaveInviteCode = doesUserHaveInviteCode || httpsCallable(firebaseFunctions, USER_HAS_INVITE_CODE);
    createPortalLink = createPortalLink || httpsCallable(firebaseFunctions, 'ext-firestore-stripe-payments-createPortalLink');
    createSubscriptionsPortalLink = createSubscriptionsPortalLink || httpsCallable(firebaseFunctions, 'ext-firestore-stripe-subscriptions-createPortalLink');
    trainCustomModelApi = trainCustomModelApi || httpsCallable(firebaseFunctions, TRAIN_CUSTOM_MODEL);
    sendMobileRedirectEmail = sendMobileRedirectEmail || httpsCallable(firebaseFunctions, SEND_MOBILE_REDIRECT_EMAIL);
    sendEmailLoginLink = sendEmailLoginLink || httpsCallable(firebaseFunctions, SEND_EMAIL_LOGIN_LINK);
    getUserInvoices = getUserInvoices || httpsCallable(firebaseFunctions, "getUserInvoices");
    downloadAndUploadInvoice = downloadAndUploadInvoice || httpsCallable(firebaseFunctions, "downloadAndUploadInvoice");
    updateStripeSusbcription = updateStripeSusbcription || httpsCallable(firebaseFunctions, "updateStripeSubscriptionColabJuly24");
    disconnectRealTimeState = disconnectRealTimeState || httpsCallable(firebaseFunctions, "disconnectRealTimeState");
    getPublicUserId = getPublicUserId || httpsCallable(firebaseFunctions, "getPublicUserIdColabJuly24");
    createOneTimePaymentCheckoutSession = createOneTimePaymentCheckoutSession || httpsCallable(firebaseFunctions, "createOneTimePaymentCheckoutSessionColabJuly24");

    firebaseInitialized = true;
    return {
        firebaseApp,
        firebaseDatabase,
        firebaseAuth,
        firebaseStorage,
        firestore,
        firebaseFunctions,
        createNewProject,
        deleteUserProject,
        callRemoveBackgroundApi,
        upscaleImageApi,
        setInviteCodeUsed,
        doesUserHaveInviteCode,
        getUserInvoices,
        downloadAndUploadInvoice,
        updateStripeSusbcription,
        disconnectRealTimeState,
        getPublicUserId,
        createOneTimePaymentCheckoutSession,
    };
}

const noop = () => { };

type DocsBatchGenerator<T> = {
    batchSize: number,
    getNextBatch: () => Promise<T[]>
};



export class FirebaseBackend implements Backend {

    private generateTemplateManager: TypesenseGenerateTemplateManager;
    elementsSearchManager: ElementsSearchManager;

    pastGenerationsGenerator?: DocsBatchGenerator<any> | undefined;

    private customModelManager: CustomModelManager;

    private environment: AppEnvironmentVairables | undefined;

    private renderStateManager: RenderStateManager;

    private videoManager: FirebaseVideoManager;

    constructor() {
        const {
            firestore,
            firebaseFunctions,
        } = getFirebaseApp();

        this.generateTemplateManager = new TypesenseGenerateTemplateManager({
            firestore,
        });
        this.elementsSearchManager = new ElementsSearchManager({ firestore });

        this.customModelManager = new CustomModelManager({
            firestore,
            firebaseFunctions,
        });

        this.renderStateManager = new RenderStateManager({
            firestore,
        });

        this.videoManager = new FirebaseVideoManager({
            firestore,
            firebaseFunctions,
        });

        // this.loadEnvironment();
    }

    static getAppEnvironmentVariablesRef(version: string) {
        const { firestore } = getFirebaseApp();

        return doc(collection(firestore, APP_ENVIRONMENT_VARIABLES), version);
    }

    private loadEnvironment() {
        try {

            const version = process.env.REACT_APP_VERSION;

            if (!version) {
                return;
            }

            debugLog(`Read environment ${APP_ENVIRONMENT_VARIABLES}/${version}`);

            getDoc(FirebaseBackend.getAppEnvironmentVariablesRef(version)).then((snapshot) => {
                debugLog(snapshot.data());
            });

            onSnapshot(
                FirebaseBackend.getAppEnvironmentVariablesRef(version),
                (snapshot) => {
                    if (!snapshot.exists()) {
                        this.environment = undefined;
                        return;
                    }

                    const environment = snapshot.data();

                    this.environment = environment as AppEnvironmentVairables;

                    debugLog(`Set environment to ${JSON.stringify(environment)}`);
                },
            );

        } catch (error) {

            debugError(error);

        }
    }

    getElementsManager() {
        return this.elementsSearchManager;
    }

    private getUserAssetStoragePath(
        contentType: EditorAssetContentType,
        assetId?: string,
    ) {
        const { firebaseAuth } = getFirebaseApp();
        const uid = firebaseAuth.currentUser?.uid;
        assetId = assetId || generateUUID();
        return `users/${uid}/assets/${assetId}${getEditorAssetExtension(contentType)}`;
    }

    private getUserDatasetStoragePath(
        modelId: string,
        contentType: EditorAssetContentType,
    ) {
        const { firebaseAuth } = getFirebaseApp();
        const uid = firebaseAuth.currentUser?.uid;
        const assetId = generateUUID();
        return `users/${uid}/dataset/${modelId}/${assetId}${getEditorAssetExtension(contentType)}`;
    }

    private async uploadUrlToStorage({
        data,
        contentType,
        assetId,
        stringFormat,
    }: {
        data: string,
        contentType: EditorAssetContentType,
        assetId?: string,
        stringFormat: StringFormat,
    }) {
        try {
            const { firebaseStorage } = getFirebaseApp();

            const storagePath = await this.generateSignedUrlForStoragePath({
                contentType,
                assetId,
            });

            const blobRef = storageRef(firebaseStorage, storagePath);

            await uploadString(
                blobRef,
                data,
                stringFormat,
            );

            const cleanedStoragePath = this.cleanupStoragePathURL(blobRef.fullPath);

            return cleanedStoragePath;
        } catch (error) {
            debugError(error);
        }
        return "";
    }

    async uploadDataUrlToStorage({
        data,
        contentType,
        assetId,
    }: {
        data: string,
        contentType: EditorAssetContentType,
        assetId?: string,
    }) {
        return this.uploadUrlToStorage({
            data,
            assetId,
            contentType,
            stringFormat: 'data_url',
        });
    }

    async uploadJsonToStorage({
        data,
        assetId,
    }: {
        data: string,
        assetId?: string,
    }) {
        return this.uploadUrlToStorage({
            data,
            assetId,
            contentType: EditorAssetContentType.json,
            stringFormat: 'raw',
        });
    }

    async generateSignedUrlForStoragePath({
        contentType,
        assetId,
    }: {
        contentType: EditorAssetContentType,
        assetId?: string,
    }) {
        const { firebaseAuth } = getFirebaseApp();
        const user = firebaseAuth.currentUser;
        if (!user) {
            throw new Error('User not authenticated');
        }

        const authToken = await user.getIdToken();

        const response = await fetch('https://getsignedurlforassetuploadv2corsfixed-6fpjtxm2eq-uc.a.run.app', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${authToken}`,
            },
            body: JSON.stringify({
                contentExtension: getEditorAssetExtension(contentType),
            }),
        });

        if (!response.ok) {
            throw new Error(`Failed to get signed URL: ${response.statusText}`);
        }

        const data = await response.json();
        return data.signedUrl as string;
    }

    static StorageURLPrefixes = [
        `https://storage.googleapis.com/${flairAiFirebaseConfig.storageBucket}/`,
        `gs://${flairAiFirebaseConfig.storageBucket}/`,
    ];

    private static cleanupStoragePathPrefix(storagePath: string, prefix: string) {
        if (storagePath.startsWith(prefix)) {
            return storagePath.slice(prefix.length);
        }
        return storagePath;
    }

    private static getStoragePathURLPrefix(storagePath: string) {
        return FirebaseBackend.StorageURLPrefixes.find((prefix) => storagePath.startsWith(prefix));
    }

    private static getStoragePathFromFirebaseStorageURL(url: string) {
        const regex = /\/o\/([^?]*)/;
        const match = url.match(regex);

        if (!match || !match[1]) {
            return url;
        }

        const encodedPath = match[1];
        const decodedPath = decodeURIComponent(encodedPath);

        return decodedPath;
    }

    isStoragePathURL = (value: string) => {
        try {
            return FirebaseBackend.getStoragePathURLPrefix(value) != null;
        } catch (error) {
            console.error(error);
        }
        return false;
    };

    cleanupStoragePathURL(storagePath: string) {
        try {
            const prefix = FirebaseBackend.getStoragePathURLPrefix(storagePath);

            if (prefix) {
                return FirebaseBackend.cleanupStoragePathPrefix(storagePath, prefix);
            }

            if (storagePath.startsWith("https://")) {
                return FirebaseBackend.getStoragePathFromFirebaseStorageURL(storagePath);
            }

            return storagePath;
        } catch (error) {
            console.error(error);
        }
        return storagePath;
    }

    async uploadFileToStorage({
        data,
        contentType,
    }: {
        data: File | Blob,
        contentType: EditorAssetContentType,
    }) {
        try {

            const { firebaseStorage } = getFirebaseApp();
            const storagePath = await this.generateSignedUrlForStoragePath({
                contentType,
            });

            const blobRef = storageRef(firebaseStorage, storagePath);

            await uploadBytes(
                blobRef,
                data,
            );

            const cleanedPath = this.cleanupStoragePathURL(blobRef.fullPath);

            return cleanedPath;

        } catch (error) {
            console.error(error);
        }
        return "";
    };

    uploadFileToDatasetStorage({
        data,
        modelId,
        contentType,
    }: {
        data: File | Blob,
        modelId: string,
        contentType: EditorAssetContentType,
    }) {
        const { firebaseStorage } = getFirebaseApp();
        const storagePath = this.getUserDatasetStoragePath(modelId, contentType);
        const blobRef = storageRef(firebaseStorage, storagePath);
        return uploadBytes(
            blobRef,
            data,
        ).then(() => storagePath);
    }

    getDownloadUrlFromStoragePath(path: string) {
        try {
            const {
                firebaseStorage,
            } = getFirebaseApp();

            if (!firebaseStorage) {
                return Promise.resolve('');
            }


            if (this.isStoragePathURL(path)) {
                const url = new URL(path);
                url.search = "";
                path = this.cleanupStoragePathURL(url.toString());
            }

            const queryParamIndex = path.indexOf("?GoogleAccessId");

            if (queryParamIndex > 0) {
                path = path.slice(0, queryParamIndex);
            }

            const ref = storageRef(firebaseStorage, path);

            // Make sure that this is not a root reference
            if (ref.fullPath === '') {
                // is root reference, return empty string
                return Promise.resolve('');
            }

            return getDownloadURL(ref);
        } catch (error) {
            console.error(error);
        }
        return Promise.resolve("");
    }

    deleteImageFromStorage(path: string) {
        if (!firebaseStorage || !path) {
            return Promise.resolve();
        }
        return deleteObject(storageRef(firebaseStorage, path)).catch(console.error);
    }



    static getUserAssetInfoRef(
        uid: string,
        assetType: UserAssetInfoType,
        assetId: string,
    ) {
        const { firestore } = getFirebaseApp();
        return doc(firestore, `${USER_ASSETS}/${uid}/${assetType}/${assetId}`);
    }

    static getUserAssetInfoQuery(
        uid: string,
        assetType: UserAssetInfoType,
        limitSize = 10,
        lastVisible?: DocumentSnapshot<unknown>,
    ) {
        const { firestore } = getFirebaseApp();
        const userAssetInfoCollectionRef = collection(
            firestore,
            `${USER_ASSETS}/${uid}/${assetType}`,
        )
        if (lastVisible) {
            return query(
                userAssetInfoCollectionRef,
                orderBy('timeModified', 'desc'),
                startAfter(lastVisible),
                limit(limitSize),
            );
        }
        return query(
            userAssetInfoCollectionRef,
            orderBy('timeModified', 'desc'),
            limit(limitSize),
        )
    }

    static getAssetIdFromStoragePath(storagePath: string) {
        const pathSplitted = storagePath.split("/");
        if (pathSplitted.length <= 0) {
            return;
        }
        const lastPath = pathSplitted[pathSplitted.length - 1];
        return lastPath.split(".")[0];
    }

    async updateUserAssetInfo({
        uid,
        assetId,
        storagePath,
        assetType,
        caption = "",
    }: UpdateUserAssetInfoArgs) {
        try {
            assetId = assetId || (storagePath ? FirebaseBackend.getAssetIdFromStoragePath(storagePath) : undefined);

            if (!assetId) {
                return {
                    isUpdated: false,
                    message: 'User asset id is invalid'
                }
            }

            const { firebaseAuth } = getFirebaseApp();
            uid = uid || firebaseAuth.currentUser?.uid;
            if (!uid) {
                return {
                    isUpdated: false,
                    message: 'User id is invalid'
                }
            }

            const assetDocRef = FirebaseBackend.getUserAssetInfoRef(
                uid,
                assetType,
                assetId,
            );
            const assetDoc: UserAssetInfo = removeUndefinedFromObject({
                id: assetId,
                storagePath,
                caption,
            }) as UserAssetInfo;
            await setDoc(
                assetDocRef,
                {
                    ...assetDoc,
                    timeModified: serverTimestamp(),
                },
                {
                    merge: true,
                }
            );
            return {
                isUpdated: true,
                message: `Updated asset ${assetId}`,
                result: assetDoc,
            }

        } catch (error) {
            return {
                isUpdated: false,
                message: (error as any)?.message || error,
            };
        }
    }

    async deleteUserAssetInfo({
        uid,
        assetId,
        assetType,
    }: DeleteUserAssetInfoArgs): Promise<UploadUserAssetInfoRespnose> {
        try {
            if (!assetId) {
                return {
                    isUpdated: false,
                    message: 'User asset id is invalid'
                }
            }

            const isDeleted = true;

            const { firebaseAuth } = getFirebaseApp();
            uid = uid || firebaseAuth.currentUser?.uid;
            if (!uid) {
                return {
                    isUpdated: false,
                    message: 'User id is invalid'
                }
            }

            const assetDocRef = FirebaseBackend.getUserAssetInfoRef(
                uid,
                assetType,
                assetId,
            );
            const assetDoc: Partial<UserAssetInfo> = removeUndefinedFromObject({
                isDeleted,
            }) as Partial<UserAssetInfo>;
            await updateDoc(
                assetDocRef,
                {
                    ...assetDoc,
                    timeModified: serverTimestamp(),
                },
            );
            return {
                isUpdated: true,
                message: `Updated asset ${assetId}`,
            };

        } catch (error) {
            return {
                isUpdated: false,
                message: (error as any)?.message || error,
            };
        }
    }

    async addUserAssetInfo({
        uid,
        assetId,
        storagePath,
        assetType,
        caption = "",
    }: UploadUserAssetInfoArgs) {
        try {

            if (!storagePath) {
                return {
                    isUpdated: false,
                    message: 'Storage path is invalid'
                }
            }
            const { firebaseAuth } = getFirebaseApp();
            uid = uid || firebaseAuth.currentUser?.uid;
            if (!uid) {
                return {
                    isUpdated: false,
                    message: 'User id is invalid'
                }
            }
            assetId = assetId || FirebaseBackend.getAssetIdFromStoragePath(
                storagePath,
            );
            if (!assetId) {
                return {
                    isUpdated: false,
                    message: 'Asset id is invalid'
                }
            }
            const assetDocRef = FirebaseBackend.getUserAssetInfoRef(
                uid,
                assetType,
                assetId,
            );
            const assetDoc: UserAssetInfo = removeUndefinedFromObject({
                id: assetId,
                storagePath,
                caption,
            }) as UserAssetInfo;
            await setDoc(
                assetDocRef,
                {
                    ...assetDoc,
                    timeModified: serverTimestamp(),
                },
                {
                    merge: true,
                }
            );
            return {
                isUpdated: true,
                message: `Updated asset ${assetId}`,
                result: assetDoc,
            }

        } catch (error) {

            return {
                isUpdated: false,
                message: (error as any)?.message || error,
            }

        }
    }

    async getUserAssetInfo({
        uid,
        assetId,
        assetType,
    }: GetUserAssetInfoArgs) {
        try {

            const { firebaseAuth } = getFirebaseApp();
            uid = uid || firebaseAuth.currentUser?.uid;
            if (!uid) {
                return {
                    isSuccess: false,
                    message: 'User id is invalid'
                };
            }

            const snapshot = await getDoc(FirebaseBackend.getUserAssetInfoRef(
                uid,
                assetType,
                assetId,
            ));

            if (!snapshot.exists()) {
                return {
                    isSuccess: false,
                    message: `Asset ${assetId} of type ${assetType} does not exist`,
                }
            }

            const data = snapshot.data();

            if (!isUserAssetInfo(data)) {
                return {
                    isSuccess: false,
                    message: 'Data is invalid',
                };
            }

            return {
                isSuccess: true,
                result: data,
            };

        } catch (error) {
            return {
                isSucecss: false,
                message: (error as any)?.message || error,
            }
        }
    }

    async countTotalUserAssets(): Promise<number> {
        try {
            const { firebaseAuth } = getFirebaseApp();
            const uid = firebaseAuth.currentUser?.uid || '';

            if (!uid) {
                throw new Error('User id is invalid');
            }
            const { firestore } = getFirebaseApp();
            const userAssetInfoCollectionRef = collection(
                firestore,
                `${USER_ASSETS}/${uid}/images`,
            )

            const userAssetsRef = query(userAssetInfoCollectionRef);
            const snapshot = await getDocs(userAssetsRef);

            const totalNotDeleted = snapshot.docs.filter(doc => !doc.data().isDeleted).length;

            return totalNotDeleted;


        } catch (error) {
            console.error('Failed to count total user assets:', error);
            throw error;
        }
    }

    getUserAssetInfoGenerator({
        assetType,
        batchSize,
    }: {
        assetType: UserAssetInfoType,
        batchSize: number,
    }) {
        const {
            firebaseAuth,
        } = getFirebaseApp();
        const uid = firebaseAuth.currentUser?.uid;
        if (!uid) {
            return;
        }
        return new FirebaseUserAssetInfoGenerator({
            uid,
            batchSize,
            assetType,
            getUserAssetInfoQuery: FirebaseBackend.getUserAssetInfoQuery,
        });
    }

    onUserAssetInfoUpdate({
        assetType,
        batchSize,
        onUpdate,
    }: OnUserAssetInfoUpdateArgs) {
        const {
            firebaseAuth,
        } = getFirebaseApp();
        const uid = firebaseAuth.currentUser?.uid;
        if (!uid) {
            return () => null;
        }
        return onSnapshot(
            FirebaseBackend.getUserAssetInfoQuery(
                uid,
                assetType,
                batchSize,
            ),
            (snapshot) => {
                onUpdate(
                    snapshot.docs.map(getAssetInfoFromFirestoreDoc).filter(isUserAssetInfo),
                );
            },
        );
    }

    // deprecated now that we send things to REACT_APP_RENDER_QUEUE_PRODUCER_API_URL
    // TODO(leon): if everything is stable for a while, then we can just delete this entire blob of commented-out code
    // private static uploadRenderImageAsset(
    //     image?: Uint8Array | Blob | ArrayBuffer | string,
    // ) {
    //     if (!image || !firebaseStorage || !firebaseAuth) {
    //         return Promise.resolve(undefined)
    //     }
    //     const uid = firebaseAuth.currentUser?.uid;
    //     if (!uid) {
    //         console.log('User is not logged in');
    //         return Promise.resolve(undefined);
    //     }
    //     const imageId = generateUUID();
    //     const inputImagePath = `users/${uid}/${imageId}.png`;
    //     const imageRef = storageRef(
    //         firebaseStorage,
    //         inputImagePath,
    //     );
    //     if (typeof (image) === 'string') {
    //         if (isImagePngDataUrl(image)) {
    //             return uploadString(
    //                 imageRef,
    //                 image,
    //                 'data_url',
    //             ).then(() => inputImagePath);
    //         } else {
    //             console.warn(`Image data ${image.slice(0, 10)} is invalid, cannot upload to storage`);
    //             return Promise.resolve(undefined);
    //         }
    //     } else {
    //         return uploadBytes(
    //             imageRef,
    //             image,
    //             {
    //                 contentType: 'image/png',
    //             },
    //         ).then(() => inputImagePath)
    //     }
    // }

    // private static parseStrength(strength: number) {
    //     return Math.max(Math.min(strength * 0.01, 1), 0);
    // }

    // private startRenderJobInternal(
    //     url: string,
    //     jsonString: string,
    //     onRenderProgress: (value: number) => void,
    // ) {
    //     return new Promise((res, rej) => {

    //         const socket = new WebSocket(url);

    //         socket.onopen = () => {
    //             console.log('Send render request to websocket server');
    //             socket.send(jsonString);
    //         }

    //         socket.onmessage = (e) => {
    //             try {
    //                 const message = JSON.parse(e.data);
    //                 const status = message?.status;
    //                 if (status === '-1') {
    //                     console.log(`Received erorr ${message}, close the socket`);
    //                     socket.close();
    //                     return rej(message);
    //                 }
    //                 const progress = message?.progress;
    //                 if (progress) {
    //                     onRenderProgress(progress);
    //                 }
    //                 const images = message?.images;
    //                 if (Array.isArray(images)) {
    //                     console.log('Received images, close the socket');
    //                     socket.close();
    //                     return res(message);
    //                 }
    //             } catch (error) {
    //                 console.error(error);
    //             }
    //         }

    //         socket.onclose = res;

    //         socket.onerror = rej;
    //     });
    // }

    // private static getRenderApiUrl({
    //     type,
    //     tier,
    //     requiresReferenceImage,
    //     path = "predict"
    // }: RenderApiUrlParams & {
    //     path?: string,
    // }) {
    //     // Define the base parts of the URL
    //     let protocol = 'https://';

    //     const baseUrl = process.env.REACT_APP_RENDER_CANNY_API_URL;

    //     // const baseUrl = "inference-backend-v2.flair.ai";
    //     // Use URLSearchParams to handle the query parameters
    //     const params = new URLSearchParams();
    //     params.append('type', type);
    //     params.append('tier', tier);
    //     params.append('requires_reference_image', requiresReferenceImage ? 'true' : 'false');

    //     // Construct the final URL
    //     const finalUrl = `${protocol}${baseUrl}/${path}?${params.toString()}`;

    //     return finalUrl;
    // }

    // private static getRenderApiHealthcheckUrl(params: RenderApiUrlParams) {
    //     return FirebaseBackend.getRenderApiUrl({
    //         ...params,
    //         path: 'healthcheck',
    //     });
    // }

    // private static async isRenderApiHealthy(params: RenderApiUrlParams) {
    //     try {

    //         const healthcheckUrl = FirebaseBackend.getRenderApiHealthcheckUrl(params);

    //         const response = await fetch(
    //             healthcheckUrl,
    //         );

    //         if (!response.ok) {
    //             return false;
    //         }

    //         const healthcheckResponse = await response.text();

    //         return healthcheckResponse.includes("state: healthy");

    //     } catch (error) {

    //         console.error(error);
    //     }

    //     return false;
    // }

    // private static async getRenderApiUrlFromPipeline(
    //     pipelineType: RenderPipelineType,
    //     userSubscriptionTier: AppUserSubscriptionTier | undefined,
    // ) {
    //     const requiresReferenceImage = doesPipelineTypeRequireReferenceImage(pipelineType);

    //     const renderApiUrlArgs: RenderApiUrlParams = {
    //         type: 'canny',
    //         tier: 'free',
    //         requiresReferenceImage,
    //     };

    //     if (
    //         pipelineType === RenderPipelineType.Canny ||
    //         pipelineType === RenderPipelineType.Default ||
    //         pipelineType === RenderPipelineType.RefCanny ||
    //         pipelineType === RenderPipelineType.RefDefault
    //     ) {
    //         renderApiUrlArgs.type = 'canny';
    //     } else if (
    //         pipelineType === RenderPipelineType.Hed ||
    //         pipelineType === RenderPipelineType.RefHed ||
    //         pipelineType === RenderPipelineType.ColorHedInpaint ||
    //         pipelineType === RenderPipelineType.RefColorHedInpaint
    //     ) {
    //         renderApiUrlArgs.type = 'hed';
    //     }

    //     if (
    //         userSubscriptionTier === AppUserSubscriptionTier.Pro ||
    //         userSubscriptionTier === AppUserSubscriptionTier.Enterprise
    //     ) {
    //         renderApiUrlArgs.tier = 'pro';
    //     } else {
    //         renderApiUrlArgs.tier = 'free';
    //     }

    //     const renderApiUrl = FirebaseBackend.getRenderApiUrl(renderApiUrlArgs);

    //     debugLog(`Render api url: ${renderApiUrl}`);

    //     return renderApiUrl;
    // }

    async stopRenderJob(args: StopRenderJobArgs) {
        return this.renderStateManager.stopRenderJob(args);
    }

    private async waitUntilRenderJobFinish({
        userId,
        jobId,
    }: {
        userId: string,
        jobId: string,
    }) {
        return new Promise<string[]>((resolve) => {
            const unsubscribe = this.renderStateManager.onRenderJobUpdate({
                userId,
                jobId,
                callback: (renderJob) => {
                    if (!renderJob) {
                        unsubscribe();

                        return resolve([]);
                    }

                    const status = renderJob?.status;

                    if (status === UserRenderJobStatus.Active) {
                        return;
                    } else if (status === UserRenderJobStatus.Succeeded) {
                        Promise.all(renderJob.gcp_storage_paths?.map(async (storagePath) => {
                            if (!storagePath) {
                                return;
                            }

                            return this.getDownloadUrlFromStoragePath(storagePath);
                        }) ?? []).then((imageUrls) => {
                            resolve(imageUrls.filter(Boolean) as string[]);
                        });
                    }

                    unsubscribe();
                },
            });
        });
    }

    private async startRenderJobDevelopment({
        onReceiveRenderResult,
        renderJobController: renderProcessController,
        renderPipelineArgs,
    }: StartRenderJobArgs) {
        const uid = firebaseAuth?.currentUser?.uid;
        if (!uid) {
            return [];
        }

        const samples = renderPipelineArgs.num_images || 1;

        const resultImages: string[] = [];

        const startJobResponse = await this.renderStateManager.startRenderJob({
            userId: uid,
            numOutputs: samples,
            renderPipelineArgs,
        });

        if (!startJobResponse.ok) {

            displayUiMessage(
                `Error generating images: ${startJobResponse.message}`,
                'error',
            );

            return [];
        }

        await Promise.all(startJobResponse.jobIds.map(async (jobId, i) => {
            try {
                renderProcessController?.jobs.push({
                    jobId,
                    userId: uid,
                });

                const imageUrls = await this.waitUntilRenderJobFinish({
                    jobId,
                    userId: uid,
                });

                await Promise.all(imageUrls.map(async (imageUrl) => {
                    resultImages.push(imageUrl);

                    await onReceiveRenderResult?.({
                        imageUrl,
                        index: resultImages.length - 1,
                    });
                }));

                return imageUrls;
            } catch (error) {
                console.error(error);

                displayUiMessage(
                    samples <= 1 ? 'Cannot generate image' : `Cannot generate image ${i + 1} / ${samples}.`,
                    'error',
                );

                return [];
            }
        }));

        return resultImages;
    }

    async startRenderJob({
        userSubscriptionTier,
        onReceiveRenderResult,
        renderJobController: renderProcessController,
        renderPipelineArgs,
        ...props
    }: StartRenderJobArgs) {
        // Upload the input images to the temporary storage
        try {

            return this.startRenderJobDevelopment({
                userSubscriptionTier,
                onReceiveRenderResult,
                renderJobController: renderProcessController,
                renderPipelineArgs,
                ...props
            });

        } catch (error) {

            console.error(error);

            displayUiMessage(
                "Cannot generate image, please wait a moment before retrying.",
                "error",
            );

        }

        return [];
    }

    async startEraseProductJob({
        renderProcessController,
        userSubscriptionTier,
        onReceiveRenderResult,
        ...args
    }: StartEraseProductJobArgs): Promise<StartEraseProductJobResponse> {
        const {
            firebaseAuth,
        } = getFirebaseApp();
        const uid = firebaseAuth?.currentUser?.uid;
        if (!uid) {
            return {
                erased_image: '',
                mask_image: '',
            };
        }

        try {
            const body = JSON.stringify({
                uid,
                ...args
            });

            const apiKey = (
                userSubscriptionTier === AppUserSubscriptionTier.Pro ||
                userSubscriptionTier === AppUserSubscriptionTier.Enterprise
            ) ? BACKEND_API_KEY_UNLIMITED : BACKEND_API_KEY_STARTER

            const signal = renderProcessController?.signal;

            const response = await fetch(
                process.env.REACT_APP_ERASE_PRODUCT_APP_URL,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Ocp-Apim-Subscription-Key': apiKey,
                        'Api-Key': process.env.REACT_APP_SAM_API_KEY,
                        'UserId': uid,
                    },
                    body,
                    signal,
                }
            );

            if (!response.ok) {
                console.error(await response.text());
                return {
                    erased_image: '',
                    mask_image: '',
                };
            }

            const result = await response.json();

            if (result?.images) {
                const {
                    erased_image,
                    mask_image,
                } = result.images;
                return {
                    erased_image: typeof (erased_image) === 'string' ? erased_image : '',
                    mask_image: typeof (mask_image) === 'string' ? mask_image : '',
                };
            }

        } catch (error) {
            console.error(error);
        }

        return {
            erased_image: '',
            mask_image: '',
        };
    }

    async startRegenerateProductJob({
        renderProcessController,
        userSubscriptionTier,
        onReceiveRenderResult,
        num_images_per_prompt = 1,
        ...args
    }: StartRegenerateProductJobArgs) {
        if (num_images_per_prompt <= 0) {
            return [];
        }

        const {
            firebaseAuth,
        } = getFirebaseApp();
        const uid = firebaseAuth?.currentUser?.uid;
        if (!uid) {
            return [];
        }

        try {
            const body = JSON.stringify({
                uid,
                num_images_per_prompt: 1,
                ...args
            });

            const apiKey = (
                userSubscriptionTier === AppUserSubscriptionTier.Pro ||
                userSubscriptionTier === AppUserSubscriptionTier.Enterprise
            ) ? BACKEND_API_KEY_UNLIMITED : BACKEND_API_KEY_STARTER


            const resultImages: string[] = [];

            const renderPromises: Promise<string[]>[] = [];

            for (let i = 0; i < num_images_per_prompt; ++i) {
                const signal = renderProcessController?.signal;
                renderPromises.push(fetch(
                    process.env.REACT_APP_REPLACE_PRODUCT_API_URL,
                    {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'Ocp-Apim-Subscription-Key': apiKey,
                            'Api-Key': process.env.REACT_APP_SAM_API_KEY,
                        },
                        body,
                        signal,
                    }
                ).then(async (response) => {
                    if (!response.ok) {
                        return [];
                    }

                    const result = await response.json();

                    const images = result?.images;
                    if (Array.isArray(images)) {

                        const imageUrls: string[] = images.filter(v => typeof (v) === 'string');

                        await Promise.all(imageUrls.map(async (imageUrl) => {
                            resultImages.push(imageUrl);
                            await onReceiveRenderResult?.({
                                imageUrl,
                                index: resultImages.length - 1,
                            });
                        }));

                        return imageUrls;
                    }

                    return [];
                }));
            }

            await Promise.all(renderPromises);

            return resultImages;
        } catch (error) {
            console.error(error);
        }


        return [];
    }

    static getRenderJobRef(
        renderJobId: string,
        renderJobsCollection = RENDER_JOBS_COLLECTION_V1,
    ) {
        return firestore && doc(firestore, `${renderJobsCollection}/${renderJobId}`);
    }

    onRenderJobUpdate(
        renderJobId: string,
        onNext: (snapshot: any) => void,
        onError?: ((error: Error) => void) | undefined,
        onCompletion?: (() => void) | undefined
    ) {
        if (!renderJobId) {
            return noop;
        }
        const renderJobRef = FirebaseBackend.getRenderJobRef(renderJobId);
        if (renderJobRef) {
            return onSnapshot(
                renderJobRef,
                (snapshot) => {
                    const renderJob = snapshot?.data?.();
                    if (renderJob) {
                        onNext(renderJob);
                    } else {
                        onError?.(new Error(`Render job ${renderJobId} data is invalid`));
                    }
                },
                onError,
                onCompletion,
            );
        }
        return noop;
    };

    private static async preprocessImageUrl(imageUrl: string) {

        return (await getDataUrlFromString(imageUrl)) ?? '';

    }

    async removeBackground({
        imageUrl,
        onError,
    }: {
        imageUrl: string,
        onError?: ((error: Error) => void) | undefined,
    }) {
        imageUrl = await FirebaseBackend.preprocessImageUrl(imageUrl);

        if (!imageUrl) {
            onError?.(new Error('Image url is invalid.'));
            return null;
        }

        if (CallExperimentalApi) {
            try {
                const {
                    firebaseAuth,
                } = getFirebaseApp();
                const uid = firebaseAuth.currentUser?.uid;
                if (!uid) {
                    onError?.(new Error('User id is invalid.'));
                    return null;
                }

                const response = await fetch(
                    RemoveBackgroundApiUrl,
                    {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            "uid": uid, // just in case we ever wanna auth this later.
                        },
                        body: JSON.stringify({
                            // id: 0,
                            'image_url': imageUrl,
                        }),
                    }
                );

                if (response.ok) {
                    const blob = await response.blob();

                    if (blob) {
                        console.log('Get blob from response');
                        return await getDataUrlFromBlob(blob) ?? null;
                    }

                }
            } catch (error) {
                console.error(error);
            }
        }

        const {
            callRemoveBackgroundApi,
        } = getFirebaseApp();
        const {
            code,
            message,
            result,
        } = (await callRemoveBackgroundApi({
            imageUrl,
        })).data;
        if (code === 200 && result) {
            return result;
        }
        onError?.(new Error(message));
        return null;
    }

    static getUpscaleImageModelType(
        modelType?: UpscaleModelType,
        userSubscriptionTier?: AppUserSubscriptionTier,
    ) {
        if (!modelType) {

            return UpscaleModelType.Basic;

        } else if (modelType === UpscaleModelType.Basic) {

            return UpscaleModelType.Basic;

        } else if (modelType === UpscaleModelType.Premium) {

            if (userSubscriptionTier === AppUserSubscriptionTier.Free) {
                return UpscaleModelType.Basic;
            }

            return UpscaleModelType.Premium;

        }
        return UpscaleModelType.Basic;
    }

    static getUpscaleApiUrlFromModelType(modelType: UpscaleModelType) {
        return UpscaleApiUrl;
    }

    async cancelUpscaleImageJob({
        uid,
        jobId,
    }: {
        uid?: string,
        jobId: string,
    }) {
        try {

            const {
                firestore,
            } = getFirebaseApp();

            debugLog("leon log: cancelling upscale job", uid, jobId);

            const jobDocRef = doc(firestore, `/userUpscalerState/${uid}/upscaleJobs/${jobId}`);

            await updateDoc(
                jobDocRef,
                {
                    status: UpscaleJobStatus.Stopped,
                },
            );

            debugLog(`Stopped upscaler render job ${jobId} for user ${uid}`);

        } catch (error) {

            console.error(error);

        }
    }

    private async waitUntilUpscaleJobFinishes({
        uid,
        jobId,
    }: {
        uid: string,
        jobId: string,
    }) {
        try {
            const {
                firestore,
            } = getFirebaseApp();

            const jobDocRef = doc(firestore, `/userUpscalerState/${uid}/upscaleJobs/${jobId}`);

            const upscaleJobState = await new Promise<UpscaleJobState | undefined>((resolve) => {
                const unsubscribe = onSnapshot(
                    jobDocRef,
                    (upscaleJobSnapshot) => {
                        if (!upscaleJobSnapshot.exists()) {
                            debugError(`Upscale job ${jobId} does not exist.`);
                            return resolve(undefined); // in case the server deletes the job
                        }

                        const upscaleJobState = upscaleJobSnapshot.data();

                        if (!isUpscaleJobState(upscaleJobState)) {
                            debugError(`Upscale job ${jobId} is invalid. ${JSON.stringify(upscaleJobState)}`);
                            return;
                        }

                        if (upscaleJobState.status === UpscaleJobStatus.Completed ||
                            upscaleJobState.status === UpscaleJobStatus.Stopped) {

                            unsubscribe?.();

                            resolve(upscaleJobState);
                        }
                    },
                );
            });

            return upscaleJobState;

        } catch (error) {
            console.error(error);
        }
    }

    /**
     * Model type should only be `Basic` because we use color-correct-v2 for the premium/creative upscale.
     * @param param0
     * @returns
     */
    async upscaleImage({
        modelType,
        userSubscriptionTier,
        renderProcessController,
        imageUrl,
        inputImageUrl,
        prompt,
        upscale = 2,
        onError,
    }: UpscaleImageArgs) {
        imageUrl = await FirebaseBackend.preprocessImageUrl(imageUrl);

        if (!imageUrl) {
            onError?.(new Error('Image url is invalid.'));
            return null;
        }

        if (!userSubscriptionTier || userSubscriptionTier === AppUserSubscriptionTier.Free) {
            onError?.(new Error("Upscale is only available to Pro users."));
            return null;
        }

        let uid: string | undefined;
        let jobId: string | undefined;

        try {
            const {
                firebaseAuth,
            } = getFirebaseApp();

            uid = firebaseAuth.currentUser?.uid;

            if (!uid) {
                onError?.(new Error("User id is invalid."));
                return null;
            }

            // jobId = generateUUID();

            modelType = FirebaseBackend.getUpscaleImageModelType(
                modelType,
                userSubscriptionTier,
            );

            const input: {
                uid: string,
                // job_id: string,
                prompt?: string,
                image_url: string,
                scale: number,
                input_image_url?: string,
            } = {
                uid,
                prompt: prompt || undefined,
                // job_id: jobId,
                'image_url': imageUrl,
                'scale': upscale,
            };

            if (inputImageUrl) {
                input['input_image_url'] = inputImageUrl;
            }

            const apiUrl = FirebaseBackend.getUpscaleApiUrlFromModelType(modelType);

            const apiKey = BACKEND_API_KEY_UNLIMITED; // not needed in the new esrgan

            const response = await fetch(
                apiUrl,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Ocp-Apim-Subscription-Key': apiKey,
                        'UserId': uid,
                    },
                    body: JSON.stringify(input),
                }
            );

            if (response.ok) {
                const responseData = await response.json();

                const jobId = responseData.job_id;

                renderProcessController?.setCancelJobCallback(async () => {
                    if (uid && jobId) {
                        await this.cancelUpscaleImageJob({ uid, jobId });
                    }
                });

                const upscaleJobState = await this.waitUntilUpscaleJobFinishes({
                    uid,
                    jobId,
                });

                const storagePaths = upscaleJobState?.storage_paths ?? [];

                const resultUrls = await Promise.all(
                    storagePaths.map((storagePath) => this.getDownloadUrlFromStoragePath(storagePath))
                );

                return resultUrls.filter(Boolean)[0];
            } else {
                const message = (await response.json())?.message || "Unknown error when upscaling the image.";
                console.error(message);
                onError?.(new Error(message));
            }

        } catch (error) {
            console.error(error);

            if (uid && jobId) {
                await this.cancelUpscaleImageJob({ uid, jobId });
            }

        }

        return null;
    }

    onAuthStateChanged(
        observer: (user: User | null) => void,
        onError?: (error: any) => void,
    ) {
        const { firebaseAuth } = getFirebaseApp();
        return onAuthStateChanged(
            firebaseAuth,
            observer,
            onError,
        );
    }

    static getUserActivitiesCollectionRef() {
        const { firestore } = getFirebaseApp();
        return collection(firestore, USER_ACTIVITIES);
    }

    static getUserActivitiesDocRef(uid: string) {
        return doc(
            FirebaseBackend.getUserActivitiesCollectionRef(),
            uid,
        );
    }

    private async updateUserActivitiesLastLoginTimestamp({
        uid,
    }: {
        uid: string,
    }) {
        try {
            const userActivitiesDoc: UserActivitiesDoc = {
                uid,
                lastLoginTimestamp: serverTimestamp() as Timestamp,
            };

            debugLog(`Start updating user ${uid} activities`);

            await setDoc(
                FirebaseBackend.getUserActivitiesDocRef(uid),
                userActivitiesDoc,
                {
                    merge: true,
                },
            );
        } catch (error) {
            debugError(`Error updating user ${uid} last login timestamp: `, error);
        }
    }

    private async callOnUserLoginApi({
        uid,
    }: {
        uid: string,
    }) {
        const url = OnUserLoginApiUrl;

        if (!url) {
            console.error("Firebase function URL is not defined.");
            return;
        }

        try {
            debugLog("Handle user login");

            // const response = await fetch(url, {
            //     method: "POST",
            //     headers: {
            //         "Content-Type": "application/json",
            //     },
            //     body: JSON.stringify({ uid }),
            // });

            // if (!response.ok) {
            //     throw new Error(`Error: ${response.status} ${response.statusText}`);
            // }

            debugLog("User login handled successfully.");
        } catch (error) {
            console.error("Failed to handle user login:", error);
        }
    }

    async onUserLogin({
        uid,
    }: {
        uid?: string,
    }) {
        const { firebaseAuth } = getFirebaseApp();

        uid = uid || firebaseAuth.currentUser?.uid;

        if (!uid) {
            debugError("Cannot handle user login because no valid user id is provided.");
            return;
        }

        await Promise.all([
            this.updateUserActivitiesLastLoginTimestamp({
                uid,
            }),
            this.callOnUserLoginApi({
                uid,
            }),
        ]);
    }

    private static async signInWithRedirect(firebaseAuth: Auth, provider: AuthProvider) {
        try {
            await signInWithRedirect(firebaseAuth, provider);
            // After returning from the redirect when your app initializes you can obtain the result
            const result = await getRedirectResult(firebaseAuth);
            if (result) {
                console.log(`Signed in as user ${result.user.displayName} with redirect`);
                return result.user;
            }
        } catch (error) {
            console.error(error);
        }
        return null;
    }

    private static async signInWithPopup(firebaseAuth: Auth, provider: AuthProvider) {
        try {
            const user = await signInWithPopup(firebaseAuth, provider);
            console.log(`Signed in as user ${user.user.displayName}`);
            return user.user;
        } catch (error) {
            console.error(error);
        }
        return null;
    }

    async signInWithCustomToken(token: string) {
        const { firebaseAuth } = getFirebaseApp();
        return signInWithCustomToken(firebaseAuth, token)
            .then((userCredential) => {
                return userCredential.user;
            });
    }

    async continueWithGoogle() {
        const { firebaseAuth } = getFirebaseApp();
        if (!firebaseAuth) {
            console.error('Auth provider is not initialized.');
            return null;
        }

        let user = await FirebaseBackend.signInWithPopup(firebaseAuth, googleProvider);

        if (!user) {
            console.error('Cannot sign in with popup.');
            user = await FirebaseBackend.signInWithRedirect(firebaseAuth, googleProvider);
        }

        return user;
    };

    sendSignInLinkToEmail(email: string) {
        const { firebaseAuth } = getFirebaseApp();
        if (firebaseAuth) {
            const currUrl = window?.location?.origin || DEFAULT_ORIGIN_URL;
            const redirectUrl = `${currUrl}/${EMAIL_LINK_SIGNIN}`;
            return sendSignInLinkToEmail(firebaseAuth, email, {
                url: redirectUrl,
                handleCodeInApp: true,
            }).then(() => {
                window?.localStorage.setItem(EMAIL_STORAGE_KEY, email);
            });
        }
        return Promise.reject('Auth provider is not initialized.');
    }

    signInWithEmailLink(email: string | null) {
        // Get the email if available. This should be available if the user completes
        // the flow on the same device where they started it.
        email = email || window.localStorage.getItem(EMAIL_STORAGE_KEY);
        if (!email) {
            return Promise.reject('Email is invalid');
        }
        const { firebaseAuth: auth } = getFirebaseApp();
        if (auth) {
            if (isSignInWithEmailLink(auth, window.location.href)) {
                // Additional state parameters can also be passed via URL.
                // This can be used to continue the user's intended action before triggering
                // the sign-in operation.
                // The client SDK will parse the code from the link for you.
                return signInWithEmailLink(auth, email, window.location.href)
                    .then((result) => {
                        // Clear email from storage.
                        window.localStorage.removeItem(EMAIL_STORAGE_KEY);
                        // You can access the new user via result.user
                        // Additional user info profile not available via:
                        // result.additionalUserInfo.profile == null
                        // You can check if the user is new or existing:
                        // result.additionalUserInfo.isNewUser
                    });
            }
        }
        return Promise.reject('Auth provider is not initialized.');
    }

    signInWithEmailAndPassword(email: string, password: string) {
        const { firebaseAuth: auth } = getFirebaseApp();
        if (auth) {
            return setPersistence(auth, browserLocalPersistence)
                .then(() => {
                    return signInWithEmailAndPassword(auth, email, password);
                });
        }
        return Promise.reject('Auth provider is not initialized.');
    }

    private static async getPublicUserIdFromCustomClaims({
        user,
        forceRefresh = false,
    }: {
        user: User,
        forceRefresh?: boolean,
    }) {
        try {
            const idTokenResult = await user.getIdTokenResult(forceRefresh);

            const claims = idTokenResult?.claims;

            console.log(claims);

            if (isCustomUserClaims(claims) && claims.publicUserId != null) {
                return claims.publicUserId;
            }
        } catch (error) {
            console.error(error);
        }
        return undefined;
    }

    private static async refreshPublicUserClaims() {
        try {
            // Refresh the id token after confirming that the user as logged-in
            const {firebaseAuth} = getFirebaseApp();

            const idTokenResult = await firebaseAuth.currentUser?.getIdTokenResult(true);

            debugLog("Refreshed user id token and custom-claim: ", idTokenResult?.claims);
        } catch (error) {
            debugError('Error refresh public user claims:\n', error);
        }
    }

    private static async getPublicUserIdFromFirebaseFunctions() {
        try {
            const {
                getPublicUserId,
            } = getFirebaseApp();

            const response = await getPublicUserId();

            const publicUserId = response?.data?.publicUserId;

            await FirebaseBackend.refreshPublicUserClaims();

            return publicUserId;
        } catch (error) {
            console.error(error);
        }
        return undefined;
    }

    async getPublicUserId() {
        try {
            const {
                firebaseAuth,
            } = getFirebaseApp();

            const user = firebaseAuth.currentUser;
            if (!user) {
                debugError("User is not logged in, cannot get public user id.");
                return undefined;
            }

            return (
                await FirebaseBackend.getPublicUserIdFromCustomClaims({
                    user,
                    forceRefresh: false,
                })
            ) || (
                    await FirebaseBackend.getPublicUserIdFromCustomClaims({
                        user,
                        forceRefresh: true,
                    })
                ) || (
                    await FirebaseBackend.getPublicUserIdFromFirebaseFunctions()
                );
        } catch (error) {
            console.error(error);
        }
        return undefined;
    }

    static getUserProjectInfoRef(
        firestore: Firestore,
        projectId: string,
    ) {
        return doc(firestore, `${PROJECTS}/${projectId}`);
    }

    getUserProjectInfo(projectId: string) {
        const { firestore } = getFirebaseApp();
        const userProjectInfoRef = FirebaseBackend.getUserProjectInfoRef(firestore, projectId);
        return getDoc(userProjectInfoRef).then((snapshot) => {
            const data = snapshot.data();
            if (isUserProject(data)) {
                return data;
            }
            return undefined;
        });
    }

    static getProjectDocRef(
        projectId: string,
    ) {
        const { firestore } = getFirebaseApp();
        return projectId && firestore && doc(collection(firestore, 'projects'), projectId);
    }

    static getProjectSnapshot(projectId: string) {
        const projectDocRef = FirebaseBackend.getProjectDocRef(projectId);
        if (projectDocRef) {
            return getDoc(projectDocRef)?.then((docSnapshot) => {
                return docSnapshot?.data();
            })?.catch((error) => {
                console.warn(error);
            });
        } else {
            console.log('The project document is invalid');
        }
        return Promise.resolve({});
    }

    static getUserProjectsQuery(
        userId: string,
    ) {
        const { firestore } = getFirebaseApp();
        return query(collection(firestore, 'projects'), where(`roles.${userId}`, 'in', Object.values(AppRoleType)));
    }

    deleteProject(projectId: string) {
        const { deleteUserProject } = getFirebaseApp();
        return deleteUserProject({ projectId }).then((res) => {
            console.log(res);
        }).catch(error => {
            console.error(error);
        });
    }

    getProjectUsers(projectId: string) {
        return this.getUserProjectInfo(projectId).then(project => project?.roles);
    };

    onUserProjectsUpdate(userId: string, callback: (projects: Record<string, UserProject>) => void) {
        return onSnapshot(
            FirebaseBackend.getUserProjectsQuery(userId),
            (projectDocsSnapshot) => {
                const newProjects: Record<string, UserProject> = {};
                projectDocsSnapshot.docs.forEach((projectDocRef) => {
                    const project = projectDocRef.data();

                    if (isUserProject(project)) {
                        newProjects[project.id] = project;
                    } else if (!project.isDeleted) {
                        console.log(project);
                    }
                });
                callback(newProjects);
            }
        );
    }

    getProjectDisplayName(projectId: string) {
        const { firestore } = getFirebaseApp();
        const projectRef = FirebaseBackend.getUserProjectInfoRef(firestore, projectId);
        return getDoc(projectRef).then(snapshot => {
            const data = snapshot.data();
            if (isUserProject(data)) {
                return data.displayName;
            }
            return undefined;
        });
    }

    setProjectDisplayName(projectId: string, displayName: string) {
        const projectDocRef = FirebaseBackend.getProjectDocRef(projectId);
        if (projectDocRef) {
            return updateDoc(
                projectDocRef,
                {
                    displayName,
                },
            ).then(() => {
                console.log(`Finish update project ${projectId} display name to ${displayName}`);
            }).catch((error) => {
                console.warn(error);
            });
        }
        return Promise.resolve();
    }

    setProjectType(projectId: string, projectType: UserProjectType) {
        const projectDocRef = FirebaseBackend.getProjectDocRef(projectId);
        if (!projectDocRef) {
            return Promise.resolve();
        }

        return updateDoc(
            projectDocRef,
            {
                projectType,
            },
        ).then(() => {
            console.log(`Finish update project ${projectId} type to ${projectType}`);
        }).catch((error) => {
            console.warn(error);
        });
    }

    async getProjectType(projectId: string) {
        try {
            const projectDocRef = FirebaseBackend.getProjectDocRef(projectId);
            if (!projectDocRef) {
                return;
            }

            const projectDocSnapshot = await getDoc(projectDocRef);

            if (!projectDocSnapshot.exists()) {
                return;
            }

            const projectDoc = projectDocSnapshot.data();

            if (!isUserProject(projectDoc)) {
                return;
            }
            return projectDoc.projectType;
        } catch (error) {
            console.error(error);
        }
    }

    setProjectThumbnail(projectId: string, thumbnail: string) {
        const projectDocRef = FirebaseBackend.getProjectDocRef(projectId);
        if (projectDocRef) {
            console.log(`Set project ${projectId} thumbnail to ${thumbnail}`);
            return updateDoc(
                projectDocRef,
                {
                    thumbnail,
                }
            );
        }
        return Promise.resolve();
    }

    createNewProject(displayName?: string | null) {
        if (typeof (createNewProject) === 'function') {
            return createNewProject({ displayName });
        }
        return Promise.resolve({ data: null });
    };

    static getUserQuotasRef(userId: string) {
        const { firestore } = getFirebaseApp();
        return userId && firestore && doc(collection(firestore, USER_QUOTAS), userId);
    }

    onUserQuotasUpdate({
        userId,
        onUpdate,
    }: {
        userId?: string,
        onUpdate: (quotas: AppUserQuotas) => void,
    }) {
        const { firebaseAuth } = getFirebaseApp();
        userId = userId || firebaseAuth.currentUser?.uid;
        if (userId) {
            const userQuotasRef = FirebaseBackend.getUserQuotasRef(userId);
            if (userQuotasRef) {
                return onSnapshot(
                    userQuotasRef,
                    (quotasSnapshot) => {
                        const quotas = quotasSnapshot?.data();
                        onUpdate(quotas as AppUserQuotas);
                    },
                    (error) => {
                        console.error(error);
                    },
                );
            }
        }
        return noop;
    }

    static getUserSubscriptionRef(userId: string) {
        const { firestore } = getFirebaseApp();
        return query(
            collection(firestore, `customers/${userId}/subscriptions`),
            where('status', 'in', ['trialing', 'active']),
        );
    }

    async getUserSubscriptions() {
        const { firebaseAuth } = getFirebaseApp();
        const userId = firebaseAuth.currentUser?.uid;
        if (!userId) {
            return [];
        }
        return getDocs(FirebaseBackend.getUserSubscriptionRef(userId)).then((snapshot) => {
            if (!snapshot || snapshot.docs.length <= 0) {
                return [];
            }

            return snapshot.docs.map(doc => doc.data()).filter(isStripeSubscriptionFirestoreDoc);
        });
    }

    private async getActiveUserSubscriptions() {
        const subscriptions = await this.getUserSubscriptions();
        return subscriptions.filter(subscription => isActiveStripeSubscriptionStatus(subscription.status));
    }

    onUserSubscriptionUpdate(callback: (data?: StripeSubscriptionFirestoreDoc[]) => void) {
        const { firebaseAuth } = getFirebaseApp();
        const userId = firebaseAuth.currentUser?.uid;
        if (!userId) {
            return () => { };
        }
        return onSnapshot(
            FirebaseBackend.getUserSubscriptionRef(userId),
            (snapshot) => {
                if (!snapshot) {
                    return callback([]);
                }
                debugLog(`User ${userId} has ${snapshot.docs.length} subscription plans: `, snapshot.docs.map(doc => doc.id));

                const subscriptionDocs = snapshot.docs.map((doc) => doc.data());

                callback(subscriptionDocs as StripeSubscriptionFirestoreDoc[]);
            }
        );
    }

    static getPricingConfigRef(version: PricingConfigVersion) {
        const {firestore} = getFirebaseApp();
        return (
            doc(
                collection(firestore, 'pricingConfigs'),
                version,
            )
        );
    }

    async getPricingConfig(version: PricingConfigVersion) {
        try {
            const snapshot = await getDoc(
                FirebaseBackend.getPricingConfigRef(version),
            );

            if (!snapshot.exists()) {
                return;
            }

            const data = snapshot.data();

            if (isPricingConfig(data)) {
                return data;
            }

            return undefined;
        } catch (error) {
            debugError(`Error retrieving pricing config version ${version}: `, error);
            return undefined;
        }
    }

    onPricingConfigUpdate({
        version,
        callback,
    }: OnPricingConfigUpdateArgs) {
        return onSnapshot(
            FirebaseBackend.getPricingConfigRef(version),
            (snapshot) => {
                if (!snapshot) {
                    return callback(undefined);
                }

                const data = snapshot.data();

                callback(
                    isPricingConfig(data) ? data : undefined,
                );
            },
        );
    }

    getProjectSceneData(projectId: string) {
        const { firestore } = getFirebaseApp();
        const projectRef = doc(collection(firestore, PROJECT_DOCS), projectId);
        return getDoc(
            projectRef,
        ).then((snapshot) => {
            const data = snapshot.data();
            return getScene(data);
        });
    }

    setProjectSceneData = throttle((
        projectId: string,
        scene: IScene,
    ) => {
        scene = removeUndefinedFromObject(scene) as IScene;
        const { firestore } = getFirebaseApp();
        const projectRef = doc(collection(firestore, PROJECT_DOCS), projectId);
        return runTransaction(
            firestore,
            async (transaction) => {
                let isUpdated = false;
                const project = getScene((await transaction.get(projectRef)).data());
                if (project) {
                    if (isBigIntLessThanEqual(project.version, scene.version)) {
                        isUpdated = true;
                        transaction.set(projectRef, scene);
                    } else {
                        isUpdated = false;
                        console.warn(`Project version ${project.version} > ${scene.version}`);
                        return {
                            isUpdated,
                            scene: project,
                        };
                    }
                } else {
                    isUpdated = true;
                    transaction.set(projectRef, scene);
                }
                return {
                    isUpdated,
                }
            },
        );
    }, 1000);

    signOutUser() {
        const { firebaseAuth } = getFirebaseApp();
        return firebaseAuth.signOut();
    }

    static getUserOnboardDataRef(uid: string) {
        const { firestore } = getFirebaseApp();
        return doc(firestore, `userOnboardData/${uid}`);
    }

    async getUserOnboardData(uid?: string) {
        const { firebaseAuth } = getFirebaseApp();
        uid = uid || firebaseAuth.currentUser?.uid;
        if (uid) {
            const userOnboardSnapshot = await getDoc(FirebaseBackend.getUserOnboardDataRef(uid));
            if (userOnboardSnapshot.exists()) {
                return {
                    ...(defaultUserOnboardData),
                    ...(userOnboardSnapshot.data() ?? {}),
                };
            }
        }
        return {
            ...(defaultUserOnboardData),
        };
    }

    async setUserOnboardData({
        uid,
        userOnboardData,
    }: {
        uid?: string,
        userOnboardData?: Partial<UserOnboardData>,
    }) {
        if (!userOnboardData) {
            return;
        }
        const { firebaseAuth } = getFirebaseApp();
        uid = uid || firebaseAuth.currentUser?.uid;
        if (uid) {
            return await setDoc(
                FirebaseBackend.getUserOnboardDataRef(uid),
                userOnboardData,
                {
                    merge: true,
                }
            );
        }
    }

    static getInviteCodeV1Ref(inviteCode: string) {
        const { firestore } = getFirebaseApp();
        return doc(firestore, `inviteCodes/${inviteCode}`);
    }

    static getInviteCodeV2Ref(inviteCode: string) {
        const { firestore } = getFirebaseApp();
        return doc(firestore, `inviteCodesV2/${inviteCode}`);
    }

    private async isInviteCodeV1Valid(inviteCode: string) {
        const docSnapshot = await getDoc(FirebaseBackend.getInviteCodeV1Ref(inviteCode));
        if (docSnapshot.exists()) {
            return {
                exists: true,
                isUsed: Boolean(docSnapshot.data()?.isUsed),
            };
        }
        return {
            exists: false,
            isUsed: true,
        };
    }

    private async isInviteCodeV2Valid(inviteCode: string) {
        const docSnapshot = await getDoc(FirebaseBackend.getInviteCodeV2Ref(inviteCode));
        if (docSnapshot.exists()) {
            const data = docSnapshot.data();
            const maxUseCount = data.maxUseCount;
            const useCount = data.useCount;
            if (!maxUseCount || typeof (useCount) !== 'number' || typeof (maxUseCount) !== 'number') {
                return {
                    exists: false,
                    isUsed: true,
                };
            }
            return {
                exists: true,
                isUsed: useCount >= maxUseCount,
            };
        }
        return {
            exists: false,
            isUsed: true,
        };
    }

    async isInviteCodeValid(inviteCode: string) {
        const {
            exists,
            isUsed,
        } = await this.isInviteCodeV2Valid(inviteCode);
        if (exists) {
            return {
                exists,
                isUsed,
            };
        }
        return await this.isInviteCodeV1Valid(inviteCode);
    }

    static getLocalInviteCodeId(email?: string | null) {
        return email && `invite-code:${email}`;
    }

    async setInviteCodeUsed(
        inviteCode: string,
        email: string,
        version?: 'v1' | 'v2',
    ) {
        const localInviteCodeId = FirebaseBackend.getLocalInviteCodeId(email);
        if (localInviteCodeId) {
            const localInviteCode = window?.localStorage.getItem(localInviteCodeId);
            if (localInviteCode) {
                return {
                    data: {
                        code: 200,
                        message: `Email ${email} already has a valid invite code`,
                    }
                }
            } else {
                window?.localStorage.setItem(localInviteCodeId, inviteCode);
            }
        }
        const { setInviteCodeUsed } = getFirebaseApp();
        console.log(`Set invite code ${inviteCode} used by email ${email}`);
        return setInviteCodeUsed({ inviteCode, email, version });
    }

    async doesUserHaveInviteCode() {
        const { firebaseAuth, doesUserHaveInviteCode } = getFirebaseApp();
        const uid = firebaseAuth?.currentUser?.uid;
        if (!uid) {
            return {
                data: {
                    code: 400,
                    message: 'User is not logged in',
                }
            };
        }
        // Find the invite code locally
        const email = firebaseAuth.currentUser.email;
        const localInviteCodeId = FirebaseBackend.getLocalInviteCodeId(email);
        if (localInviteCodeId) {
            const inviteCode = window?.localStorage.getItem(localInviteCodeId);
            if (inviteCode) {
                return {
                    data: {
                        code: 200,
                        inviteCode,
                        message: 'ok',
                    },
                }
            }
        }

        return doesUserHaveInviteCode().then((response) => {
            if (localInviteCodeId && response.data.inviteCode) {
                window?.localStorage.setItem(localInviteCodeId, response.data.inviteCode);
            }
            return response;
        });
    }

    async getSampleProjectScene({
        storagePath,
    }: {
        storagePath: string,
    }): Promise<SampleProjectScene | null> {
        const { firebaseStorage } = getFirebaseApp();
        const blobRef = storageRef(firebaseStorage, storagePath);
        const url = await getDownloadURL(blobRef);
        const sampleProjectScene = await (await fetch(url)).json();
        // Add sample project json doc
        if (isSampleProjectScene(sampleProjectScene)) {
            return sampleProjectScene;
        }
        console.log('Sample scene is not a valid project scene');
        return null;
    }

    static getPastGenerationsRef(uid: string) {
        const { firestore } = getFirebaseApp();
        return collection(firestore, `userGenerations/${uid}/generations`);
    }

    static getPastGenerationDocRef(uid: string, docId: string) {
        const { firestore } = getFirebaseApp();
        return doc(firestore, `userGenerations/${uid}/generations/${docId}`);
    }

    static getPastGenerationsQuery(
        uid: string,
        limitSize = 10,
        earliestTimeModified: Timestamp,
        lastVisible?: DocumentSnapshot<unknown>,
    ) {


        const pastGenerationsRef = FirebaseBackend.getPastGenerationsRef(uid);
        let baseQuery = query(pastGenerationsRef, orderBy('timeModified', 'desc'), limit(limitSize));

        if (lastVisible && earliestTimeModified) {
            return query(
                baseQuery,
                startAfter(lastVisible),
                where('timeModified', '>', earliestTimeModified),
            );
        } else if (lastVisible) {
            return query(
                baseQuery,
                startAfter(lastVisible),
            );
        } else if (earliestTimeModified) {
            return query(
                baseQuery,
                where('timeModified', '>', earliestTimeModified),
            );
        }

        return baseQuery;
    }
    async getPastGeneration({
        generationId,
    }: {
        generationId: string,
    }) {
        const {
            firebaseAuth,
        } = getFirebaseApp();
        const uid = firebaseAuth.currentUser?.uid;
        if (!uid) {
            return;
        }
        const snapshot = await getDoc(FirebaseBackend.getPastGenerationDocRef(uid, generationId));
        return snapshot.data();
    }

    async getPastGenerations({
        batchSize,
        earliestTimeModified,
    }: {
        batchSize: number,
        earliestTimeModified: Timestamp,
    }) {
        const {
            firebaseAuth,
        } = getFirebaseApp();
        const uid = firebaseAuth.currentUser?.uid;
        if (uid) {
            const docSnapshots = await getDocs(FirebaseBackend.getPastGenerationsQuery(
                uid,
                batchSize,
                earliestTimeModified,
            ));

            return docSnapshots.docs.map(doc => doc.data());
        }
    }

    async addPastGeneration({
        pastGeneration,
    }: {
        pastGeneration: PastGeneration,
    }) {
        try {

            if (pastGeneration && typeof (pastGeneration.id) === 'string') {
                const {
                    firebaseAuth,
                } = getFirebaseApp();
                const uid = firebaseAuth.currentUser?.uid;
                if (uid) {
                    const docId = pastGeneration.id;
                    const pastGenerationDoc = {
                        ...pastGeneration,
                        timeModified: serverTimestamp(),
                    };
                    await setDoc(
                        FirebaseBackend.getPastGenerationDocRef(uid, docId),
                        removeUndefinedFromObject(pastGenerationDoc),
                        {
                            merge: true,
                        }
                    )
                    return {
                        isUpdated: true,
                        message: `Updated document ${docId}`,
                    };
                }
                return {
                    isUpdated: false,
                    message: 'User is not logged in.',
                }
            }
            return {
                isUpdated: false,
                message: `Past generation with id ${pastGeneration?.id} is invalid`,
            };

        } catch (error) {
            return {
                isUpdated: false,
                message: (error as any)?.message || error,
            }
        }
    }

    getPastGenerationGenerator() {
        return this.pastGenerationsGenerator
    }

    initializePastGenerator({
        batchSize,
        earliestTimeModified,
    }: {
        batchSize: number,
        earliestTimeModified: Timestamp,
    }) {
        const {
            firebaseAuth,
        } = getFirebaseApp();
        const uid = firebaseAuth.currentUser?.uid;
        if (uid) {
            let isFinished = false;
            let lastVisible: DocumentSnapshot<unknown> | undefined = undefined;
            this.pastGenerationsGenerator = {
                batchSize,
                getNextBatch: async () => {
                    if (isFinished) {
                        return [];
                    }

                    const docSnapshots = await getDocs(FirebaseBackend.getPastGenerationsQuery(
                        uid,
                        batchSize,
                        earliestTimeModified,
                        lastVisible,
                    ));

                    lastVisible = docSnapshots.docs[docSnapshots.docs.length - 1];

                    if (docSnapshots.empty) {
                        isFinished = true;
                    }

                    return docSnapshots.docs.map(doc => doc.data());
                }
            }
        }
    }

    async createSubscriptionsPortalLink(props: CreateSubscriptionsPortalLinkArgs) {
        const response = await createPortalLink?.(props);
        return response?.data.url;
    }

    private async filterUserSubscriptionLineItems({
        uid,
        line_items = [],
    }: {
        uid: string,
        line_items?: StripeCheckoutSessionLineItem[],
    }) {
        try {
            if (!line_items || line_items.length <= 0) {
                return [];
            }

            const userSubscriptions = await this.getActiveUserSubscriptions();

            return line_items.filter((item) => {
                const price = item.price;

                const alreadyHasPrice = userSubscriptions
                    .find((subscription) => subscription.items.find(item => item?.price?.id === price));

                return !alreadyHasPrice;
            });
        } catch (error) {
            debugError(`Error checking user ${uid} subscription:\n`, error);
            return [];
        }
    }

    async createCheckoutSession(props: CreateCheckoutSessionParams) {
        const { firebaseAuth, firestore } = getFirebaseApp();

        if (!firebaseAuth || !firestore) {
            console.log('Firebase is invalid');
            return {
                message: 'Backend is not initialized yet, please refresh the page.',
            };
        }

        const uid = firebaseAuth.currentUser?.uid;

        if (!uid) {
            console.log('uid is invalid');
            return {
                message: 'Please login before subscribing to the plan.',
            }
        }

        const line_items = await this.filterUserSubscriptionLineItems({
            uid,
            line_items: props.line_items ?? [],
        });

        if (line_items.length <= 0) {
            return {
                message: 'You are already subscribed to the plan.'
            };
        }

        // Check if the user subscription already has the target line item

        const currentUrl = window.location.href;
        const cancel_url = props.cancel_url || currentUrl;
        const success_url = props.success_url || currentUrl;
        const mode = props.mode ?? 'subscription';

        const checkoutSession: StripeCheckoutSessionData = {
            ...props,
            allow_promotion_codes: true,
            line_items,
            success_url,
            cancel_url,
            mode,
        };

        // if (process.env.NODE_ENV === 'development') {
        //     debugLog('Create checkout session with params:\n', checkoutSession);
        //     return {
        //         message: "Dev",
        //     };
        // }

        const collectionRef = collection(firestore, `customers/${uid}/checkout_sessions`);

        const docRef = await addDoc(collectionRef, removeUndefinedFromObject(checkoutSession));

        // Wait for the CheckoutSession to get attached by the extension
        const url = await new Promise<string | undefined>((resolve, reject) => {
            const unsubscribe = onSnapshot(docRef, (snap) => {
                const data = snap.data();
                if (data) {
                    const { error, url } = data;
                    if (error) {
                        return reject(error);
                    }
                    if (url) {
                        console.log(`Unsubscribe to snapshot after getting url ${url}`);
                        unsubscribe();
                        return resolve(url as string);
                    }
                } else {
                    console.log('Snapshot data is invalid');
                    return resolve(undefined);
                }
            });
        });

        return {
            url,
        };
    }



    async createOneTimePaymentCheckoutSession(args: CreateOneTimePaymentCheckoutSessionArgs) {
        try {
            const {
                createOneTimePaymentCheckoutSession,
            } = getFirebaseApp();

            const response = await createOneTimePaymentCheckoutSession(args);

            return response.data;
        } catch (error) {
            debugError("Error creating one-time payment checkout session: ", error);
            return {
                ok: false,
                message: "Error creating one-time payment checkout session",
            } as {ok: false, message: string};
        }
    }

    getCustomModelDataset(modelId: string) {
        return this.customModelManager.getCustomModelDataset(modelId);
    }

    onCustomModelDatasetUpdate(
        modelId: string,
        callback: (dataset?: CustomModelDataset) => void,
    ) {
        return this.customModelManager.onCustomModelDatasetUpdate(
            modelId,
            callback,
        );
    }

    setCustomModelDataItem({
        modelId,
        dataId,
        data,
    }: {
        modelId: string,
        dataId: string,
        data: Partial<CustomModelDatasetItem>,
    }) {
        return this.customModelManager.setCustomModelDataItem({
            modelId,
            dataId,
            data,
        });
    }

    updateCustomModelDataItem({
        modelId,
        dataId,
        data,
    }: {
        modelId: string,
        dataId: string,
        data: Partial<CustomModelDatasetItem>,
    }) {
        return this.customModelManager.updateCustomModelDataItem({
            modelId,
            dataId,
            data,
        });
    }

    deleteCustomModelDataItem({
        modelId,
        dataId,
    }: {
        modelId: string,
        dataId: string,
    }) {
        return this.customModelManager.deleteCustomModelDataItem({
            modelId,
            dataId,
        });
    }

    uploadCustomModelDataItemToStorage(args: UploadCustomModelDataItemToStorageArgs) {
        return this.customModelManager.uploadCustomModelDataItemToStorage(args);
    }

    onCustomModelDatasetItemUpdate(
        modelId: string,
        dataId: string,
        callback: (dataItem?: CustomModelDatasetItem) => void,
    ) {
        return this.customModelManager.onCustomModelDatasetItemUpdate(
            modelId,
            dataId,
            callback,
        );
    }

    onUserCustomModelsUpdate(
        publicUserId: PublicUserId,
        callback: (customModels: Record<string, CustomModelInfo>) => void,
    ) {
        return this.customModelManager.onUserCustomModelsUpdate(
            publicUserId,
            callback,
        );
    }

    getPublicCustomModels() {
        return this.customModelManager.getPublicCustomModels();
    }

    createCustomModel(args: CreateCustomModelArgs) {
        return this.customModelManager.createCustomModel(args);
    }

    deleteCustomModel(args: DeleteCustomModelArgs) {
        return this.customModelManager.deleteCustomModel(args);
    }

    startCustomModelTraining(args: StartCustomModelTrainingArgs) {
        return this.customModelManager.startCustomModelTraining(args);
    }

    getCustomModelTraining(args: GetCustomModelTrainingArgs) {
        return this.customModelManager.getCustomModelTraining(args);
    }

    getCustomModelTrainings(args: GetCustomModelTrainingsArgs) {
        return this.customModelManager.getCustomModelTrainings(args);
    }

    onCustomModelTrainingUpdate(args: OnCustomModelTrainingUpdateArgs) {
        return this.customModelManager.onCustomModelTrainingUpdate(args);
    }

    onCustomModelTrainingCollectionUpdate(args: OnCustomModelTrainingCollectionUpdateArgs) {
        return this.customModelManager.onCustomModelTrainingCollectionUpdate(args);
    }

    stopCustomModelTraining(args: StopCustomModelTrainingArgs) {
        return this.customModelManager.stopCustomModelTraining(args);
    }

    startCustomModelPrediction(args: StartCustomModelPredictionArgs) {
        return this.customModelManager.startCustomModelPrediction(args);
    }

    stopCustomModelPrediction(args: StopCustomModelPredictionArgs) {
        return this.customModelManager.stopCustomModelPrediction(args);
    }

    deleteCustomModelPrediction(args: DeleteCustomModelPredictionArgs) {
        return this.customModelManager.deleteCustomModelPrediction(args);
    }

    getPublicCustomModelPredictions(args: GetPublicCustomModelPredictionsArgs) {
        return this.customModelManager.getPublicCustomModelPredictions(args);
    }

    onCustomModelPredictionUpdate(args: OnCustomModelPredictionUpdateArgs) {
        return this.customModelManager.onCustomModelPredictionUpdate(args);
    }

    onCustomModelPredictionsUpdate(args: OnCustomModelPredictionsUpdateArgs) {
        return this.customModelManager.onCustomModelPredictionsUpdate(args);
    }

    getCustomModelInfo(modelId: string) {
        return this.customModelManager.getCustomModelInfo(modelId);
    }

    updateCustomModelInfo({
        modelId,
        modelInfo,
    }: UpdateCustomModelInfoArgs) {
        return this.customModelManager.updateCustomModelInfo({
            modelId,
            modelInfo,
        });
    }

    sendEmailRedirectLink({
        email,
        name = '',
        noAuthToken = false,
    }: {
        email: string,
        name?: string,
        noAuthToken?: boolean,
    }) {
        if (!sendMobileRedirectEmail) {
            return Promise.resolve({
                data: 'Cannot send email',
            });
        }
        return sendMobileRedirectEmail({
            email,
            name,
            noAuthToken,
        });
    }

    sendEmailLoginLink({
        email,
        name = '',
    }: {
        email: string,
        name?: string,
    }) {
        if (!sendEmailLoginLink) {
            return Promise.resolve({
                data: 'Cannot send email',
            });
        }
        const currUrl = window?.location?.origin || DEFAULT_ORIGIN_URL;
        const redirectUrl = new URL(`${currUrl}/${EMAIL_LINK_SIGNIN}`);
        redirectUrl.searchParams.append('email', email);
        return sendEmailLoginLink({
            email,
            name,
            redirectUrl: redirectUrl.toString(),
        }).then((result) => {
            window?.localStorage.setItem(EMAIL_STORAGE_KEY, email);
            return result;
        });
    }

    private getBackendSubscriptionId() {
        const {
            userQuotas,
        } = editorContextStore.getState();

        const userSubscriptionTier = userQuotas?.tier;

        if (!userSubscriptionTier) {
            return "";
        }

        if (userSubscriptionTier === AppUserSubscriptionTier.Free) {
            return BACKEND_API_KEY_STARTER;
        }

        if (userSubscriptionTier === AppUserSubscriptionTier.Pro) {
            return BACKEND_API_KEY_UNLIMITED;
        }

        if (userSubscriptionTier === AppUserSubscriptionTier.Enterprise) {
            return BACKEND_API_KEY_UNLIMITED;
        }

        return "";
    }

    async warpParsedClothImage({
        clothImageUrl,
        parsedClothMaskImageUrl,
        personImageId,
        epsilon = 0.002,
        leftColor = TryOnClothMaskTypeColorHex['left-sleeve'],
        middleColor = TryOnClothMaskTypeColorHex['torso'],
        rightColor = TryOnClothMaskTypeColorHex['right-sleeve'],
    }: {
        clothImageUrl: string,
        parsedClothMaskImageUrl: string,
        personImageId?: string,
        epsilon?: number,
        leftColor?: string,
        middleColor?: string,
        rightColor?: string,
    }) {
        try {
            const body = JSON.stringify({
                'cloth_image_url': clothImageUrl,
                'parsed_cloth_mask_image_url': parsedClothMaskImageUrl,
                'person_image_id': personImageId,
                'epsilon': epsilon,
                'left_color': leftColor,
                'middle_color': middleColor,
                'right_color': rightColor,
            });

            const response = await fetch(
                WarpClothApiUrl,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Ocp-Apim-Subscription-Key': this.getBackendSubscriptionId(),
                        'Api-Key': process.env.REACT_APP_TRYON_SECRET_KEY,
                    },
                    body,
                }
            );

            if (response.ok) {

                const result = await response.json();

                if (isWarpParsedImageResult(result)) {

                    return result;

                }

            } else {
                const message = await response.json();

                console.warn(message);
            }

        } catch (error) {

            console.error(error);

        }
    }

    async parseClothImage({
        imageUrl,
        personImageId,
        useClothAlpha = false,
        epsilon = 0.002,
        leftColor = TryOnClothMaskTypeColorHex['left-sleeve'],
        middleColor = TryOnClothMaskTypeColorHex['torso'],
        rightColor = TryOnClothMaskTypeColorHex['right-sleeve'],
    }: {
        imageUrl: string,
        personImageId?: string,
        useClothAlpha?: boolean,
        epsilon?: number,
        leftColor?: string,
        middleColor?: string,
        rightColor?: string,
    }) {
        try {

            const response = await fetch(
                ParseClothApiUrl,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Ocp-Apim-Subscription-Key': this.getBackendSubscriptionId(),
                        'Api-Key': process.env.REACT_APP_TRYON_SECRET_KEY,
                    },
                    body: JSON.stringify({
                        'cloth_image_url': imageUrl,
                        'use_cloth_alpha': useClothAlpha,
                        'epsilon': epsilon,
                        'left_color': leftColor,
                        'middle_color': middleColor,
                        'right_color': rightColor,
                        'person_image_id': personImageId,
                    }),
                }
            );

            if (response.ok) {

                const result = await response.json();

                if (isParseClothImageResult(result)) {

                    return result;

                }

            } else {
                const message = await response.json();

                console.warn(message);
            }

        } catch (error) {

            console.error(error);

        }
    }

    async renderClothImages(renderArgs: RenderClothImageArgs) {

        const response = await fetch(
            RenderClothApiUrl,
            {
                method: "POST",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Ocp-Apim-Subscription-Key': this.getBackendSubscriptionId(),
                    'Api-Key': process.env.REACT_APP_TRYON_SECRET_KEY,
                },
                body: JSON.stringify(renderArgs),
            }
        );


        const result = await response.json();

        if (response.status !== 200 || !isRenderClothImageResult(result)) {

            const message = result?.message;

            console.warn(message || "Result is invalid");

            return;
        }

        return result;
    }

    async getImageCaption({
        imageUrl,
        prompt = "a photo of",
        extractSubject = true,
    }: ImageCaptionArgs) {
        if (!imageUrl) {
            return;
        }

        // const image = imageUrl;

        const {
            firebaseAuth,
        } = getFirebaseApp();
        const uid = firebaseAuth.currentUser?.uid;

        if (!uid) {
            return;
        }

        const response = await fetch(
            CaptionApiUrl,
            {
                method: "POST",
                headers: {
                    // 'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    // 'Ocp-Apim-Subscription-Key': this.getBackendSubscriptionId(),
                    // 'Api-Key': process.env.REACT_APP_CAPTION_SECRET_KEY,
                    'UserId': uid,
                },
                body: JSON.stringify({
                    image: imageUrl,
                    // prompt,
                    // "extract_subject": extractSubject,
                }),
            }
        );

        const result = await response.json();

        if (response.status !== 200 || !result?.caption || typeof (result.caption) !== 'string') {

            console.warn(result?.message || "Result is invalid");

            return;
        }

        return result.caption;
    }

    static TryOnModelPreviewCollectionPath = "assets/tryOn/modelPreviewImages2";

    static getTryOnModelPreviewsQuery(
        limitSize = 10,
        lastVisible?: DocumentSnapshot<unknown>,
    ) {
        const { firestore } = getFirebaseApp();
        const posePreviewsRef = collection(firestore, FirebaseBackend.TryOnModelPreviewCollectionPath);
        if (lastVisible) {
            return query(
                posePreviewsRef,
                orderBy('imageId', 'desc'),
                startAfter(lastVisible),
                limit(limitSize),
            );
        }
        return query(
            posePreviewsRef,
            orderBy('imageId', 'desc'),
            limit(limitSize),
        );
    }

    static getTryOnModelPreviews = throttle((
        limitSize: number = 10,
        lastVisible?: DocumentSnapshot<unknown>,
    ) => {
        return getDocs(FirebaseBackend.getTryOnModelPreviewsQuery(
            limitSize,
            lastVisible,
        ));
    }, 150);

    getTryOnModelPreview = throttle(async ({
        imageId,
    }: {
        imageId: string,
    }) => {
        const { firestore } = getFirebaseApp();
        const docSnapshot = await getDoc(
            doc(firestore, `${FirebaseBackend.TryOnModelPreviewCollectionPath}/${imageId}`),
        );
        if (!docSnapshot.exists()) {
            return undefined;
        }
        const data = docSnapshot.data();
        if (isTryOnModelPreviewData(data)) {
            return data;
        }
        return undefined;
    }, 150);

    getTryOnModelPreviewsGenerator({
        batchSize,
    }: {
        batchSize: number,
    }) {
        const {
            firestore,
        } = getFirebaseApp();
        return new TryOnPreviewGenerator({
            firestore,
            batchSize,
        })
    }

    getImageText = throttle(async ({
        imageUrl,
        texts,
    }: {
        imageUrl: string | string[],
        texts: string[],
    }) => {
        try {
            if (!imageUrl) {
                return;
            }

            if (!texts || texts.length <= 1) {
                return texts?.[0];
            }

            if (!imageUrl || imageUrl.length <= 0) {
                return;
            }

            const response = await fetch(
                process.env.REACT_APP_CLIP_API_URL,
                {
                    method: "POST",
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Api-Key': process.env.REACT_APP_CLIP_API_KEY,
                        'Ocp-Apim-Subscription-Key': this.getBackendSubscriptionId(),
                    },
                    body: JSON.stringify({
                        'job_type': 'get_image_text',
                        'text': texts,
                        'images': imageUrl,
                    }),
                }
            );

            if (response.ok) {
                const result = await response.json();

                const imageText = result?.text;

                if (typeof (imageText) === 'string') {
                    return imageText;
                }
            }
        } catch (error) {
            console.error(error);
        }
    }, 150);

    getImageMultiText = throttle(async ({
        imageUrl,
        texts,
    }: {
        imageUrl: string | string[],
        texts: string[][],
    }) => {
        try {
            if (!imageUrl) {
                return;
            }

            if (!texts || texts.length <= 1) {
                return texts?.[0];
            }

            if (!imageUrl || imageUrl.length <= 0) {
                return;
            }

            const response = await fetch(
                process.env.REACT_APP_CLIP_API_URL,
                {
                    method: "POST",
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Api-Key': process.env.REACT_APP_CLIP_API_KEY,
                        'Ocp-Apim-Subscription-Key': this.getBackendSubscriptionId(),
                    },
                    body: JSON.stringify({
                        'job_type': 'get_image_multi_text',
                        'text': texts,
                        'images': imageUrl,
                    }),
                }
            );

            if (response.ok) {
                const result = await response.json();

                const imageText = result?.text;

                if (Array.isArray(imageText) && typeof (imageText[0]) === 'string') {
                    return imageText as string[];
                }
            }
        } catch (error) {
            console.error(error);
        }
    }, 150);

    getMaskImageBoundingBox = throttle(async ({
        imageUrl,
    }: {
        imageUrl: string,
    }) => {
        const response = await fetch(
            `${process.env.REACT_APP_IMAGE_API_URL}`,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Api-Key': process.env.REACT_APP_IMAGE_API_KEY,
                    'Ocp-Apim-Subscription-Key': this.getBackendSubscriptionId(),
                },
                body: JSON.stringify({
                    'image': imageUrl,
                }),
            }
        );

        if (!response.ok) {
            return;
        }

        const result = await response.json();

        if (result.bbox) {

            const {
                left = 0,
                right = 0,
                top = 0,
                bottom = 0,
            } = result.bbox;

            const width = right - left;
            const height = bottom - top;

            return {
                left,
                top,
                width,
                height,
            };
        }
    }, 150);

    static getUserApiDataRef(
        userId: string,
    ) {
        const { firestore } = getFirebaseApp();
        return doc(firestore, `${USER_API_DATA}/${userId}`);
    }

    async getUserApiDataRef(userId: string) {
        const userApiDataRef = FirebaseBackend.getUserApiDataRef(userId);
        const snapshot = await getDoc(userApiDataRef);
        const data = snapshot.data();
        if (!isUserApiDataDoc(data)) {
            return null;
        }
        return data;
    }

    onUserApiDataUpdate(callback: (doc?: UserApiDataDoc) => void) {
        const { firebaseAuth } = getFirebaseApp();
        const userId = firebaseAuth.currentUser?.uid;
        if (!userId) {
            return () => { };
        }
        return onSnapshot(
            FirebaseBackend.getUserApiDataRef(userId),
            (snapshot) => {
                const data = snapshot?.data();
                const userApiDataDoc = isUserApiDataDoc(data) ? data : undefined;
                callback(userApiDataDoc);
            }
        );
    }

    static ApiModelUsageCollectionName: Record<ApiModelType, string> = {
        [ApiModelType.GenerateImage]: 'generateImageUsage',
    }

    static getApiUsagCollectionRef(
        uid: string,
        modelType: ApiModelType,
    ) {
        const { firestore } = getFirebaseApp();
        const usageCollectionName = FirebaseBackend.ApiModelUsageCollectionName[modelType];
        return collection(firestore, `userApiData/${uid}/${usageCollectionName}`);
    }

    static getApiUsageDocRef(
        uid: string,
        docId: string,
        modelType: ApiModelType,
    ) {
        const { firestore } = getFirebaseApp();
        const usageCollectionName = FirebaseBackend.ApiModelUsageCollectionName[modelType];
        return doc(firestore, `userApiData/${uid}/${usageCollectionName}/${docId}`);
    }

    static getApiUsageQuery({
        uid,
        batchSize = 10,
        lastVisible,
        modelType = ApiModelType.GenerateImage,
    }: {
        uid: string,
        batchSize?: number,
        lastVisible?: DocumentSnapshot<unknown>,
        modelType?: ApiModelType,
    }) {
        const apiUsageCollectionRef = FirebaseBackend.getApiUsagCollectionRef(
            uid,
            modelType,
        );
        if (lastVisible) {
            return query(
                apiUsageCollectionRef,
                orderBy('timestamp', 'desc'),
                startAfter(lastVisible),
                limit(batchSize),
            );
        }
        return query(
            apiUsageCollectionRef,
            orderBy('timestamp', 'desc'),
            limit(batchSize),
        );
    }

    getApiUsageGenerator({
        batchSize,
    }: GetApiUsageGeneratorArgs) {
        const {
            firebaseAuth,
        } = getFirebaseApp();
        const uid = firebaseAuth.currentUser?.uid;
        if (uid) {
            let isFinished = false;
            let lastVisible: DocumentSnapshot<unknown> | undefined = undefined;
            return {
                batchSize,
                getNextBatch: async () => {
                    if (isFinished) {
                        return [];
                    }

                    const docSnapshots = await getDocs(FirebaseBackend.getApiUsageQuery({
                        uid,
                        batchSize,
                        lastVisible,
                    }));

                    lastVisible = docSnapshots.docs[docSnapshots.docs.length - 1];

                    if (docSnapshots.empty) {
                        isFinished = true;
                    }

                    return docSnapshots.docs.map(doc => doc.data()).filter(isApiUsageDoc);
                }
            }
        }
        return undefined;
    }

    onApiUsageUpdate({
        onUpdate,
        batchSize,
    }: OnApiUsageUpdateArgs) {
        const {
            firebaseAuth,
        } = getFirebaseApp();
        const uid = firebaseAuth.currentUser?.uid;
        if (uid) {
            const pastGenerationsQuery = FirebaseBackend.getApiUsageQuery({
                uid,
                batchSize,
            });
            return onSnapshot(
                pastGenerationsQuery,
                (snapshot) => {
                    onUpdate(snapshot.docs.map(d => d.data()));
                },
            );
        }
        return () => null;
    }



    static getEmailApiDataDocRef(
        email: string,
    ) {
        const { firestore } = getFirebaseApp();
        return doc(firestore, `emailToApiData/${email}`);
    }


    async getEmailApiData(email: string) {
        const snapshot = await getDoc(FirebaseBackend.getEmailApiDataDocRef(email));
        if (!snapshot.exists()) {
            return {};
        }
        const data = snapshot.data();
        if (isEmailApiDataDoc(data)) {
            return data;
        }
        return {};
    }
    getFirestoreTemplate(id: string) {
        return this.generateTemplateManager.getFirestoreTemplate(id);
    }
    getFirestoreTemplatesByTag(tag: string) {
        return this.generateTemplateManager.getFirestoreTemplatesByTag(tag);
    }
    getTagsInOrder(projectType?: UserProjectType) {
        return this.generateTemplateManager.getTagsInOrder(projectType);
    }
    setFirestoreTemplatesByTagNextBatch(tag: string, pageSize: number) {
        return this.generateTemplateManager.setFirestoreTemplatesByTagNextBatch(tag, pageSize);
    }

    async getDefaultGenerateTemplates() {
        const templates = await this.generateTemplateManager.loadDefaultTemplates();
        return templates || [];
    }

    getGenerateTemplateGenerator(props: { batchSize?: number | undefined; }) {
        return this.generateTemplateManager.getGenerator(props);
    };

    async getUserInvoices(params: StripeListInvoicesParams) {
        try {

            const {
                getUserInvoices,
            } = getFirebaseApp();

            const response = await getUserInvoices(params);

            const data = response.data;

            if (data) {
                return data;
            }


        } catch (error) {
            console.error(error);
        }
        return {
            data: [],
        };
    }

    async downloadAndUploadInvoice(invoiceId: string) {
        try {

            const {
                downloadAndUploadInvoice,
            } = getFirebaseApp();

            const response = await downloadAndUploadInvoice({
                invoiceId,
            });

            return response.data?.filePath;

        } catch (error) {
            console.error(error);
        }

        return "";
    }

    static getRealTimeRenderConfigsRef() {
        const { firestore } = getFirebaseApp();
        return collection(firestore, `realtimeRenderServerConfigs`);
    }

    static async getRealTimeRenderConfigs() {
        return getDocs(FirebaseBackend.getRealTimeRenderConfigsRef()).then((snapshot) => {
            if (!snapshot || snapshot.empty) {
                return [];
            }
            return snapshot.docs.map(
                doc => doc.data()
            );
        });
    }

    async updateStripeSubscription({
        fromProductId,
        toProductId,
        toPriceId,
    }: UpdateStripeSusbcriptionArgs) {
        const {
            updateStripeSusbcription,
        } = getFirebaseApp();

        if (process.env.NODE_ENV === 'development') {
            debugLog('Update stripe subscription:\n', {
                fromProductId,
                toProductId,
                toPriceId,
            });
        }

        const response = await updateStripeSusbcription({
            fromProductId,
            toProductId,
            toPriceId,
        });
        return response.data;
    }

    disconnectRealTimeState = debounce(async (connectionId: string) => {
        try {
            const {
                disconnectRealTimeState,
            } = getFirebaseApp();

            await disconnectRealTimeState({ connectionId });
        } catch (error) {
            console.error(error);
        }
    }, 1000);

    // FeatureFlag functions
    async getUserFeatureFlags(userId: string): Promise<BackendUserFeatureFlags> {
        const { firestore } = getFirebaseApp();

        const userDocRef = doc(firestore, `${USER_FEATUREFLAGS}/${userId}`);
        const userDoc = await getDoc(userDocRef);

        if (userDoc.exists()) {
            const data = userDoc.data() as BackendUserFeatureFlags;
            const cleanFlags = FirebaseBackend.cleanupUserFeatureFlags(
                userId,
                data?.featureFlags || {},
            );
            return FirebaseBackend.updateUserFeatureFlags(userId, {
                featureFlags: cleanFlags,
                isVIP: data?.isVIP || false,
            });
        } else {
            return await FirebaseBackend.createUserFeatureFlags(userId);
        }
    }

    static async updateUserFeatureFlags(userId: string, { featureFlags, isVIP }: BackendUserFeatureFlags) {
        const { firestore } = getFirebaseApp();

        const userDocRef = doc(firestore, `userFeatureFlags/${userId}`);
        await setDoc(userDocRef, {
            isVIP,
            featureFlags,
        });

        return {
            isVIP,
            featureFlags,
        }
    }

    static applyRolloutFunctions(userId: string, featureFlags: Partial<FeatureFlags>, overwrite: boolean = true): Partial<FeatureFlags> {
        const updatedFlags: Partial<FeatureFlags> = { ...featureFlags };

        // use existing rollout functions if overwrite is true or there is no value in the given featureFlags
        for (const key in FeatureFlagRolloutFunctions) {
            const typedKey = key as keyof FeatureFlags;
            const rolloutFn = FeatureFlagRolloutFunctions[typedKey];
            if (rolloutFn && (overwrite || !(typedKey in updatedFlags))) {
                // TODO fix weird type issue when calling FeatureFlagRolloutFunctions
                // @ts-ignore
                updatedFlags[typedKey] = rolloutFn(userId) as FeatureFlags[typeof typedKey];
            }
        }

        return updatedFlags;
    }

    static async createUserFeatureFlags(userId: string): Promise<BackendUserFeatureFlags> {
        let featureFlags: FeatureFlags = { ...DefaultFeatureFlags };

        // overwrite default flags with rollout functions
        featureFlags = FirebaseBackend.applyRolloutFunctions(userId, featureFlags, true) as FeatureFlags;

        const isVIP = false; // TODO load vip userIds from somewhere?

        FirebaseBackend.updateUserFeatureFlags(userId, {
            isVIP,
            featureFlags,
        });

        return {
            featureFlags,
            isVIP,
        };
    }

    static cleanupUserFeatureFlags(userId: string, featureFlags: BackendUserFeatureFlags['featureFlags']): FeatureFlags {
        let cleanedFlags: Partial<FeatureFlags> = {};

        // Use existing values for feature flags that exist in defaults and in the given featureFlags
        for (const key in featureFlags) {
            if (key in DefaultFeatureFlags) {
                const typedKey = key as keyof FeatureFlags;
                // TODO fix same type issue as above
                // @ts-ignore
                cleanedFlags[typedKey] = featureFlags[typedKey]!;
            }
        }

        // Apply rollout functions without overwriting existing values
        cleanedFlags = FirebaseBackend.applyRolloutFunctions(userId, cleanedFlags, false);

        // Fill non-existent feature flags with defaults
        return { ...DefaultFeatureFlags, ...cleanedFlags };

    }

    static getColorCorrectV2RenderState(firestore: Firestore) {
        return collection(firestore, COLOR_CORRECT_V2_RENDER_STATE);
    }

    static getColorCorrectV2UserRenderStateDoc({
        uid,
        firestore,
    }: {
        uid: string,
        firestore: Firestore,
    }) {
        return doc(
            FirebaseBackend.getColorCorrectV2RenderState(firestore),
            uid,
        );
    }

    static getColorCorrectV2UserRenderJobsCollection({
        uid,
        firestore,
    }: {
        uid: string,
        firestore: Firestore,
    }) {
        return collection(
            FirebaseBackend.getColorCorrectV2UserRenderStateDoc({
                uid,
                firestore,
            }),
            COLOR_CORRECT_V2_JOB_STATE,
        );
    }

    static getColorCorrectV2UserRenderJobDoc({
        uid,
        jobId,
        firestore,
    }: {
        uid: string,
        jobId: string,
        firestore: Firestore,
    }) {
        return doc(
            FirebaseBackend.getColorCorrectV2UserRenderJobsCollection({
                uid,
                firestore,
            }),
            jobId,
        );
    }

    onColorCorrectV2Update({
        uid,
        jobId,
        onUpdate,
    }: OnColorCorrectV2UpdateArgs) {
        try {
            const {
                firestore,
            } = getFirebaseApp();

            const colorCorrectionV2UserRenderJobDoc = FirebaseBackend.getColorCorrectV2UserRenderJobDoc({
                uid,
                jobId,
                firestore,
            });

            return onSnapshot(
                colorCorrectionV2UserRenderJobDoc,
                (snapshot) => {
                    if (!snapshot.exists()) {
                        debugError(`User ${uid} color correction render job ${jobId} state doc is invalid.`);
                        onUpdate(undefined);
                        return;
                    }

                    // Find the latest stage with a valid result
                    const renderJobDoc = snapshot.data();

                    if (!isColorCorrectV2RenderJobDoc(renderJobDoc)) {
                        onUpdate(undefined);
                        return;
                    }

                    onUpdate(renderJobDoc);
                }
            );
        } catch (error) {
            console.error(error);
        }
        return noop;
    }


    private static getColorCorrectV2Endpoint({
        gpu_stages_to_run = [],
    }: ColorCorrectV2Args) {
        if (gpu_stages_to_run.includes(ColorCorrectV2Stage.ObjectDrop)) {
            return `${process.env.REACT_APP_COLOR_CORRECT_V2_WITH_OBJECT_DROP_API_URL}/predict`;
        }
        return `${process.env.REACT_APP_COLOR_CORRECT_V2_WITHOUT_OBJECT_DROP_API_URL}/predict`;
    }

    private static getColorCorrectV2StateManagerEndpoint() {
        return process.env.REACT_APP_COLOR_CORRECT_V2_STATE_MANAGER_API_URL;
    }

    async cancelColorCorrectV2Job({
        uid,
        jobId,
    }: CancelColorCorrectV2JobArgs) {
        try {
            // this will get called on controller destroy anyways!!! so the state manager will ahve to guard against this for already-completed jobs.
            const response = await fetch(
                FirebaseBackend.getColorCorrectV2StateManagerEndpoint(),
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        uid: uid,
                        render_job_id: jobId,
                        requires_subscription: false,
                        request_type: "remove-render",
                    }),
                }
            );

            if (!response.ok) {
                debugError(await response.text());
                return {
                    success: false,
                };
            }

            return await response.json();
        } catch (error) {
            console.error(error);
        }

        return {
            success: false,
        };
    }

    async startColorCorrectV2({
        renderProcessController,
        ...args
    }: ColorCorrectV2Args & {
        renderProcessController: RenderProcessController,
    }) {
        try {
            const {
                firebaseAuth,
            } = getFirebaseApp();

            const uid = firebaseAuth.currentUser?.uid;

            if (!uid) {
                return {
                    status: ColorCorrectV2ResponseStatus.Error,
                    message: "The current user is not logged in.",
                };
            }

            const endpoint = FirebaseBackend.getColorCorrectV2Endpoint(args);

            const signal = renderProcessController.signal;

            const jobIdRef: { current?: string } = { current: undefined };

            const onCancel = async () => {
                const jobId = jobIdRef.current;

                if (!jobId) {
                    return;
                }

                await this.cancelColorCorrectV2Job({
                    uid,
                    jobId,
                });
            };

            renderProcessController.setCancelJobCallback(onCancel);

            const response = await fetch(
                endpoint,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'UserId': uid,
                        'Api_Key': 'flair-render-realtime-v1.0',
                    },
                    body: JSON.stringify(args),
                    signal,
                },
            );

            if (!response.ok) {
                return {
                    status: ColorCorrectV2ResponseStatus.Error,
                    message: "Unknown error.",
                };
            }

            const data = await response.json() as ColorCorrectV2Response;

            jobIdRef.current = data.job_id;

            return data;
        } catch (error) {
            console.error(error);
        }

        return {
            status: ColorCorrectV2ResponseStatus.Error,
            message: "Unknown error.",
        };
    }

    async outpaintImage(args: OutpaintImageArgs): Promise<OutpaintImageResponse> {
        const {
            firebaseAuth,
        } = getFirebaseApp();

        const userId = firebaseAuth.currentUser?.uid;

        if (!userId) {
            return {
                ok: false,
                message: "User is not logged in.",
            };
        }

        return BackendOutpaintManager.outpaintImage({
            ...args,
            userId,
        });
    };

    onVideoGenerationDocUpdate(args: OnVideoGenerationDocUpdateArgs) {
        return this.videoManager.onVideoGenerationDocUpdate(args);
    }

    getVideoGenerationDoc(generationId: string) {
        return this.videoManager.getVideoGenerationDoc(generationId);
    }

    generateVideo(request: VideoGenerationRequest) {
        return this.videoManager.generateVideo(request);
    }

    onUserVideoGenerationsUpdate(args: OnUserVideoGenerationsUpdateArgs) {
        return this.videoManager.onUserVideoGenerationsUpdate(args);
    }

    uploadVideoKeyFrameToStorage(args: UploadVideoKeyFrameToStorageArgs) {
        return this.videoManager.uploadVideoKeyFrameToStorage(args);
    }

    generateVideoPrompt(args: GenerateVideoPromptArgs) {
        return this.videoManager.generateVideoPrompt(args);
    }
}