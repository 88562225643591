import { editorContextStore } from "contexts/editor-context";
import { ApiDashboardModelType, getApiDashboardModelUrl } from "core/common/types/api";
import { classNames } from "core/utils/classname-utils";
import React from "react";
import { Link, LinkProps, useNavigate } from 'react-router-dom';



export type ApiDashboardNavigateHeaderPathProps = {
    name: string,
    to?: string,
    modelType?: ApiDashboardModelType,
}

function Slash() {
    return (
        <span className="mx-2 text-zinc-500">/</span>
    )
}

const titleClassName = "text-sm md:text-base 2xl:text-lg transition-colors";

function ApiDashboardNavigateHeaderPath({
    name,
    to,
    modelType,
    isLast = false,
    hideSlash = false,
}: ApiDashboardNavigateHeaderPathProps & {
    isLast?: boolean,
    hideSlash?: boolean,
}) {
    const showSlash = !isLast && !hideSlash;

    to = to || getApiDashboardModelUrl(modelType);

    if (isLast) {
        return (
            <span
                className={classNames(
                    titleClassName,
                    "font-semibold text-zinc-300 hover:text-zinc-200",
                )}
            >
                {name}
            </span>
        );
    }

    return (
        <>
            <Link
                to={to}
                onClick={() => {
                    const {
                        setApiDashboardModelType,
                    } = editorContextStore.getState();

                    setApiDashboardModelType(modelType);
                }}
            >
                <span
                    className={classNames(
                        titleClassName,
                        "font-semibold text-zinc-500 hover:text-zinc-400",
                    )}
                >
                    {name}
                </span>
            </Link>
            {
                showSlash && <Slash />
            }
        </>
    )
}



export type ApiDashboardNavigateHeaderProps = {
    paths: ApiDashboardNavigateHeaderPathProps[],
}

export function ApiDashboardNavigateHeader({
    paths,
}: ApiDashboardNavigateHeaderProps) {
    return (
        <div
            className="p-4 flex flex-row items-center justify-start"
        >
            {paths.map((pathProps, index) => (
                <ApiDashboardNavigateHeaderPath
                    key={index}
                    isLast={index >= paths.length - 1}
                    hideSlash={paths.length <= 1}
                    {...pathProps}
                />
            ))}
        </div>
    )
}