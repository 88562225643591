import { GenerateVideoKeyFrames, GenerateVideoKeyFrameType } from "core/common/types/video";
import { generateUUID } from "core/utils/uuid-utils";
import { DashboardVideosTab } from "./dashboard-video-context";
import { debugError } from "core/utils/print-utilts";

export function openAnimateVideoWindow({
    prompt = "",
    width,
    height,
    imageUrl,
}: {
    prompt?: string,
    width: number,
    height: number,
    imageUrl: string,
}) {
    try {
        const keyFrames: GenerateVideoKeyFrames = [
            {
                id: generateUUID(),
                type: GenerateVideoKeyFrameType.UploadedImage,
                width,
                height,
                src: imageUrl,
                asset: {
                    type: 'image-url',
                    path: imageUrl,
                },
            }
        ]

        const params = new URLSearchParams({
            tab: DashboardVideosTab.Generate,
            prompt,
            keyFrames: JSON.stringify(keyFrames),
        });

        // Construct the full URL
        const url = `${window.location.origin}/videos?${params.toString()}`;

        // Open the new tab
        window.open(url, '_blank');
    } catch (error) {
        debugError(`Error opening animate image window with image ${imageUrl}: `, error);
    }
}