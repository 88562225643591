import React from 'react';
import { DropdownClassName, PrimaryButtonClassName, PrimaryButtonClassNameDisabled } from 'components/constants/class-names';
import { classNames } from 'core/utils/classname-utils';
import { ApiInputType } from 'core/common/types/api';
import { SliderInput } from 'components/dashboard/api/slider-input';
import { CustomModelTrainingInputContextType, getDurationSecondsFromTrainingSteps, useCustomModelTraininingInput } from './custom-model-training-context';
import { editorContextStore } from 'contexts/editor-context';
import { CustomModelTrainingBackendType, CustomModelTrainingInput, CustomModelTrainingInputFalSpecific, CustomModelTrainingInputReplicateSpecific, CustomModelType, getModelTriggerWord, UiDisplayMessageEventHandler } from 'core/common/types';
import { SimpleSpinner } from 'components/icons/simple-spinner';
import * as Dialog from '@radix-ui/react-dialog';
import styles from './custom-model.module.css';
import { FloatTagZIndex } from 'components/constants/zIndex';
import { Cross1Icon } from '@radix-ui/react-icons';
import { TriangleAlert } from 'lucide-react';
import { customModelWorkflowData, defaultCustomModelTrainingInputSliderOptions, getCustomModelTrainingInputFromSliderValue } from 'components/custom-model/custom-model-workflows-data';
import { debugError, debugLog } from 'core/utils/print-utilts';
import { clamp } from 'lodash';
import { OptionsInput } from 'components/dashboard/api/options-input';
import { useCanUserEditCustomModel } from 'hooks/use-custom-models-effect';
import { ToggleGroupInput } from 'components/dashboard/api/toggle-group';

export function CustomModelTraininingInputEditor() {
    const {
        trainingStrengthPercent,
        trainingBackendType,
        setTrainingBackendType,
        setTrainingStrengthPercent,
    } = useCustomModelTraininingInput();

    const workflow = editorContextStore(state => state.customModelWorkflow);

    const trainingSliderConfigs = React.useMemo(() =>
        customModelWorkflowData[workflow]?.trainingSliderConfigs ?? defaultCustomModelTrainingInputSliderOptions,
        [workflow]
    );

    const sliderValue = React.useMemo(() => {
        return Math.floor(clamp(trainingStrengthPercent, 0, 1) * trainingSliderConfigs.maxNumSteps);
    }, [trainingStrengthPercent, trainingSliderConfigs.maxNumSteps]);

    const setTrainingInputFromStep = React.useCallback((step: number) => {
        const percent = clamp(step / trainingSliderConfigs.maxNumSteps, 0.0, 1.0);

        debugLog('Set training strength from step: ', step);

        setTrainingStrengthPercent(percent);
    }, [
        trainingSliderConfigs.maxNumSteps,
        setTrainingStrengthPercent,
    ]);

    const {
        steps,
    } = React.useMemo(() => getCustomModelTrainingInputFromSliderValue({
        percent: trainingStrengthPercent,
        sliderOptions: trainingSliderConfigs.options,
    }), [trainingStrengthPercent, trainingSliderConfigs.options]);

    return (
        <form
            className='flex flex-col gap-8'
            onSubmit={(e) => {
                e.preventDefault();
            }}
        >
            <SliderInput
                type={ApiInputType.Slider}
                id="custom-model-strength"
                name="Training strength"
                value={sliderValue}
                onValueChange={(value) => {
                    setTrainingInputFromStep(value);
                }}
                min={0}
                max={trainingSliderConfigs.maxNumSteps}
                step={1}
                description="The stronger the Training Strength, the closer you get to moodboard content"
                descriptionClassName="hidden md:block md:max-w-[50vw] xl:max-w-[100vw]"
                parseStringValue={(value: string) => {
                    const number = parseInt(value);
                    if (isNaN(number)) return 0;
                    return number;
                }}
                required
                hideRequiredLabel
                numberInputEnabled={false}
                minLabel='Weak'
                maxLabel='Strong'
            />
            <div
                className='flex flex-row items-center gap-2'
            >
                <div className='text-base'>
                    Training speed
                </div>
                <div className='hidden md:block flex-1 min-w-0 truncate'/>
                <ToggleGroupInput
                    type='single'
                    value={trainingBackendType}
                    onValueChange={setTrainingBackendType}
                    itemClassName='lg:min-w-[21rem]'
                    options={[
                        {
                            name: "Fast: Takes about 5 minutes to finish.",
                            value: CustomModelTrainingBackendType.Fal,
                        },
                        {
                            name: `High quality: Takes about ${Math.max(30, Math.round(getDurationSecondsFromTrainingSteps(steps) / 60))} minutes to finish.`,
                            value: CustomModelTrainingBackendType.Replicate,
                        },
                    ]}
                />
            </div>
        </form>
    )
}



function getTrainingInputFromCustomModelTrainingInputContext({
    ...context
}: Partial<CustomModelTrainingInputContextType>): Omit<CustomModelTrainingInput, 'trigger_word'> {
    const {
        customModelWorkflow,
    } = editorContextStore.getState();

    const sliderConfigs = customModelWorkflowData[customModelWorkflow]?.trainingSliderConfigs ?? defaultCustomModelTrainingInputSliderOptions;

    const {
        trainingStrengthPercent = 0.5,
        trainingBackendType = CustomModelTrainingBackendType.Replicate,
    } = context;

    const {
        steps,
        iterMultipler,
        learningRate,
    } = getCustomModelTrainingInputFromSliderValue({
        percent: trainingStrengthPercent,
        sliderOptions: sliderConfigs.options,
    });

    debugLog(`Training backend type: ${trainingBackendType}`);

    if (trainingBackendType === CustomModelTrainingBackendType.Fal) {
        const input: Omit<CustomModelTrainingInputFalSpecific, 'trigger_word'> = {
            backendType: CustomModelTrainingBackendType.Fal,
            trainingStrengthPercent,
            is_style: customModelWorkflow === CustomModelType.Style,
            iter_multiplier: iterMultipler,
        };

        return input;
    } else {

        // const input: Omit<CustomModelTrainingInputFalSpecific, 'trigger_word'> = {
        //     backendType: CustomModelTrainingBackendType.Fal,
        //     trainingStrengthPercent,
        //     is_style: customModelWorkflow === CustomModelType.Style,
        //     iter_multiplier: clamp(Math.round(steps) * 0.0006, 1.0, 2.5),
        // };

        const input: Omit<CustomModelTrainingInputReplicateSpecific, 'trigger_word'> = {
            backendType: CustomModelTrainingBackendType.Replicate,
            steps,
            autocaption: true,
            batch_size: 4,
            learning_rate: learningRate,
            trainingStrengthPercent,
        };

        return input;
    }
}

export type CustomModelStartTrainEditorProps = {
    modelId?: string,
    onExit: () => void,
    isDialog?: boolean,
    className?: string,
    disabledStart?: boolean
}

export function CustomModelStartTrainEditor({
    modelId,
    onExit,
    isDialog = true,
    className = '',
    disabledStart = false,
}: CustomModelStartTrainEditorProps) {
    const {
        trainingStrengthPercent,
        trainingBackendType,
    } = useCustomModelTraininingInput();
    const dataset = editorContextStore(state => state.customModelDataset);

    const [isLoading, setIsLoading] = React.useState(false);

    const needDatasetInfo = React.useMemo(() => Object.entries(dataset || {}).length <= 0, [dataset]);

    const canUserEdit = useCanUserEditCustomModel();

    debugLog(`Custom model training strength percent: ${trainingStrengthPercent}`);

    return (
        <div
            className={classNames(
                isDialog ? classNames(
                    DropdownClassName,
                    'md:min-w-[50vw] xl:min-w-[40vw] max-h-[80vh] px-4 py-3 rounded-xl',
                ) : '',
                'flex flex-col gap-4',
                className
            )}
        >

            {isDialog &&
            <div className='flex flex-row items-center'>
                <Dialog.DialogTitle
                    className='flex-1 text-base truncate font-semibold text-zinc-500'
                    aria-describedby='Training configuration'
                >
                    Training configuration
                </Dialog.DialogTitle>
                <Dialog.Close>
                    <Cross1Icon
                        className='text-zinc-500 hover:text-zinc-300 active:text-zinc-700 cursor-pointer transition-colors'
                    />
                </Dialog.Close>
            </div>}
            <CustomModelTraininingInputEditor/>
            {needDatasetInfo && <div className='flex flex-row items-center bg-lime-900/20 border border-lime-900/30 p-2 py-1 rounded-lg shadow-md text-lime-400 text-sm font-semibold'>
                <TriangleAlert size={18} className='mr-2' />
                {needDatasetInfo && <div>Please upload images to the dataset in Step 1 to start training.</div>}
            </div>}
            <button
                className={classNames(
                    disabledStart?
                        'bg-zinc-800 select-none text-center text-zinc-500 font-semibold transition-colors cursor-not-allowed max-w-full flex flex-row items-center justify-center gap-2 px-3 py-2 rounded-lg':
                        canUserEdit ? PrimaryButtonClassName : PrimaryButtonClassNameDisabled,
                    "mt-[2vh] pointer-events-auto flex flex-row items-center justify-center gap-2",
                )}
                onClick={() => {
                    if (disabledStart || isLoading || !modelId || !canUserEdit) {
                        return;
                    }

                    setIsLoading(true);

                    const {
                        backend,
                        eventEmitter,
                    } = editorContextStore.getState();

                    if (!backend || needDatasetInfo) {
                        setIsLoading(false);
                        return;
                    }

                    const triggerWord = getModelTriggerWord({
                        modelId,
                    });

                    backend.startCustomModelTraining({
                        modelId,
                        trainingInput: {
                            ...getTrainingInputFromCustomModelTrainingInputContext({
                                trainingStrengthPercent,
                                trainingBackendType,
                            }),
                            trigger_word: triggerWord,
                        } as CustomModelTrainingInput,
                    }).then((response) => {

                        if (!response.ok) {

                            if (!response.message?.includes('quota left')) {
                                eventEmitter.emit<UiDisplayMessageEventHandler>(
                                    'ui:display-message',
                                    'error',
                                    `Error starting training: ${response.message}`,
                                );
                            }

                            debugError('Error starting training: ', response);

                        }

                    }).finally(() => {
                        setIsLoading(false);

                        onExit();
                    });
                }}
            >
                {disabledStart ? <>
                    Waiting for Training to finish
                </> :
                canUserEdit ? <>
                    {isLoading && <SimpleSpinner
                        width={18} height={18} pathClassName="fill-lime-800"
                    />}
                    <span>
                        {isLoading ? "Starting ..." : "Start training"}
                    </span>
                </> :
                <>
                    Cannot train example model
                </>}
            </button>
        </div>
    )
}

export function CustomModelStartTrainEditorDialog({
    triggerChildren,
    triggerProps,
    customModelStartTrainEditorProps,
    ...dialogProps
}: Dialog.DialogProps & {
    triggerChildren: React.ReactNode,
    triggerProps?: Dialog.DialogTriggerProps,
    customModelStartTrainEditorProps: CustomModelStartTrainEditorProps,
}) {
    return (
        <Dialog.Root
            {...dialogProps}
        >
            <Dialog.Trigger {...triggerProps}>
                {triggerChildren}
            </Dialog.Trigger>
            <Dialog.Portal>
                <Dialog.Overlay
                    className={styles.DialogOverlay}
                    style={{
                        zIndex: FloatTagZIndex,
                    }}
                />
                <Dialog.Content
                    className={classNames(
                        styles.DialogContent,
                        'relative focus-visible:outline-lime-500'
                    )}
                    style={{
                        zIndex: FloatTagZIndex,
                    }}
                    aria-describedby='Training configuration'
                >
                    <CustomModelStartTrainEditor
                        {...customModelStartTrainEditorProps}
                    />
                </Dialog.Content>
            </Dialog.Portal>
        </Dialog.Root>
    )
}