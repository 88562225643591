import { noop } from "lodash";
import type { StateUpdater } from "./utils";
import { getUpdaterFunction, type SetEditorStateFunction } from "contexts/editor-context-utils";

export enum EditorCanvasRenderMode {
    Lazy = 'Lazy',
    Loop = 'Loop',
}

export interface EditorCanvasRenderModeEditorState {
    editorCanvasRenderMode: EditorCanvasRenderMode,
    setEditorCanvasRenderMode: (value: StateUpdater<EditorCanvasRenderMode>) => void,
}

export function getDummyEditorCanvasRenderModeEditorState(): EditorCanvasRenderModeEditorState {
    return {
        editorCanvasRenderMode: EditorCanvasRenderMode.Lazy,
        setEditorCanvasRenderMode: noop,
    };
}

export function getDefaultEditorCanvasRenderModeEditorState(set: SetEditorStateFunction): EditorCanvasRenderModeEditorState {
    return {
        editorCanvasRenderMode: EditorCanvasRenderMode.Lazy,
        setEditorCanvasRenderMode: getUpdaterFunction(
            set,
            'editorCanvasRenderMode',
        ),
    };
}

export function resetEditorCanvasRenderModeEditorState(state: EditorCanvasRenderModeEditorState) {
    state.setEditorCanvasRenderMode(EditorCanvasRenderMode.Lazy);
}