import type { LeftPanelItemType } from 'components/constants/editor-options';
import { Text } from './text';

import { GenerateSimple, GenerateSimpleFixedTop } from './generate/generate-simple';
import { RegenerateProduct } from './generate/regenerate-product';
import { EditLeftPanel } from './edit/edit';
import { Assets } from './assets';
import { MagicErase } from './edit/magic-erase';
import { RemoveBackground } from './edit/remove-background';
import { ImageVariations } from './edit/image-variations';
import { Elements } from './elements';
import { Humans } from './humans';
import { TryOnSelectPose } from './tryon-select-pose';
import { TryOnRender } from './tryon-render';
import { TryOnUploadCloth } from './tryon-upload';
import { TransformProps3d } from './transform-props-3d';
import { ReplaceProduct } from './generate/replace-product';
import { LeftPanelUpscaleV2 } from './edit/upscale-v2';
import { OutpaintImage } from 'components/panels/panel-items/generate/outpaint-image';
import { GenerateVideo } from 'components/panels/panel-items/generate/generate-video';

export type PanelItem = (JSX.Element) | (() => JSX.Element);

export const panelItems: Record<LeftPanelItemType, PanelItem> = {
    Assets: <Assets />,
    Elements: <Elements />,
    Generate: <GenerateSimple />,
    Text: <Text />,
    WordMask: <GenerateSimple />,
    PaintPrompt: <GenerateSimple />,
    Edit: EditLeftPanel,
    MagicErase: MagicErase,
    RemoveBackground: RemoveBackground,
    ImageVariations: ImageVariations,
    Humans: Humans,
    TryOnUploadCloth: TryOnUploadCloth,
    TryOnSelectPose: TryOnSelectPose,
    TryOnRender: TryOnRender,
    TransformProps3d: TransformProps3d,
    RegenerateProduct: RegenerateProduct,
    ReplaceProduct: ReplaceProduct,
    UpscaleV2: LeftPanelUpscaleV2,
    Outpaint: OutpaintImage,
    GenerateVideo: GenerateVideo,
};

export const isPanelItemCustomized: Record<LeftPanelItemType, boolean> = {
    Assets: false,
    Elements: false,
    Generate: false,
    Text: false,
    WordMask: false,
    PaintPrompt: false,
    Edit: false,
    MagicErase: false,
    RemoveBackground: false,
    ImageVariations: false,
    Humans: false,
    TryOnUploadCloth: false,
    TryOnSelectPose: false,
    TryOnRender: false,
    TransformProps3d: false,
    RegenerateProduct: false,
    ReplaceProduct: false,
    UpscaleV2: false,
    Outpaint: false,
    GenerateVideo: false,
}


export const panelFixedTopItems: Record<LeftPanelItemType, (() => JSX.Element) | undefined> = {
    Assets: undefined,
    Elements: undefined,
    Generate: GenerateSimpleFixedTop,
    Text: undefined,
    WordMask: undefined,
    PaintPrompt: undefined,
    Edit: undefined,
    MagicErase: undefined,
    RemoveBackground: undefined,
    ImageVariations: undefined,
    Humans: undefined,
    TryOnUploadCloth: undefined,
    TryOnSelectPose: undefined,
    TryOnRender: undefined,
    TransformProps3d: undefined,
    RegenerateProduct: undefined,
    ReplaceProduct: undefined,
    UpscaleV2: undefined,
    Outpaint: undefined,
    GenerateVideo: undefined,
}

export const panelFixedBottomItems: Record<LeftPanelItemType, (() => JSX.Element) | undefined> = {
    Assets: undefined,
    Elements: undefined,
    Generate: undefined,
    Text: undefined,
    WordMask: undefined,
    PaintPrompt: undefined,
    Edit: undefined,
    MagicErase: undefined,
    RemoveBackground: undefined,
    ImageVariations: undefined,
    Humans: undefined,
    TryOnUploadCloth: undefined,
    TryOnSelectPose: undefined,
    TryOnRender: undefined,
    TransformProps3d: undefined,
    RegenerateProduct: undefined,
    ReplaceProduct: undefined,
    UpscaleV2: undefined,
    Outpaint: undefined,
    GenerateVideo: undefined,
}
