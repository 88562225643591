import React, { HTMLAttributes } from 'react';
import * as Dialog from '@radix-ui/react-dialog';
import { ChevronDownIcon, PlayIcon, PlusIcon } from '@radix-ui/react-icons';
import { CreateCustomModelNavbar } from './navbar';
import { UploadButton } from './upload-button';
import * as Tabs from '@radix-ui/react-tabs';
import styles from './custom-model.module.css';
import { classNames } from 'core/utils/classname-utils';
import { ScrollAreaContainer } from 'components/scroll-area/scroll-area';
import { CustomModelEditorTab, CustomModelInfo, isCustomModelInfo, CustomModelTrainingItem, CustomModelTrainingStatus, isCustomModelTrainingStatusActive, getTrainingDisplayName, resetCustomModelEditorState, StateUpdater, CustomModelTrainingBackendType, CustomModelSetPromptEditorStateEventHandler, CustomModelType } from 'core/common/types';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { editorContextStore } from 'contexts/editor-context';
import { CustomModelDatasetGridItem, DeleteDataAlert } from './dataset-grid-item';
import { PrimaryButtonClassName, PrimaryButtonClassNameDisabled, SecondaryButtonClassNameInactive } from 'components/constants/class-names';
import { formatDistanceToNow } from 'date-fns';
import { getTimeDifferenceInSeconds, sortByTimeModified } from 'core/utils/time-utils';
import { CustomModelPlaygroundHeaderZIndex, FloatTagZIndex } from 'components/constants/zIndex';
import { CustomModelTrainingEditor, CustomModelTrainingEditorStatus } from './custom-model-training-editor';
import { CustomModelStartTrainEditor } from './custom-model-start-train-editor';
import { CustomModelTrainingInputProvider, getDurationSecondsFromTrainingSteps } from './custom-model-training-context';
import { SimpleSpinner } from 'components/icons/simple-spinner';
import { capitalizeFirstLetter, cleanupText } from 'core/utils/string-utils';
import { CustomModelPlaygroundEditor } from './custom-model-playground-editor';
import { UploadImageFormPopover } from './upload-image';
import { CustomModelPlaygroundProvider, getCustomModelPlaygroundEditorStateFromPredictionInput, getCustomModelPlaygroundPromptEditorStateFromTrainingId, useCustomModelPlayground } from './custom-model-playground-context';
import { debugLog } from 'core/utils/print-utilts';
import StickyBox from 'components/utils/sticky-box';
import { SquarePen, Lightbulb } from 'lucide-react';
import { Tooltip } from 'components/utils/tooltip';
import { CustomModelEditorProvider, useCustomModelEditor } from './custom-model-editor-context';
import { customModelWorkflowData } from 'components/custom-model/custom-model-workflows-data';
import { useCanUserEditCustomModel, useCustomModelsEffect, usePublicCustomModelsEffect } from 'hooks/use-custom-models-effect';
import { clamp, noop } from 'lodash';
import { ProgressHandler } from 'components/utils/progress-handler';
import { getCustomModelPlaygroundPromptEditorStateFromSerializedEditorState } from './custom-model-mention-plugin';
import { Assets } from 'core/controllers/assets';
import { useCustomModelWorkflowSliderConfigUpdateEffect } from 'hooks/use-custom-model-workflow-slider-config-update';
import { ManageSubscriptionDialogProvider } from 'components/popup/message-dialog/manage-subscription-dialog';
import { getEditorStateJSONWithPrompt } from './custom-model-prompt-editor';

import infoBoxStyles from './info-box.module.css';

import * as Accordion from "@radix-ui/react-accordion";

const triggerClassName = classNames(
    'w-fit flex flex-row items-center justify-center gap-2',
    styles.TabsTrigger,
    'group px-6 py-2 md:min-w-[4rem] xl:min-w-[8rem] transition-colors text-center truncate',
);

/* @tw */
const triggerIndexClassName = 'w-[22px] h-[22px] text-xs flex flex-row items-center justify-center text-center rounded bg-zinc-800 text-zinc-500 group-hover:text-lime-500 group-hover:bg-zinc-800/50 transition-colors';

const editorHeadrButtonClassName = 'md:min-w-[128px] min-h-[40px] flex flex-row items-center justify-center rounded-full px-4 py-2 font-semibold gap-2 cursor-pointer transition-colors';

function EditorHeaderAddImageButton() {
    const modelId = editorContextStore(state => state.customModelId);

    if (!modelId) {
        return (
            <div
                className={classNames(
                    PrimaryButtonClassNameDisabled,
                    'flex flex-row items-center justify-center rounded-full px-4 py-2 font-semibold cursor-not-allowed gap-1',
                )}
            >
                <PlusIcon
                    width={18}
                    height={18}
                />
                <span className='hidden md:block'>
                    Add image
                </span>
            </div>
        )
    }

    return (
        <UploadImageFormPopover
            modelId={modelId}
            triggerProps={{
                asChild: true,
            }}
        >
            <button
                className={classNames(
                    PrimaryButtonClassName,
                    editorHeadrButtonClassName,
                    'bg-lime-500 text-zinc-900',
                )}
            >
                <PlusIcon
                    width={18}
                    height={18}
                />
                <span className='hidden sm:block'>
                    Add image
                </span>
            </button>
        </UploadImageFormPopover>
    );
}

function CustomModelNameInput({
    modelId,
    modelInfo,
    setModelInfo,
    disableEdit = false,
}: {
    modelId?: string,
    modelInfo?: CustomModelInfo,
    setModelInfo: (value: StateUpdater<CustomModelInfo | undefined>) => void,
    disableEdit?: boolean,
}) {
    const backend = editorContextStore(state => state.backend);
    const [isEditing, setEditing] = React.useState(false);

    const modelDisplayName = modelInfo?.displayName || "";

    const [name, setName] = React.useState(modelDisplayName);

    const navigate = useNavigate();
    const textInputRef = React.useRef<HTMLInputElement | null>(null);
    const isEscapeRef = React.useRef(false);

    React.useEffect(() => {
        setName(modelDisplayName);
    }, [modelDisplayName]);

    React.useEffect(() => {
        if (isEditing) {
            textInputRef.current?.focus();
            textInputRef.current?.select?.();
        }
    }, [isEditing]);

    const updateCustomModelModelName = React.useCallback((value: string) => {
        if (!modelId) {
            return;
        }

        setName(value);

        setModelInfo((prevInfo) => prevInfo ?
            {
                ...prevInfo,
                displayName: value,
            } : undefined);

        backend?.updateCustomModelInfo({
            modelId,
            modelInfo: {
                displayName: value,
            },
        });

    }, [backend, modelId, setModelInfo]);

    const commitModelName = React.useCallback(() => {
        const nameCleanup = cleanupText(name);
        if (!isEscapeRef.current && nameCleanup?.length && nameCleanup !== modelDisplayName) {
            updateCustomModelModelName(nameCleanup);
        } else {
            setName(modelDisplayName);
        }
        isEscapeRef.current = false;
    }, [updateCustomModelModelName, name, modelDisplayName, setName]);

    if (disableEdit) {
        return (
            <div className='flex flex-row justify-center items-center text-xl lg:text-2xl font-semibold text-zinc-500'>
                <div
                    className="text-zinc-500 hover:text-zinc-200 select-none cursor-default transition-colors"
                    onClick={() => {
                        navigate('/models');
                    }}
                >
                    Models
                </div>
                <div className="px-1 select-none">/</div>
                <div
                    className="text-zinc-300 hover:text-zinc-200 select-none cursor-default transition-colors"
                >
                    {modelInfo?.displayName || 'Custom Model'}
                </div>
            </div>
        );
    }

    return (
        <div className='flex flex-row justify-center items-center text-xl lg:text-2xl font-semibold text-zinc-500'>
            {
                isEditing ?
                    <input
                        ref={textInputRef}
                        type="text"
                        name="filename"
                        value={name}
                        className="max-w-[50vw] outline outline-1 rounded-sm focus:outline-zinc-800 text-zinc-300 bg-transparent focus-visible:outline-none"
                        onChange={(e) => {
                            setName(e.currentTarget?.value || '');
                        }}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                setName(e.currentTarget?.value || '');
                                setEditing(false);
                                commitModelName();
                            } else if (e.key === 'Escape') {
                                setEditing(false);
                                isEscapeRef.current = true;
                            }
                        }}
                        onBlur={() => {
                            setEditing(false);
                            commitModelName();
                        }}
                    /> :
                    <>
                        <div
                            className="text-zinc-500 hover:text-zinc-200 select-none cursor-default transition-colors"
                            onClick={() => {
                                navigate('/models');
                            }}
                        >
                            Models
                        </div>
                        <div className="px-1 select-none">/</div>
                        <div
                            className="text-zinc-300 hover:text-zinc-200 select-none cursor-default transition-colors"
                            onDoubleClick={() => {
                                setEditing(true);
                            }}
                        >
                            {modelInfo?.displayName || 'Custom Model'}
                        </div>
                        <button
                            className='ml-2.5 pt-px text-zinc-500 hover:text-zinc-300 active:text-zinc-700 transition-colors cursor-pointer'
                            onClick={() => {
                                setEditing(true);
                            }}
                        >
                            <SquarePen size={20} />
                        </button>
                    </>
            }
        </div>
    )
}

function EditorHeader() {
    const modelId = editorContextStore(state => state.customModelId);
    const modelInfo = editorContextStore(state => state.customModelInfo);
    const setModelInfo = editorContextStore(state => state.setCustomModelInfo);
    const canUserEdit = useCanUserEditCustomModel();

    return (
        <div className='w-full flex flex-row items-center py-6'>
            <CustomModelNameInput
                modelId={modelId}
                modelInfo={modelInfo}
                setModelInfo={setModelInfo}
                disableEdit={!canUserEdit}
            />
        </div>
    );
}

interface InfoBoxProps extends Omit<Accordion.AccordionSingleProps, 'type'> {
    children: React.ReactNode;
    className?: string;
}

export const InfoBox: React.FC<InfoBoxProps> = ({
    children,
    className = '',
    defaultValue="info",
    ...props
}) => {
    return (
        <Accordion.Root
            {...props}
            className={classNames(
                'self-start inline-block bg-lime-900/20 border border-lime-900/30 text-white p-4 py-3 rounded-lg shadow-md',
                className
            )}
            type="single" // Ensures only one item can be open at a time
            collapsible // Allows the single open item to be collapsible
            defaultValue={defaultValue}
        >
            <Accordion.Item value="info">
                <Accordion.Header className=''>
                    <Accordion.Trigger className="group flex flex-row items-center gap-2 text-lime-400 text-sm font-semibold w-full">
                        <Lightbulb size={16} className="flex-shrink-0" />
                        <span>Tips</span>
                        <ChevronDownIcon
                            className={classNames(
                                'ml-auto text-lime-400 transition-transform duration-300 ease-[cubic-bezier(0.87,0,0.13,1)]',
                                // Tailwind's group-data-[state=open]:rotate-180 to rotate the chevron
                                'group-data-[state=open]:rotate-180'
                            )}
                            aria-hidden
                        />
                    </Accordion.Trigger>
                </Accordion.Header>
                <Accordion.Content
                    className={classNames(
                        infoBoxStyles.content,
                        "mt-2",
                    )}
                >
                    <div>{children}</div>
                </Accordion.Content>
            </Accordion.Item>
        </Accordion.Root>
    );
};

const datasetGridClassName = 'w-full grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-2'

function DatasetGrid() {

    const workflow = editorContextStore(state => state.customModelWorkflow);
    const modelId = editorContextStore(state => state.customModelId);
    const dataset = editorContextStore(state => state.customModelDataset);
    const dataIdToDeleteRef = React.useRef<string | undefined>();
    const [isDeleteAlertOpen, setDeleteAlertOpen] = React.useState(false);
    const canUserEdit = useCanUserEditCustomModel();

    if (!modelId || !dataset) {
        return null;
    }

    const deleteDataEntry = (dataId: string) => {
        const backend = editorContextStore.getState()?.backend;

        if (!dataId || !backend) {
            return Promise.resolve();
        }

        return backend.deleteCustomModelDataItem({
            modelId,
            dataId,
        }).then(() => {
            editorContextStore.getState().setCustomModelDataset((dataset) => {
                if (!dataset?.[dataId]) {
                    return dataset;
                }
                const newDataset = { ...dataset };
                delete newDataset[dataId];
                return newDataset;
            });
            dataIdToDeleteRef.current = undefined;
        })
    }



    return (
        <div className='w-full flex flex-col mb-6'>
            <div className='w-full'>
                <InfoBox
                    className='w-full'
                    defaultValue="info"
                >
                    <div className='flex flex-col md:flex-row gap-4'>
                        <ol className="max-w-[400px] list-decimal list-outside pl-5 flex flex-col gap-2 text-lime-200 text-sm leading-relaxed">
                            {customModelWorkflowData[workflow]?.moodboardInfoboxText ? (
                                Array.isArray(customModelWorkflowData[workflow]?.moodboardInfoboxText) ? (
                                    (customModelWorkflowData[workflow]?.moodboardInfoboxText as string[]).map((textLine, index) => (
                                        <li key={index} className="">
                                            {textLine}
                                        </li>
                                    ))
                                ) : (
                                    <li className="">
                                        {customModelWorkflowData[workflow]?.moodboardInfoboxText}
                                    </li>
                                )
                            ) : (
                                <li className="">
                                    Upload at least 3 photos to help your model get better results
                                </li>
                            )}
                            <li className="">Image size must be at least 384×384</li>
                        </ol>
                        <div>
                            {customModelWorkflowData[workflow]?.moodboardExampleUrls &&
                                <div className='flex flex-1 flex-row gap-2 overflow-x-scroll'>
                                    {customModelWorkflowData[workflow]?.moodboardExampleUrls?.map((exampleUrl, index) => {
                                        return <img
                                            key={index}
                                            src={exampleUrl}
                                            alt='example'
                                            className="h-[12vh] object-cover rounded-md"
                                        />
                                    })}
                                </div>
                            }
                        </div>
                    </div>
                </InfoBox>
            </div>
            <div className="w-full h-6"></div>
            <div className={datasetGridClassName}>
                {canUserEdit && <UploadButton
                    modelId={modelId}
                    onDelete={(dataId) => {
                        deleteDataEntry(dataId);
                    }}
                />}
                {Object.entries(dataset).map(([key, item]) => (
                    <CustomModelDatasetGridItem
                        id={key}
                        key={key}
                        item={item}
                        modelId={modelId}
                        onDelete={() => {
                            setDeleteAlertOpen(true);
                            dataIdToDeleteRef.current = key;
                        }}
                    />
                ))}
                <DeleteDataAlert
                    open={isDeleteAlertOpen}
                    onOpenChange={setDeleteAlertOpen}
                    onDelete={() => {
                        const dataId = dataIdToDeleteRef.current;
                        if (dataId) deleteDataEntry(dataId);
                    }}
                />
            </div>
        </div>
    );
}

const TrainingItemToClassNames = {
    [CustomModelTrainingStatus.Starting]: "bg-lime-900 border-lime-700 text-lime-500",
    [CustomModelTrainingStatus.Processing]: "bg-lime-900 border-lime-700 text-lime-500",
    [CustomModelTrainingStatus.Succeeded]: "bg-lime-900/50 border-lime-700 text-lime-500",
    [CustomModelTrainingStatus.Canceled]: "bg-zinc-800/50 border-zinc-800 text-zinc-500",
    [CustomModelTrainingStatus.Failed]: "bg-red-900/10 border-red-900/60 text-red-500",
}

function TrainingItemStatus({
    training,
}: {
    training: CustomModelTrainingItem,
}) {
    return (
        <div className='relative flex flex-row justify-end'>
            <div className={classNames(
                'flex flex-row items-center gap-2 max-w-full w-fit px-2 py-1 text-sm rounded truncate border transition-colors',
                TrainingItemToClassNames[training.status] || TrainingItemToClassNames[CustomModelTrainingStatus.Canceled],
            )}>
                {isCustomModelTrainingStatusActive(training.status) && (
                    <SimpleSpinner
                        width={18} height={18} pathClassName="fill-lime-500"
                    />
                )}
                <span>
                    {capitalizeFirstLetter(training.status)}
                </span>
            </div>
        </div>
    );
}

function getTrainingMessage({
    training,
}: {
    training: CustomModelTrainingItem,
}) {
    const status = training.status;

    const time = training.timeModified || training.timeCreated;

    const input = training.input;

    const numInferenceSteps = input.backendType === CustomModelTrainingBackendType.Replicate ?
        input.steps :
        100;


    if (!time) {
        return '';
    }

    const timeAgo = formatDistanceToNow(
        time.toDate(),
        {
            addSuffix: true
        },
    );

    if (isCustomModelTrainingStatusActive(status)) {
        const expectedDuration = getDurationSecondsFromTrainingSteps(numInferenceSteps) * 1000; // convert to milliseconds
        const currentDuration = Date.now() - training.timeCreated.toDate().getTime();
        const timeLeftMilliseconds = Math.max(expectedDuration - currentDuration, 0);
        const timeLeft = formatDistanceToNow(new Date(Date.now() + timeLeftMilliseconds));
        return `Estimated time left: ${timeLeft}`;
    }

    return `Last updated ${timeAgo}`;
}

function getExpectedDurationSecondsFromTraining(training: CustomModelTrainingItem) {
    const input = training.input;

    if (input.backendType === CustomModelTrainingBackendType.Fal) {
        return getDurationSecondsFromTrainingSteps((input.iter_multiplier ?? 1) * 100);
    } else if (input.backendType === CustomModelTrainingBackendType.Replicate) {
        return getDurationSecondsFromTrainingSteps(input.steps);
    }

    return getDurationSecondsFromTrainingSteps(1000);
}

function getProgressFromTraining(training: CustomModelTrainingItem) {
    if (typeof (training?.progress) === 'number' && training.progress > 0) {

        return Math.max(training.progress * 0.01, 0.01);
    }

    const timestamp = training.timeModified || training.timeCreated;

    const elapsedSeconds = getTimeDifferenceInSeconds({
        startTime: timestamp.toDate(),
    });

    const expectedDurationSeconds = getExpectedDurationSecondsFromTraining(training);

    const progress = clamp(elapsedSeconds / expectedDurationSeconds, 0.01, 0.99);

    return progress;
}

function TrainingItem({
    modelId,
    training,
}: {
    modelId: string,
    training: CustomModelTrainingItem,
}) {
    const [dialogOpen, setDialogOpen] = React.useState(false);

    const backend = editorContextStore(state => state.backend);

    const trainingMessage = getTrainingMessage({
        training,
    });

    const trainingId = React.useMemo(() => training.id, [training.id]);

    const trainingInputType = training.input.backendType;

    const [progressInternal, setProgressInternal] = React.useState(
        getProgressFromTraining(training)
    );

    const progressHandlerRef = React.useRef<ProgressHandler>(new ProgressHandler({
        speed: trainingInputType === CustomModelTrainingBackendType.Fal ? 5e-4 : 1e-5,
        setProgress: setProgressInternal,
    }));

    const setProgressFromTraining = React.useCallback((training: CustomModelTrainingItem) => {
        const progress = getProgressFromTraining(training);

        progressHandlerRef.current.setProgress(progress);
    }, []);

    React.useEffect(() => {
        debugLog(`Training ${training.id} updated`);
        setProgressFromTraining(training);
    }, [training, setProgressFromTraining]);

    React.useEffect(() => {
        if (!backend || !trainingId) {
            return;
        }

        return backend.onCustomModelTrainingUpdate({
            modelId,
            trainingId,
            callback: (newTraining) => {
                const {
                    setCustomModelTrainings,
                } = editorContextStore.getState();

                setCustomModelTrainings((prevTrainings) => {
                    return {
                        ...prevTrainings,
                        [trainingId]: newTraining,
                    };
                });

                debugLog(`Training ${newTraining.id} snapshot`);

                setProgressFromTraining(newTraining);
            }
        });

    }, [backend, modelId, trainingId, setProgressFromTraining]);


    const handleOpenChange = React.useCallback((isOpen: boolean) => {
        setDialogOpen(isOpen);
    }, []);

    const isActive = React.useMemo(() => isCustomModelTrainingStatusActive(
        training.status,
    ), [training.status]);

    const {
        setTab,
    } = useCustomModelEditor();

    const {
        setApiState,
    } = useCustomModelPlayground();

    return (
        <Dialog.Root
            open={dialogOpen}
            onOpenChange={handleOpenChange}
        >
            <Dialog.Trigger asChild>
                <div className={classNames(
                    SecondaryButtonClassNameInactive,
                    'relative max-w-full h-12 flex flex-row items-center justify-start gap-2 px-3 py-2 rounded-lg text-zinc-300 active:border-lime-700 overflow-hidden',
                )}>
                    <div className='text-sm font-semibold'>
                        {getTrainingDisplayName(training)}
                    </div>
                    <div className='min-w-0 flex-1 text-sm text-zinc-500 truncate'>
                        {trainingMessage}
                    </div>
                    <div className={classNames(
                        'relative min-w-0 sm:w-[10%] flex flex-row justify-end',
                    )}>
                        {
                            isActive ? (
                                <span className='max-w-full w-fit text-sm truncate'>
                                    {Math.round(progressInternal * 100) ?? 0} %
                                </span>
                            ) : (
                                <Tooltip
                                    triggerProps={{
                                        asChild: true,
                                    }}
                                    triggerChildren={(
                                        <button
                                            className={classNames(
                                                training.status === CustomModelTrainingStatus.Succeeded ?
                                                    PrimaryButtonClassName :
                                                    'opacity-0',
                                                "flex flex-row items-center gap-1 max-w-full w-fit px-2 py-1 text-sm rounded truncate border transition-colors",
                                            )}
                                            onClick={(e) => {
                                                if (training.status !== CustomModelTrainingStatus.Succeeded) {
                                                    return;
                                                }

                                                e.preventDefault();

                                                const {
                                                    customModelInfo,
                                                    customModelWorkflow,
                                                } = editorContextStore.getState();

                                                const promptEditorState = getCustomModelPlaygroundPromptEditorStateFromTrainingId({
                                                    customModelType: customModelInfo?.customModelType ?? customModelWorkflow,
                                                    trainingId: training.id,
                                                    trainingDisplayName: training.displayName ?? "",
                                                    caption: training.caption ?? "",
                                                    modelId: modelId,
                                                    modelDisplayName: customModelInfo?.displayName ?? "",
                                                });

                                                setApiState((apiState) => ({
                                                    ...apiState,
                                                    promptEditorState,
                                                }));

                                                setTab('play');
                                            }}
                                        >
                                            <PlayIcon />
                                            <span>
                                                Test
                                            </span>
                                        </button>
                                    )}
                                    contentChildren={(
                                        <span className='text-xs'>
                                            Test this training in Generate prompt editor.
                                        </span>
                                    )}
                                />
                            )
                        }
                    </div>
                    <TrainingItemStatus
                        training={training}
                    />
                    <div
                        className={classNames(
                            'absolute left-0 bottom-0 w-full h-px bg-zinc-800',
                            isActive ? '' : 'hidden',
                        )}
                    >
                        <div
                            className={`${styles.TransitionWidth} rounded-full h-full bg-lime-500`}
                            style={{
                                width: `${progressInternal * 100}%`,
                            }}
                        />
                    </div>
                </div>
            </Dialog.Trigger>
            <Dialog.Portal>
                <Dialog.Overlay
                    className={styles.DialogOverlay}
                    style={{
                        zIndex: FloatTagZIndex,
                    }}
                />
                <Dialog.Content
                    className={classNames(
                        styles.DialogContent,
                        'relative focus-visible:outline-lime-500'
                    )}
                    style={{
                        zIndex: FloatTagZIndex,
                    }}
                >
                    <CustomModelTrainingEditor
                        status={CustomModelTrainingEditorStatus.Default}
                        modelId={modelId}
                        training={training}
                        onExit={() => handleOpenChange(false)}
                    />
                </Dialog.Content>
            </Dialog.Portal>
        </Dialog.Root>
    )
}

function Play() {
    const modelId = editorContextStore(state => state.customModelId);

    if (!modelId) {
        return null;
    }

    return (
        <div className='relative flex flex-col items-stretch pb-12 gap-4'>
            <CustomModelPlaygroundEditor
                modelId={modelId}
            />
        </div>
    );
}

function Train() {
    const modelId = editorContextStore(state => state.customModelId);
    const trainings = editorContextStore(state => state.customModelTrainings);

    const hasActiveTrainings = React.useMemo(() => {
        return Object.values(trainings).filter((training) => (
            training.status === CustomModelTrainingStatus.Starting
        ) || (
                training.status === CustomModelTrainingStatus.Processing
            )).length > 0;
    }, [trainings]);


    if (!modelId) {
        return null;
    }

    return (
        <div className='relative flex flex-col items-stretch pb-12 gap-8'>
            <CustomModelStartTrainEditor
                disabledStart={hasActiveTrainings}
                isDialog={false}
                modelId={modelId}
                onExit={noop}
                className='pt-0'
            />
            <div className='flex flex-col items-stretch gap-4'>
                <div>
                    Training history
                </div>
                {Object.values(trainings).sort(sortByTimeModified).map((training) => (
                    <TrainingItem
                        key={training.id}
                        modelId={modelId}
                        training={training}
                    />
                ))}
            </div>
        </div>
    );
}

function Data() {
    return (
        <DatasetGrid />
    )
}

function isCustomModelEditorTab(value: any): value is CustomModelEditorTab {
    return value === 'data' || value === 'train' || value === 'play';
}

const CustomModelEditorContainer = React.forwardRef(({
    className,
    children,
    ...props
}: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>, forwardedRef: React.Ref<HTMLDivElement>) => {
    return (
        <div
            ref={forwardedRef}
            className={classNames(
                'w-full px-2 flex items-center justify-center',
                className ?? '',
            )}
            {...props}
        >
            <div className='w-full xl:max-w-[1280px]'>
                {children}
            </div>
        </div>
    )
})

function CustomModelEditorInner({
    tab: initTab,
    trainingId,
}: {
    tab?: string | null,
    trainingId?: string | null,
}) {
    const {
        tab,
        setTab,
    } = useCustomModelEditor();

    React.useEffect(() => {
        if (!isCustomModelEditorTab(initTab)) {
            return;
        }
        setTab(initTab);
    }, [initTab, setTab]);

    const scrollAreaContainerRef = React.useRef<HTMLDivElement | null>(null);
    const tabsRootRef = React.useRef<HTMLDivElement | null>(null);
    const tabsListRef = React.useRef<HTMLDivElement | null>(null);

    React.useEffect(() => {
        if (!tabsListRef.current) {
            return;
        }
    }, []);

    const customModelWorkflow = editorContextStore(state => state.customModelWorkflow);

    const {
        setApiState,
        setOutputImages,
    } = useCustomModelPlayground();

    const isFirstLoadPredictionsRef = React.useRef(true);
    const customModelInfo = editorContextStore(state => state.customModelInfo);
    const customModelTrainings = editorContextStore(state => state.customModelTrainings);
    const customModelPredictions = editorContextStore(state => state.customModelPredictions);

    React.useEffect(() => {
        const {
            backend,
            eventEmitter,
            customModelWorkflow,
        } = editorContextStore.getState();

        const training = (
            trainingId ? customModelTrainings[trainingId] : undefined
        ) || Object.values(customModelTrainings)
            .sort(sortByTimeModified)
            .find(training => training.status === CustomModelTrainingStatus.Succeeded);

        const predictionItem = Object.values(customModelPredictions)
            .filter(prediction => prediction?.output?.length)
            .sort(sortByTimeModified)?.[0];

        const promptJson = predictionItem?.input?.promptJson;

        const predictionOutputs = predictionItem?.output ?? [];

        debugLog('New training:\n', training);

        if (backend && predictionItem && promptJson && isFirstLoadPredictionsRef.current) {
            // Find the most recent prediction and use that as the default prompt
            setApiState((apiState) => ({
                ...apiState,
                ...getCustomModelPlaygroundEditorStateFromPredictionInput(
                    predictionItem.input,
                ),
                promptEditorState: getCustomModelPlaygroundPromptEditorStateFromSerializedEditorState({
                    promptEditorState: JSON.parse(promptJson),
                }),
            }));

            eventEmitter.emit<CustomModelSetPromptEditorStateEventHandler>(
                'custom-model:set-prompt-editor-state',
                {
                    promptEditorStateJson: promptJson,
                },
            );

            isFirstLoadPredictionsRef.current = false;

            debugLog('Init api state from the most recent prediction:\n', predictionItem);

            Promise.all(predictionOutputs.map((path) => Assets.loadAssetFromPath({
                path,
                backend,
            }))).then((urls) => {
                setOutputImages(urls.filter(Boolean) as string[]);
            })


        } else if (training) {
            setApiState((apiState) => {
                if (apiState.promptEditorState.text) {
                    return apiState;
                }

                const promptEditorState = getCustomModelPlaygroundPromptEditorStateFromTrainingId({
                    customModelType: customModelWorkflow,
                    trainingId: training.id,
                    trainingDisplayName: training.displayName ?? "",
                    caption: training.caption ?? "",
                    modelId: customModelInfo?.id ?? "",
                    modelDisplayName: customModelInfo?.displayName ?? "",
                });

                if (promptEditorState.json) {
                    eventEmitter.emit<CustomModelSetPromptEditorStateEventHandler>(
                        'custom-model:set-prompt-editor-state',
                        {
                            promptEditorStateJson: promptEditorState.json,
                        },
                    );
                }

                return {
                    ...apiState,
                    promptEditorState,
                };
            });

            debugLog('Init api state from the most recent training:\n', training);
        }
        /* else if (customModelWorkflow && customModelWorkflowData[customModelWorkflow]?.playgroundPrompt) {
            const prompt = customModelWorkflowData[customModelWorkflow]?.playgroundPrompt!;
            const promptJSON = getEditorStateJSONWithPrompt(prompt);
            setApiState((apiState) => ({
                ...apiState,
                promptEditorState: getCustomModelPlaygroundPromptEditorStateFromSerializedEditorState({
                    promptEditorState: promptJSON,
                }),
            }));
        } */

    }, [
        trainingId,
        setApiState,
        setOutputImages,
        customModelInfo,
        customModelTrainings,
        customModelPredictions,
    ]);

    useCustomModelWorkflowSliderConfigUpdateEffect();

    return (
        <ScrollAreaContainer
            ref={scrollAreaContainerRef}
            className='relative w-full min-h-0 flex-1 px-4'
        >
            <CustomModelEditorContainer>
                <EditorHeader />
            </CustomModelEditorContainer>
            <Tabs.Root
                ref={tabsRootRef}
                value={tab}
                onValueChange={(value) => {
                    if (isCustomModelEditorTab(value)) {
                        setTab(value);
                    }
                }}
                defaultValue="data"
                className='w-full min-h-0 flex-1 flex flex-col'
            >
                <StickyBox
                    style={{
                        zIndex: CustomModelPlaygroundHeaderZIndex,
                    }}
                >
                    <Tabs.List
                        ref={tabsListRef}
                        className='h-[42px] w-full text-md font-semibold border-b border-zinc-800 bg-zinc-900'
                    >
                        <CustomModelEditorContainer className='flex flex-row bg-zinc-900'>
                            <div className='flex flex-row items-center'>
                                <Tabs.Trigger value="data" className={triggerClassName}>
                                    <span className={triggerIndexClassName}>
                                        1
                                    </span>
                                    <span>
                                        {(customModelWorkflow && customModelWorkflowData[customModelWorkflow]?.dataTabLabel) || 'Moodboard'}
                                    </span>
                                </Tabs.Trigger>
                                <Tabs.Trigger value="train" className={triggerClassName}>
                                    <span className={triggerIndexClassName}>
                                        2
                                    </span>
                                    <span>
                                        Training
                                    </span>
                                </Tabs.Trigger>
                                <Tabs.Trigger value="play" className={triggerClassName}>
                                    <span className={triggerIndexClassName}>
                                        3
                                    </span>
                                    <span>
                                        Generate
                                    </span>
                                </Tabs.Trigger>
                            </div>
                        </CustomModelEditorContainer>
                    </Tabs.List>
                </StickyBox>
                <CustomModelEditorContainer>
                    <Tabs.Content
                        value="data"
                        className='pt-6 w-full min-h-0 flex-1 focus:outline-none'
                    >
                        <Data />
                    </Tabs.Content>
                    <Tabs.Content
                        value="train"
                        className='pt-6'
                    >
                        <Train />
                    </Tabs.Content>
                    <Tabs.Content value="play">
                        <Play />
                    </Tabs.Content>
                </CustomModelEditorContainer>
            </Tabs.Root>
        </ScrollAreaContainer>
    );
}

export function CustomModelEditor() {
    const params = useParams();
    const { state } = useLocation();
    const modelId = params?.modelId;

    const [searchParams,] = useSearchParams();

    const tab = searchParams.get('tab');

    const trainingId = searchParams.get('trainingId');

    const publicUserId = editorContextStore(state => state.publicUserId);
    const backend = editorContextStore(state => state.backend);
    const customModelInfo = editorContextStore(state => state.customModelInfo);
    const setCustomModelId = editorContextStore(state => state.setCustomModelId);
    const setCustomModelInfo = editorContextStore(state => state.setCustomModelInfo);
    const setDataset = editorContextStore(state => state.setCustomModelDataset);
    const setCustomModelWorkflow = editorContextStore(state => state.setCustomModelWorkflow);
    const setCustomModelTrainings = editorContextStore(state => state.setCustomModelTrainings);
    const setCustomModelPredictions = editorContextStore(state => state.setCustomModelPredictions);

    // debugLog(`Custom model ${modelId} training ${trainingId} tab ${tab} workflow: ${customModelWorkflow}; custom model info: `, customModelInfo);

    React.useEffect(() => {
        setCustomModelWorkflow(customModelInfo?.customModelType ?? CustomModelType.Custom);
    }, [customModelInfo, setCustomModelWorkflow]);

    React.useEffect(() => {
        setCustomModelId(modelId);

        if (isCustomModelInfo(state)) {
            setCustomModelInfo(state);
        } else if (modelId) {
            backend?.getCustomModelInfo(modelId)
                .then(setCustomModelInfo);
        }

        return () => {
            resetCustomModelEditorState(editorContextStore.getState());
        }
    }, [
        state,
        backend,
        modelId,
        setCustomModelId,
        setCustomModelInfo,
    ]);

    React.useEffect(() => {
        if (!modelId) {
            return;
        }

        if (!backend) {
            return;
        }

        return backend.onCustomModelDatasetUpdate(
            modelId,
            setDataset,
        );
    }, [backend, modelId, setDataset]);

    React.useEffect(() => {
        if (!modelId || !backend) {
            return;
        }

        const unsubscribeTrainingUpdate = backend.onCustomModelTrainingCollectionUpdate({
            modelId,
            callback: (trainings) => {
                setCustomModelTrainings(Object.fromEntries(trainings.map((training) => [training.id, training])));
            },
        });

        return () => {
            unsubscribeTrainingUpdate();

            setCustomModelTrainings({});
        }
    }, [backend, modelId, setCustomModelTrainings]);

    React.useEffect(() => {
        setCustomModelPredictions({});

        if (!modelId || !backend || !publicUserId) {
            return;
        }

        const unsubscribePromise = backend.getPublicCustomModelPredictions({
            modelId,
        }).then((publicPredictions) => {
            return backend.onCustomModelPredictionsUpdate({
                modelId,
                publicUserId,
                callback: (predictions) => {
                    setCustomModelPredictions({
                        ...predictions,
                        ...publicPredictions,
                    });
                },
            });
        });

        return () => {
            setCustomModelPredictions({});

            unsubscribePromise.then((unsubscribe) => {
                unsubscribe();
            });
        };
    }, [
        backend,
        modelId,
        publicUserId,
        setCustomModelPredictions
    ]);

    useCustomModelsEffect();

    usePublicCustomModelsEffect();

    return (
        <ManageSubscriptionDialogProvider>
            <CustomModelEditorProvider>
                <CustomModelTrainingInputProvider>
                    <CustomModelPlaygroundProvider>
                        <div
                            className='h-screen bg-zinc-900 text-zinc-100 flex flex-col'
                        >
                            <CreateCustomModelNavbar />
                            {modelId && <CustomModelEditorInner tab={tab} trainingId={trainingId} />}
                        </div>
                    </CustomModelPlaygroundProvider>
                </CustomModelTrainingInputProvider>
            </CustomModelEditorProvider>
        </ManageSubscriptionDialogProvider>
    );
}