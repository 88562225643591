import { editorContextStore } from 'contexts/editor-context';
import React from 'react';


import {
    Routes,
    Route,
    Navigate,
} from 'react-router-dom';
import { FinishEmailLogin } from 'components/auth/finish-email-login';
import { FullScreenInviteCodeInput } from 'components/auth/invite-code';
import { LoginFullscreen } from 'components/auth/login-fullscreen';
import { MobileRedirectErrorFullscreen, MobileRedirectFullscreen } from 'components/auth/require-desktop';
import { EMAIL_LINK_SIGNIN, NEW_PROJECT, NEW_TRYON_PROJECT } from 'components/constants/routes';
import { CreateNewProject } from 'components-mobile/dashboard/create-new-project';
import { Dashboard } from 'components-mobile/dashboard/dashboard';
import { EditorInternal } from './editor/canvas-editor';
import { EditorRouter } from 'components-mobile/editor/editor-router';
import { CreateNewTryOnProject } from 'components-mobile/dashboard/create-new-tryon-project';
import { ApiPlaygroundRouter } from 'components-mobile/dashboard/api';

function Projects() {
    React.useEffect(() => {
        editorContextStore.getState().setDashboardType('projects');
    }, []);
    return (
        <Navigate to="/" />
    );
}

export function MobileAppRoutes() {
    return (
        <Routes>
            <Route path="/" element={<Dashboard />} />
            <Route path="/login" element={<LoginFullscreen />} />
            <Route path="/mobile" element={<MobileRedirectFullscreen />} />
            <Route path="/mobileError" element={<MobileRedirectErrorFullscreen />} />
            <Route path={`/${EMAIL_LINK_SIGNIN}`} element={<FinishEmailLogin />} />
            <Route path="inviteCode" element={<FullScreenInviteCodeInput />} />
            <Route path="/projects" element={<Projects />} />
            <Route path="/projects/:projectId" element={<EditorRouter />} />
            <Route path="/api" element={<Dashboard dashboardType='manage-api' />} />
            <Route path="/api/:apiId" element={<ApiPlaygroundRouter />} />
            <Route path="/editorDebug" element={<EditorInternal />} />
            <Route path={`/${NEW_PROJECT}`} element={<CreateNewProject />} />
            <Route path={`/${NEW_TRYON_PROJECT}`} element={<CreateNewTryOnProject />} />

            <Route path="/workflows" element={<MobileRedirectFullscreen destination='workflows'  />} />
            <Route path="/newModel" element={<MobileRedirectFullscreen destination='customModels' />} />
            <Route path="/models" element={<MobileRedirectFullscreen destination='customModels' />} />
            <Route path="/videos" element={<MobileRedirectFullscreen destination='video' />} />
        </Routes>
    );
}