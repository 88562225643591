import React from "react";
import { RequireAuth } from "components/auth/require-auth";
import { ScrollAreaContainer } from "components/scroll-area/scroll-area";
import { Outlet } from "react-router-dom";
import DashboardLeftPanel from "./dashboard-left-panel";
import { DashboardNavBar } from "./dashboard-navbar";
import { DashboardProjects } from "./dashboard-projects";
import { DashboardWorkflows } from "./dashboard-workflows";
import { Chatbot } from "components/chatbot";
import { AnalyticsConfig } from "analytics/config";
import { editorContextStore } from "contexts/editor-context";
import { RequireDesktop } from "components/auth/require-desktop";
import { DashboardManageApi } from "./api";
import { DashboardType } from "core/common/types";
import { useDashboardModelNavigateEffect } from "./use-dashboard-navigate-effect";
import { useUserApiStatusUpdateEffect } from "hooks/use-api-update";
import { useProjectInitSceneContext } from "contexts/project-init-scene-context";
import { ManageSubscriptionDialogProvider } from "components/popup/message-dialog/manage-subscription-dialog";
import { DashboardCustomModels } from "components/custom-model/dashboard-custom-models";
import { DashboardVideos } from "components/video/dashboard-video";


function DashboardRightPanelInner() {
    const dashboardType = editorContextStore(state => state.dashboardType);

    useDashboardModelNavigateEffect();

    return (
        <>
            <DashboardWorkflows
                visible={dashboardType === 'workflows'}
            />
            <DashboardProjects
                visible={dashboardType === 'projects'}
            />
            <DashboardManageApi
                visible={dashboardType === 'manage-api'}
            />
            <DashboardCustomModels
                visible={dashboardType === 'models'}
            />
            <DashboardVideos
                visible={dashboardType === 'videos'}
            />
        </>
    );
}

function DashboardInner() {
    const {
        setInitScene,
    } = useProjectInitSceneContext();

    React.useEffect(() => {

        setInitScene(undefined);

    }, [setInitScene]);

    React.useEffect(() => {

        editorContextStore.getState().analytics.track(
            AnalyticsConfig.PageOpen,
            {
                name: 'dashboard',
                url: window.location.href,
            }
        );
    }, []);

    useUserApiStatusUpdateEffect();

    return (
        <div
            className="h-screen bg-zinc-900 text-zinc-100 flex flex-col"
        >
            <DashboardNavBar />
            <div
                className="grow flex flex-row overflow-hidden"
            >
                <DashboardLeftPanel />
                <ScrollAreaContainer
                    className="flex-1 max-h-screen overflow-x-hidden"
                >
                    <DashboardRightPanelInner />
                </ScrollAreaContainer>
            </div>
            <Outlet />
        </div>
    )
}

export const Dashboard = React.memo(function Dashboard({
    dashboardType,
}: {
    dashboardType?: DashboardType,
}) {
    React.useEffect(() => {
        if (!dashboardType) {
            return;
        }

        const {
            setDashboardType,
        } = editorContextStore.getState();

        setDashboardType(dashboardType);
    }, [dashboardType]);

    return (
        <RequireAuth>
            <RequireDesktop>
                <ManageSubscriptionDialogProvider>
                    <Chatbot />
                    <DashboardInner />
                </ManageSubscriptionDialogProvider>
            </RequireDesktop>
        </RequireAuth>
    );
})