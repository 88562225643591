import React from "react"
import { MessageDialog } from "./message-dialog";
import { DropdownClassName } from "components/constants/class-names";
import { MessageDialogContentInner, MessageDialogContentInnerProps } from "./message-dialog-content";
import { classNames } from "core/utils/classname-utils";



export function UploadClothObjectDialog({
    image,
    onClose,
}: {
    image: HTMLImageElement,
    onClose?: () => void,
}) {
    const [open, setOpen] = React.useState(true);
    const [step, setStep] = React.useState(0);

    React.useEffect(() => {
        if (!open) {
            onClose?.();
        }
    }, [open, onClose]);

    const handleOpenChange = React.useCallback((value: boolean, forceClose = false) => {
        setOpen(value);
    }, []);

    const messageDialogProps: MessageDialogContentInnerProps[] = React.useMemo(() => ([
        {
            type: 'categorize-cloth',
            image,
            setOpen,
            onClickNext: () => {
                setOpen(false);
            },
            onClickPrev: () => {
                setStep(step => step - 1);
            },
        }
    ]), [
        image,
    ]);

    return (
        <MessageDialog
            open={open}
            onOpenChange={handleOpenChange}
            contentChildren={
                <div
                    className={classNames(
                        DropdownClassName,
                        'min-w-[80vw] md:min-w-[680px] max-h-[80vh] px-6 py-5 flex flex-col items-center',
                    )}
                >
                    <MessageDialogContentInner
                        {...messageDialogProps[step % messageDialogProps.length]}
                        step={step + 1}
                        isEnd={step === messageDialogProps.length - 1}
                    />
                </div>
            }
        />
    )
}