import React from "react"
import { classNames } from "core/utils/classname-utils";


export function LeftPanelSectionContainer({
    label,
    children,
    className,
    ...props
}: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
    label?: string,
}) {
    return (
        <div
            className='flex flex-col'
        >
            {label && <div
                className="font-semibold mb-2"
            >
                {label}
            </div>}
            <div
                className={classNames(
                    'flex-1 flex flex-col',
                    className ?? '',
                )}
                {...props}
            >
                {children}
            </div>
        </div>
    )
}