import React from 'react';
import { InputBoxClassName } from 'components/constants/class-names';
import { SmartphoneOff } from 'components/icons/smartphone-off';
import { isMobile } from 'components/utils/is-mobile';
import { editorContextStore } from 'contexts/editor-context';
import { classNames } from 'core/utils/classname-utils';
import { isValidEmail } from 'core/utils/string-utils';
import { Monitor, Send } from 'lucide-react';
import { Navigate } from 'react-router-dom';
import { FullScreenAuthContainer } from './auth-conatiner';



export function RequireDesktop({
    children,
}: {
    children: React.ReactNode,
}) {
    const [isMobileBrowser, setIsMobileBrowser] = React.useState(false);
    React.useEffect(() => {
        setIsMobileBrowser(isMobile({
            tablet: true,
            featureDetect: true,
        }));
    }, []);
    return (
        <>{children}</>
    );
}

function isDefaultEmailSent(uid: string) {
    return localStorage.getItem(`${uid}_mobile-redirect-default-sent`) === 'true';
}

function setDefaultEmailSent(uid: string, sent: boolean = true) {
    localStorage.setItem(`${uid}_mobile-redirect-default-sent`, sent ? 'true' : 'false');
}

function NoPhoneMessage({ destination }: { destination?: 'customModels' | 'video' |'workflows' }) {
    let text;
    switch (destination) {
        case 'customModels':
            text = <>Flair <b>Custom Models</b> only work on <span className="text-lime-500">desktop</span> currently.</>
            break;
        case 'video':
            text = <>Flair <b>Video Creation</b> only works on <span className="text-lime-500">desktop</span> currently.</>
            break;
        case 'workflows':
            text = <>Flair <b>Workflows</b> only work on <span className="text-lime-500">desktop</span> currently.</>
            break;
        default:
            text = <>Flair only works on <span className="text-lime-500">desktop</span> currently.</>
    }
    return (
        <>
            <div className='w-full flex flex-row items-center justify-center'>
                <Monitor />
                <div className='w-4' />
                <SmartphoneOff />
            </div>
            <div className='h-4' />
            <div className='text-xl text-center font-semibold text-zinc-300'>
                {text}
            </div>
        </>
    )
}

export function MobileRedirectErrorFullscreen() {
    return (
        <FullScreenAuthContainer>
            <div className='h-4' />
            <NoPhoneMessage />
            <div className='h-4' />
            <div className='text-md text-zinc-500'>
                Please login again on computer 💻.
            </div>
            <div className='h-4' />
        </FullScreenAuthContainer>
    );
}

export function MobileRedirectFullscreen({ destination }: { destination?: 'customModels' | 'video' | 'workflows' }) {
    const [email, setEmail] = React.useState('');
    const [name, setName] = React.useState('');
    const [isEmailSent, setEmailSent] = React.useState(false);
    const [isEmailValid, setEmailValid] = React.useState(false);
    const [isLoading, setLoading] = React.useState(false);
    const isDefaultEmailSentRef = React.useRef(false);
    const user = editorContextStore(state => state.user);
    React.useEffect(() => {
        if (!email) {
            return setEmailValid(false);
        }
        setEmailValid(isValidEmail(email));
    }, [email]);
    React.useEffect(() => {
        if (!user || isDefaultEmailSentRef.current) {
            return;
        }
        const email = user.email;
        if (!email) {
            return;
        }
        const name = user.displayName?.split(' ')[0].trim();
        setEmail(user.email);
        if (name) {
            setName(name);
        }
        const isDefaultSent = isDefaultEmailSent(user.uid);
        if (!isDefaultSent) {
            console.log('Default email is already sent');
            return;
        }
        if (email) {
            setLoading((isLoading) => {
                if (isLoading) {
                    return true;
                }
                const {
                    backend,
                } = editorContextStore.getState();
                isDefaultEmailSentRef.current = true;
                backend?.sendEmailRedirectLink({
                    email,
                    name,
                    noAuthToken: false,
                })?.then(() => {
                    setTimeout(() => {
                        setDefaultEmailSent(user.uid);
                        setEmailSent(true);
                        setLoading(false);
                    }, 1000);
                });
                return true;
            });
        }
    }, [user]);
    return (
        <FullScreenAuthContainer>
            <div className='h-4' />
            <NoPhoneMessage destination={destination} />
            <div className='h-4' />
            <div className='mb-4 text-md text-center text-zinc-500'>
                {
                    isEmailSent ?
                        `We have emailed a login link to ${email}. Please login again on desktop.` :
                        'Please enter your email below so that we can send you a login link.'
                }
            </div>
            <input
                type="email"
                className={classNames(
                    InputBoxClassName,
                    'text-center'
                )}
                value={email}
                onChange={(e) => setEmail(e.currentTarget.value)}
            />
            <button
                type="button"
                className={classNames(
                    "w-full rounded-md border-solid border border-zinc-700 transition-colors my-4 px-2 py-2 flex flex-row justify-center items-center shadow-md box-border",
                    isLoading ?
                        "cursor-wait" :
                        isEmailValid ? "cursor-pointer text-zinc-300 hover:text-lime-500 hover:border-lime-500" : "cursor-not-allowed text-zinc-500",
                )}
                onClick={() => {
                    if (isLoading || !isEmailValid) {
                        return;
                    }
                    setLoading(true);
                    const {
                        backend,
                    } = editorContextStore.getState();
                    backend?.sendEmailRedirectLink({
                        email,
                        name,
                        noAuthToken: true,
                    })?.then(() => {
                        setTimeout(() => {
                            setEmailSent(true);
                            setLoading(false);
                        }, 1000);
                    });
                }}
            >
                <Send size={18} className="mr-2" />
                {
                    isLoading ?
                        "Sending email ..." :
                        isEmailValid ?
                            isEmailSent ?
                                "Click to resend login link" :
                                "Click to send login link" :
                            "Please enter a valid email"
                }
            </button>
        </FullScreenAuthContainer>
    )
}