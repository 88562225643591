import React from 'react';
import { initObjects } from 'objects';
import { AppRoutes } from 'components/app-routes';
import { MobileAppRoutes } from 'components-mobile/app-routes'
import * as Tooltip from '@radix-ui/react-tooltip';
import { useAuthUpdateEffect } from 'hooks/use-auth-update';
import { InviteCodeContextProvider } from 'components/auth/invite-code';
import { editorContextStore, initEditorContextVanillaStore } from 'contexts/editor-context';
import { ProjectInitSceneContextProvider } from 'contexts/project-init-scene-context';
import { useCheckMobileEffect } from 'hooks/use-mobile-context';
import { useFeatureFlagsEffect } from 'hooks/use-featureFlags';
import { MessageToast } from 'components/popup/message-toast';

import {EditorPopups as DesktopEditorPopups} from 'components/popup/popups';
import {EditorPopups as MobileEditorPopups} from 'components-mobile/popup/popups';
import { useUserStripeSubscriptionUpdateEffect } from 'hooks/use-user-stripe-subscription-update';
import { usePricingConfigUpdateEffect } from 'hooks/use-pricing-config-update';
import { UiDisplayMessageDialogEventHandler } from 'core/common/types';

initObjects();
initEditorContextVanillaStore();

function Routes() {
  const isMobile = editorContextStore(state => state.isMobile);

  return (
    isMobile ? (
      <MobileAppRoutes />
    ) : (
      <AppRoutes />
    )
  );
}

function Popups() {
  const isMobile = editorContextStore(state => state.isMobile);

  return isMobile ? <MobileEditorPopups/> : <DesktopEditorPopups/>;
}

function App() {

  React.useEffect(() => {
    const handleWheelEvent = (e: WheelEvent) => {
      if (e.ctrlKey) {
        e.preventDefault(); //prevent zoom
      }
      // prevent horizontal scrolling
      if (e.deltaX !== 0) {
        e.preventDefault();
      }
    }
    const handleKeyEvent = (e: KeyboardEvent) => {
      if (!e.ctrlKey) {
        return;
      }
      if (e.code === 'Minus' || e.code === 'Equal') {
        e.preventDefault();
      }
    }
    document.body.addEventListener("wheel", handleWheelEvent, { passive: false });
    window.addEventListener("keydown", handleKeyEvent);
    return () => {
      document.body.removeEventListener("wheel", handleWheelEvent);
      window.removeEventListener("keydown", handleKeyEvent);
    }
  }, []);

  useAuthUpdateEffect();

  useCheckMobileEffect();

  useFeatureFlagsEffect();

  useUserStripeSubscriptionUpdateEffect();

  usePricingConfigUpdateEffect();

  // React.useEffect(() => {
  //   if (process.env.NODE_ENV === 'development') {
  //     editorContextStore.getState().eventEmitter.emit<UiDisplayMessageDialogEventHandler>(
  //       'ui:display-message-dialog',
  //       'quota-subscribe',
  //       {
  //           title: 'No custom model credits left.',
  //           header: "You have used all custom model credits.",
  //       },
  //   );
  //   }
  // }, []);

  return (
    <Tooltip.Provider>
      <InviteCodeContextProvider>
        <ProjectInitSceneContextProvider>
          <Routes />
          <MessageToast/>
          <Popups/>
        </ProjectInitSceneContextProvider>
      </InviteCodeContextProvider>
    </Tooltip.Provider>
  );
}

export default App;
