import React, { createContext, useContext, useState } from 'react';
import { OptionItem } from 'components/dashboard/api/options-input';
import { CustomModelTrainingBackendType, CustomModelTrainingInput } from 'core/common/types';

export enum CustomModelLearningRate {
    VerySlow = "VerySlow",
    Slow = "Slow",
    Fast = "Fast",
    VeryFast = "VeryFast",
    None = "None",
}


export const customModelLearningRateOptionItems: OptionItem<CustomModelLearningRate>[] = [
    // {
    //     name: "None (is this even possibruh?)",
    //     value: CustomModelLearningRate.None,
    // },
    {
        name: "Very Slow (for large moodboards)",
        value: CustomModelLearningRate.VerySlow,
    },
    {
        name: "Slow (for difficult concepts)",
        value: CustomModelLearningRate.Slow,
    },
    {
        name: "Fast (for most use cases)",
        value: CustomModelLearningRate.Fast,
    },
    {
        name: "Very fast (might produce artifacts).",
        value: CustomModelLearningRate.VeryFast,
    },
];

const secondsPerStep = 2.5;

export function getDurationSecondsFromTrainingSteps(steps: number) {
    return secondsPerStep * steps;
}

export interface CustomModelTrainingInputContextType {
    trainingStrengthPercent: number;
    setTrainingStrengthPercent: React.Dispatch<React.SetStateAction<number>>;
    trainingBackendType: CustomModelTrainingBackendType;
    setTrainingBackendType: React.Dispatch<React.SetStateAction<CustomModelTrainingBackendType>>;
}


const CustomModelTrainContext = createContext<CustomModelTrainingInputContextType | undefined>(undefined);

export const useCustomModelTraininingInput = () => {
    const context = useContext(CustomModelTrainContext);
    if (!context) {
        throw new Error("useCustomModelTrain must be used within a CustomModelTrainProvider");
    }
    return context;
};

export const CustomModelTrainingInputProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {

    const [
        trainingStrengthPercent,
        setTrainingStrengthPercent,
    ] = useState(0.5);

    const [
        trainingBackendType,
        setTrainingBackendType,
    ] = useState(CustomModelTrainingBackendType.Fal);

    return (
        <CustomModelTrainContext.Provider
            value={{
                trainingStrengthPercent,
                setTrainingStrengthPercent,
                trainingBackendType,
                setTrainingBackendType,
             }}
        >
            {children}
        </CustomModelTrainContext.Provider>
    );
};

const learningRateToTrainingInput: Record<CustomModelLearningRate, Partial<CustomModelTrainingInput>> = {
    [CustomModelLearningRate.VerySlow]: {
        learning_rate: 1e-5,
    },
    [CustomModelLearningRate.Slow]: {
        learning_rate: 5e-5,
    },
    [CustomModelLearningRate.Fast]: {
        learning_rate: 4e-4,
    },
    [CustomModelLearningRate.VeryFast]: {
        learning_rate: 8e-4,
    },
    [CustomModelLearningRate.None]: {},
}


export function getTrainingInputFromLearningRate(learningRate: CustomModelLearningRate) {
    return learningRateToTrainingInput[learningRate];
}