import { editorContextStore } from 'contexts/editor-context';
import { AppUserQuotas, RealTimeRenderSaveResultEventHandler, UiDisplayMessageDialogEventHandler } from 'core/common/types';
import { RealTimeRenderMode } from 'core/common/types/realtime-render';
import { isRealTimeRenderAvailable } from 'core/utils/quota-utils';
import React from 'react';

function tryDisplaySusbcribeMessageDialog(userQuotas: AppUserQuotas | null) {
    const {
        editor,
        setRealtimeRenderMode,
    } = editorContextStore.getState();

    if (!isRealTimeRenderAvailable(userQuotas)) {


        editor?.emit<UiDisplayMessageDialogEventHandler>(
            'ui:display-message-dialog',
            'realtime-subscribe',
            {},
        );

        editor?.emit<RealTimeRenderSaveResultEventHandler>(
            'realtime-render:save-result',
        );

        setRealtimeRenderMode(RealTimeRenderMode.Disabled);
    }
}

export function useRealTimeQuotaUpdate() {

    const userQuotas = editorContextStore(state => state.userQuotas);

    const displayedDialogRef = React.useRef(false);

    React.useEffect(() => {
        const {
            editor,
            realtimeRenderMode,
        } = editorContextStore.getState();

        if (!editor) {
            return;
        }

        if (realtimeRenderMode === RealTimeRenderMode.Disabled) {

            if (displayedDialogRef.current) {
                // No need to display the dialog again
                return;
            }

            displayedDialogRef.current = true;

            tryDisplaySusbcribeMessageDialog(userQuotas);

        } else {

            tryDisplaySusbcribeMessageDialog(userQuotas);

        }


    }, [userQuotas]);
}
