import React, { PropsWithChildren } from 'react';
import { fabric } from 'fabric';
import { editorContextStore } from 'contexts/editor-context';
import { FloatTagButtonWithTooltip, FloatTagButtonWithTooltipProps } from './float-tag-button';
import { Editor } from 'core/editor';
import { ArrowDown, ArrowUp, Box, Camera, Pause, Play, Scaling, Sparkles } from 'lucide-react';
import { isStaticImageObject3d } from 'core/common/types/3d';
import { isActiveSelection, isGenerationFrame, isStaticImageObject, isStaticImageObjectGenerated, isStaticImageObjectVideoGenerationResult } from 'core/utils/type-guards';
import { SetObjectEditImageProgressControllerEventHandler, StartUpscaleV2Job, UiDisplayMessageEventHandler } from 'core/common/types';
import { EditorActiveObject } from 'core/common/interfaces';
import { EditImageProgressController, ObjectWithProgress } from 'components/panels/panel-items/edit/edit-image-process';
import { classNames } from 'core/utils/classname-utils';
import { debugError, debugLog } from 'core/utils/print-utilts';
import { StatcImageVideoGenerationMetadata } from 'core/common/types/elements';
import { GenerateVideoLeftPanelTab, VideoPlayStatus } from 'core/common/types/video';
import { getVideoFrameAsDataURL, setStaticImageObjectVideoGenerationResultVideoElement } from 'core/utils/video-utils';
import { EditorCanvasRenderMode } from 'core/common/types/editor-canvas-render-mode';
import { SimpleSpinner } from 'components/icons/simple-spinner';

function Divider() {
    return (
        <div className='w-px' />
    );
}

const ButtonClassName = "flex flex-row items-center justify-center text-xs gap-1 font-semibold"

const ArrowButtonClassName = classNames(
    ButtonClassName,
    "group px-1.5",
);

const ArrowButtonIconClassName = classNames(
    ButtonClassName,
    "text-zinc-500 group-hover:text-zinc-300 transition-colors",
);

const IconSize = 14;

function HotKey({
    children,
}: PropsWithChildren) {
    return (
        <div className='w-5 h-5 flex items-center justify-center text-center text-xs bg-zinc-800 border border-zinc-700 shadow-md rounded'>
            {children}
        </div>
    )
}

function BringToFrontButton({
    editor,
    showText = true,
}: {
    editor: Editor | null,
    showText?: boolean,
}) {
    return (
        <FloatTagButtonWithTooltip
            className={ArrowButtonClassName}
            tooltipChildren={(
                <span className='flex flex-row items-center gap-2'>
                    Bring to front
                    <HotKey>
                        {"["}
                    </HotKey>
                </span>
            )}
            onClick={() => {
                editor?.objects.bringToFront();
            }}
        >
            <ArrowUp size={IconSize} className={ArrowButtonIconClassName} />
            {showText && "Front"}
        </FloatTagButtonWithTooltip>
    )
}

function SendToBackButton({
    editor,
    showText = true,
}: {
    editor: Editor | null,
    showText?: boolean,
}) {
    return (
        <FloatTagButtonWithTooltip
            className={ArrowButtonClassName}
            tooltipChildren={(
                <span className='flex flex-row items-center gap-2'>
                    Send to back
                    <HotKey>
                        {"]"}
                    </HotKey>
                </span>
            )}
            onClick={() => {
                editor?.objects.sendToBack();
            }}
        >
            <ArrowDown size={IconSize} className={ArrowButtonIconClassName} />
            {showText && "Back"}
        </FloatTagButtonWithTooltip>
    )
}


function GenerateVideoButton({
    editor,
}: {
    editor: Editor | null,
}) {
    return (
        <FloatTagButtonWithTooltip
            className={classNames(
                ButtonClassName,
            )}
            tooltipChildren="Generate video from this image."
            onClick={() => {
                if (!editor) {
                    return;
                }

                const {
                    setActiveLeftPanels,
                    setGenerateVideoLeftPanelTab,
                } = editor.state;

                setGenerateVideoLeftPanelTab(GenerateVideoLeftPanelTab.Guide);

                setActiveLeftPanels((activeLeftPanels) => {
                    return [
                        ...activeLeftPanels,
                        'GenerateVideo',
                    ]
                });
            }}
        >
            <Sparkles size={IconSize} className="text-zinc-500" />
            Animate
        </FloatTagButtonWithTooltip>
    )
}

function UpscaleButton({
    editor,
    activeObject,
}: {
    editor: Editor | null,
    activeObject: EditorActiveObject,
}) {
    const [disabled, setDisabled] = React.useState(false);
    const [editImageController, setEditImageController] = React.useState<EditImageProgressController | undefined>();

    React.useEffect(() => {
        if (!editor) {
            setDisabled(true);
            return;
        }

        if (!activeObject || !isStaticImageObject(activeObject)) {
            setDisabled(true);
            return;
        }

        const handleSetController = ({
            object
        }: {
            object: fabric.StaticImage,
        }) => {
            if (!object || object.id !== activeObject.id || object.type !== activeObject.type) {
                return;
            }
            const controller = (object as any as ObjectWithProgress).editImageProgressController;
            if (!controller) {
                return;
            }
            setEditImageController(controller);
        }

        handleSetController({
            object: activeObject,
        });

        editor.on<SetObjectEditImageProgressControllerEventHandler>(
            'object:set-edit-image-progress-controller',
            handleSetController,
        );
        return () => {
            editor.off<SetObjectEditImageProgressControllerEventHandler>(
                'object:set-edit-image-progress-controller',
                handleSetController,
            );
        }
    }, [editor, activeObject]);

    React.useEffect(() => {
        if (editImageController?.isDestroyed === false) {
            setDisabled(true);

            const handleFinish = () => {
                setDisabled(false);
            };

            editImageController.on(EditImageProgressController.PROGRESS_FINISH_EVENT, handleFinish);
            editImageController.on(EditImageProgressController.DESTROY_EVENT, handleFinish);

            return () => {
                editImageController.off(EditImageProgressController.PROGRESS_FINISH_EVENT, handleFinish);
                editImageController.off(EditImageProgressController.DESTROY_EVENT, handleFinish);
            };
        } else {
            setDisabled(false);
        }
    }, [editImageController]);

    return (
        <FloatTagButtonWithTooltip
            className={classNames(
                ButtonClassName,
            )}
            disabled={disabled}
            tooltipChildren="Upscale the image to 2k resolution with details."
            onClick={() => {
                if (disabled) {
                    debugLog("Processing ...");
                    return;
                }

                setDisabled(true);

                editor?.state.setActiveLeftPanels((activeLeftPanels) => {
                    return [
                        ...activeLeftPanels,
                        'UpscaleV2',
                    ]
                });

                setTimeout(() => {
                    editor?.emit<StartUpscaleV2Job>('upscale-v2:start-job');
                }, 100);
            }}
        >
            <Scaling size={IconSize} className="text-zinc-500" />
            Upscale
        </FloatTagButtonWithTooltip>
    )
}

function Edit3dButton({
    editor,
}: {
    editor: Editor | null,
}) {
    return (
        <FloatTagButtonWithTooltip
            className={ButtonClassName}
            tooltipChildren="Rotate 3D prop"
            onClick={() => {
                editorContextStore.getState().setActiveLeftPanels((prevLeftPanels) => ([
                    ...prevLeftPanels,
                    'TransformProps3d',
                ]));
            }}
        >
            <Box size={IconSize} className="text-zinc-500" />
            3D
        </FloatTagButtonWithTooltip>
    )
}

function DefaultObjectFloatTag({
    editor,
    onComponentMount,
    onComponentUnmount,
}: {
    editor: Editor | null,
    onComponentMount: () => void,
    onComponentUnmount: () => void,
}) {
    React.useEffect(() => {
        onComponentMount();
        return () => onComponentUnmount();
    }, [onComponentMount, onComponentUnmount]);

    return (
        <>
            <BringToFrontButton
                editor={editor}
            />
            <Divider />
            <SendToBackButton
                editor={editor}
            />
            <Divider/>
            <GenerateVideoButton
                editor={editor}
            />
        </>
    )
}

function PlayVideoButton({
    className = "",
    tooltipChildren = "Click to play this video.",
    ...props
}: FloatTagButtonWithTooltipProps) {
    return (
        <FloatTagButtonWithTooltip
            {...props}
            className={classNames(
                ButtonClassName,
                className,
            )}
            tooltipChildren={tooltipChildren}
        >
            <Play size={IconSize} className="text-zinc-500" />
            <span className='truncate'>
                Play
            </span>
        </FloatTagButtonWithTooltip>
    );
}

function StopVideoButton({
    className = "",
    tooltipChildren = "Click to stop playing this video.",
    ...props
}: FloatTagButtonWithTooltipProps) {
    return (
        <FloatTagButtonWithTooltip
            {...props}
            className={classNames(
                ButtonClassName,
                className,
            )}
            tooltipChildren={tooltipChildren}
        >
            <Pause size={IconSize} className="text-zinc-500" />
            <span className='truncate'>
                Pause
            </span>
        </FloatTagButtonWithTooltip>
    );
}

function LoadingVideoButton({
    className = "",
    tooltipChildren = "Click to stop playing this video.",
    ...props
}: FloatTagButtonWithTooltipProps) {
    return (
        <FloatTagButtonWithTooltip
            {...props}
            className={classNames(
                ButtonClassName,
                className,
            )}
            tooltipChildren={tooltipChildren}
            disabled
        >
            <SimpleSpinner width={IconSize} height={IconSize} pathClassName="fill-lime-500" />
            <span className='truncate'>
                Loading
            </span>
        </FloatTagButtonWithTooltip>
    );
}

function ScreenshotVideoButton({
    className = "",
    tooltipChildren = "Take a screenshot of the video and add it to the canvas.",
    ...props
}: FloatTagButtonWithTooltipProps) {
    return (
        <FloatTagButtonWithTooltip
            {...props}
            className={classNames(
                ButtonClassName,
                className,
            )}
            tooltipChildren={tooltipChildren}
        >
            <Camera size={IconSize} className="text-zinc-500" />
            <span className='truncate'>
                Save
            </span>
        </FloatTagButtonWithTooltip>
    );
}

function VideoControlButton({
    editor,
    activeObject,
}: {
    editor: Editor | null,
    activeObject: fabric.StaticImage & {
        metadata: StatcImageVideoGenerationMetadata,
    },
}) {
    const backend = editorContextStore(state => state.backend);

    const [videoPlayStatus, setVideoPlayStatus] = React.useState(VideoPlayStatus.Idle);

    const playVideo = React.useCallback((videoElement: HTMLVideoElement) => {
        setVideoPlayStatus(VideoPlayStatus.Playing);

        videoElement.play();

        editorContextStore.getState().setEditorCanvasRenderMode(EditorCanvasRenderMode.Loop);
    }, []);

    const pauseVideo = React.useCallback((videoElement: any) => {
        setVideoPlayStatus(VideoPlayStatus.Stopped);

        if (videoElement instanceof HTMLVideoElement) {
            videoElement.pause();
        }

        editorContextStore.getState().setEditorCanvasRenderMode(EditorCanvasRenderMode.Lazy);
    }, []);

    const screenshotVideo = React.useCallback(async (videoElement: any) => {
        if (!editor) {
            return;
        }

        if (!(videoElement instanceof HTMLVideoElement)) {
            editor?.emit<UiDisplayMessageEventHandler>(
                'ui:display-message',
                'error',
                'Video is not loaded yet.',
            );

            return;
        }

        const isPaused = videoElement.paused || videoElement.ended;

        videoElement.pause();

        try {

            const screenshotUrl = await getVideoFrameAsDataURL(videoElement);

            const center = activeObject.getCenterPoint();

            const location = center.setX(
                center.x + activeObject.getScaledWidth(),
            );

            const screenshotImageObject = await editor.objects.addImageFromUrl({
                url: screenshotUrl,
                location,
                uploadStorage: true,
                setActive: false,
                targetHeight: activeObject.getScaledHeight(),
            });

            debugLog(`Added screenshot to canvas as object ${screenshotImageObject?.id}`);

        } catch (error) {

            debugError('Error taking a screenshot of the video element: ', error);

            editor?.emit<UiDisplayMessageEventHandler>(
                'ui:display-message',
                'error',
                'Error taking a screenshot of the video.',
            );

        } finally {
            if (!isPaused) {
                videoElement.play();
            }
        }
    }, [editor, activeObject]);

    React.useEffect(() => {

        if (activeObject.getElement() instanceof HTMLVideoElement) {
            setVideoPlayStatus(VideoPlayStatus.Stopped);
        } else {
            setVideoPlayStatus(VideoPlayStatus.Idle);
        }

        return () => {
            const currentElement = activeObject.getElement();

            pauseVideo(currentElement);
        }
    }, [activeObject, pauseVideo]);

    return (
        <>
        {videoPlayStatus === VideoPlayStatus.Loading ?
        <LoadingVideoButton/> :
        (
            videoPlayStatus === VideoPlayStatus.Idle ||
            videoPlayStatus  === VideoPlayStatus.Stopped
        ) ?
        <PlayVideoButton
            onClick={() => {
                const currentElement = activeObject.getElement();

                if (currentElement instanceof HTMLVideoElement) {

                    playVideo(currentElement);

                } else {
                    setVideoPlayStatus(VideoPlayStatus.Loading);

                    if (!editor || !backend) {
                        return;
                    }

                    setStaticImageObjectVideoGenerationResultVideoElement({
                        editor,
                        backend,
                        object: activeObject,
                    }).then((response) => {
                        if (response.isLoaded) {
                            playVideo(response.videoElement);
                        } else {
                            editor.emit<UiDisplayMessageEventHandler>(
                                'ui:display-message',
                                'error',
                                'Canont load video.',
                            );
                        }
                    }).catch((e) => {
                        debugError(`Error loading video from ${activeObject.metadata?.videoGenerationId}: `, e);

                        editor.emit<UiDisplayMessageEventHandler>(
                            'ui:display-message',
                            'error',
                            'Error loading video.',
                        );
                    });
                }

            }}
        /> :
        <StopVideoButton
            disabled={videoPlayStatus !== VideoPlayStatus.Playing}
            onClick={() => {
                if (videoPlayStatus !== VideoPlayStatus.Playing) {
                    return;
                }

                pauseVideo(activeObject.getElement());
            }}
        />}
        {
            <ScreenshotVideoButton
                disabled={videoPlayStatus !== VideoPlayStatus.Stopped}
                onClick={() => {
                    if (videoPlayStatus !== VideoPlayStatus.Stopped) {
                        return;
                    }

                    const currentElement = activeObject.getElement();

                    screenshotVideo(currentElement);
                }}
            />
        }
        </>
    )
}

function GeneratedVideoFloatTag({
    editor,
    activeObject,
    onComponentMount,
    onComponentUnmount,
}: {
    editor: Editor | null,
    activeObject: fabric.StaticImage & {
        metadata: StatcImageVideoGenerationMetadata,
    },
    onComponentMount: () => void,
    onComponentUnmount: () => void,
}) {
    React.useEffect(() => {
        onComponentMount();
        return () => onComponentUnmount();
    }, [onComponentMount, onComponentUnmount]);

    return (
        <>
            <BringToFrontButton
                editor={editor}
                showText={false}
            />
            <Divider />
            <SendToBackButton
                editor={editor}
                showText={false}
            />
            <Divider/>
            <VideoControlButton
                editor={editor}
                activeObject={activeObject}
            />
        </>
    )
}

function GeneratedObjectFloatTag({
    editor,
    activeObject,
    onComponentMount,
    onComponentUnmount,
}: {
    editor: Editor | null,
    activeObject: EditorActiveObject,
    onComponentMount: () => void,
    onComponentUnmount: () => void,
}) {
    React.useEffect(() => {
        onComponentMount();
        return () => onComponentUnmount();
    }, [onComponentMount, onComponentUnmount]);

    return (
        <>
            <BringToFrontButton
                editor={editor}
                showText={false}
            />
            <Divider />
            <SendToBackButton
                editor={editor}
                showText={false}
            />
            <Divider/>
            <UpscaleButton
                editor={editor}
                activeObject={activeObject}
            />
            <Divider/>
            <GenerateVideoButton
                editor={editor}
            />
        </>
    )
}

function Object3dFloatTag({
    editor,
    onComponentMount,
    onComponentUnmount,
}: {
    editor: Editor | null,
    onComponentMount: () => void,
    onComponentUnmount: () => void,
}) {
    React.useEffect(() => {
        onComponentMount();
        return () => onComponentUnmount();
    }, [onComponentMount, onComponentUnmount]);

    return (
        <>
            <BringToFrontButton
                editor={editor}
            />
            <Divider />
            <SendToBackButton
                editor={editor}
            />
            <Edit3dButton
                editor={editor}
            />
        </>
    )
}

export function ObjectFloatTag({
    onComponentMount,
    onComponentUnmount,
}: {
    onComponentMount: () => void,
    onComponentUnmount: () => void,
}) {
    const editor = editorContextStore(state => state.editor);
    const activeObject = editorContextStore(state => state.activeObject);

    if (!editor || !activeObject) {
        return null;
    }

    if (isGenerationFrame(activeObject as any)) {
        return null;
    }

    if (isActiveSelection(activeObject)) {
        return null;
    }

    if (isStaticImageObject3d(activeObject)) {
        return (
            <Object3dFloatTag
                editor={editor}
                onComponentMount={onComponentMount}
                onComponentUnmount={onComponentUnmount}
            />
        );
    }

    if (isStaticImageObjectGenerated(activeObject)) {
        return (
            <GeneratedObjectFloatTag
                editor={editor}
                activeObject={activeObject}
                onComponentMount={onComponentMount}
                onComponentUnmount={onComponentUnmount}
            />
        );
    }

    if (isStaticImageObjectVideoGenerationResult(activeObject)) {
        return (
            <GeneratedVideoFloatTag
                editor={editor}
                activeObject={activeObject}
                onComponentMount={onComponentMount}
                onComponentUnmount={onComponentUnmount}
            />
        );
    }

    return (
        <DefaultObjectFloatTag
            editor={editor}
            onComponentMount={onComponentMount}
            onComponentUnmount={onComponentUnmount}
        />
    );
}