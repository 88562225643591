import { Timestamp } from "firebase/firestore";

export function sortByTimeModified(
    a: { timeModified?: Timestamp },
    b: { timeModified?: Timestamp },
) {
    const aTimeModified = a.timeModified;
    if (!aTimeModified) {
        return 1; // b before a
    }
    const bTimeModified = b.timeModified;
    if (!bTimeModified) {
        return -1; // a before b
    }
    return bTimeModified.toDate().getTime() - aTimeModified.toDate().getTime();
}

export function sleepSeconds(durationSeconds: number) {
    return new Promise<void>((resolve) => {
        setTimeout(() => {
            resolve();
        }, durationSeconds * 1000);
    });
}

export class TimeoutCallback {
    private timeoutMilliseconds: number;
    private callback: () => unknown;
    private timerId: NodeJS.Timeout | null = null;

    constructor(timeoutSeconds: number, callback: () => unknown) {
        this.timeoutMilliseconds = timeoutSeconds * 1000; // Convert seconds to milliseconds
        this.callback = callback;
    }

    public start(): void {
        this.stop(); // Ensure no previous timer is running
        this.timerId = setTimeout(this.callback, this.timeoutMilliseconds);
    }

    public stop(): void {
        if (this.timerId !== null) {
            clearTimeout(this.timerId);
            this.timerId = null;
        }
    }
}

interface TimeDifferenceParams {
    startTime: Date;
    endTime?: Date;
}

export function getTimeDifferenceInSeconds({
    startTime,
    endTime = new Date(),
}: TimeDifferenceParams): number {
    const differenceInMilliseconds = endTime.getTime() - startTime.getTime();
    const differenceInSeconds = differenceInMilliseconds / 1000;
    return Math.floor(differenceInSeconds); // Rounding down to the nearest second
}

interface SubtractSecondsParams {
    date: Date;
    seconds: number;
}

export function subtractSecondsFromDate({ date, seconds }: SubtractSecondsParams): Date {
    const newDate = new Date(date.getTime() - seconds * 1000);
    return newDate;
}