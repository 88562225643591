import { DashboardCardClassName, DashboardTitleClassName, SecondaryButtonClassName, SecondaryButtonClassNameInactive } from 'components/constants/class-names';
import { CustomModelInfo, CustomModelType, UiDisplayMessageEventHandler } from 'core/common/types';
import React from 'react';
import { Link } from 'react-router-dom';
import * as AspectRatio from '@radix-ui/react-aspect-ratio';
import { editorContextStore } from 'contexts/editor-context';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { classNames } from 'core/utils/classname-utils';
import styles from './dashboard-custom-models.module.css';
import { useCustomModelsEffect, usePublicCustomModelsEffect } from 'hooks/use-custom-models-effect';

import { CustomModelWorkflows } from './dashboard-custom-model-workflows';
import { FloatTagZIndex } from 'components/constants/zIndex';

import { DotsVerticalIcon, Pencil1Icon, TrashIcon } from '@radix-ui/react-icons';
import * as AlertDialogPrimitive from '@radix-ui/react-alert-dialog';
import { Assets } from 'core/controllers/assets';
import { Backend } from 'backend/base';
import { sortByTimeModified } from 'core/utils/time-utils';
import { GridCarousel } from 'components/utils/grid-carousel';

const ASPECT_RATIO = 4/3;

function ModelCardContainer({
    link,
    children,
    model,
}: {
    model?: CustomModelInfo,
    link?: string,
    children?: React.ReactNode,
}) {
    return link ? <Link to={link} state={model}>{children}</Link> : <>{children}</>;
}

function DeleteCustomModelAlert({
    open = true,
    onOpenChange,
    onDelete,
}: {
    open?: boolean,
    onOpenChange: (isOpen: boolean) => void,
    onDelete: () => void,
}) {
    return (
        <AlertDialogPrimitive.Root open={open} onOpenChange={onOpenChange}>
            <AlertDialogPrimitive.Portal>
                <AlertDialogPrimitive.Overlay
                    className={styles.AlertDialogOverlay}
                />
                <AlertDialogPrimitive.Content
                    className='absolute w-full h-full top-0 flex justify-center items-center text-zinc-300'
                    onClick={() => {
                        onOpenChange(false);
                    }}
                >
                    <div
                        className='flex flex-col px-4 py-4 bg-zinc-900 rounded-md border border-zinc-800 shadow-lg-center shadow-zinc-300/10'
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                        }}
                    >
                        <AlertDialogPrimitive.Title
                            className='font-medium text-zinc-300'
                        >
                            Are you sure to delete this custom model?
                        </AlertDialogPrimitive.Title>
                        <div className='flex flex-row mt-4 justify-end items-center'>
                            <AlertDialogPrimitive.Cancel
                                className={`ml-4 px-3 py-1 rounded-md font-medium ${SecondaryButtonClassNameInactive}`}
                            >
                                Cancel
                            </AlertDialogPrimitive.Cancel>
                            <AlertDialogPrimitive.Action
                                className='ml-4 px-3 py-1 rounded-md font-medium bg-red-500 hover:bg-red-600 text-red-100 focus-visible:outline-red-600'
                                onClick={onDelete}
                            >
                                Delete
                            </AlertDialogPrimitive.Action>
                        </div>
                    </div>
                </AlertDialogPrimitive.Content>
            </AlertDialogPrimitive.Portal>
        </AlertDialogPrimitive.Root>
    );
}

function ModelCardMenu({
    setIsEditingName,
    onDelete,
}: {
    setIsEditingName: () => void,
    onDelete: () => void,
}) {
    const [isOpen, setOpen] = React.useState(false);
    return (
        <DropdownMenu.Root
            open={isOpen}
            onOpenChange={setOpen}
        >
            <DropdownMenu.Trigger asChild>
                <div className='flex-none flex justify-center items-center rounded-full bg-zinc-800 hover:text-lime-500 w-7 h-7'>
                    <DotsVerticalIcon />
                </div>
            </DropdownMenu.Trigger>
            <DropdownMenu.Content
                className='bg-zinc-900 border border-zinc-800 text-sm text-slate-700 rounded-lg shadow-md'
                style={{
                    zIndex: FloatTagZIndex,
                }}
            >
                <DropdownMenu.Item
                    className={`${styles.dropdownItem} ${styles.dropdownSelectable} text-zinc-300 hover:bg-zinc-800`}
                    onClick={(e) => {
                        e.preventDefault();
                        setOpen(false);
                        setIsEditingName();
                    }}
                >
                    <Pencil1Icon />
                    <span className='mx-2'>
                        Rename
                    </span>
                </DropdownMenu.Item>
                <DropdownMenu.Item
                    className={`${styles.dropdownItem} ${styles.dropdownSelectable} text-zinc-300 hover:bg-zinc-800`}
                    onClick={(e) => {
                        e.preventDefault();
                        onDelete?.();
                        setOpen(false);
                    }}
                >
                    <TrashIcon className='text-zinc-300' />
                    <span className='mx-2'>
                        Delete
                    </span>
                </DropdownMenu.Item>
            </DropdownMenu.Content>
        </DropdownMenu.Root>
    )
}

function useModelThumbnailUrl({
    model,
    backend,
}: {
    model: CustomModelInfo,
    backend?: Backend,
}) {
    const [thumbnailUrl, setThumbnailUrl] = React.useState<string>();

    React.useEffect(() => {
        if (!backend) {
            return;
        }

        const thumbnail = model.thumbnailStoragePath;
        if (typeof (thumbnail) === 'string') {

            Assets.loadAssetFromPath({
                backend,
                path: thumbnail,
            }).then((thumbnailUrl) => {
                if (!thumbnailUrl) {
                    return;
                }

                setThumbnailUrl(thumbnailUrl);
            })

        }
    }, [backend, model]);

    return thumbnailUrl;
}

function PublicModelCard({
    model,
    link,
}: {
    model: CustomModelInfo,
    link?: string,
}) {
    const backend = editorContextStore(state => state.backend);

    const thumbnailUrl = useModelThumbnailUrl({
        model,
        backend,
    });

    return (
        <ModelCardContainer
            link={link}
            model={model}
        >
            <div className={classNames(
                DashboardCardClassName,
                'flex items-center justify-start group transition-colors rounded-md',
                'pb-[90%] relative overflow-hidden'
                )}>
                <div
                    className="absolute pointer-events-none select-none inset-0 transition-all duration-300 bg-cover bg-center group-hover:scale-110"
                    style={{
                        backgroundImage: thumbnailUrl ? `url(${thumbnailUrl})` : ''
                    }}
                ></div>
                <div className='absolute w-full bg-zinc-900 bottom-0 flex flex-row items-center px-3 py-3'>
                    <div className='flex-1 mr-2 truncate'>
                        <span>
                            {model.displayName}
                        </span>
                    </div>
                </div>
            </div>
        </ModelCardContainer>
    )
}

function ModelCard({
    modelId,
    model,
    link,
    onDelete,
}: {
    modelId: string,
    model: CustomModelInfo,
    link?: string,
    onDelete: () => void,
}) {
    const [displayName, setDisplayName] = React.useState(model.displayName || '');
    const [isEditingName, setIsEditingName] = React.useState(false);
    const textInputRef = React.useRef<HTMLInputElement | null>(null);
    const isEscapeRef = React.useRef(false);
    const backend = editorContextStore(state => state.backend);

    const thumbnailUrl = useModelThumbnailUrl({
        model,
        backend,
    });

    return (
        <ModelCardContainer
            link={link}
            model={model}
        >
            <div className={DashboardCardClassName}>
                <AspectRatio.Root
                    ratio={ASPECT_RATIO}
                    className='pointer-events-none select-none bg-cover bg-center'
                    style={{
                        backgroundImage: thumbnailUrl ? `url(${thumbnailUrl})` : '',
                    }}
                >
                </AspectRatio.Root>
                <div className='flex flex-row items-center px-3 py-3'>
                <div className='flex-1 mr-2 truncate'>
                    {
                        isEditingName
                            ? <input
                                ref={textInputRef}
                                type="text"
                                name="filename"
                                value={displayName}
                                className="w-full outline outline-1 rounded-sm focus:outline-zinc-800 bg-zinc-900 text-zinc-300"
                                onChange={(e) => {
                                    setDisplayName(e.currentTarget?.value || '');
                                }}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        setDisplayName(e.currentTarget?.value || '');
                                        setIsEditingName(false);
                                    } else if (e.key === 'Escape') {
                                        setIsEditingName(false);
                                        isEscapeRef.current = true;
                                    }
                                }}
                                onBlur={() => setIsEditingName(false)} />
                            : <span>
                                {displayName}
                            </span>
                    }
                    </div>
                    <ModelCardMenu
                        setIsEditingName={() => {
                            setIsEditingName(true);
                        }}
                        onDelete={onDelete}
                    />
                </div>
            </div>
        </ModelCardContainer>
    )
}

const availableModelTypes = [
    CustomModelType.Custom,
    CustomModelType.Fashion,
    CustomModelType.Product,
    CustomModelType.Style,
];

function DashboardExampleCustomModels() {
    const publicCustomModels = editorContextStore(state => state.publicCustomModels);

    const [activeModelType, setActiveModelType] = React.useState(CustomModelType.Fashion);

    return (
        <div className='flex flex-col items-stretch'>
            <div
                className={classNames(
                    DashboardTitleClassName,
                    'my-4 flex flex-row items-center',
                )}
            >
                <span className='truncate mr-6'>
                    Try out a few examples
                </span>
                <div className='flex-1 min-[1800px]:flex-none'/>
                <div className='flex flex-row items-center justify-center gap-2.5'>
                    {availableModelTypes.map((modelType) => (
                        <button
                            key={modelType}
                            className={classNames(
                                "flex flex-row items-center justify-center min-w-[2rem] px-4 py-2 text-xs font-semibold rounded-full transition-colors",
                                activeModelType === modelType ?
                                    "bg-zinc-800 text-zinc-300" :
                                    "hover:bg-zinc-800/50 active:bg-zinc-900 text-zinc-500 hover:text-zinc-300 cursor-pointer",
                            )}
                            onClick={() => {
                                setActiveModelType(modelType);
                            }}
                        >
                            {modelType === CustomModelType.Custom ? "All" : modelType}
                        </button>
                    ))}
                </div>
            </div>
            <div className='flex flex-row w-full'>
                <GridCarousel
                    columnsPerPage={5}
                    maxItemWidth={350}
                    className='mb-5 w-full'
                >
                    {
                        Object.entries(publicCustomModels)
                        .sort(([, a], [, b]) => sortByTimeModified(a, b))
                        .filter(([, {customModelType}]) => customModelType === activeModelType || activeModelType === CustomModelType.Custom)
                        .map(([, model]) => (
                            <PublicModelCard
                                key={model.id}
                                model={model}
                                link={`/models/${model.id}?tab=play`}
                            />
                        ))
                    }
                </GridCarousel>
            </div>
        </div>
    )
}

export function DashboardCustomModels({
    visible = true,
}: {
    visible?: boolean,
}) {
    const backend = editorContextStore(state => state.backend);
    const eventEmitter = editorContextStore(state => state.eventEmitter);
    const customModels = editorContextStore(state => state.customModels);
    const setCustomModels = editorContextStore(state => state.setCustomModels);

    const [isAlertOpen, setIsAlertOpen] = React.useState(false);

    const currentModelIdRef = React.useRef<string>();

    useCustomModelsEffect();

    usePublicCustomModelsEffect();

    const customModelsSorted = React.useMemo(() => {
        return Object.entries(customModels).sort(([,a], [,b]) => {
            return sortByTimeModified(a, b);
        });
    }, [customModels]);

    return (
        <div className={classNames(
            visible ? 'w-full flex flex-col' : 'hidden',
            'p-4',
        )}>
            <CustomModelWorkflows />
            <DashboardExampleCustomModels/>
            <div
                className={classNames(
                    DashboardTitleClassName,
                    'my-4',
                )}
            >
                Your models
            </div>
            <div
                className={classNames(
                    styles.DashboardGrid,
                )}
            >
                {
                    customModelsSorted.map(([modelId, model]) => (
                        <ModelCard
                            key={modelId}
                            modelId={modelId}
                            model={model}
                            link={`/models/${modelId}`}
                            onDelete={() => {
                                setIsAlertOpen(true);
                                console.log('Set alert box to be open');
                                currentModelIdRef.current = modelId;
                            }}
                        />
                    ))
                }
            </div>
            <DeleteCustomModelAlert
                open={isAlertOpen}
                onOpenChange={setIsAlertOpen}
                onDelete={() => {
                    const modelId = currentModelIdRef.current;

                    if (!modelId) {
                        return;
                    }

                    setCustomModels(models => {
                        delete models[modelId];
                        return models;
                    });

                    backend?.deleteCustomModel({
                        modelId,
                    }).then((response) => {
                        if (!response.ok) {
                            eventEmitter.emit<UiDisplayMessageEventHandler>(
                                'ui:display-message',
                                'error',
                                response.message,
                            );
                        }
                    });
                }}
            />
        </div>
    );
}