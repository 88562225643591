import React from "react";
import { fabric } from "fabric"
import { editorContextStore } from "contexts/editor-context";
import { isStaticImageObjectVideoGenerationResult } from "core/utils/type-guards";
import { Backend } from "backend/base";
import { isVideoGenerationStatusActive, VideoGenerationDoc, VideoGenerationStatus } from "core/common/types/video";
import { Editor } from "core/editor";
import { StatcImageVideoGenerationMetadata } from "core/common/types/elements";
import { removeItems } from "core/utils/array-utils";
import { debugError } from "core/utils/print-utilts";
import { ObjectLoadingCover } from "components/utils/object-loading-cover";


interface VideoObjectLoadingCoverState {
    objectIds: string[],
    status: VideoGenerationStatus,
    videoGenerationId: string,
    outputVideoStoragePath?: string,
    thumbnailStoragePath?: string,
    unsubscribeToSnapshotCallback: () => void,
    timeModified?: Date,
}

const imageFilterColor = "#18181b";

function createVideoLoadingImageFilter() {
    return new fabric.Image.filters.BlendColor({
        color: imageFilterColor,
        mode: 'tint',
        alpha: 0.5,
    });
}

function updateVideoGenerationObjectFilter({
    object,
    imageFilter,
}: {
    object: any,
    imageFilter: fabric.IBlendImageFilter,
}) {
    try {
        if (!isStaticImageObjectVideoGenerationResult(object)) {
            return;
        }

        const status = object.metadata.videoGenerationStatus;
        if (!isVideoGenerationStatusActive(status)) {
            // Remove all image filters on the object (Maybe we should only remove the current image filter?)
            object.filters = [];
            object.applyFilters();
            return;
        }

        object.filters = [
            imageFilter,
        ];

        object.applyFilters();
    } catch (error) {
        debugError(`Error setting image filter on video generation object ${object.id}`);
    }
}

function updateObjectsMetadataFromVideoLoadingState({
    editor,
    imageFilter,
    loadingCoverState,
}: {
    editor: Editor | null,
    imageFilter: fabric.IBlendImageFilter,
    loadingCoverState: VideoObjectLoadingCoverState,
}) {
    if (!editor) {
        return;
    }

    const newMetadata: StatcImageVideoGenerationMetadata = {
        videoGenerationId: loadingCoverState.videoGenerationId,
        videoGenerationStatus: loadingCoverState.status,
        videoGenerationResultStoragePath: loadingCoverState.outputVideoStoragePath,
        videoGenerationThumbnailStoragePath: loadingCoverState.thumbnailStoragePath,
    };

    const objectIds = new Set(loadingCoverState.objectIds);

    editor.objects
        .find((object) => objectIds.has(object.id))
        .forEach((object) => {
            object.metadata = {
                ...(object.metadata ?? {}),
                ...newMetadata,
            } as StatcImageVideoGenerationMetadata;

            updateVideoGenerationObjectFilter({
                object,
                imageFilter,
            });
        });
}

export function VideoObjectLoadingCovers() {
    const editor = editorContextStore(state => state.editor);

    const backend = editorContextStore(state => state.backend);

    const [videoGenerationsMap, setVideoGenerationsMap] = React.useState<Record<string, VideoObjectLoadingCoverState>>({});

    const imageFilterRef = React.useRef(createVideoLoadingImageFilter());

    React.useEffect(() => {
        Object.values(videoGenerationsMap)
            .filter((loadingCoverState) => loadingCoverState.objectIds.length > 0)
            .forEach((loadingCoverState) => {
                return updateObjectsMetadataFromVideoLoadingState({
                    editor,
                    imageFilter: imageFilterRef.current,
                    loadingCoverState,
                });
            });
    }, [editor, videoGenerationsMap]);

    const handleObjectAdded = React.useCallback(({
        object,
        backend,
    }: {
        object?: fabric.Object | null,
        backend?: Backend,
    }) => {
        if (!backend || !object) {
            return;
        }

        if (!isStaticImageObjectVideoGenerationResult(object)) {
            return;
        }

        const objectId = object.id;
        const videoGenerationId = object.metadata.videoGenerationId;

        updateVideoGenerationObjectFilter({
            object,
            imageFilter: imageFilterRef.current,
        });

        const handleVideoGenerationUpdate = (generation: VideoGenerationDoc | undefined) => {
            if (!generation) {
                setVideoGenerationsMap((loadingState) => {
                    const prevVideoGeneration = loadingState?.[videoGenerationId] as VideoObjectLoadingCoverState | undefined;

                    if (!prevVideoGeneration) {
                        return loadingState;
                    }

                    prevVideoGeneration.unsubscribeToSnapshotCallback?.();

                    return ({
                        ...loadingState,
                        [videoGenerationId]: {
                            ...prevVideoGeneration,
                            status: VideoGenerationStatus.Failed,
                        },
                    });
                });

                return;
            }

            setVideoGenerationsMap((loadingState) => {
                const prevVideoGeneration = loadingState?.[videoGenerationId];

                if (!isVideoGenerationStatusActive(generation.status)) {
                    prevVideoGeneration?.unsubscribeToSnapshotCallback?.();
                }

                return ({
                    ...loadingState,
                    [videoGenerationId]: {
                        ...prevVideoGeneration,
                        status: generation.status,
                        timeModified: generation.timeModified?.toDate() || generation.timeCreated?.toDate(),
                        outputVideoStoragePath: generation.outputVideoStoragePath,
                        thumbnailStoragePath: generation.thumbnailStoragePath,
                    },
                });
            });
        };

        setVideoGenerationsMap((loadingState) => {
            const prevVideoGeneration = loadingState?.[videoGenerationId] as VideoObjectLoadingCoverState | undefined;

            const prevStatus = prevVideoGeneration?.status;

            if (prevStatus && !isVideoGenerationStatusActive(prevStatus)) {
                // No need to subscribe to video generation updates because it is already finished
                return {
                    ...loadingState,
                    [videoGenerationId]: {
                        ...(prevVideoGeneration ?? {}),
                        objectIds: [
                            ...(prevVideoGeneration?.objectIds ?? []),
                            objectId,
                        ],
                    },
                };
            }

            const prevCallback = prevVideoGeneration?.unsubscribeToSnapshotCallback;

            return (
                {
                    ...loadingState,
                    [videoGenerationId]: {
                        ...(prevVideoGeneration ?? {}),
                        objectIds: [
                            ...(prevVideoGeneration?.objectIds ?? []),
                            objectId,
                        ],
                        status: prevStatus ?? object.metadata.videoGenerationStatus,
                        videoGenerationId,
                        unsubscribeToSnapshotCallback: prevCallback || backend.onVideoGenerationDocUpdate({
                            generationId: videoGenerationId,
                            callback: handleVideoGenerationUpdate,
                        }),
                    },
                }
            );
        });
    }, []);

    const handleObjectRemoved = React.useCallback((object: fabric.Object | undefined) => {
        if (!object) {
            return;
        }

        if (!isStaticImageObjectVideoGenerationResult(object)) {
            return;
        }

        const objectId = object.id;
        const videoGenerationId = object.metadata.videoGenerationId;

        setVideoGenerationsMap((loadingState) => {
            const prevVideoGeneration = loadingState?.[videoGenerationId];

            if (!prevVideoGeneration || !prevVideoGeneration.objectIds.includes(objectId)) {
                return loadingState;
            }

            // Remove the objectId
            const newObjectIds = removeItems(prevVideoGeneration.objectIds, objectId);

            // If no more objects, remove the entry and unsubscribe
            if (newObjectIds.length === 0) {
                prevVideoGeneration.unsubscribeToSnapshotCallback?.();
                const { [videoGenerationId]: _, ...rest } = loadingState;
                return rest;
            }

            return {
                ...loadingState,
                [videoGenerationId]: {
                    ...prevVideoGeneration,
                    objectIds: newObjectIds,
                },
            };
        });
    }, []);

    React.useEffect(() => {
        if (!backend || !editor) {
            return;
        }

        const canvas = editor.canvas.canvas;

        if (!canvas) {
            return;
        }

        // Loop through all objects on canvas
        canvas.getObjects().forEach((object) => {
            return handleObjectAdded({
                object,
                backend,
            });
        });

        const onObjectAdded = (e: fabric.IEvent<Event>) => {
            return handleObjectAdded({
                object: e.target,
                backend,
            });
        };

        const onObjectRemoved = (e: fabric.IEvent<Event>) => {
            return handleObjectRemoved(e.target);
        };

        canvas.on('object:added', onObjectAdded);
        canvas.on('object:removed', onObjectRemoved);

        return () => {
            canvas.off('object:added', onObjectAdded);
            canvas.off('object:removed', onObjectRemoved);
        };
    }, [editor, backend, handleObjectAdded, handleObjectRemoved]);

    const activeObject = editorContextStore(state => state.activeObject);

    if (!isStaticImageObjectVideoGenerationResult(activeObject) ||
        !isVideoGenerationStatusActive(videoGenerationsMap[activeObject.metadata.videoGenerationId]?.status)) {
        return null;
    }

    return (
        <>
            <ObjectLoadingCover
                object={activeObject}
                className="bg-transparent"
                message="Generating video ..."
            />
        </>
    );
}