import React from 'react';
import { editorContextStore } from 'contexts/editor-context';
import { isPricingConfigVersion, PricingConfigVersion } from 'core/common/types/pricing-config';


export function usePricingConfigUpdateEffect() {
    const backend = editorContextStore(state => state.backend);

    React.useEffect(() => {
        if (!backend) {
            return;
        }


        const versionEnv = process.env.REACT_APP_PRICING_CONFIG_VERSION ?? "";
        const version = isPricingConfigVersion(versionEnv) ? versionEnv : PricingConfigVersion.Oct9th2024;

        return backend.onPricingConfigUpdate({
            version,
            callback: (pricingConfig) => {
                if (pricingConfig) {
                    editorContextStore.getState().setPricingConfig(pricingConfig);
                }
            },
        });
    }, [backend]);
}