import { collection, deleteDoc, doc, Firestore, getDoc, getDocs, onSnapshot, query, QueryConstraint, QueryDocumentSnapshot, serverTimestamp, setDoc, Timestamp, updateDoc, where } from "firebase/firestore";
import {
    Functions,
} from 'firebase/functions';
import {
    CustomModelAction,
    CustomModelDataset,
    CustomModelDatasetItem,
    CustomModelInfo,
    CustomModelPredictionItem,
    CustomModelTrainingItem,
    HandleCreateCustomModelArgs,
    HandleCreateCustomModelResponse,
    HandleCustomModelTrainingStartArgs,
    HandleCustomModelTrainingStartResponse,
    HandleCustomModelTrainingStopArgs,
    HandleCustomModelTrainingStopResponse,
    HandleStartCustomModelPredictionArgs,
    HandleStartCustomModelPredictionResponse,
    HandleStopCustomModelPredictionArgs,
    HandleStopCustomModelPredictionResponse,
    isCustomModelDatasetItem,
    isCustomModelInfo,
    isCustomModelPredictionItem,
    isCustomModelTrainingItem,
} from 'core/common/types/custom-model-types';
import { httpsCallable, HttpsCallable } from "firebase/functions";
import { AppRoleType, PublicUserId, UiDisplayMessageDialogEventHandler, UserAssetType } from "core/common/types";
import { debugError, debugLog } from "core/utils/print-utilts";
import { CreateCustomModelArgs, DeleteCustomModelArgs, DeleteCustomModelPredictionArgs, DeleteCustomModelPredictionResponse, DeleteCustomModelResponse, GetCustomModelTrainingArgs, GetCustomModelTrainingsArgs, GetCustomModelTrainingsResponse, GetPublicCustomModelPredictionsArgs, GetPublicCustomModelPredictionsResponse, OnCustomModelPredictionsUpdateArgs, OnCustomModelPredictionUpdateArgs, OnCustomModelTrainingCollectionUpdateArgs, OnCustomModelTrainingUpdateArgs, StartCustomModelPredictionArgs, StartCustomModelTrainingArgs, StopCustomModelPredictionArgs, StopCustomModelTrainingArgs, UpdateCustomModelInfoArgs, UploadCustomModelDataItemToStorageArgs, UploadCustomModelDataItemToStorageResponse, } from "backend/base";
import { removeUndefinedFromObject } from "core/utils/object-utils";
import { editorContextStore } from "contexts/editor-context";
import { canUserCreateCustomModel, canUserStartPrediction, canUserStartTraining } from "core/utils/custom-model-utils";
import { isDataURL, isValidHttpsUrl } from "core/utils/string-utils";
import { DocVisibility } from "core/common/types/doc-visibility";
import { createGenerateUserAssetUploadUrlFunction, GenerateUserAssetUploadUrlFunction } from "./asset-upload-utils";

export const customModelCollectionName = "customModelsV2";
export const customModelDatasetCollectionName = "dataset";
export const customModelTrainingCollectionName = "training";
export const customModelPredictionCollectionName = "customModelsV2Predictions";

export function getCustomModelCollectionRef(firestore: Firestore) {
    return collection(firestore, customModelCollectionName);
}

export function getCustomModelDocRef({
    firestore,
    modelId,
}: {
    firestore: Firestore,
    modelId: string,
}) {
    return doc(getCustomModelCollectionRef(firestore), modelId);
}

export function getCustomModelDatasetCollectionRef({
    firestore,
    modelId,
}: {
    firestore: Firestore,
    modelId: string,
}) {
    return collection(getCustomModelDocRef({ firestore, modelId }), customModelDatasetCollectionName);
}

export function getCustomModelDatasetItemDocRef({
    firestore,
    modelId,
    dataId,
}: {
    firestore: Firestore,
    modelId: string,
    dataId: string,
}) {
    return doc(
        getCustomModelDatasetCollectionRef({
            firestore,
            modelId,
        }),
        dataId,
    );
}

export function getCustomModelTrainingCollectionRef({
    firestore,
    modelId,
}: {
    firestore: Firestore,
    modelId: string,
}) {
    return collection(
        getCustomModelDocRef({ firestore, modelId }),
        customModelTrainingCollectionName,
    );
}

export function getCustomModelTrainingDocRef({
    firestore,
    modelId,
    trainingId,
}: {
    firestore: Firestore,
    modelId: string,
    trainingId: string,
}) {
    return doc(
        getCustomModelTrainingCollectionRef({
            firestore,
            modelId,
        }),
        trainingId,
    );
}



function getCustomModelPredictionCollectionRef({
    firestore,
}: {
    firestore: Firestore,
}) {
    return collection(
        firestore,
        customModelPredictionCollectionName,
    );
}


function getCustomModelPredictionDocRef({
    firestore,
    predictionId,
}: {
    firestore: Firestore,
    predictionId: string,
}) {
    return doc(
        getCustomModelPredictionCollectionRef({
            firestore,
        }),
        predictionId,
    );
}

function getStoragePathFromSignedUrl(signedUrl: string): string {
    try {
        const url = new URL(signedUrl);
        // Assuming the file path is the pathname component of the URL

        const prefix = '/flair-ai.appspot.com/';

        const pathname = url.pathname;

        if (pathname.startsWith(prefix)) {
            return pathname.slice(prefix.length);
        }

        return pathname;
    } catch (error) {
        console.error('Invalid URL:', error);
        return ''; // Return an empty string in case of an invalid URL
    }
}


async function getUploadDataFromString(body: string) {
    if (isDataURL(body) || isValidHttpsUrl(body)) {
        const response = await fetch(body);
        if (!response.ok) {
            throw new Error(`Failed to fetch data from URI. Status: ${response.status}`);
        }
        return await response.blob();
    }
    return body;
}

/**
 * Uploads a string to cloud storage using a signed URL and sets custom metadata.
 *
 * @param signedUrl The signed URL for the upload.
 * @param content The string content to upload.
 * @param metadata An object containing metadata key-value pairs.
 * @returns A promise that resolves with the HTTP response.
 */
async function uploadDataWithMetadata(
    signedUrl: string,
    body: string | File | Blob,
    contentType: string,
    metadata: Record<string, string>,
): Promise<Response> {
    // Convert data URI to Blob if the body is a string
    body = typeof (body) === 'string' ?
        await getUploadDataFromString(body) :
        body;

    const headers = new Headers({
        'Content-Type': contentType,
    });

    // Add metadata headers
    Object.entries(metadata).forEach(([key, value]) => {
        headers.append(key, value);
    });


    try {
        const response = await fetch(signedUrl, {
            method: 'PUT', // PUT is used for uploads to signed URLs
            headers,
            body, // The actual content to upload
        });

        if (!response.ok) {
            throw new Error(`Failed to upload content. Status: ${response.status}`);
        }

        return response;
    } catch (error) {
        console.error('Error uploading string with metadata:', error);
        throw error;
    }
}

export class CustomModelManager {

    private firestore: Firestore;

    private cachedPublicCustomModels: CustomModelInfo[] = [];

    private customModelActionEntrypoint: HttpsCallable<
        HandleCreateCustomModelArgs | HandleCustomModelTrainingStartArgs | HandleCustomModelTrainingStopArgs | HandleStartCustomModelPredictionArgs | HandleStopCustomModelPredictionArgs,
        HandleCreateCustomModelResponse | HandleCustomModelTrainingStartResponse | HandleCustomModelTrainingStopResponse | HandleStartCustomModelPredictionResponse | HandleStopCustomModelPredictionResponse
    >;

    private generateUserAssetUploadUrlColabJuly24: GenerateUserAssetUploadUrlFunction;

    constructor({
        firestore,
        firebaseFunctions,
    }: {
        firestore: Firestore,
        firebaseFunctions: Functions,
    }) {
        this.firestore = firestore;
        this.customModelActionEntrypoint = httpsCallable(
            firebaseFunctions,
            "customModelActionEntrypointColabJuly24"
        );
        this.generateUserAssetUploadUrlColabJuly24 = createGenerateUserAssetUploadUrlFunction({
            firebaseFunctions,
        });
    }

    private getCustomModelCollectionRef() {
        return getCustomModelCollectionRef(this.firestore);
    }

    private getCustomModelDocRef(modelId: string) {
        return getCustomModelDocRef({
            firestore: this.firestore,
            modelId,
        });
    }

    private getCustomModelDatasetRef(modelId: string) {
        return getCustomModelDatasetCollectionRef({
            firestore: this.firestore,
            modelId,
        });
    }

    private getCustomModelTrainingColectionRef(modelId: string) {
        return getCustomModelTrainingCollectionRef({
            firestore: this.firestore,
            modelId,
        });
    }

    private getCustomModelTrainingDocRef({
        modelId,
        trainingId,
    }: {
        modelId: string,
        trainingId: string,
    }) {
        return getCustomModelTrainingDocRef({
            firestore: this.firestore,
            modelId,
            trainingId,
        });
    }

    private getCustomModelPredictionCollectionRef() {
        return getCustomModelPredictionCollectionRef({
            firestore: this.firestore,
        });
    }

    private getCustomModelPredictionDocRef({
        predictionId,
    }: {
        predictionId: string,
    }) {
        return getCustomModelPredictionDocRef({
            firestore: this.firestore,
            predictionId,
        });
    }

    private getCustomModelDatasetItemRef(
        modelId: string,
        dataId: string,
    ) {
        return getCustomModelDatasetItemDocRef({
            firestore: this.firestore,
            dataId,
            modelId,
        });
    }

    private static getCustomModelDatasetFromDocs<T>(docs: QueryDocumentSnapshot<T>[]): CustomModelDataset {
        const output: CustomModelDataset = {};
        docs.forEach((doc) => {
            const item = doc.data();
            if (isCustomModelDatasetItem(item)) {
                output[doc.id] = item;
            }
        });
        return output;
    }

    getCustomModelDataset(modelId: string): Promise<CustomModelDataset> {
        return getDocs(
            this.getCustomModelDatasetRef(modelId)
        ).then(snap => snap.docs)
        .then(CustomModelManager.getCustomModelDatasetFromDocs);
    }

    onCustomModelDatasetUpdate(
        modelId: string,
        callback: (dataset?: CustomModelDataset) => void,
    ) {
        return onSnapshot(
            this.getCustomModelDatasetRef(modelId),
            (snapshot) => {
                callback(CustomModelManager.getCustomModelDatasetFromDocs(snapshot.docs));
            }
        );
    }

    async setCustomModelDataItem({
        modelId,
        dataId,
        data,
    }: {
        modelId: string,
        dataId: string,
        data: Partial<CustomModelDatasetItem>,
    }) {
        data = {
            ...data,
            timeCreated: serverTimestamp() as Timestamp,
            timeModified: serverTimestamp() as Timestamp,
        };

        if (!data?.id || !data?.storagePath) {
            debugError(`Cannot set model ${modelId} data ${dataId}: `, data);
            return;
        }

        return setDoc(
            this.getCustomModelDatasetItemRef(modelId, dataId),
            data,
        );
    }

    updateCustomModelDataItem({
        modelId,
        dataId,
        data,
    }: {
        modelId: string,
        dataId: string,
        data: Partial<CustomModelDatasetItem>,
    }) {
        data = {
            ...data,
            timeModified: serverTimestamp() as Timestamp,
        };

        return updateDoc(
            this.getCustomModelDatasetItemRef(modelId, dataId),
            data,
        );
    }

    async deleteCustomModelDataItem({
        modelId,
        dataId,
    }: {
        modelId: string,
        dataId: string,
    }) {
        debugLog(`Delete model ${modelId} data ${dataId}`);
        return await deleteDoc(
            this.getCustomModelDatasetItemRef(modelId, dataId)
        );
    }

    onCustomModelDatasetItemUpdate(
        modelId: string,
        dataId: string,
        callback: (dataItem?: CustomModelDatasetItem) => void,
    ) {
        return onSnapshot(
            this.getCustomModelDatasetItemRef(modelId, dataId),
            (snapshot) => {
                const data = snapshot.data();
                if (isCustomModelDatasetItem(data)) {
                    callback(data);
                } else {
                    callback(undefined);
                }
            },
        );
    }

    private async generateAssetUploadUrl({
        contentType,
    }: {
        contentType: string,
    }) {
        const response = await this.generateUserAssetUploadUrlColabJuly24({
            contentType,
            assetType: UserAssetType.CustomModelDatasetItem,
        });

        const {
            assetId,
            signedUrl,
            extensionHeaders,
        } = response.data ?? {};

        return {
            assetId,
            signedUrl,
            extensionHeaders,
        };
    }

    async uploadCustomModelDataItemToStorage({
        data,
        modelId,
    }: UploadCustomModelDataItemToStorageArgs): Promise<UploadCustomModelDataItemToStorageResponse> {
        try {
            const contentType = data.type;

            const {
                assetId,
                signedUrl,
                extensionHeaders = {},
            } = await this.generateAssetUploadUrl({
                contentType,
            });

            if (!signedUrl || !assetId) {
                debugError(`Cannot generate upload url for asset ${assetId} of type ${data.type}`);
                return undefined;
            }

            const storagePath = getStoragePathFromSignedUrl(signedUrl);

            debugLog(`Upload data to storage path ${storagePath} with signed url ${signedUrl}`);

            if (!storagePath) {
                return undefined;
            }

            // extensionHeaders['x-goog-meta-asset_id'] = assetId;

            const headers = Object.fromEntries(
                Object.entries(extensionHeaders).filter(([, value]) => value != null)
            ) as Record<string, string>;

            await uploadDataWithMetadata(
                signedUrl,
                data,
                contentType,
                headers,
            );

            return storagePath;
        } catch (error) {
            debugError(`Cannot upload model ${modelId} data item to storage`);
        }
        return undefined;
    }

    private getPublicCustomModelsQuery() {
        return query(
            this.getCustomModelCollectionRef(),
            where(
                'visibility', '==', DocVisibility.Public,
            )
        );
    }

    private async getPublicCustomModelsInternal() {
        try {
            const snapshot = await getDocs(
                this.getPublicCustomModelsQuery()
            );

            const models = snapshot.docs.map((doc) => {
                if (!doc.exists()) {
                    return undefined;
                }

                const customModelInfo = doc.data();

                if (!isCustomModelInfo(customModelInfo)) {
                    return undefined;
                }

                return customModelInfo;
            }).filter(Boolean) as CustomModelInfo[];

            return models;
        } catch (error) {
            debugError('Cannot get public custom models: ', error);
        }
        return [];
    }

    async getPublicCustomModels() {
        if (this.cachedPublicCustomModels.length  > 0) {
            return this.cachedPublicCustomModels;
        }

        this.cachedPublicCustomModels = await this.getPublicCustomModelsInternal();

        return this.cachedPublicCustomModels;
    }

    private getUserCustomModelsQuery(
        publicUserId: PublicUserId,
    ) {
        return query(
            this.getCustomModelCollectionRef(),
            where(
                `roles.${publicUserId}`, 'in', Object.values(AppRoleType)
            ),
        );
    }

    onUserCustomModelsUpdate(
        publicUserId: PublicUserId,
        callback: (customModels: Record<string, CustomModelInfo>) => void,
    ) {
        return onSnapshot(
            this.getUserCustomModelsQuery(publicUserId),
            (customModelsSnapshot) => {
                const newModels: Record<string, CustomModelInfo> = {};
                customModelsSnapshot.docs.forEach((customModelDocRef) => {
                    const project = customModelDocRef.data();
                    if (isCustomModelInfo(project) && project.isDeleted !== true) {
                        newModels[customModelDocRef.id] = project;
                    }
                });
                callback(newModels);
            }
        );
    }

    async createCustomModel(args: CreateCustomModelArgs): Promise<HandleCreateCustomModelResponse> {
        try {
            debugLog('Create custom model args:\n', args);

            const {
                userQuotas,
                eventEmitter,
            } = editorContextStore.getState();

            if (!canUserCreateCustomModel({
                userQuotas,
            })) {

                eventEmitter.emit<UiDisplayMessageDialogEventHandler>(
                    'ui:display-message-dialog',
                    'quota-subscribe',
                    {
                        title: 'No custom model credits left.',
                        header: "You have used all custom model credits.",
                    },
                );

                return {
                    ok: false,
                    message: "No custom model quota left.",
                };
            }


            const response = await this.customModelActionEntrypoint({
                ...args,
                type: CustomModelAction.CreateNewModel,
            });

            const data = response.data;

            return data as HandleCreateCustomModelResponse;
        } catch (error) {
            debugError('Cannot create new custom model: ', error);
        }

        return {
            ok: false,
            message: "Unknown error when creating custom model.",
        }
    }

    async deleteCustomModel({
        modelId,
    }: DeleteCustomModelArgs): Promise<DeleteCustomModelResponse> {
        try {

            const newCustomModelDoc: Partial<CustomModelInfo> = {
                isDeleted: true,
            };

            await updateDoc(
                this.getCustomModelDocRef(modelId),
                removeUndefinedFromObject(newCustomModelDoc),
            );

            return {
                ok: true,
                message: "OK.",
            };
        } catch (error) {
            debugError(`Error when deleting custom model ${modelId}: `, error);
        }
        return {
            ok: false,
            message: "Cannot delete model.",
        };
    }

    async startCustomModelTraining(args: StartCustomModelTrainingArgs): Promise<HandleCustomModelTrainingStartResponse> {
        try {
            debugLog('Start custom model args:\n', args);

            const {
                userQuotas,
                eventEmitter,
            } = editorContextStore.getState();

            const canStartResponse = canUserStartTraining({ userQuotas, input: args.trainingInput });

            if (!canStartResponse.ok) {

                const {
                    message,
                    title,
                    header,
                } = canStartResponse;

                eventEmitter.emit<UiDisplayMessageDialogEventHandler>(
                    'ui:display-message-dialog',
                    'quota-subscribe',
                    {
                        title,
                        header,
                    },
                );

                return {
                    ok: false,
                    message,
                };
            }

            // if (process.env.NODE_ENV === 'development') {
            //     return {
            //         ok: false,
            //         message: "Dev",
            //     };
            // }

            const response = await this.customModelActionEntrypoint({
                ...args,
                type: CustomModelAction.StartTraining,
            });

            const data = response.data;

            return data as HandleCustomModelTrainingStartResponse;
        } catch (error) {
            debugError('Cannot start custom model training');
        }

        return {
            ok: false,
            message: "Unknown error when starting custom model training.",
        }
    }

    async stopCustomModelTraining(args: StopCustomModelTrainingArgs) {
        try {
            debugLog('Stop custom model args:\n', args);

            const response = await this.customModelActionEntrypoint({
                ...args,
                type: CustomModelAction.StopTraining,
            });

            const data = response.data;

            return data as HandleCustomModelTrainingStopResponse;
        } catch (error) {
            debugError('Cannot stop new custom model: ', error);
        }
        return {
            ok: false,
            message: "Unknown error when stopping the custom model.",
        }
    }

    async startCustomModelPrediction(args: StartCustomModelPredictionArgs): Promise<HandleStartCustomModelPredictionResponse> {
        try {
            debugLog('Start custom model prediction args:\n', args);

            const {
                userQuotas,
                eventEmitter,
            } = editorContextStore.getState();

            if (!canUserStartPrediction({ userQuotas })) {

                eventEmitter.emit<UiDisplayMessageDialogEventHandler>(
                    'ui:display-message-dialog',
                    'quota-subscribe',
                    {
                        title: 'No custom model generate credits left.',
                        header: "You have used all custom model credits.",
                    },
                );

                return {
                    ok: false,
                    message: "No custom model generate quota left.",
                }
            }

            const response = await this.customModelActionEntrypoint({
                ...args,
                type: CustomModelAction.StartPrediction,
            });

            const data = response.data;

            return data as HandleStartCustomModelPredictionResponse;
        } catch (error) {
            debugError('Cannot start custom model training: ', error);
        }
        return {
            ok: false,
            message: "Unknown error when starting the custom model.",
        }
    }

    async stopCustomModelPrediction(
        args: StopCustomModelPredictionArgs
    ): Promise<HandleStopCustomModelPredictionResponse> {
        try {

            const response = await this.customModelActionEntrypoint({
                ...args,
                type: CustomModelAction.StopPrediction,
            });

            const data = response.data;

            return data as HandleStopCustomModelPredictionResponse;
        } catch (error) {
            debugError('Cannot stop custom model training: ', error);
        }
        return {
            ok: false,
            message: "Unknown error when stopping the custom model.",
        }
    }

    async deleteCustomModelPrediction({
        predictionId,
    }: DeleteCustomModelPredictionArgs): Promise<DeleteCustomModelPredictionResponse> {
        try {
            const newCustomModelPredictionDoc: Partial<CustomModelPredictionItem> = {
                isDeleted: true,
            };

            await updateDoc(
                this.getCustomModelPredictionDocRef({
                    predictionId,
                }),
                removeUndefinedFromObject(newCustomModelPredictionDoc),
            );

            return {
                ok: true,
                message: "OK.",
            }
        } catch (error) {
            debugError('Cannot delete custom model prediction: ', error);
        }

        return {
            ok: false,
            message: 'Cannot delete custom model prediction.',
        }
    }

    onCustomModelPredictionUpdate({
        predictionId,
        callback,
    }: OnCustomModelPredictionUpdateArgs) {
        return onSnapshot(
            this.getCustomModelPredictionDocRef({
                predictionId,
            }),
            (snapshot) => {
                const data = snapshot.data();

                if (!isCustomModelPredictionItem(data)) {
                    return;
                }

                callback(data);
            }
        );
    }

    private getUserCustomModelPredictionsQuery({
        publicUserId,
        modelId,
    }: {
        publicUserId: PublicUserId,
        modelId?: string,
    }) {

        const queryConstraints: QueryConstraint[] = [
            where(
                `roles.${publicUserId}`, 'in', Object.values(AppRoleType)
            ),
        ];

        if (modelId) {
            queryConstraints.push(
                where(
                    `usedModels.${modelId}`, '==', true
                )
            );
        }

        // debugLog(`Custom model ${modelId} predictions query constraints: `, queryConstraints);

        return query(
            this.getCustomModelPredictionCollectionRef(),
            ...queryConstraints,
        );
    }

    private getPublicCustomModelPredictionsQuery({
        modelId,
    }: {
        modelId?: string,
    }) {
        const queryConstraints: QueryConstraint[] = [
            where(
                'visibility', '==', DocVisibility.Public,
            ),
        ];

        if (modelId) {
            queryConstraints.push(
                where(
                    `usedModels.${modelId}`, '==', true
                )
            );
        }

        // debugLog(`Custom model ${modelId} predictions query constraints: `, queryConstraints);

        return query(
            this.getCustomModelPredictionCollectionRef(),
            ...queryConstraints,
        );
    }

    private async getPublicCustomModelPredictionsInternal({
        modelId,
    }: GetPublicCustomModelPredictionsArgs): Promise<GetPublicCustomModelPredictionsResponse> {
        const querySnapshot = await getDocs(this.getPublicCustomModelPredictionsQuery({
            modelId,
        }));

        const outputPredictions: Record<string, CustomModelPredictionItem> = {};

        querySnapshot.docs.forEach((predictionDocRef) => {
            const predictionDoc = predictionDocRef.data();

            if (!isCustomModelPredictionItem(predictionDoc)) {
                return;
            }

            outputPredictions[predictionDoc.id] = predictionDoc;
        });

        // debugLog(`Get public custom model ${modelId} predictions:\n`, outputPredictions);

        return outputPredictions;
    }

    async getPublicCustomModelPredictions(
        args: GetPublicCustomModelPredictionsArgs,
    ): Promise<GetPublicCustomModelPredictionsResponse> {
        return this.getPublicCustomModelPredictionsInternal(args);
    }

    onCustomModelPredictionsUpdate({
        publicUserId,
        modelId,
        callback,
    }: OnCustomModelPredictionsUpdateArgs) {
        return onSnapshot(
            this.getUserCustomModelPredictionsQuery({
                publicUserId,
                modelId,
            }),
            (customModelsSnapshot) => {
                // debugLog(`Custom model ${modelId} predictions:\n`, customModelsSnapshot.docs.length);

                const newPredictions: Record<string, CustomModelPredictionItem> = {};
                customModelsSnapshot.docs.forEach((predictionDocRef) => {
                    const predictionDoc = predictionDocRef.data();

                    if (!isCustomModelPredictionItem(predictionDoc)) {
                        return;
                    }

                    newPredictions[predictionDoc.id] = predictionDoc;
                });
                callback(newPredictions);
            }
        );
    }

    async getCustomModelTrainings({
        modelId,
    }: GetCustomModelTrainingsArgs): Promise<GetCustomModelTrainingsResponse> {
        const snapshot = await getDocs(
            this.getCustomModelTrainingColectionRef(modelId),
        );

        return snapshot.docs.map((doc) => {
            const data = doc.data();

            if (isCustomModelTrainingItem(data)) {
                return data;
            }

            return undefined;
        }).filter(Boolean) as GetCustomModelTrainingsResponse;
    }

    async getCustomModelTraining({
        modelId,
        trainingId,
    }: GetCustomModelTrainingArgs): Promise<CustomModelTrainingItem | undefined> {
        const snapshot = await getDoc(
            this.getCustomModelTrainingDocRef({
                modelId,
                trainingId,
            }),
        );

        const data = snapshot.data();

        if (isCustomModelTrainingItem(data)) {
            return data;
        }

        return undefined;
    }

    onCustomModelTrainingUpdate({
        modelId,
        trainingId,
        callback,
    }: OnCustomModelTrainingUpdateArgs) {
        return onSnapshot(
            this.getCustomModelTrainingDocRef({
                modelId,
                trainingId,
            }),
            (snapshot ) => {
                const data = snapshot.data();

                if (isCustomModelTrainingItem(data)) {
                    return callback(data);
                }
            },
        );
    }

    onCustomModelTrainingCollectionUpdate({
        modelId,
        callback,
    }: OnCustomModelTrainingCollectionUpdateArgs) {
        return onSnapshot(
            this.getCustomModelTrainingColectionRef(modelId),
            (snapshot ) => {
                return callback(
                    snapshot.docs
                    .map((doc) => doc.exists() && doc.data())
                    .filter((data) => data && isCustomModelTrainingItem(data)) as CustomModelTrainingItem[]
                );
            },
        );
    }

    async getCustomModelInfo(modelId: string): Promise<CustomModelInfo | undefined> {
        const snapshot = await getDoc(this.getCustomModelDocRef(modelId));

        const data = snapshot.data();

        if (!isCustomModelInfo(data)) {
            return undefined;
        }

        return data;
    }

    async updateCustomModelInfo({
        modelId,
        modelInfo,
    }: UpdateCustomModelInfoArgs) {
        try {
            modelInfo = removeUndefinedFromObject(modelInfo);

            if (Object.keys(modelInfo).length <= 0) {

                debugLog(`Model ${modelId} update info is empty.`);

                return;
            }

            await updateDoc(
                this.getCustomModelDocRef(modelId),
                modelInfo,
            );
        } catch (error) {
            debugError(`Cannot update custom model ${modelId}`, error);
        }
    }
}