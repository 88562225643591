import React from "react";
import { Editor } from "core/editor";
import { isDataURL, isValidFirebaseStoragePath, isValidHttpsUrl } from "core/utils/string-utils";
import { preprocessImageUrl } from "core/utils/url-utils";
import { debugError } from "core/utils/print-utilts";
import { classNames } from "core/utils/classname-utils";

export const ImageComponentPrimitive = React.forwardRef(({
    editor,
    style = {},
    onLoad,
    onError,
    alt,
    src,
    isImageLoaded,
    setImageLoaded,
    ...props
}: React.ImgHTMLAttributes<HTMLImageElement> & {
    editor?: Editor | null,
    isImageLoaded: boolean,
    setImageLoaded: (value: boolean,) => void,
}, forwardedRef?: React.ForwardedRef<HTMLImageElement>) => {
    const [imageUrl, setImageUrl] = React.useState<string | undefined>(undefined);

    const handleImageLoadError = (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
        setImageLoaded(false);

        if (src === imageUrl) {
            return;
        } else {
            setImageUrl(src);
        }

        onError?.(e);
    };

    React.useEffect(() => {
        setImageLoaded(false);

        if (!src) {
            return;
        }

        if (isDataURL(src) || isValidHttpsUrl(src)) {

            setImageUrl(
                preprocessImageUrl(src)
            );

        } else if (editor && isValidFirebaseStoragePath(src)) {

            editor.assets.loadAsset({
                type: 'image-storage',
                path: src,
                saveToMemory: true,
            }).then((imageUrl) => {
                if (!imageUrl) {
                    return;
                }

                setImageUrl(imageUrl);
            });

        }
    }, [src, editor, setImageLoaded]);

    return (
        <img
            {...props}
            ref={forwardedRef}
            alt={alt}
            src={imageUrl}
            style={{
                ...style,
                display: isImageLoaded ?
                    (style?.display || 'block') :
                    'none',
            }}
            onLoad={(e) => {
                setImageLoaded(true);
                onLoad?.(e);
            }}
            onError={handleImageLoadError}
        />
    )
})

export const ImageComponent = React.forwardRef((props: React.ImgHTMLAttributes<HTMLImageElement>, forwardedRef: React.ForwardedRef<HTMLImageElement>) => {
    const [isImageLoaded, setImageLoaded] = React.useState(false);
    return (
        <ImageComponentPrimitive
            {...props}
            ref={forwardedRef}
            isImageLoaded={isImageLoaded}
            setImageLoaded={setImageLoaded}
        />
    );
});
