import React from 'react';
import * as Switch from '@radix-ui/react-switch';
import { classNames } from 'core/utils/classname-utils';
import styles from './switch.module.css';



export type RoundedSwitchProps = Switch.SwitchProps & React.RefAttributes<HTMLButtonElement> & {
    thumbProps?: Switch.SwitchThumbProps & React.RefAttributes<HTMLSpanElement>,

}

export const RoundedSwitch = React.forwardRef(({
    className = "",
    thumbProps = {},
    ...props
}: RoundedSwitchProps, forwardedRef: React.ForwardedRef<HTMLButtonElement>) => {
    const { className: thumbClassName = "" } = thumbProps;
    return (
        <Switch.Root
            ref={forwardedRef}
            {...props}
            className={classNames(
                styles.SwitchRoot,
                className,
            )}
        >
            <Switch.Thumb
                {...thumbProps}
                className={classNames(
                    styles.SwitchThumb,
                    thumbClassName,
                )}
            />
        </Switch.Root>
    )
});