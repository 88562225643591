import { IHistory, IShortcutsManager } from './interfaces';
import { SerializedEditorState } from 'lexical';
import { PromptAutocompleteType } from "components/text-editor/prompt-autocomplete"
import { InpaintCommandData } from "core/command"
import { getUniqueArray } from "core/utils/array-utils"
import { Timestamp } from "firebase/firestore"
import { fabric } from 'fabric';
import { ILayerOptions } from './layers';
import { getEncryptedIdFromPublicId, isPublicId, PublicId } from './types/public-id';
import { UserCustomModelQuotas } from './types/custom-model-quotas';
import { UserVideoGenerationQuotas } from './types/video';

export * from './types/utils';

export interface Dimension {
  width: number
  height: number
}

export interface TimeRange {
  from?: number
  to?: number
}

export type BBox2d = {
  xmin: number,
  xmax: number,
  ymin: number,
  ymax: number,
}

export type ObjectBounds2d = {
  left: number,
  top: number,
  width: number,
  height: number,
}

export type DashboardType = 'workflows' | 'projects' | 'models' | 'manage-api' | 'videos';

export type RotationControlPosition = "TOP" | "BOTTOM"

type SceneType = "CUSTOMIZATION" | "GRAPHIC" | "PRESENTATION" | "VIDEO"

export interface EditorConfig {
  id: string
  clipToFrame: boolean
  scrollLimit: number
  propertiesToInclude?: string[]
  shortcuts?: boolean
  guidelines?: boolean
  shadow: any
  frameMargin: number
  background: string
  size: Dimension
  controlsPosition: ControlsPosition
  type: SceneType
}

export interface ControlsPosition {
  rotation: RotationControlPosition
}

export type CommandType = 'update' | 'redo' | 'undo';

export type InpaintMode = null | 'magic-eraser' | 'generate';

export type InpaintBrushType = null | 'paint' | 'erase';

export type EditorEventType = 'canvas:updated' | 'canvas:export-image' | 'history:changed' | 'inpaint:clear' | 'inpaint:commit-snapshot' | 'ui:display-message';

export type RenderVersion = "RenderJob";
export const RENDER_JOBS_COLLECTION_V1 = "RenderJob";

export enum RenderStatus {
  Queued = "2",
  Rendering = "1",
  Stopped = "0",
  Error = "-1",
}


export enum EngineType {
  ProductPhotography = "product-photography",
  Humans = "humans",
  ExperimentalV2 = 'experimental-v2',
}

export const GenerationFramePointerOverEventName = 'generation-frame:pointer-over';
export const GenerationFramePointerLeaveEventName = 'generation-frame:pointer-leave';

export type GenerateToolPaintTab = 'Tags' | 'Inpaint';

export type GenerateToolEditorTab = 'Templates' | 'Editor';
export const GenerateToolEditorTabLabels: Record<GenerateToolEditorTab, string> = {
  'Templates': 'Templates',
  'Editor': 'Prompt Builder',
}

export type GenerateToolTemplateGridType = 'Templates' | 'Recently Used';

export type DisplayMessageType = 'info' | 'error' | 'action';

export type HistoryOperationType = 'save' | 'restore' | 'reset';

export type DisplayMessageDialogType = 'remove-background' | 'quota-subscribe' | 'upload-cloth' | 'process-uploaded-image' | 'realtime-subscribe';


export type EditorObjectJson = Partial<fabric.IObjectOptions & { id: string }>;

export type EditorCanvasJson = {
  objects: EditorObjectJson[],
}

export type EditorSnapshot = {
  mainCanvas: EditorObjectJson[],
}

export type InpaintCanvasSnapshot = {
  type: GenerateToolPaintTab,
  commands?: InpaintCommandData[],
}

export type MagicEraseCanvasSnapshot = {
  commands?: InpaintCommandData[],
  imageObject?: EditorObjectJson,
}

export type DefaultEdtiorEventHandler = {
  type: EditorEventType,
  handler: (...args: any) => void,
};

export type UserAuthChangedEventHandler = {
  type: 'user:auth-changed',
  handler: (user: AppUser | null) => void,
}

export type CanvasExportImageEventHanlder = {
  type: 'canvas:export-image',
  handler: (options: fabric.IDataURLOptions) => void,
}

export type HistoryChangedEditorEventHandler = {
  type: 'history:changed',
  handler: (params: { hasUndo: boolean, hasRedo: boolean, operation: HistoryOperationType }) => void,
};

export type SetActiveHistoryHandler = {
  type: 'history:set',
  handler: (historyRef: { current?: (IHistory | undefined) }) => void,
}

export type SetActiveShortcutsManagerHandler = {
  type: 'shortcuts-manager:set',
  handler: (historyRef: { current?: (IShortcutsManager | undefined) }) => void,
}

export type ShuffleStackEventHandler = {
  type: 'objects:shuffle-stack',
  handler: () => void,
}

export type InpaintStrokeEndEditorEventHandler = {
  type: 'inpaint:commit-snapshot',
  handler: (data: InpaintCanvasSnapshot) => void,
}

export type RestoreInpaintCanvasSnapshotHandler = {
  type: 'inpaint:restore-snapshot',
  handler: (snapshot: InpaintCanvasSnapshot) => void,
}

export type UiDisplayMessageEventHandler = {
  type: 'ui:display-message',
  handler: (
    type: DisplayMessageType,
    message: string,
    title?: string,
    actionMessage?: string,
    onAction?: () => void | Promise<void>,
  ) => void,
}

export type UiDisplayMessageDialogEventHandlerProps = {
  objects?: (fabric.Object | undefined)[],
  image?: HTMLImageElement,
  centerGenerationFrames?: boolean,
  onDialogClose?: () => void,
  message?: string,
  title?: string,
  header?: string,
};

export type UiDisplayMessageDialogEventHandler = {
  type: 'ui:display-message-dialog',
  handler: (
    type: DisplayMessageDialogType,
    props: UiDisplayMessageDialogEventHandlerProps,
  ) => void,
}

export type UiCloseMessageDialogRemoveBackgroundResponse = {
  type: 'remove-background',
}

export type UiCloseMessageDialogQuotaSubscribeResponse = {
  type: 'quota-subscribe',
}

export type UiCloseMessageDialogUploadClothResponse = {
  type: 'upload-cloth',
}

export type UiCloseMessageDialogProcessUploadedImageResponse = {
  type: 'process-uploaded-image',
  imageUrl?: string,
  caption?: string,
}

export type UiCloseMessageDialogRealTimeSubscribeResponse = {
  type: 'realtime-subscribe',
}

export type UiCloseMessageDialogResponse = UiCloseMessageDialogRemoveBackgroundResponse | UiCloseMessageDialogQuotaSubscribeResponse | UiCloseMessageDialogUploadClothResponse | UiCloseMessageDialogProcessUploadedImageResponse | UiCloseMessageDialogRealTimeSubscribeResponse;

export type UiCloseMessageDialogEventHandler = {
  type: 'ui:close-message-dialog',
  handler: (
    response: UiCloseMessageDialogResponse,
  ) => void,
}

export type PromptEditorEventHandler = {
  type: 'prompt-editor:set-state',
  handler: (state: SerializedEditorState) => void,
}

export type OnAddAssetEventHandler = {
  type: 'assets:on-add',
  handler: (params: {
    path: string,
    contentType: EditorAssetContentType,
  }) => void,
}

export type PastGenerationsGetNextBatchEventHandler = {
  type: 'past-generations:next-batch',
  handler: () => void,
}

export type TriggerStartGenerationRenderEventHandler = {
  type: 'generation:trigger-start',
  handler: () => void,
}

export type TriggerRemoveBackgroundEventHandler = {
  type: 'remove-background:trigger-start',
  handler: () => void,
};

export type SetObjectEditImageProgressControllerEventHandler = {
  type: 'object:set-edit-image-progress-controller',
  handler: (params: {
    object: fabric.StaticImage,
  }) => void,
}
//
export type UpdateObjectIndexEventHandler = {
  type: 'object:update-index',
  handler: (params: {
    object: fabric.Object,
  }) => void,
}

export type SetObjectLoadingCover = {
  type: 'object:set-object-loading-cover',
  handler: (params: {
    objectId: string,
    message?: string,
    callback?: () => Promise<void>,
  }) => void,
}

export type RemoveObjectLoadingCover = {
  type: 'object:remove-object-loading-cover',
  handler: (params: {
    objectId: string,
  }) => void,
}

export type UpdateObjectPropsEventHandler = {
  type: 'object:update-props',
  handler: (params: {
    objectId: string,
    props: Partial<ILayerOptions>,
  }) => void,
}

export type TutorialStartEventHandler = {
  type: 'tutorial:start',
  handler: (name: string | undefined) => void,
}

export type TutorialSkipEventHandler = {
  type: 'tutorial:skip',
  handler: (step: number, name: string | undefined) => void,
}

export type TutorialFinishEventHandler = {
  type: 'tutorial:finish',
  handler: (step: number, name: string | undefined) => void,
}

export type EditorInitEventHandler = {
  type: 'editor:init',
  handler: () => void,
}

export type LeftPanelTriggerScrollTopEventHandler = {
  type: 'left-panel:scroll-top',
  handler: (scrollTop?: number) => void,
}

export type LeftPanelScrollEventHandler = {
  type: 'left-panel:scroll',
  handler: (containerBoundingRect: DOMRect) => void,
}

export type LeftPanelAlertConfirmEventHandler = {
  type: 'left-panel:alert-confirm',
  handler: () => void,
}

export type LeftPanelAlertCancelEventHandler = {
  type: 'left-panel:alert-cancel',
  handler: () => void,
}

export type TryOnGeneratedImagesEventHandler = {
  type: 'tryon:generated-images',
  handler: (params: {
    images: string[],
  }) => void,
}

export type TryOnRenderClothImageEventHandler = {
  type: "tryon:render-cloth-image",
  handler: () => void,
}

export type RealTimeRenderEditorInitEventHandler = {
  type: 'realtime-render:init-editor',
  handler: () => void,
}

export type RealTimeRenderGetResultEventHandler = {
  type: 'realtime-render:get-result',
  handler: (onResult: (imageUrl: string | undefined) => void) => void,
}

export type RealTimeRenderCheckPointerOverHandler = {
  type: 'realtime-render:check-pointer-over',
  handler: (
    pointerCoordinate: { x: number, y: number },
    callback?: (value: boolean) => void,
  ) => void,
}

export type RealTimeRenderSaveResultEventHandler = {
  type: 'realtime-render:save-result',
  handler: () => void,
}

export type RealTimeRenderStartRenderEventHandler = {
  type: 'realtime-render:start-render',
  handler: () => void,
}

export type RealTimeRenderStartColorCorrectionEventHandler = {
  type: 'realtime-render:start-color-correction',
  handler: () => void,
}

export type CanvasUpdateEventHandler = {
  type: 'canvas:updated',
  handler: () => void,
}

export type InpaintClearEventHandler = {
  type: 'inpaint:clear',
  handler: () => void,
};

export type CanvasContainerMountEventHandler = {
  type: 'canvas-container:mount',
  handler: () => void,
}

export type CanvasContainerUnMountEventHandler = {
  type: 'canvas-container:unmount',
  handler: () => void,
}

export type ExportGenerationFrameTemplateEventHandler = {
  type: 'generation-frame:export-template',
  handler: () => void,
}

export type StartUpscaleV2Job = {
  type: 'upscale-v2:start-job',
  handler: () => void,
}

export type CancelUpscaleV2Job = {
  type: 'upscale-v2:cancel-job',
  handler: (params: {
    jobId: string,
  }) => void,
}

export type CancelColorCorrectV2Job = {
  type: 'color-correct-v2:cancel-job',
  handler: (params: {
    jobId: string,
  }) => void,
}

export type CustomModelSetPromptEditorStateEventHandler = {
  type: 'custom-model:set-prompt-editor-state',
  handler: (params: {
    promptEditorStateJson: string,
  }) => void,
}

export type StartOutpaintImageJobEventHandler = {
  type: 'outpaint-image:start-job',
  handler: () => void,
}

export type UndoOutpaintImageEventHandler = {
  type: 'outpaint-image:undo',
  handler: () => void,
}

export type RedoOutpaintImageEventHandler = {
  type: 'outpaint-image:redo',
  handler: () => void,
}

export type SetOutpaintImageAspectRatioEventHandler = {
  type: 'outpaint-image:set-aspect-ratio',
  handler: (aspectRatio: number) => void,
}

export type ResetOutpaintImageEventHandler = {
  type: 'outpaint-image:reset',
  handler: () => void,
}

export type EditorEventHandler =
  CanvasUpdateEventHandler |
  SetActiveHistoryHandler |
  SetActiveShortcutsManagerHandler |
  HistoryChangedEditorEventHandler |
  InpaintClearEventHandler |
  InpaintStrokeEndEditorEventHandler |
  CanvasExportImageEventHanlder |
  UiDisplayMessageEventHandler |
  UiDisplayMessageDialogEventHandler |
  UiCloseMessageDialogEventHandler |
  PromptEditorEventHandler |
  OnAddAssetEventHandler |
  RestoreInpaintCanvasSnapshotHandler |
  ShuffleStackEventHandler |
  PastGenerationsGetNextBatchEventHandler |
  TriggerStartGenerationRenderEventHandler |
  TriggerRemoveBackgroundEventHandler |
  SetObjectEditImageProgressControllerEventHandler |
  UpdateObjectIndexEventHandler |
  SetObjectLoadingCover |
  UpdateObjectPropsEventHandler |
  UserAuthChangedEventHandler |
  TutorialStartEventHandler |
  TutorialFinishEventHandler |
  TutorialSkipEventHandler |
  EditorInitEventHandler |
  LeftPanelTriggerScrollTopEventHandler |
  LeftPanelScrollEventHandler |
  LeftPanelAlertConfirmEventHandler |
  LeftPanelAlertCancelEventHandler |
  TryOnGeneratedImagesEventHandler |
  TryOnRenderClothImageEventHandler |
  RealTimeRenderGetResultEventHandler |
  RealTimeRenderSaveResultEventHandler |
  RealTimeRenderCheckPointerOverHandler |
  RealTimeRenderEditorInitEventHandler |
  RealTimeRenderStartRenderEventHandler |
  RealTimeRenderStartColorCorrectionEventHandler |
  CanvasContainerMountEventHandler |
  CanvasContainerUnMountEventHandler |
  ExportGenerationFrameTemplateEventHandler |
  RemoveObjectLoadingCover |
  StartUpscaleV2Job |
  CancelUpscaleV2Job |
  CancelColorCorrectV2Job |
  CustomModelSetPromptEditorStateEventHandler |
  StartOutpaintImageJobEventHandler |
  StartOutpaintImageJobEventHandler |
  UndoOutpaintImageEventHandler |
  RedoOutpaintImageEventHandler |
  SetOutpaintImageAspectRatioEventHandler |
  ResetOutpaintImageEventHandler;

export type PromptState = string;

export type PromptEditorType = 'Template' | 'Textarea';

export type PromptWordType = 'input' | 'fixed';

export type PromptWord = {
  type: PromptWordType,
  prefix?: string,
  value: string,
  placeholder?: string,
  autocompleteType?: PromptAutocompleteType,
  autocompleteValues?: string[],
  isAutoFilled?: boolean,
  valueBeforeAutoFill?: string,
  objectIds?: string[],
}

export type PromptTemplate = {
  words: PromptWord[],
  style?: string,
};


type GenerateTemplateReferenceImage = string;

export type GenerateTemplate = {
  prompt: PromptTemplate,
  searchIndex?: string,
  referenceImage?: GenerateTemplateReferenceImage,
}

export type GenerateTemplateItemV1 = {
  imgSrc: string,
  alt?: string,
  template: GenerateTemplate,
  score?: number,
  tags?: string[];
  id?: string;
}

export type GenerateTemplateItemV2 = {
  version: "gen-template-v2",
    id: string,
    prompt: string,
    promptTemplate: PromptTemplate,
    previewImagePath: string,
    referenceImagePath?: string,
    sceneJSONFilePath: string,
    tags?: string[],
    score: number,
}

export type GenerateTemplateItem = GenerateTemplateItemV1 | GenerateTemplateItemV2;

export type GenerateTemplateItems = Record<string, GenerateTemplateItem>;

export type PastGenerationImagePathType = 'storage' | 'image-id';

export type PastGenerationVersion = 'v1' | 'v2';

export type PastGeneration = {
  id: string,
  projectId?: string,
  timeModified: Timestamp,
  imgPath: string,
  imgPathType?: PastGenerationImagePathType,
  alt?: string,
  prompt: string,
  inputImagePath?: string,
  inputMaskImagePath?: string,
  sceneJsonPath?: string,
  referenceImagePath?: string,
  promptTemplate?: PromptTemplate,
  regenerateErasedImagePath?: string,
  regenerateMaskImagePath?: string,
  version: PastGenerationVersion,
}

export type AttentionMask = {
  words: string,
  images: string[],
}

export type ColorAttentionMaskMap = Record<string, AttentionMask>;

export function getAllObjectColors(colorMaskMap: ColorAttentionMaskMap, id: string) {
  if (!colorMaskMap) {
    return [];
  }
  const result: string[] = []
  Object.entries(colorMaskMap).forEach(([color, { images }]) => {
    if (images?.findIndex(i => i === id) > -1) {
      result.push(color);
    }
  });

  return result;
}

export function removeObjectFromColorAttentionMaskMap(colorMaskMap: ColorAttentionMaskMap, id: string) {
  Object.values(colorMaskMap).forEach(mask => {
    if (mask.images) {
      mask.images = mask.images.filter(i => i !== id);
    }
  });
  return colorMaskMap;
}

export function setObjectColorAttentionMaskMap(colorMaskMap: ColorAttentionMaskMap, id: string, color: string) {
  removeObjectFromColorAttentionMaskMap(colorMaskMap, id);
  colorMaskMap[color] = {
    ...(colorMaskMap[color] || {}),
    images: getUniqueArray([
      ...(colorMaskMap[color]?.images || []),
      id,
    ])
  };

  return colorMaskMap;
}

export type WordColors = Record<string, string>;

export type DocsBatchGenerator<T = any> = {
  batchSize: number,
  getNextBatch: () => Promise<T[]>,
}

export enum AppRoleType {
  Owner = 'owner',
  Writer = 'writer',
  Reader = 'reader',
  Commenter = 'commenter',
}

const rolesCanWrite = new Set([
  AppRoleType.Owner,
  AppRoleType.Writer,
]);

const rolesCanRead = new Set([
  AppRoleType.Owner,
  AppRoleType.Writer,
  AppRoleType.Reader,
  AppRoleType.Commenter,
]);

export function canRoleWrite(type: AppRoleType) {
  return rolesCanWrite.has(type);
}

export function canRoleRead(type: AppRoleType) {
  return rolesCanRead.has(type);
}

export interface AppUser {
  /**
   * The display name of the user.
   */
  readonly displayName: string | null;
  /**
   * The email of the user.
   */
  readonly email: string | null;
  /**
   * The phone number normalized based on the E.164 standard (e.g. +16505550101) for the
   * user.
   *
   * @remarks
   * This is null if the user has no phone credential linked to the account.
   */
  readonly phoneNumber: string | null;
  /**
   * The profile photo URL of the user.
   */
  readonly photoURL: string | null;
  /**
   * The provider used to authenticate the user.
   */
  readonly providerId: string;
  /**
   * The user's unique ID, scoped to the project.
   */
  readonly uid: string;
}

export enum UserProjectType {
  ProductPhotography = "product-photography",
  TryOn = 'tryon',
  Accessories = 'accessories',
  Beauty = 'beauty',
  CPG = 'cpg',
  Fashion = 'fashion',
  Food = 'food',
  Furniture = 'furniture',
  Homegoods = 'homegoods',
  Staging = 'staging',
  Humans = 'humans',
  CustomModels = 'custommodels',
};

export type UsedUserProjectType = Exclude<UserProjectType,
  UserProjectType.CustomModels
>;

export function getUserProjectType(value?: string): UserProjectType | undefined {
  return Object.values(UserProjectType).find(
    (v) => v === value
  );
}

export const publicUserIdSuffix = "_puid";

export type PublicUserId = PublicId<typeof publicUserIdSuffix>;

export function getEncryptedUserIdFromPublicUserId(publicUserId: PublicUserId) {
  return getEncryptedIdFromPublicId(publicUserId, publicUserIdSuffix);
}

export function isPublicUserId(id: string | undefined | null): id is PublicUserId {
  return isPublicId(id, publicUserIdSuffix);
}

export type PublicUserRoles = Record<PublicUserId, AppRoleType>;

export type UserProject = {
  id: string,
  displayName: string,
  roles?: Record<string, AppRoleType>,
  timeModified?: Timestamp,
  thumbnail?: string,
  projectType?: UserProjectType,
};

export enum AppUserSubscriptionTier {
  Free = "Free",
  Pro = "Pro",
  Enterprise = "Enterprise",
}


export enum AppUserSubscriptionTierV2 {
  Free = "Free",
  Pro = "Pro",
  ProPlus = "Pro+",
}


export type RealTimeRenderUserQuotas = {
  maxRealTimeRenderTimeSec?: number,
  realTimeRenderTimeSec?: number,
  realTimeRenewPeriodSec?: number,
  realTimeRenewTimestamp?: Timestamp,
}

export type ColorCorrectV2UserQuotas = {
  numColorCorrectV2Renders: number;
  maxNumColorCorrectV2Renders: number;
}

export type AppUserQuotas = RealTimeRenderUserQuotas & ColorCorrectV2UserQuotas & UserCustomModelQuotas & UserVideoGenerationQuotas & {
  id?: string;
  tier: AppUserSubscriptionTier;
  tierV2?: AppUserSubscriptionTierV2;
  numProjects: number;
  numRenders: number;
  maxNumProjects: number;
  maxRenderArea: number;
  maxRenderTimeSec: number;
  maxNumRenders: number;
  maxAssetSizeMB: number;

};

/**
 * An `HttpsCallableResult` wraps a single result from a function call.
 * @public
 */
export interface HttpsCallableResult<ResponseData = unknown> {
  /**
   * Data returned from callable function.
   */
  readonly data: ResponseData;
}

/**
 * A reference to a "callable" HTTP trigger in Google Cloud Functions.
 * @param data - Data to be passed to callable function.
 * @public
 */
export type HttpsCallable<RequestData = unknown, ResponseData = unknown> = (
  data?: RequestData
) => Promise<HttpsCallableResult<ResponseData>>;


export type UserAssetInfoType = 'images' | 'props';

export enum UserAssetType {
  UploadedProduct = "uploaded-product",
  GeneratedResult = "generated-result",
  GenerateTemplateScene = "generate-template-scene",
  RenderInput = "render-input",
  RenderInputMask = "render-input-mask",
  MagicEraseOutput = "magic-erase-output",
  UpscaleOutput = "upscale-output",
  RemoveBackgroundOutput = "remove-background-output",
  RegenerateProducteErasedImage = "regenerate-product-erased-image",
  RegenerateProductMaskImage = "regenerate-product-mask-image",
  Props3dCanvasSnapshot = "asset3d-canvas-snapshot",
  UploadedProps = "uploaded-props",
  ReferenceImage = "reference-image",
  TryOnClothImage = "tryon-cloth-image",
  TryOnRenderResult = "tryon-render-result",
  ImageTo3dResult = "image-to-3d-result",
  CustomModelDatasetItem = "custom-model-dataset-item",
  VideoGenerationKeyframe = "video-generation-keyframe",
  VideoGenerationResult = "video-generation-result",
  Unknown = "unknown",
}

export type UserAssetInfo = {
  id: string,
  storagePath: string,
  timeModified?: Timestamp,
  caption?: string,
  isDeleted?: boolean,
}

export function isUserAssetInfo(data: any): data is UserAssetInfo {
  return data && data.storagePath;
}

export type UserAssetInfoCollection = Record<string, UserAssetInfo>

export type UserAssetInfoGenerator = DocsBatchGenerator<UserAssetInfo>;

export type UserAssetInfoGeneratorRef = { current?: UserAssetInfoGenerator };

export type EditorAssetType = 'image-storage' | 'image-url';
export enum EditorAssetContentType {
  png = 'image/png',
  jpeg = 'image/jpeg',
  webp = 'image/webp',
  mp4 = 'video/mp4',
  heic = 'image/heic',
  json = 'application/json',
}

export type EditorImageAsset = {
  type: EditorAssetType,
  path: string,
  contentType?: EditorAssetContentType.png | EditorAssetContentType.jpeg | EditorAssetContentType.webp,
}

export type EditorVideoAsset = {
  type: 'video-storage' | 'video-url',
  path: string,
  contentType?: EditorAssetContentType.mp4,
}

export const EditorAssetExtension: Record<EditorAssetContentType, string> = {
  [EditorAssetContentType.png]: '.png',
  [EditorAssetContentType.jpeg]: '.jpeg',
  [EditorAssetContentType.webp]: '.webp',
  [EditorAssetContentType.mp4]: '.mp4',
  [EditorAssetContentType.heic]: '.heic',
  [EditorAssetContentType.json]: '.json',
}

export type EditorAsset = EditorImageAsset | EditorVideoAsset;

export type UserOnboardData = {
  isProjectTutorialFinished: boolean,
}

export type RightClickMenuTypes = 'default' | 'object' | 'static-image' | 'active-selection' | 'realtime-render-result';

export type BackendCallableResponseData = {
  code: number,
  result?: string,
  message?: string,
  url?: string,
}

export type EditImageProcessType = 'remove-background' | 'magic-erase' | 'upscale' | 'upscale-premium' | 'image-variations' | 'generic-loading' | 'extend-image';

export const EditImageProcessTypeName: Record<EditImageProcessType, string> = {
  'remove-background': 'Remove Background',
  'magic-erase': 'Magic Erase',
  'upscale': 'Upscale',
  'upscale-premium': 'Upscale Premium',
  'image-variations': 'Image Variations',
  'generic-loading': 'Loading',
  'extend-image': 'Extend Image',
}

export type ImageVariationStrength = 'Extra Light' | 'Light' | 'Normal' | 'Strong' | 'Extra Strong';

export * from './types/custom-model-types';

export type TryOnPromptType = 'cloth' | 'model' | 'background';


export type TryOnRenderResult = {
  imageUrl: string,
  width: number,
  height: number,
};

export type TryOnRenderResults = TryOnRenderResult[];

export type TryOnParsedClothImageBbox = [number, number, number, number];

export type TryOnEditorState = 'idle' | 'warping' | 'rendering' | 'upscaling';

export type TryOnClothMaskPaintState = 'idle' | 'painting' | 'erasing';

export type TryOnPersonPaintState = 'idle' | 'erasing';

export type TryOnClothPromptState = 'idle' | 'generating';

export type TryOnClothMaskType = 'empty' | 'left-sleeve' | 'right-sleeve' | 'torso';

export const TryOnClothMaskTypeName: Record<TryOnClothMaskType, string> = {
  'empty': 'Empty',
  'left-sleeve': "Right Sleeve",
  'torso': "Torso",
  'right-sleeve': "Left Sleeve",
}

export const TryOnClothMaskTypeColorHex: Record<TryOnClothMaskType, string> = {
  'empty': '#71717a',
  'left-sleeve': "#dc2626",
  'torso': "#84cc16",
  'right-sleeve': "#3b82f6",
}

export function getColorNameFromClothMaskType(type: TryOnClothMaskType) {
  if (type === 'empty') {
    return 'black';
  }
  if (type === 'left-sleeve') {
    return 'red';
  }
  if (type === 'torso') {
    return 'green';
  }
  if (type === 'right-sleeve') {
    return 'blue';
  }
  return 'black';
}

export type TryOnModelPreviewDataTopFitTypes = "tight-fit" | "loose-fit";

export type TryOnModelPreviewDataNeckTypes = "round-neck_top" | "halter_top" | "turtle-neck_top" | "tie-neck_top";

export type TryOnModelPreviewDataTopTuckTypes = "tucked-in_high-waist_pants" | "untucked_top";

export type TryOnModelPreviewDataTopTypes = "long-sleeve_shirt" | "short-sleeve_shirt" | "off-shoulder_top";

export type TryOnModelPreviewDataDressCodeTypes = 'sports' | 'casual' | 'business';

export type TryOnModelPreviewDataBodyType = 'thin' | 'fat';

export type TryOnModelPreviewDataTags = TryOnModelPreviewDataTopFitTypes | TryOnModelPreviewDataNeckTypes | TryOnModelPreviewDataTopTuckTypes | TryOnModelPreviewDataTopTypes | TryOnModelPreviewDataDressCodeTypes | TryOnModelPreviewDataBodyType;


export type TryOnModelPreviewData = {
  topFitTypes: TryOnModelPreviewDataTopFitTypes,
  topNeckType: TryOnModelPreviewDataNeckTypes,
  topTuckType: TryOnModelPreviewDataTopTuckTypes,
  topType: TryOnModelPreviewDataTopTypes,
  dresscodeType: TryOnModelPreviewDataDressCodeTypes,
  bodyType: TryOnModelPreviewDataBodyType,
  imageId: string,
  modelId: string,
  imageUrl: string,
  imageUrls: string[],
  tags: Record<TryOnModelPreviewDataTags, string>,
};

export type TryOnModelPreviewFilterQueryConstraints = TryOnModelPreviewDataTags[];

export const defaultTryOnModelPreviewFilterQueryConstraints: TryOnModelPreviewFilterQueryConstraints = [];

export interface ITryOnModelPreviewGenerator {
  batchSize: number,
  getNextBatch(): Promise<TryOnModelPreviewData[]>,
  setConstraints(constraints: TryOnModelPreviewFilterQueryConstraints): Promise<TryOnModelPreviewData[]>,
  reset: (localOnly?: boolean) => void,
}

export function isTryOnModelPreviewData(data: any): data is TryOnModelPreviewData {
  return data && typeof (data.imageId) === 'string' && typeof (data.imageUrl) === 'string' && typeof (data.modelId) === 'string';
}

export type LeftPanelAlertDialogProps = {
  title?: string,
  subtitle?: string,
}

export type RegenerateProductJobType = 'replace-product' | 'erase-from-prompt';

export type ReplaceProductArgs = {
  job_type: 'replace-product'
  image_url: string
  mask_url?: string
  erased_image_url?: string
  subject_image_url?: string
  subject_mask_url?: string
  subject_prompt?: string
  prompt: string
  negative_prompt?: string
  inpaint_refine?: boolean
  mask_expand_factor?: number
  num_images_per_prompt?: number
  refine_mask?: boolean
  controlnet_conditioning_scale_start?: number
  controlnet_conditioning_scale_finish?: number
  img2img_strength?: number
  use_p_correction?: boolean
  use_c_correction?: boolean
}

export type EraseProductArgs = {
  job_type: 'erase-from-prompt'
  image_url: string
  mask_url?: string
  subject_image_url?: string
  subject_mask_url?: string
  subject_prompt?: string
}

export type RegenerateProductArgs = ReplaceProductArgs;

export type RegenerateProductRenderState = 'idle' | 'rendering' | 'error';


export enum GenerateStrength {
  None = 'None',
  ExtraWeak = 'Extra Weak',
  Weak = 'Weak',
  Default = 'Default',
  Strong = 'Strong',
  ExtraStrong = 'Extra Strong'
};

export type RegenerateProductResult = {
  imageUrl?: string,
  isSelected?: boolean,
  label?: string,
}

export type RegenerateProductResults = RegenerateProductResult[];

export enum GeneralObjectCategory {
  bottle = 'bottle',
  furniture = 'furniture',
  car = 'car',
  electronics = 'electronics',
  watch = 'watch',
  bag = 'bag',
  cloth = 'cloth',
  pants = 'pants',
  shoes = 'shoes',
  food = 'food',
  animal = 'animal',
}

export enum ClothSleeveCategory {
  LongSleeve = 'long-sleeve shirt',
  ShortSleeve = 'short-sleeve shirt',
  TankTop = 'tank top',
}

export enum ClothFitCategory {
  TightFit = 'tight-fit cloth',
  LooseFit = 'loose-fit cloth',
}

export const clothSleeveToQueryConstraint: Record<ClothSleeveCategory, TryOnModelPreviewDataTopTypes> = {
  [ClothSleeveCategory.LongSleeve]: 'long-sleeve_shirt',
  [ClothSleeveCategory.ShortSleeve]: 'short-sleeve_shirt',
  [ClothSleeveCategory.TankTop]: 'off-shoulder_top',
}

export const clothSleeveQueryConstraints = new Set(Object.values(clothSleeveToQueryConstraint));

export const clothFitToQueryConstraint: Record<ClothFitCategory, TryOnModelPreviewDataTopFitTypes> = {
  [ClothFitCategory.LooseFit]: 'loose-fit',
  [ClothFitCategory.TightFit]: 'tight-fit',
}

export const clothFitQueryConstraints = new Set(Object.values(clothFitToQueryConstraint));

export type GenerateToolReferenceImage = EditorAsset & {
  previewPath?: string,
};

export interface CustomUserClaims {
  publicUserId: PublicUserId,
}

export function isCustomUserClaims(claims: any): claims is CustomUserClaims {
  return typeof (claims?.publicUserId) === 'string';
}
