
import { PrimaryButtonClassName, SecondaryButtonClassNameDisabled, SecondaryButtonClassNameInactive } from 'components/constants/class-names';
import { editorContextStore } from 'contexts/editor-context';
import { classNames } from 'core/utils/classname-utils';
import { appendPathToRootUrl } from 'core/utils/url-utils';
import { Lock } from 'lucide-react';
import React from 'react';
import dashboardStyles from '../dashboard.module.css';
import apiStyles from './api-playground.module.css';
import * as AspectRatio from '@radix-ui/react-aspect-ratio';
import { ApiBetaAccess, ApiDashboardModelType, ApiDashboardTab, EmailApiDataDoc, UserApiStatus } from 'core/common/types/api';
import { ApiDashboardNavigateHeader } from './navigate-header';
import * as Tabs from "@radix-ui/react-tabs";
import { CubeIcon, GearIcon } from '@radix-ui/react-icons';
import { CopyButton } from 'components/utils/copy-button';
import { replaceMiddleWithAsterisks } from 'core/utils/string-utils';
import { useApiKeys } from 'hooks/use-api-update';
import { SimpleSpinner } from 'components/icons/simple-spinner';
import { openSubscriptionsLink, subscribeToApiStandardPlan } from 'components/subscription/checkout-portal';
import * as AlertDialogPrimitive from '@radix-ui/react-alert-dialog';
import ApiDashboardStyles from './api-dashboard.module.css';
import { EmailSubscribeInput } from 'components/utils/email-subscribe-input';
import { StripeSubscriptionPriceId } from 'core/common/types/subscription';
import { displayUiMessage } from 'components/utils/display-message';


async function subscribeToWaitlist({
    email,
}: {
    email: string,
}) {
    try {

        const response = await fetch(
            'https://us-central1-flair-ai.cloudfunctions.net/subscribe-api-waitlist',
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    email,
                })
            }
        );

        if (response.ok) {
            return true;
        } else {
            console.error('Failed to subscribe:', await response.text());
        }
    } catch (error) {
        console.error('An error occurred:', error);
    }
    return false;
}

function OnboardWaitlistAlert({
    open = true,
    onOpenChange,
}: {
    open?: boolean,
    onOpenChange: (isOpen: boolean) => void,
}) {
    return (
        <AlertDialogPrimitive.Root open={open} onOpenChange={onOpenChange}>
            <AlertDialogPrimitive.Portal>
                <AlertDialogPrimitive.Overlay
                    className={ApiDashboardStyles.AlertDialogOverlay}
                />
                <AlertDialogPrimitive.Content
                    className='absolute w-full h-full top-0 flex justify-center items-center text-zinc-300'
                    onClick={() => {
                        onOpenChange(false);
                    }}
                >
                    <div
                        className='w-[80vw] md:w-[60vw] lg:w-[600px] flex flex-col px-4 pt-4 pb-8 bg-zinc-900 rounded-md border border-zinc-800 shadow-lg-center shadow-zinc-300/10 gap-8'
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                        }}
                    >
                        <AlertDialogPrimitive.Title
                            className='font-medium text-zinc-200'
                        >
                            Enter your email below to join the API waitlist.
                        </AlertDialogPrimitive.Title>
                        <EmailSubscribeInput
                            handleEmailSusbcribe={(email) => {
                                return subscribeToWaitlist({ email }).then((isSubscribed) => {
                                    if (isSubscribed) {
                                        // Auto-close after subscription success
                                        setTimeout(() => {
                                            onOpenChange(false);
                                        }, 1000);
                                    }

                                    return isSubscribed;
                                });
                            }}
                        />
                    </div>
                </AlertDialogPrimitive.Content>
            </AlertDialogPrimitive.Portal>
        </AlertDialogPrimitive.Root>
    );
}

function OnboardWaitlistButton() {
    const [isAlertOpen, setIsAlertOpen] = React.useState(false);

    return (
        <>
            <button
                className={PrimaryButtonClassName}
                onClick={() => {
                    setIsAlertOpen(true);
                }}
            >
                Join API waitlist
            </button>
            <OnboardWaitlistAlert
                open={isAlertOpen}
                onOpenChange={setIsAlertOpen}
            />
        </>
    )
}

function SubscribeToApiButton() {
    return (
        <button
            className={PrimaryButtonClassName}
            onClick={() => {
                const {
                    backend,
                    setUserApiData,
                } = editorContextStore.getState();

                if (!backend) {
                    return;
                }

                setUserApiData((apiData) => ({
                    ...apiData,
                    status: UserApiStatus.Creating,
                }));

                subscribeToApiStandardPlan().then(({ url, message }) => {
                    if (!url) {

                        if (message) displayUiMessage(message);

                        return;
                    }

                    window?.location.assign(url);

                }).catch((error) => {
                    console.error(error);
                });
            }}
        >
            Subscribe to API
        </button>
    )
}

const ApiSubscriptionPanelClassName = "group h-[180px] flex flex-col gap-4 items-center justify-center rounded-md border border-zinc-800 bg-zinc-800/20"

function ApiSubscriptionPanel({
    className = "",
    ...props
}: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>) {
    const backend = editorContextStore(state => state.backend);
    const user = editorContextStore(state => state.user);

    const [emailDataDoc, setEmailDataDoc] = React.useState<EmailApiDataDoc>({});

    React.useEffect(() => {
        if (!backend) {
            return;
        }

        const email = user?.email;
        if (!email) {
            return;
        }

        backend.getEmailApiData(email).then(setEmailDataDoc);
    }, [
        user,
        backend,
    ]);

    return (
        <div
            {...props}
            className={classNames(
                className,
                ApiSubscriptionPanelClassName,
            )}
        >
            <div className='flex flex-col items-center justify-center gap-2'>
                <div
                    className='flex flex-row items-center gap-2 text-zinc-500 group-hover:text-zinc-300 transition-colors'
                >
                    <Lock size={16} />
                    You currently do not have API subscription.
                </div>
                <div className='text-sm text-zinc-600 group-hover:text-zinc-500 transition-colors'>
                    Just subscribed? Please wait up to 1 min for your API to be setup.
                </div>
            </div>
            {emailDataDoc.apiBetaAccess === ApiBetaAccess.Full ? (
                <SubscribeToApiButton />
            ) : (
                <OnboardWaitlistButton />
            )}
        </div>
    )
}

function ApiSubscriptionCreatingPanel({
    className = "",
    ...props
}: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>) {
    return (
        <div
            {...props}
            className={classNames(
                className,
                ApiSubscriptionPanelClassName,
            )}
        >
            <div
                className='flex flex-row items-center gap-2 text-zinc-500'
            >
                <SimpleSpinner
                    width={23}
                    height={23}
                    className="mr-2"
                    pathClassName="fill-lime-500"
                />
                Subscribing to API ...
            </div>
        </div>
    )
}


function DashboardSubscribeApi() {
    const userApiData = editorContextStore(state => state.userApiData);


    if (userApiData.status === UserApiStatus.Ready) {
        return null;
    } else if (userApiData.status === UserApiStatus.Creating) {
        return <ApiSubscriptionCreatingPanel />;
    }

    return (
        <ApiSubscriptionPanel />
    )
}


type DashboardApiGridItemPrivateProps = {
    aspectRatio?: number,
    thumbnailUrl?: string,
    className?: string,
    title?: string,
}

function DashboardApiGridItemPrivate({
    aspectRatio = 4 / 3,
    thumbnailUrl,
    className = '',
    title = '',
}: DashboardApiGridItemPrivateProps) {

    return (
        <div
            className={classNames(
                'group outline-none border border-zinc-800 shadow-sm rounded-md overflow-hidden cursor-not-allowed text-zinc-600 transition text-sm 2xl:text-base',
                className,
            )}
        >
            <AspectRatio.Root
                ratio={aspectRatio}
                className='pointer-events-none select-none bg-cover'
                style={{
                    backgroundImage: thumbnailUrl ? `url(${thumbnailUrl})` : '',
                }}
            >
                <div className='w-full h-full flex items-center justify-center bg-zinc-900/50 group-hover:bg-zinc-900/80 transition-colors'>
                    <div className='p-2 text-zinc-200 flex flex-row items-center justify-center gap-2'>
                        <Lock size={18} />
                        Coming soon
                    </div>
                </div>
            </AspectRatio.Root>
            <div className='flex flex-row items-center px-3 py-4'>
                <div>
                    {title}
                </div>
            </div>
        </div>
    )
}

type DashboardApiGridItemProps = {
    aspectRatio?: number,
    thumbnailUrl?: string,
    className?: string,
    title?: string,
    apiModelType: ApiDashboardModelType,
}

function DashboardApiGridItem({
    aspectRatio = 4 / 3,
    thumbnailUrl,
    className = '',
    title = '',
    apiModelType,
}: DashboardApiGridItemProps) {

    title = title || apiModelType;

    return (
        <div
            className={classNames(
                'outline-none border border-zinc-800 hover:border-lime-600 shadow-sm hover:shadow-xl hover:shadow-lime-500/5 rounded-md overflow-hidden cursor-pointer text-zinc-400 hover:text-zinc-300 transition text-sm 2xl:text-base',
                className,
            )}
            onClick={() => {
                editorContextStore.getState().setApiDashboardModelType(ApiDashboardModelType.ImageGeneration);
            }}
        >
            <AspectRatio.Root
                ratio={aspectRatio}
                className='pointer-events-none select-none bg-cover bg-center'
                style={{
                    backgroundImage: thumbnailUrl ? `url(${thumbnailUrl})` : '',
                }}
            />
            <div className='flex flex-row items-center px-3 py-4'>
                <div>
                    {title}
                </div>
            </div>
        </div>
    )
}

function DashboardApiGrid() {
    return (
        <div
            className={classNames(
                dashboardStyles.DashboardGrid,
            )}
        >
            <DashboardApiGridItem
                thumbnailUrl='https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/c78d7209-e9e6-4615-8d8b-4f5c473b2600/512'
                title='Generate Image API'
                apiModelType={ApiDashboardModelType.ImageGeneration}
            />
            <DashboardApiGridItemPrivate
                thumbnailUrl='https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/d80e2224-7473-41a1-3f9a-fa719dc18200/public'
                title='Fashion API'
            />
        </div>
    );
}

function DashboardManageApiTabTrigger({
    className = "",
    children,
    ...props
}: Tabs.TabsTriggerProps & {
    value: ApiDashboardTab,
}) {
    return (
        <Tabs.Trigger
            {...props}
            className={classNames(
                apiStyles.TabsTrigger,
                "group px-1 py-1 text-sm text-center focus:outline-none active:outline-none focus-visible:outline-none border-solid border-0 border-b-2 transition-colors",
                className,
            )}
        >
            <div className='w-fit min-w-[2rem] px-3 py-2 rounded flex flex-row items-center justify-center gap-3 bg-transparent group-hover:text-zinc-300 group-hover:bg-zinc-800 transition-colors'>
                {children}
            </div>
        </Tabs.Trigger>
    )
}

function ApiDashboardModels() {
    return (
        <div className='flex flex-col gap-4'>
            <DashboardSubscribeApi />
            <DashboardApiGrid />
        </div>
    )
}

const ApiKeyRow = ({
    apiKeyName,
    apiKeyValue,
}: {
    apiKeyName: string,
    apiKeyValue: string,
}) => {
    return (
        <div className='flex-1 flex flex-row gap-8 items-center'>
            <div className='text-base truncate text-zinc-400 w-[150px]'>
                {apiKeyName}:
            </div>
            <div className='flex-1 flex flex-row items-center justify-center rounded-md px-3 py-2 text-base text-zinc-200 bg-zinc-800/30'>
                <div className='flex-1 min-w-0 truncate'>
                    {apiKeyValue ? replaceMiddleWithAsterisks(
                        apiKeyValue,
                        Math.floor(apiKeyValue.length * 0.6)
                    ) : "No valid API key found."}
                </div>
                <CopyButton
                    textToCopy={apiKeyValue}
                />
            </div>
        </div>
    )
}

function ApiDashboardSettingsContainer({
    title,
    children,
}: {
    title: string,
    children: React.ReactNode,
}) {
    return (
        <div className='flex flex-col items-stretch justify-start md:flex-row gap-4 md:gap-8 lg:gap-[12vw]'>
            <div className='md:py-1 text-lg font-semibold text-zinc-300 min-w-[118px]'>
                {title}
            </div>
            <div className='flex-1 flex flex-col gap-4'>
                {children}
            </div>
        </div>
    )
}

function ApiDashboardSettingsApiKeys() {
    const apiKeys = useApiKeys();
    return (
        <ApiDashboardSettingsContainer
            title='API Keys'
        >
            {Object.entries(apiKeys).map(([apiKeyName, apiKeyValue]) => (<ApiKeyRow
                key={apiKeyName}
                apiKeyName={apiKeyName}
                apiKeyValue={apiKeyValue}
            />))}
        </ApiDashboardSettingsContainer>
    );
}

function ApiDashboardSettingsSubscription() {
    const [isLoading, setLoading] = React.useState(false);

    const userApiData = editorContextStore(state => state.userApiData);

    return (
        <ApiDashboardSettingsContainer
            title='Subscription'
        >
            {userApiData.status === UserApiStatus.Ready ?
                <button
                    className={classNames(
                        SecondaryButtonClassNameInactive,
                        "w-fit px-4 gap-4",
                        isLoading ? "cursor-wait" : "cursor-pointer",
                    )}
                    onClick={() => {
                        if (isLoading) {
                            return;
                        }
                        setLoading(true);
                        openSubscriptionsLink(true).then(() => {
                            setLoading(false);
                        });
                    }}
                >
                    {isLoading && <SimpleSpinner
                        width={23}
                        height={23}
                        className="mr-2"
                        pathClassName="fill-lime-500"
                    />}
                    {isLoading ?
                        "Redirecting ..." :
                        "Manage API Subscription"
                    }
                </button> :
                <button className={classNames(
                    SecondaryButtonClassNameDisabled,
                    "w-fit px-4 gap-4"
                )}>
                    No Subscription Found
                </button>
            }

        </ApiDashboardSettingsContainer>
    );
}

function ApiDahsboardSettingsDivider() {
    return (
        <div className='my-1 w-full h-px bg-zinc-800' />
    )
}

function ApiDashboardSettings() {
    return (
        <div
            className='flex flex-col gap-4'
        >
            <DashboardSubscribeApi />
            <ApiDashboardSettingsApiKeys />
            <ApiDahsboardSettingsDivider />
            <ApiDashboardSettingsSubscription />
        </div>
    )
}

function DashboardManageApiTabs() {
    const [dashboardTab, setDashboardTab] = React.useState<ApiDashboardTab>(ApiDashboardTab.Models);

    return (
        <Tabs.Root
            value={dashboardTab}
            // @ts-ignore
            onValueChange={setDashboardTab}
        >
            <Tabs.List
                className='border-0 border-b border-zinc-800'
            >
                <DashboardManageApiTabTrigger
                    value={ApiDashboardTab.Models}
                >
                    <CubeIcon />
                    Models
                </DashboardManageApiTabTrigger>
                <DashboardManageApiTabTrigger
                    value={ApiDashboardTab.Settings}
                >
                    <GearIcon />
                    Settings
                </DashboardManageApiTabTrigger>
            </Tabs.List>
            <div className='p-4'>
                <Tabs.Content
                    value={ApiDashboardTab.Models}
                >
                    <ApiDashboardModels />
                </Tabs.Content>
                <Tabs.Content
                    value={ApiDashboardTab.Settings}
                >
                    <ApiDashboardSettings />
                </Tabs.Content>
            </div>
        </Tabs.Root>
    );
}

export function DashboardManageApiDefault() {

    return (
        <div>
            <ApiDashboardNavigateHeader
                paths={[
                    {
                        name: 'API Dashboard',
                        to: '/api',
                    }
                ]}
            />
            <DashboardManageApiTabs />
        </div>
    )
}