import React from 'react';
import { fabric } from "fabric"
import { FloatTagZIndex } from 'components/constants/zIndex';
import { editorContextStore, editorContextVanillaStore } from 'contexts/editor-context';
import { EditorActiveObject } from 'core/common/interfaces';
import { ObjectFloatTag } from './object-float-tag';
import Objects from 'core/controllers/objects';
import { LeftPanelItemType } from 'components/constants/editor-options';
import { noop } from 'lodash';
import { mergeRefs } from 'components/utils/merge-refs';
import { isStaticImageObject, isStaticImageObjectGenerated } from 'core/utils/type-guards';
import { classNames } from 'core/utils/classname-utils';
import { IEvent } from 'fabric/fabric-impl';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import styles from './float-tag.module.css';
import { SelectOptionIcon, SelectOptions } from 'components/utils/select-options';
import { StaticImageElement2dType, StaticImageElementType, getStaticImageElement2dType, getStaticImageElementType, setStaticImageElement2dType } from 'core/common/types/elements';
import { BACKGROUND_DARK } from 'components/constants/colors';
import { DropdownImageElement2dTypeValue, imageElement2dTypeDropdownOptions, setDropdownImageElement2dType } from 'components/panels/panel-items/components/element-type';
import { argmin } from 'core/utils/array-utils';
import { StartUpscaleV2Job, UpdateObjectPropsEventHandler } from 'core/common/types';
import { distanceSquared, magnitude } from 'core/utils/fabric';
import { EDITOR_FLOAT_TAG_CONTAINER_ID } from 'components/constants/ids';
import { isObjectWithProgress } from 'components/panels/panel-items/edit/edit-image-process';
import { debugLog } from 'core/utils/print-utilts';


function canObjectHaveTag(object: EditorActiveObject): object is fabric.Object {
    return object != null
        //@ts-ignore
        && Objects.isMultiSelectableObjects(object);
}

function doesLeftPanelsAllowFloatTag(leftPanels: LeftPanelItemType[]) {
    if (leftPanels.length <= 0) {
        return false;
    }

    const lastPanel = leftPanels[leftPanels.length - 1];
    return lastPanel !== 'TransformProps3d';
}

function useIsFloatTagVisible() {
    const activeLeftPanels = editorContextStore(state => state.activeLeftPanels);
    return React.useMemo(() => doesLeftPanelsAllowFloatTag(activeLeftPanels), [activeLeftPanels]);
}

function doesObjectHaveCenterTag(object: any) {
    if (isStaticImageObjectGenerated(object)) {

        if (isObjectWithProgress(object)) {
            const progress = object.editImageProgressController?.progress ?? 0;

            return progress <= 0 || progress >= 1;
        }

        return true;
    }

    return false;
}

const CenterFloatTag = React.forwardRef(function CenterFloatTag({
    className = "",
    opacity = 1,
    ...props
}: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
    opacity?: number,
}, forwardedRef: React.ForwardedRef<HTMLDivElement>) {

    const containerRef = React.useRef<HTMLDivElement>(null);
    const containerWidthRef = React.useRef(0);
    const isVisibleRef = React.useRef(false);
    const isPointerOverRef = React.useRef(false);
    const isDropdownOpenRef = React.useRef(false);
    const hoveredObjectRef = React.useRef<fabric.Object | undefined>();

    const editor = editorContextStore(state => state.editor);

    const setVisibleInternal = React.useCallback((isVisible: boolean) => {
        if (!containerRef.current) {
            isVisibleRef.current = false;
            return;
        }

        isVisible = isDropdownOpenRef.current ? true : isVisible;

        isVisibleRef.current = isVisible;

        containerRef.current.style.opacity = isVisible ? opacity.toString() : '0';
        containerRef.current.style.pointerEvents = isVisible ? 'auto' : 'none';
    }, []);

    const updatePosition = React.useCallback((object?: fabric.Object) => {
        if (!containerRef.current || !isVisibleRef.current || !object) {
            return;
        }

        object.setCoords();
        const oCoords = object.oCoords;

        if (!oCoords) {
            return;
        }

        const tl = oCoords.tl;
        const tr = oCoords.tr;
        const bl = oCoords.bl;
        const br = oCoords.br;
        const centerX = (tl.x + tr.x + bl.x + br.x) / 4;
        const centerY = (tl.y + tr.y + bl.y + br.y) / 4;
        const bottomY = Math.max(tl.y, tr.y, bl.y, br.y);

        containerRef.current.style.left = `${centerX - containerWidthRef.current / 2}px`;
        containerRef.current.style.top = `${centerY + (bottomY - centerY) * 0.7}px`;

        hoveredObjectRef.current = object;
    }, []);

    React.useEffect(() => {
        if (!editor) {
            return;
        }
        if (!containerRef.current) {
            return;
        }

        containerWidthRef.current = containerRef.current.getBoundingClientRect().width;

        const handleSelectionCleared = () => {
            setVisibleInternal(false);
        }

        const handleMouseDown = () => {
            setVisibleInternal(false);
        };

        const handleMouseWheel = () => {
            setVisibleInternal(false);
        }

        const handleMouseOver = (e: IEvent<Event>) => {
            const object = e.target;
            if (!doesObjectHaveCenterTag(object)) {
                setVisibleInternal(false);
                return;
            }

            setVisibleInternal(true);
            updatePosition(e.target);
        }

        const handleMouseOut = (e: IEvent<Event>) => {
            if (isPointerOverRef.current) {
                setVisibleInternal(true);
            } else {
                setVisibleInternal(false);
            }
        }

        const handleBeforeTransform = () => {
            setVisibleInternal(false);
        }

        editor.canvas.canvas.on('selection:cleared', handleSelectionCleared);
        editor.canvas.canvas.on('mouse:down', handleMouseDown);
        editor.canvas.canvas.on('mouse:wheel', handleMouseWheel);
        editor.canvas.canvas.on('mouse:over', handleMouseOver);
        editor.canvas.canvas.on('mouse:out', handleMouseOut);
        editor.canvas.canvas.on('before:transform', handleBeforeTransform);

        return () => {
            editor.canvas.canvas.off('selection:cleared', handleSelectionCleared);
            editor.canvas.canvas.off('mouse:down', handleMouseDown);
            editor.canvas.canvas.off('mouse:wheel', handleMouseWheel);
            editor.canvas.canvas.off('mouse:over', handleMouseOver);
            editor.canvas.canvas.off('mouse:out', handleMouseOut);
            editor.canvas.canvas.off('before:transform', handleBeforeTransform);
        }

    }, [
        editor,
        setVisibleInternal,
        updatePosition,
    ]);

    return (
        <DropdownMenu.Root
            defaultOpen={false}
            onOpenChange={(value) => {
                isDropdownOpenRef.current = value;
            }}
        >
            <DropdownMenu.Trigger asChild>
                <div
                    ref={mergeRefs([forwardedRef, containerRef])}
                    {...props}
                    className={classNames(
                        'absolute min-w-[150px] p-1 rounded-full flex flex-row items-center justify-center text-xs backdrop-blur-sm bg-zinc-800/30 hover:bg-zinc-800/50 shadow-md transition-colors select-none cursor-pointer',
                        className,
                    )}
                    style={{
                        zIndex: FloatTagZIndex,
                        opacity: 0,
                    }}
                    onPointerOver={() => {
                        isPointerOverRef.current = true;
                    }}
                    onPointerLeave={() => {
                        isPointerOverRef.current = false;
                        setVisibleInternal(false);
                    }}
                >
                    Improve the image?
                </div>
            </DropdownMenu.Trigger>
            <DropdownMenu.Content
                className='bg-zinc-900 border border-zinc-800 text-xs text-slate-700 rounded-lg shadow-md'
                sideOffset={4}
                style={{
                    zIndex: FloatTagZIndex + 1,
                }}
            >
                <DropdownMenu.Item
                    className={classNames(
                        styles.dropdownItem,
                        styles.dropdownSelectable,
                        'text-xs text-zinc-300 hover:bg-zinc-800'
                    )}
                    onClick={(e) => {
                        if (!hoveredObjectRef.current) {
                            return;
                        }

                        const {
                            setActiveLeftPanels,
                            setEditingObjectId,
                        } = editorContextStore.getState();

                        setEditingObjectId(hoveredObjectRef.current.id);

                        setActiveLeftPanels((activeLeftPanels) => {
                            return [
                                ...activeLeftPanels,
                                'RegenerateProduct',
                            ]
                        });
                    }}
                >
                    <span className=''>
                        Regenerate product
                    </span>
                </DropdownMenu.Item>
            </DropdownMenu.Content>
        </DropdownMenu.Root>
    )
});


const computeVectorDisplacement = (tl: fabric.Point, bl: fabric.Point, scale: number): fabric.Point => {
    // Vector subtraction (tl - bl)
    const vectorX = tl.x - bl.x;
    const vectorY = tl.y - bl.y;

    // Magnitude of the vector (tl - bl)
    const magnitude = Math.sqrt(vectorX * vectorX + vectorY * vectorY);

    if (magnitude === 0) {
        return tl;
    }

    // Normalize (tl - bl) and scale
    const normalizedAndScaledX = (vectorX / magnitude) * scale;
    const normalizedAndScaledY = (vectorY / magnitude) * scale;

    // Compute the new point based on tl and the displacement
    const newX = tl.x + normalizedAndScaledX;
    const newY = tl.y + normalizedAndScaledY;

    return new fabric.Point(newX, newY);
};

const TopLeftFloatTagHeightPx = 32;

function BottomFloatTag() {
    const editor = editorContextStore(state => state.editor);
    const activeObjectRef = React.useRef<EditorActiveObject>();
    const containerRef = React.useRef<HTMLDivElement>(null);
    const containerVisibleRef = React.useRef(false);
    const isFloatTagVisible = useIsFloatTagVisible();

    const topLeftFloatTagRef = React.useRef<HTMLDivElement | null>(null);
    const bottomFloatTagRef = React.useRef<HTMLDivElement>(null);
    const bottomFloatTagWidthRef = React.useRef(0);
    const [staticImageObjectType, setStaticImageObjectType] = React.useState<StaticImageElement2dType | undefined>();

    const ignoreUpdatePropEventRef = React.useRef(false);

    React.useEffect(() => {
        const handleUpdateObjectPropsEvent: UpdateObjectPropsEventHandler['handler'] = ({ objectId }) => {

            if (ignoreUpdatePropEventRef.current) {

                ignoreUpdatePropEventRef.current = false;

                return;
            }

            if (!activeObjectRef.current || objectId !== activeObjectRef.current?.id) {
                return;
            }

            setStaticImageObjectType(
                getStaticImageElement2dType(activeObjectRef.current)
            );
        };

        editor?.on<UpdateObjectPropsEventHandler>(
            'object:update-props',
            handleUpdateObjectPropsEvent,
        );

        return () => {
            editor?.off<UpdateObjectPropsEventHandler>(
                'object:update-props',
                handleUpdateObjectPropsEvent,
            );
        };
    }, [editor]);

    const updatePosition = React.useCallback(() => {
        if (!containerVisibleRef.current ||
            !containerRef.current ||
            !activeObjectRef.current ||
            !bottomFloatTagRef.current ||
            !topLeftFloatTagRef.current) {
            return;
        }
        const oCoords = activeObjectRef.current.oCoords;
        if (oCoords) {
            const tl = oCoords.tl;
            const tr = oCoords.tr;
            const bl = oCoords.bl;
            const br = oCoords.br;
            const centerX = (tl.x + tr.x + bl.x + br.x) / 4;
            const centerY = (tl.y + tr.y + bl.y + br.y) / 4;

            const bottomY = Math.max(tl.y, tr.y, bl.y, br.y);

            const handlePosition = computeVectorDisplacement(
                tl,
                bl,
                50,
            );

            const bottomTagPosition = new fabric.Point(
                centerX,
                Math.max(bottomY, handlePosition.y),
            );

            const leftMostPoint = argmin(
                [
                    tl,
                    tr,
                    bl,
                    br,
                ],
                (a, b) => {
                    const centerA = a.x - centerX;
                    const centerB = b.x - centerX;

                    if (centerA > 0 && centerB < 0) {
                        return false;
                    } else if (centerA < 0 && centerB > 0) {
                        return true;
                    }

                    const distanceA = distanceSquared(a, bottomTagPosition);
                    const distanceB = distanceSquared(b, bottomTagPosition);

                    return distanceA > distanceB;

                    // // Primary comparison based on the 'x' property
                    // if (a.x !== b.x) {
                    //     return a.x < b.x;
                    // }
                    // // Secondary comparison based on the 'y' property when 'x' values are equal
                    // // Note: To sort by 'y' in descending order when 'x' values are equal, we subtract 'a.y' from 'b.y'
                    // // console.log(`Values are close`);
                    // return a.y < b.y;
                },
            ) || tl;

            containerRef.current.style.left = `${centerX}px`;
            containerRef.current.style.top = `${centerY}px`;

            topLeftFloatTagRef.current.style.left = `${leftMostPoint.x - centerX + 6}px`;
            topLeftFloatTagRef.current.style.top = `${leftMostPoint.y - centerY - TopLeftFloatTagHeightPx}px`;

            bottomFloatTagRef.current.style.left = `${-bottomFloatTagWidthRef.current / 2}px`;
            bottomFloatTagRef.current.style.top = `${Math.max(bottomY, handlePosition.y) - centerY + 12}px`;
        }
    }, []);

    const setActiveObject = React.useCallback((activeObject: EditorActiveObject) => {
        activeObjectRef.current = activeObject;
    }, []);

    const setVisibleInternal = React.useCallback((isVisible: boolean) => {
        if (containerRef.current && isVisible !== containerVisibleRef.current) {
            containerVisibleRef.current = isVisible;
            containerRef.current.style.opacity = isVisible ? '1' : '0';
            containerRef.current.style.pointerEvents = isVisible ? 'auto' : 'none';
            if (!isVisible) {
                // activeObjectRef.current = undefined;
            } else {
                updatePosition();
            }
        }
    }, [updatePosition]);

    const setVisible = React.useCallback((isVisible: boolean) => {
        if (!isFloatTagVisible) {
            isVisible = false;
        }
        setVisibleInternal(isVisible);
    }, [
        isFloatTagVisible,
        setVisibleInternal,
    ]);

    React.useEffect(() => {
        if (!isFloatTagVisible) {
            setVisibleInternal(false);
        }
    }, [
        isFloatTagVisible,
        setVisibleInternal,
    ]);

    const handleBottomFloatTagMount = React.useCallback(() => {
        if (!bottomFloatTagRef.current) {
            return;
        }
        const clientRect = bottomFloatTagRef.current.getBoundingClientRect();
        if (!clientRect) {
            return;
        }
        const {
            width = 0,
        } = clientRect;

        bottomFloatTagWidthRef.current = width;
        updatePosition();
        setVisible(true);
    }, [
        updatePosition,
        setVisible,
    ]);

    const handleComponentUnmount = noop;

    React.useEffect(() => {
        if (!editor) {
            return;
        }
        const handleActiveObjectChange = (activeObject: EditorActiveObject) => {
            if (!canObjectHaveTag(activeObject)) {
                setVisible(false);
                return;
            }
            setActiveObject(activeObject);
            setVisible(true);
        }

        const unsubscribeToStateChanges = editorContextVanillaStore.subscribe(
            state => state.activeObject?.id,
            (activeObjectId) => {
                if (!activeObjectId) {
                    handleActiveObjectChange(null);
                    setVisible(false);
                    setStaticImageObjectType(undefined);
                    return;
                }
                const object = editor.objects.findOneById(activeObjectId);
                handleActiveObjectChange(object);
                setStaticImageObjectType(getStaticImageElement2dType(object));
            }
        );

        const handleSelectionCleared = () => {
            setVisible(false);
        }
        const handleBeforeRender = () => {
            updatePosition();
        }
        const handleBeforeTransform = () => {
            setVisible(false);
        }

        editor.canvas.canvas.on('selection:cleared', handleSelectionCleared);
        editor.canvas.canvas.on('before:render', handleBeforeRender);
        editor.canvas.canvas.on('before:transform', handleBeforeTransform);
        return () => {
            editor.canvas.canvas.off('selection:cleared', handleSelectionCleared);
            editor.canvas.canvas.off('before:render', handleBeforeRender);
            editor.canvas.canvas.off('before:transform', handleBeforeTransform);
            unsubscribeToStateChanges?.();
        }
    }, [
        editor,
        setVisible,
        setActiveObject,
        updatePosition,
    ]);

    return (
        <div
            ref={containerRef}
            id={EDITOR_FLOAT_TAG_CONTAINER_ID}
            className='absolute'
            style={{
                display: 'flex',
                zIndex: FloatTagZIndex,
                opacity: 0,
                pointerEvents: 'none',
            }}
        >
            <div
                ref={topLeftFloatTagRef}
                className='absolute px-1 py-1 rounded-md flex flex-row items-center justify-center text-sm'
            >
                <SelectOptions<StaticImageElement2dType | undefined>
                    value={staticImageObjectType}
                    onValueChange={(value) => {
                        activeObjectRef.current = activeObjectRef.current || editorContextStore.getState().activeObject;

                        if (!activeObjectRef.current) {
                            return;
                        }

                        const type = value as any as StaticImageElement2dType;

                        ignoreUpdatePropEventRef.current = true;

                        setStaticImageElement2dType(
                            editor,
                            activeObjectRef.current,
                            type,
                        );

                        setStaticImageObjectType(type);
                    }}
                    onOpenChange={(open) => {
                        if (!open) {
                            document.body.style.pointerEvents = '';
                        }
                    }}
                    options={imageElement2dTypeDropdownOptions}
                    triggerProps={{
                        className: "p-1 rounded text-xs flex flex-row items-center gap-1 text-zinc-400 hover:text-zinc-300 transition-colors",
                        style: {
                            backgroundColor: BACKGROUND_DARK,
                        }
                    }}
                    triggerChildren={(
                        <>
                            <DropdownImageElement2dTypeValue
                                staticImageObjectType={staticImageObjectType}
                            />
                            <SelectOptionIcon
                                className='text-xs'
                            />
                        </>
                    )}
                />
            </div>
            <div
                ref={bottomFloatTagRef}
                className='absolute px-1 py-1 rounded-md flex flex-row items-center justify-center text-sm bg-zinc-800 shadow-md border border-zinc-700 transition-opacity'
            >
                <ObjectFloatTag
                    onComponentMount={handleBottomFloatTagMount}
                    onComponentUnmount={handleComponentUnmount}
                />
            </div>
        </div>
    );
}

export const EditorFloatTag = React.memo(function EditorFloatTag() {

    return (
        <>
            <CenterFloatTag />
            <BottomFloatTag />
        </>
    );
});