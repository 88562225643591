import React from 'react';
import * as Slider from '@radix-ui/react-slider';
import { InputBoxClassName, InputBoxClassNameError } from 'components/constants/class-names';
import { classNames } from 'core/utils/classname-utils';
import { ApiInputType } from 'core/common/types/api';
import { InputLabel, isApiInputValueMissing } from './input-utils';
import { Tooltip } from 'components/utils/tooltip';

function mapValueToNearestStep(inputValue: number | string, min: number, max: number, step: number): number {
    let value = typeof (inputValue) === 'number' ? inputValue : parseFloat(inputValue);

    // Ensure the value is in the range
    value = Math.min(Math.max(value, min), max);

    // Calculate how many steps the value is from the minimum
    const stepsFromMin = Math.round((value - min) / step);

    // Get the nearest value by adding the calculated steps to the min value
    let nearestValue = min + (stepsFromMin * step);

    // Ensure the final value is in the range
    nearestValue = Math.min(Math.max(nearestValue, min), max);

    return nearestValue;
}

export type SliderInputProps = React.HTMLAttributes<HTMLDivElement> & {
    type: ApiInputType.Slider,
    id: string,
    name: React.ReactNode,
    description?: React.ReactNode,
    descriptionPosition?: 'label' | 'bottom',
    descriptionClassName?: string,
    value: number,
    defaultValue?: number,
    min?: number,
    max?: number,
    step?: number,
    required?: boolean,
    hideRequiredLabel?: boolean,
    numberInputEnabled?: boolean,
    minLabel?: string,
    minTooltip?: React.ReactNode,
    maxLabel?: string,
    maxTooltip?: React.ReactNode,
    onValueChange: (value: number) => void,
    parseStringValue?: (value: string) => number,
};

export function SliderInput({
    id,
    name,
    description,
    descriptionPosition = 'label',
    descriptionClassName = "",
    value,
    defaultValue,
    onValueChange: handleValueChange,
    parseStringValue = parseFloat,
    min = 0,
    max = 100,
    step = 1,
    required = false,
    hideRequiredLabel = false,
    numberInputEnabled = true,
    minLabel,
    minTooltip,
    maxLabel,
    maxTooltip,
    className = "",
    ...props
}: SliderInputProps) {
    defaultValue = defaultValue == null ? (
        (min + max) * 0.5
    ) : defaultValue;
    const [inputValue, setInputValue] = React.useState('');

    React.useEffect(() => {
        setInputValue(value.toString());
    }, [value]);

    const onValueChange = React.useCallback((value: string | number) => {
        const parsedValue = mapValueToNearestStep(
            value,
            min,
            max,
            step,
        );
        handleValueChange(
            parsedValue,
        );
        setInputValue(parsedValue.toString());
    }, [min, max, step, handleValueChange]);

    const missingRequired = required && isApiInputValueMissing({
        type: ApiInputType.Slider,
        value,
        id,
        name,
        onValueChange,
    });

    return (
        <div
            {...props}
            className={classNames(
                'flex flex-col gap-2',
                className,
            )}
        >
            <InputLabel
                htmlFor={id}
                required={required}
                hideRequiredLabel={hideRequiredLabel}
                description={descriptionPosition === 'label' && description}
                descriptionClassName={descriptionClassName}
                missingRequired={missingRequired}
            >
                {name}
            </InputLabel>
            <div className='flex-1 flex flex-row items-center gap-2'>
                <Slider.Root
                    className={`${!numberInputEnabled ? 'mt-2' : ''} flex-1 relative flex items-center select-none touch-none h-5`}
                    defaultValue={[defaultValue]}
                    min={min}
                    max={max}
                    step={step}
                    value={[value]}
                    onValueChange={([value]) => onValueChange(value)}
                >
                    <Slider.Track className="bg-zinc-500 relative grow rounded-full h-[3px]">
                        <Slider.Range className="absolute bg-lime-500 rounded-full h-full" />
                    </Slider.Track>
                    <Slider.Thumb
                        className="block w-5 h-5 bg-lime-500 shadow-[0_2px_10px] shadow-zinc-900/10 rounded-[10px] hover:bg-lime-300 focus:outline-none focus:shadow-[0_0_0_5px] focus:shadow-zinc-900/20"
                        aria-label={id}
                    />
                </Slider.Root>
                {numberInputEnabled && <input
                    type='number'
                    className={classNames(
                        missingRequired ? InputBoxClassNameError : InputBoxClassName,
                        'w-fit max-w-[80px]'
                    )}
                    value={inputValue}
                    onChange={(e) => {
                        setInputValue(e.currentTarget.value);
                    }}
                    onBlur={() => {
                        onValueChange(parseStringValue(inputValue));
                    }}
                />}
            </div>
            {(minLabel || maxLabel) &&
                <div className='w-full flex flex-row justify-between text-sm'>
                    {minLabel && (
                        <div className="justify-start cursor-pointer text-lime-400" onClick={() => onValueChange(min)}>
                            {minTooltip ? (
                              <Tooltip
                                triggerProps={{
                                  asChild: true,
                                }}
                                triggerChildren={
                                  <div>
                                    {minLabel}
                                  </div>
                                }
                                contentProps={{
                                  align: 'start',
                                  sideOffset: 8,
                                }}
                                contentChildren={minTooltip}
                              />
                            ) : (
                              minLabel
                            )}
                        </div>)
                    }
                    {maxLabel && (
                        <div className='align-end cursor-pointer text-lime-400' onClick={() => onValueChange(max)}>
                            {maxTooltip ? (
                                <Tooltip
                                    triggerProps={{
                                      asChild: true,
                                    }}
                                    triggerChildren={
                                      <div>
                                        {maxLabel}
                                      </div>
                                    }
                                    contentProps={{
                                      align: 'start',
                                      sideOffset: 8,
                                    }}
                                    contentChildren={maxTooltip}
                                />
                            ) : (
                                maxLabel
                            )}
                        </div>)
                    }
                </div>
            }
            {descriptionPosition === 'bottom' && description &&
                <div
                    className={classNames(
                        'truncate select-none text-xs text-zinc-600 group-hover:text-zinc-500 transition-colors',
                        descriptionClassName,
                    )}
                >
                    {description}
                </div>}
        </div>
    );

}