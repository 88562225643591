import React from 'react';
import { DropdownClassName, PrimaryButtonClassName, PrimaryButtonClassNameDisabled, PrimaryButtonClassNameLoading, SecondaryButtonClassNameDisabled, SecondaryButtonClassNameInactive } from 'components/constants/class-names';
import { editorContextStore } from 'contexts/editor-context';
import { CustomModelTrainingItem, CustomModelTrainingStatus, getModelTriggerWord, isCustomModelTrainingStatusActive, UiDisplayMessageEventHandler } from 'core/common/types';
import { classNames } from 'core/utils/classname-utils';
import { useCustomModelTraininingInput } from './custom-model-training-context';
import { PlayIcon, RotateCcw } from 'lucide-react';
import * as Dialog from '@radix-ui/react-dialog';
import { Tooltip } from 'components/utils/tooltip';
import { getCustomModelPlaygroundPromptEditorStateFromTrainingId, useCustomModelPlayground } from './custom-model-playground-context';
import { useCustomModelEditor } from './custom-model-editor-context';
import { debugError } from 'core/utils/print-utilts';



const CancelButtonClassNameActive = classNames(
    PrimaryButtonClassName,
    "bg-red-500 hover:bg-red-400 active:bg-red-500 cursor-pointer",
);

const CancelButtonClassNameLoading = classNames(
    PrimaryButtonClassNameLoading,
    "bg-red-500 text-red-800 cursor-wait",
);

function CancelTrainingEditor({
    modelId,
    training,
    setStatus,
    onExit,
}: {
    modelId: string,
    training: CustomModelTrainingItem,
    setStatus: (value: CustomModelTrainingEditorStatus) => void,
    onExit: () => void,
}) {
    const backend = editorContextStore(state => state.backend);
    const [isLoading, setIsLoading] = React.useState(false);

    return (
        <div className='w-full flex flex-col gap-4'>
            <div className=''>
                Are you sure to stop training?
            </div>
            <div className='flex flex-row items-center gap-2'>
                <button
                    className={classNames(
                        isLoading ? SecondaryButtonClassNameDisabled : SecondaryButtonClassNameInactive,
                        "flex-1 flex flex-row items-center justify-center",
                    )}
                    onClick={() => {
                        if (isLoading) {
                            return;
                        }
                        setStatus(CustomModelTrainingEditorStatus.Default);
                    }}
                >
                    Back
                </button>
                <button
                    className={classNames(
                        isLoading ? CancelButtonClassNameLoading : CancelButtonClassNameActive,
                        "flex-1",
                    )}
                    onClick={() => {
                        if (isLoading) {
                            return;
                        }

                        const trainingId = training?.id;

                        if (!backend || !trainingId) {
                            onExit();
                            return;
                        }

                        const {
                            eventEmitter,
                        } = editorContextStore.getState();

                        setIsLoading(true);

                        backend.stopCustomModelTraining({
                            trainingId,
                            modelId,
                        }).then((response) => {

                            if (!response.ok) {
                                eventEmitter.emit<UiDisplayMessageEventHandler>(
                                    'ui:display-message',
                                    'error',
                                    `Error stopping training: ${response.message}`,
                                );
                            }

                        }).finally(() => {
                            setIsLoading(false);
                            onExit();
                        });

                    }}
                >
                    {isLoading ? "Stopping ..." : "Stop"}
                </button>
            </div>
        </div>
    )
}

function StopTrainingButton({
    setStatus,
}: {
    setStatus: (value: CustomModelTrainingEditorStatus) => void,
}) {
    return (
        <button
            className={classNames(
                CancelButtonClassNameActive,
            )}
            onClick={() => {
                setStatus(CustomModelTrainingEditorStatus.Cancel);
            }}
        >
            Stop training
        </button>
    )
}

function RetryTrainingButton({
    onExit,
    modelId,
    training,
    disabled = false,
}: {
    onExit: () => void,
    modelId: string,
    training: CustomModelTrainingItem,
    disabled?: boolean
}) {
    const backend = editorContextStore(state => state.backend);
    const eventEmitter = editorContextStore(state => state.eventEmitter);

    const [isLoading, setIsLoading] = React.useState(false);

    const triggerWord = getModelTriggerWord({
        modelId,
    });

    return (
        <button
            className={classNames(
                disabled ?
                    SecondaryButtonClassNameDisabled :
                    isLoading ? SecondaryButtonClassNameDisabled : SecondaryButtonClassNameInactive,
                "flex flex-row items-center justify-center gap-1",
            )}
            onClick={() => {
                if (!backend || isLoading || disabled) {
                    return;
                }

                onExit();

                setIsLoading(true);

                backend?.startCustomModelTraining({
                    modelId,
                    trainingInput: {
                        ...training.input,
                        trigger_word: triggerWord,
                    },
                }).then((response) => {

                    if (!response.ok) {

                        if (!response.message?.includes('quota left')) {
                            eventEmitter.emit<UiDisplayMessageEventHandler>(
                                'ui:display-message',
                                'error',
                                `Error starting training: ${response.message}`,
                            );
                        }

                        debugError('Error starting training: ', response);

                    }

                }).finally(() => {
                    setIsLoading(false);

                    onExit();
                });
            }}
        >
            <RotateCcw size={16}/>
            <span>
                Retry Training
            </span>
        </button>
    );
}

function TestTrainingButton({
    modelId,
    training,
    onExit,
}: {
    modelId: string,
    training: CustomModelTrainingItem,
    onExit: () => void,
}) {
    const {
        setTab,
    } = useCustomModelEditor();

    const {
        setApiState,
    } = useCustomModelPlayground();

    return (
        <Tooltip
            triggerProps={{
                asChild: true,
            }}
            triggerChildren={(
                <button
                    className={classNames(
                        training.status === CustomModelTrainingStatus.Succeeded ?
                            PrimaryButtonClassName :
                            PrimaryButtonClassNameDisabled,
                        "flex flex-row items-center justify-center gap-1",
                    )}
                    onClick={(e) => {
                        if (training.status !== CustomModelTrainingStatus.Succeeded) {
                            return;
                        }

                        e.preventDefault();

                        const {
                            customModelInfo,
                            customModelWorkflow,
                        } = editorContextStore.getState();

                        const promptEditorState = getCustomModelPlaygroundPromptEditorStateFromTrainingId({
                            customModelType: customModelInfo?.customModelType ?? customModelWorkflow,
                            trainingId: training.id,
                            trainingDisplayName: training.displayName ?? "",
                            caption: training.caption ?? "",
                            modelId: modelId,
                            modelDisplayName: customModelInfo?.displayName ?? "",
                        });

                        setApiState((apiState) => ({
                            ...apiState,
                            promptEditorState,
                        }));

                        onExit();

                        setTab('play');
                    }}
                >
                    <PlayIcon width={16} height={16}/>
                    <span>
                        Test Training
                    </span>
                </button>
            )}
            contentChildren={(
                <span className='text-xs'>
                    Test this training in Generate prompt editor.
                </span>
            )}
        />
    )
}

function TrainingDefaultEditor({
    modelId,
    training,
    setStatus,
    onExit,
}: {
    modelId: string,
    training: CustomModelTrainingItem,
    setStatus: (value: CustomModelTrainingEditorStatus) => void,
    onExit: () => void,
}) {
    const isActive = React.useMemo(() => isCustomModelTrainingStatusActive(training.status), [training.status]);

    return (
        <div className='w-full flex flex-col gap-8 pb-1'>
            <div
                className='flex flex-col items-stretch gap-4 text-sm text-zinc-300'
            >
                <div className='flex flex-row items-center'>
                    <div className='w-[80%] md:w-[90%] text-zinc-500 truncate'>
                        Training Strength
                    </div>
                    <div className=''>
                        {training.input?.trainingStrengthPercent}
                    </div>
                </div>
            </div>
            <div className='flex flex-col items-stretch gap-2'>
                {training.status === CustomModelTrainingStatus.Succeeded && (
                    <TestTrainingButton
                        modelId={modelId}
                        training={training}
                        onExit={onExit}
                    />
                )}
                {isActive ? (
                    <StopTrainingButton
                        setStatus={setStatus}
                    />
                ) : (
                    <RetryTrainingButton
                        onExit={onExit}
                        modelId={modelId}
                        training={training}
                    />
                )}
            </div>
        </div>
    )
}

export enum CustomModelTrainingEditorStatus {
    Default,
    Cancel,
}

function CustomModelTrainingEditorInner({
    status,
    modelId,
    training,
    setStatus,
    onExit,
}: {
    status: CustomModelTrainingEditorStatus,
    modelId: string,
    training: CustomModelTrainingItem,
    setStatus: (value: CustomModelTrainingEditorStatus) => void,
    onExit: () => void,
}) {
    if (status === CustomModelTrainingEditorStatus.Cancel) {
        return (
            <CancelTrainingEditor
                modelId={modelId}
                training={training}
                setStatus={setStatus}
                onExit={onExit}
            />
        )
    }
    return (
        <TrainingDefaultEditor
            modelId={modelId}
            training={training}
            setStatus={setStatus}
            onExit={onExit}
        />
    );
}

export function CustomModelTrainingEditor({
    status: initStatus,
    modelId,
    training,
    onExit,
}: {
    status: CustomModelTrainingEditorStatus,
    modelId: string,
    training: CustomModelTrainingItem,
    onExit: () => void,
}) {
    const backend = editorContextStore(state => state.backend);

    const [status, setStatus] = React.useState(initStatus);

    React.useEffect(() => {
        if (!backend) {
            return;
        }
    }, [backend]);

    const {
        setTrainingStrengthPercent,
    } = useCustomModelTraininingInput();

    React.useEffect(() => {
        setTrainingStrengthPercent(
            training.input?.trainingStrengthPercent ?? 0.5,
        );

    }, [training, setTrainingStrengthPercent]);

    return (
        <div
            className={classNames(
                DropdownClassName,
                'w-[90vw] md:w-[50vw] xl:max-w-[600px] flex flex-col gap-4 px-4 py-3 rounded-xl',
            )}
        >
            <Dialog.Title className='max-w-full truncate font-semibold'>
                Training <span className='text-zinc-500'>{training.id.slice(0, 5)}</span>
            </Dialog.Title>
            <CustomModelTrainingEditorInner
                status={status}
                modelId={modelId}
                training={training}
                setStatus={setStatus}
                onExit={onExit}
            />
        </div>
    )
}