import React from 'react';
import { editorContextStore } from 'contexts/editor-context';
import { subscriptionPlanToRecommendedPlan, subscriptionPlanToRecommendedPlans } from "core/common/types/subscription";
import { getHighestUserStripeSubscriptionItem, getHighestUserStripeSubscriptionPlan } from "core/common/types/user-stripe-subscriptions";



export function useUserStripeSubscriptionUpdateEffect() {
    const user = editorContextStore(state => state.user);
    const backend = editorContextStore(state => state.backend);
    const setUserStripeSubscriptions = editorContextStore(state => state.setUserStripeSubscriptions);

    React.useEffect(() => {
        if (!backend) {
            return;
        }

        return backend.onUserSubscriptionUpdate(
            (stripeSubscriptions = []) => {
                setUserStripeSubscriptions(stripeSubscriptions);
            },
        );
    }, [user, backend, setUserStripeSubscriptions]);
}

export function useUserHighestStripeSubscriptionItem() {
    const userStripeSubscriptions = editorContextStore(state => state.userStripeSubscriptions);
    return React.useMemo(() => {
        return getHighestUserStripeSubscriptionItem({
            userStripeSubscriptions,
        });
    }, [userStripeSubscriptions]);
}

export function useUserHighestStripeSubscription() {
    const userStripeSubscriptions = editorContextStore(state => state.userStripeSubscriptions);

    return React.useMemo(() => {
        return getHighestUserStripeSubscriptionPlan({
            userStripeSubscriptions,
        });
    }, [userStripeSubscriptions]);
}


export function useUserRecommendedSubscriptionPlans() {
    const userSubscriptionPlan = useUserHighestStripeSubscription();
    return React.useMemo(() => subscriptionPlanToRecommendedPlans[userSubscriptionPlan], [userSubscriptionPlan]);
}

export function useUserRecommendedSubscriptionPlan() {
    const userSubscriptionPlan = useUserHighestStripeSubscription();
    return React.useMemo(() => subscriptionPlanToRecommendedPlan[userSubscriptionPlan], [userSubscriptionPlan]);
}

