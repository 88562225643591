import { Timestamp } from "firebase/firestore";


export enum UserRenderJobStatus {
    Active="Active",
    Stopped="Stopped",
    Succeeded="Succeeded",
}

export interface UserRenderJobState {
    id: string;
    status: UserRenderJobStatus;
    gcp_storage_paths?: string[];
    render_finish_timestamp?: Timestamp;
    render_start_timestamp?: Timestamp;
}

export function isUserRenderJobState(renderJobState: any): renderJobState is UserRenderJobState {
    return renderJobState &&
        typeof(renderJobState.id) === 'string' &&
        renderJobState.status
}