import React from 'react';
import { useNavigate } from 'react-router-dom';

import { classNames } from 'core/utils/classname-utils';
import { Tooltip } from 'components/utils/tooltip';

import styles from './dashboard-custom-models.module.css';
import { useCanUserCreateCustomModel } from 'hooks/use-custom-models-effect';
import { editorContextStore } from 'contexts/editor-context';
import { UiDisplayMessageDialogEventHandler } from 'core/common/types';
import { debugLog } from 'core/utils/print-utilts';
import { getObjectEntries } from 'core/utils/type-utils';
import { DashboardTitleClassName } from 'components/constants/class-names';
import { ChevronRight } from 'lucide-react';
import { GridCarousel } from 'components/utils/grid-carousel';
import { CustomModelWorkflow, customModelWorkflowData } from './custom-model-workflows-data';

export type CustomModelWorkflowCardProps = {
    disabled?: boolean;
    workflow: CustomModelWorkflow;
    onClick: () => void;
};

export function CustomModelWorkflowCard({
    workflow,
    disabled = false,
    onClick = () => { },
}: CustomModelWorkflowCardProps) {
    return (
        <Tooltip
            triggerProps={{
                asChild: true,
            }}
            triggerChildren={
                <div
                    className={classNames(
                        'custom-model-workflow-card',
                        'flex items-center justify-start group transition-colors rounded-md ',
                        disabled
                            ? 'cursor-not-allowed text-zinc-500'
                            : `cursor-pointer group-hover:border-neutral-700 group-hover:shadow-neutral-100`,
                        'pb-[82%] relative overflow-hidden'
                    )}
                    onClick={disabled ? undefined : onClick}
                >
                    <div
                        className="absolute pointer-events-none select-none inset-0 transition-all duration-300 bg-cover bg-center group-hover:scale-110"
                        style={{
                            backgroundImage: workflow.imageUrl
                                ? `url(${workflow.imageUrl})`
                                : 'url(https://picsum.photos/seed/' +
                                (workflow.label || 'CustomModelWorkflow').split(' ')[0] +
                                '/500/500)',
                        }}
                    ></div>
                    <div className="absolute inset-0 pointer-events-none select-none bg-cover bg-center bg-black/30 transition-all duration-300 group-hover:bg-transparent"></div>
                    <div
                        className="absolute w-full flex flex-col bottom-0 bg-gradient-to-t from-black to-transparent transition-all group-hover:from-transparent duration-300 pt-12 pb-4 px-4 overflow-hidden text-ellipsis text-left"
                        style={{
                            lineClamp: 2,
                            WebkitLineClamp: 2,
                        }}
                    >
                        {workflow.label && (
                            <div
                                className={`w-full text-zinc-300 text-base font-bold relative left-0 group-hover:left-10 opacity-100 group-hover:opacity-0 transition-all`}
                            >
                                {workflow.label}
                            </div>
                        )}
                        {workflow.subline && (
                            <div
                                className={`w-full text-zinc-400 text-xs font-normal relative left-0 group-hover:left-10 opacity-100 group-hover:opacity-0 transition-all`}
                            >
                                {workflow.subline}
                            </div>
                        )}
                        <button
                            className={classNames(
                                "flex flex-row items-center gap-2 mt-4 w-fit px-2 py-1 text-sm font-semibold rounded backdrop-blur-sm bg-zinc-950/20 border border-zinc-800 text-zinc-300 transition-colors",
                                "hover:text-zinc-900 hover:bg-lime-500"
                            )}
                        >
                            <span>
                                Start training
                            </span>
                            <ChevronRight size={16} className='relative left-0 group-hover:left-1 transition-all'/>
                        </button>
                    </div>
                </div>
            }
            contentProps={{
                align: 'start',
                sideOffset: 8,
            }}
            contentChildren={
                workflow.hoverText || `Create ${workflow.label} photography project.`
            }
        />
    );
}

const workflowGridClassName = `${styles.DashboardCustomModelsWorkflowGrid}`;

export function CustomModelWorkflows() {
    const navigate = useNavigate();

    const canCreate = useCanUserCreateCustomModel();

    const onClickCustomModelWorkflow = React.useCallback(({
        customModelType,
    }: {
        customModelType: string,
    }) => {
        if (!canCreate) {

            const {
                eventEmitter,
            } = editorContextStore.getState();

            debugLog('Display custom model quota subscribe dialog');

            eventEmitter.emit<UiDisplayMessageDialogEventHandler>(
                'ui:display-message-dialog',
                'quota-subscribe',
                {
                    title: "No custom model credits left.",
                    header: "You have used all custom model credits.",
                }
            );

            return;
        }


        navigate(`/newModel/${customModelType}`, {
            replace: true,
        });

    }, [navigate, canCreate]);

    return (
        <div className='flex flex-col items-stretch gap-4'>
            <div className={DashboardTitleClassName}>
                Create custom model
            </div>
            <div className='flex flex-row w-full'>
                <GridCarousel columnsPerPage={3} className='mb-5' childrenContainerClassName='gap-4'>
                    {getObjectEntries(customModelWorkflowData).map((entry) => {
                        if (!entry) return null;

                        const [customModelType, workflow] = entry;

                        if (!workflow) return null;

                        return (
                            <CustomModelWorkflowCard
                                key={customModelType}
                                workflow={workflow}
                                onClick={() => {
                                    onClickCustomModelWorkflow({
                                        customModelType,
                                    });
                                }}
                            />
                        );
                    })}
                </GridCarousel>
            </div>
        </div>
    );
}
