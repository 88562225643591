import React from 'react';
import { Clapperboard, Code2, LayoutGrid, Rocket, Sparkles } from 'lucide-react';

import { ScrollAreaContainer } from 'components/scroll-area/scroll-area';
import { editorContextStore } from 'contexts/editor-context';
import { DashboardType } from 'core/common/types';
import { CustomModelType } from 'core/common/types';
import {
  useFeatureFlags
} from 'hooks/use-featureFlags';

import {
  trackWorkflowAction
} from 'components/panels/panel-items/components/WorkflowCarousel';

const dashboardLeftPanelTabs: {
    [key in DashboardType]?: {
        name: string,
        icon: React.ReactNode,
        onClick?: () => void
    }
} = {
    'workflows': {
        name: 'Workflows',
        icon: <Rocket size={18} />,
        onClick: () => {
            trackWorkflowAction('SidebarNavClick');
        }
    },
    'projects': {
        name: 'Projects',
        icon: <LayoutGrid size={18} />,
    },
    'models': {
        name: 'Custom Models',
        icon: <Sparkles size={18} />,
    },
    'videos': {
        name: 'Videos',
        icon: <Clapperboard size={18}/>
    },
    'manage-api': {
        name: "API",
        icon: <Code2 size={18} />
    },
};

export default function DashboardLeftPanel() {
    const dashboardType = editorContextStore(state => state.dashboardType);
    const setDashboardType = editorContextStore(state => state.setDashboardType);
    const isWorkflowsEnabled = useFeatureFlags('Workflows');
    const {
        setCustomModelWorkflow,
    } = editorContextStore.getState();

    return (
        <ScrollAreaContainer
            className='p-4 lg:w-[268px] hidden md:block md:flex-none border-r border-zinc-800'
        >
            {Object.entries(dashboardLeftPanelTabs).filter(([key, tab]) => {
                return key !== 'workflows' || isWorkflowsEnabled;
            }).map(([key, tab]) => <div
                key={key}
                className={`w-full flex flex-row items-center justify-start select-none cursor-pointer px-4 py-2 mb-2 rounded-md transition-colors border active:border-zinc-700 active:text-white ${dashboardType === key ? 'text-zinc-100 bg-zinc-800 shadow-lg border-zinc-800 hover:border-zinc-700/50' : 'text-zinc-300 hover:text-zinc-200 hover:bg-zinc-800/80 border-zinc-900'}`}
                onClick={() => {
                    if (key === 'models') {
                        setCustomModelWorkflow(CustomModelType.Custom);
                    }
                    // @ts-ignore
                    setDashboardType(key);
                    tab.onClick?.();
                }}
            >
                <span className='mr-3'>
                    {tab.icon}
                </span>
                <span>
                    {tab.name}
                </span>
            </div>)}
        </ScrollAreaContainer>
    );
}