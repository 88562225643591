import { Backend } from "backend/base";
import { RenderJobControllerStatus, RenderJobControllerV2, RenderJobControllerV2JobData } from "core/common/interfaces";
import { debugError } from "core/utils/print-utilts";


interface WebRenderJobControllerV2Args {
    backend: Backend,
}

export class WebRenderJobControllerV2 implements RenderJobControllerV2 {
    jobs: RenderJobControllerV2JobData[] = [];

    private _backend: Backend;

    private _status: RenderJobControllerStatus = RenderJobControllerStatus.Rendering;

    constructor({
        backend,
    }: WebRenderJobControllerV2Args) {
        this._backend = backend;
    }

    get status() {
        return this._status;
    }

    private cancelJobCallbacks: (() => void | Promise<void>)[] = [];

    setCancelJobCallback(callback: () => void | Promise<void>) {
        this.cancelJobCallbacks.push(callback);
    }

    destroy() {
        this.abort();
        this.jobs.length = 0;
        this.cancelJobCallbacks.length = 0;
    }

    private async abortInternal() {
        await Promise.all(this.jobs.map(({jobId, userId}) => {
            return this._backend.stopRenderJob({
                jobId,
                userId,
            });
        }));
    }

    async abort() {
        await this.cancelJob();
    }

    async cancelJob() {
        debugError('Cancelling web render process controller');

        this._status = RenderJobControllerStatus.Cancelled;

        try {

            await this.abortInternal();

        } catch (error) {

            debugError(error);

        }

        try {

            await Promise.all(this.cancelJobCallbacks.map((callback) => callback?.()?.catch?.(debugError)));

        } catch (error) {

            debugError(error);

        }


        this.cancelJobCallbacks.length = 0;
    }

    isCancelled() {
        return this.status !== RenderJobControllerStatus.Rendering;
    }
}