import React from "react";
import { LeftPanelTitle } from "../components/left-panel-title";
import { LeftPanelSectionContainer } from "../base";
import { SimpleSpinner } from "components/icons/simple-spinner";
import { Download } from "lucide-react";
import { classNames } from "core/utils/classname-utils";
import { PrimaryButtonClassName, PrimaryButtonClassNameDisabled, PrimaryButtonClassNameLoading } from "components/constants/class-names";
import { downloadVideoUrl } from "components/utils/data";
import { editorContextStore } from "contexts/editor-context";
import { StaticImageObjectVideoGenerationResult } from "core/common/types/elements";
import { Assets } from "core/controllers/assets";
import { debugError } from "core/utils/print-utilts";
import { isStaticImageObjectVideoGenerationResult } from "core/utils/type-guards";

enum DownloadVideoStatus {
    Idle = "Idle",
    Downloading = "Downloading",
}


function DownloadVideoSection({
    className = "",
    activeObject,
    onClick,
    ...props
}: React.ButtonHTMLAttributes<HTMLButtonElement> & {
    activeObject: StaticImageObjectVideoGenerationResult,
}) {
    const [status, setStatus] = React.useState(DownloadVideoStatus.Idle);

    const isLoading = React.useMemo(() => status !== DownloadVideoStatus.Idle, [status]);

    const videoStoragePath = React.useMemo(() => (
        activeObject?.metadata?.videoGenerationResultStoragePath
    ), [activeObject?.metadata]);

    const isDisabled = React.useMemo(() => !videoStoragePath, [videoStoragePath]);

    const handleDownload = React.useCallback(async () => {
        if (isDisabled || status !== DownloadVideoStatus.Idle || !videoStoragePath) {
            return;
        }

        const {
            backend,
        } = editorContextStore.getState();

        if (!backend) {
            return;
        }

        setStatus(DownloadVideoStatus.Downloading);

        try {

            const videoUrl = await Assets.loadAssetFromPath({
                backend,
                path: videoStoragePath,
            });

            if (!videoUrl) {
                return;
            }

            await downloadVideoUrl(
                videoUrl,
                "output-video.mp4",
            );

        } catch (error) {

            debugError(`Error downloading video path ${videoStoragePath}: `, error);

        } finally {
            setStatus(DownloadVideoStatus.Idle);
        }
    }, [status, isDisabled, videoStoragePath]);

    return (
        <LeftPanelSectionContainer label="Download">
            <button
                {...props}
                className={classNames(
                    isDisabled ?
                        PrimaryButtonClassNameDisabled :
                        (isLoading ? PrimaryButtonClassNameLoading : PrimaryButtonClassName),
                    'flex flex-row justify-center text-center gap-2',
                    className,
                )}
                onClick={(e) => {
                    handleDownload();

                    onClick?.(e);
                }}
            >
                {isLoading ?
                    <SimpleSpinner
                        width={18}
                        height={18}
                        pathClassName="fill-lime-500"
                    /> :
                    <Download
                        size={18}
                        className="mr-2"
                    />}
                Download video
            </button>
        </LeftPanelSectionContainer>
    )
}


export function EditVideoPanel() {
    const activeObject = editorContextStore(state => state.activeObject);


    if (!isStaticImageObjectVideoGenerationResult(activeObject)) {
        return null;
    }

    return (
        <div
            className="flex flex-col items-stretch"
        >
            <LeftPanelTitle>
                Edit Video
            </LeftPanelTitle>
            <div className="flex flex-col items-stretch gaps-2">
                <DownloadVideoSection
                    activeObject={activeObject}
                />
            </div>
        </div>
    );
}