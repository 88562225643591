import { PrimaryButtonClassName, PrimaryButtonClassNameDisabled } from 'components/constants/class-names';
import { editorContextStore } from 'contexts/editor-context';
import { EditImageProcessType, EditImageProcessTypeName, PastGeneration, PromptTemplate } from 'core/common/types';
import { classNames } from 'core/utils/classname-utils';
import React from 'react';
import { LeftPanelSectionContainer } from '../base';
import { Navigate } from '../components/navigate';
import { ProgressBar } from '../components/progress-bar';
import { Tooltip } from '../../../utils/tooltip';
import { fabric } from 'fabric'
import { StartRenderJobArgs } from 'backend/base';
import { createObjectEditImageProgressController, ObjectWithProgress } from './edit-image-process';
import { EditorActiveObject } from 'core/common/interfaces';
import { isStaticImageObject } from 'core/utils/type-guards';
import { Editor } from 'core/editor';

import { getDataUrlFromImageElement } from 'core/utils/image-utils';

import { addRenderImageResultToCanvas, getNegativePrompt, onRenderImageResultAdded } from '../../../utils/render';
import { WebRenderProcessController } from '../../../utils/render-process-controller';

import { RenderPipelineArgs, RenderPipelineType } from 'core/common/types/render-args';

import { getDataUrlFromString } from 'core/utils/asset-utils';
import { getTemplateFromPrompt } from "core/common/prompt-template";
import { DropdownOptionItem, DropdownOptionsWithTrigger } from 'components/utils/dropdown-options';
import { QuestionMarkCircledIcon } from '@radix-ui/react-icons';
import { getMaskImageFromPastGeneration, getPastGenerationFromStaticImage } from 'core/utils/past-generation-utils';
import { downloadDataUrl } from 'components/utils/data';
import { WebRenderJobControllerV2 } from 'components/utils/render-process-controller-v2';

type ProcessingButtonObject = fabric.StaticImage & ObjectWithProgress;

export interface RenderImageVariationsArgs {
    img2imgUrl: string;
    img2imgStrength: number;
    strength: number;
    prompt?: string;
    imageUrl?: string;
}

export async function renderImageVariations({ img2imgUrl, img2imgStrength, prompt, strength, imageUrl }: RenderImageVariationsArgs) {
    const {
        editor,
        backend,
        userQuotas,
        setGenerateToolIsRendering,
        setGenerateToolRenderProgress,
        setGenerateToolRenderProcessController,
    } = editorContextStore.getState();

    if (!editor) {
        console.error('Editor is invalid.');
        return;
    }

    if (!backend) {
        console.error('Backend is invalid.');
        return;
    }

    const pipelineType = RenderPipelineType.RefCanny;

    const {
        user,
    } = editorContextStore.getState();

    if (!user) {
        console.error('User is invalid.');
        return;
    }

    const { uid } = user;
    const renderPipelineArgs: RenderPipelineArgs = {
        uid,
        prompt: prompt || await backend.getImageCaption({
            imageUrl: img2imgUrl,
        }),
        negative_prompt: getNegativePrompt(),
        guidance_scale: 3,
        pipeline_type: pipelineType,
        ref_image: img2imgUrl,
        ref_image_canny_strength: strength,
    };

    if (imageUrl !== "" && imageUrl !== undefined) {
        renderPipelineArgs.composite_image = imageUrl;
    } else {
        renderPipelineArgs.composite_image = generateTransparentCanvasDataUrl(100, 100);
    }

    if (!renderPipelineArgs) {
        setGenerateToolIsRendering(false);
        setGenerateToolRenderProgress(0);
        return;
    }

    const renderProcessController = new WebRenderJobControllerV2({
        backend,
    });
    setGenerateToolRenderProcessController(renderProcessController);

    let newImgUrl = "";
    let newIndex = 0;

    await backend.startRenderJob({
        renderPipelineType: pipelineType,
        renderPipelineArgs,
        userSubscriptionTier: userQuotas?.tier,
        onRenderProgress: (progress) => {
            setGenerateToolIsRendering(true);
            if (typeof (progress) === 'number') {
                setGenerateToolRenderProgress(progress);
            } else if (typeof (progress) === 'string') {
                progress = parseFloat(progress);
                if (!isNaN(progress)) {
                    setGenerateToolRenderProgress(progress);
                }
            }
        },
        onReceiveRenderResult: async ({
            imageUrl,
            index,
        }) => {
            if (!imageUrl) {
                console.error('Image url is invalid');
                return;
            }


            if (!imageUrl) {
                return;
            }

            newImgUrl = imageUrl;
            newIndex = index;
        },
        renderJobController: renderProcessController,
    });

    return { newImgUrl, newIndex };
}

function ProcessingButton({
    object,
    onFinish,
}: {
    object: ProcessingButtonObject,
    onFinish: () => void,
}) {
    const [progress, setProgress] = React.useState(0);
    React.useEffect(() => {
        return object.editImageProgressController?.subscribeToProgress(
            setProgress,
            () => setProgress(1),
            onFinish,
        );
    }, [object.editImageProgressController, onFinish]);
    return (
        <ProgressBar
            progress={progress}
        />
    )
}

function OtherProcessRunningButton({
    type,
}: {
    type?: EditImageProcessType,
}) {
    const name = type ? EditImageProcessTypeName[type].toLowerCase() : 'undefined';
    return (
        <div
            className={classNames(
                PrimaryButtonClassNameDisabled,
                'cursor-wait',
            )}
        >
            Waiting for {name} tool to finish
        </div>
    )
}

function RenderButton({
    className,
    ...props
}: React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>) {
    return (
        <button
            {...props}
            className={classNames(
                PrimaryButtonClassName,
                className ?? '',
            )}
        >
            Generate
        </button>
    )
}

async function loadPastGeneration(
    object: fabric.StaticImage,
    editor: Editor,
) {
    return getPastGenerationFromStaticImage(
        editor.assets,
        object,
    );
}

async function loadInputImage(
    pastGeneration: PastGeneration | undefined,
    editor: Editor,
) {
    if (!pastGeneration?.inputImagePath) {
        return;
    }

    return await editor.assets.loadAsset({
        path: pastGeneration.inputImagePath,
        type: 'image-storage',
    });
}

export async function imageVariationImageObject({
    object,
    strength,
    onRenderError,
}: {
    object: fabric.StaticImage & ObjectWithProgress,
    renderArgs?: StartRenderJobArgs,
    strength: number,
    onRenderError: (error: Error) => void,
}) {
    const {
        editor,
        backend,
    } = editorContextStore.getState();

    if (!backend || !editor) {
        console.error('Editor or backend is invalid.');
        return;
    }

    // let productDataUrl: string | undefined;

    const pastGeneration = await loadPastGeneration(object, editor);

    const productDataUrl = await getMaskImageFromPastGeneration({
        editor,
        backend,
        pastGeneration,
    });

    try {
        const {
            editor,
            backend,
        } = editorContextStore.getState();
        if (editor && backend && object.aCoords) {

            const fullBackgroundImage = await getStaticImageElement(object);
            if (!(fullBackgroundImage instanceof HTMLImageElement)) {
                onRenderError(new Error('Object is not a valid image'));
                return;
            }

            const width = fullBackgroundImage.width;
            const height = fullBackgroundImage.height;

            fullBackgroundImage.crossOrigin = 'anonymous';

            const img2imgUrl = await getDataUrlFromImageElement({
                from: fullBackgroundImage,
                width,
                height,
            });

            if (!img2imgUrl) {
                onRenderError(new Error('Image url is invalid'));
                return;
            }

            const img2imgStrength = 0.05;
            const renderImageVariationsParams: RenderImageVariationsArgs = {
                img2imgUrl: img2imgUrl,
                img2imgStrength: img2imgStrength,
                strength: strength,
                imageUrl: productDataUrl,
            };

            if (pastGeneration && pastGeneration.prompt) {
                renderImageVariationsParams.prompt = pastGeneration.prompt;
            }

            const outputUrlObj = await renderImageVariations(renderImageVariationsParams);
            if (!outputUrlObj) {
                onRenderError(new Error('variant image url is invalid'));
                return;
            }


            const objectScaledWidth = object.getScaledWidth() || 100;

            const objectScaledHeight = object.getScaledHeight() || 100;

            const location = object.getCenterPoint() || new fabric.Point(0, 0);
            location.setX(location.x + objectScaledWidth);

            await addRenderResultToCanvasAndStorage({
                imageUrl: outputUrlObj.newImgUrl,
                prompt: pastGeneration?.prompt ?? '',
                promptTemplate: pastGeneration?.promptTemplate,
                index: outputUrlObj.newIndex,
                width: objectScaledWidth,
                height: objectScaledHeight,
                startLocation: location,
                inputImagePath: pastGeneration?.inputImagePath,
                inputMaskImagePath: pastGeneration?.inputMaskImagePath,
            });
        } else {
            onRenderError(new Error('Image url is invalid'));
        }

    } catch (error) {
        onRenderError(error as Error);
    }
}

function generateTransparentCanvasDataUrl(width: number, height: number) {
    const canvas = document.createElement('canvas');
    canvas.width = width;
    canvas.height = height;
    const ctx = canvas.getContext('2d');
    ctx!.fillStyle = 'rgba(0,0,0,0)';
    ctx!.fillRect(0, 0, canvas.width, canvas.height);
    return canvas.toDataURL();
}

async function addRenderResultToCanvasAndStorage({
    imageUrl,
    prompt,
    promptTemplate,
    index,
    width,
    height,
    startLocation,
    inputImagePath,
    inputMaskImagePath,
}: {
    imageUrl: string,
    prompt: string,
    promptTemplate?: PromptTemplate,
    index: number,
    width: number,
    height: number,
    startLocation: fabric.Point,
    inputImagePath?: string,
    inputMaskImagePath?: string,
}) {
    const renderImageObject = await addRenderImageResultToCanvas({
        imageUrl,
        index,
        width,
        height,
        startLocation,
    });

    if (!renderImageObject) {
        return;
    }

    return onRenderImageResultAdded({
        outputImage: renderImageObject,
        prompt,
        promptTemplate,
        inputImagePath,
        inputMaskImagePath,
    });
}

function getStaticImageElement(object: fabric.StaticImage) {
    return new Promise<HTMLImageElement>((resolve, reject) => {
        const imageElement = new Image();

        imageElement.crossOrigin = 'anonymous';

        imageElement.onload = () => resolve(imageElement);

        imageElement.onerror = reject;

        imageElement.src = object.getSrc();
    });
}

function ObjectInvalidButton() {
    return (
        <div
            className={PrimaryButtonClassNameDisabled}
        >
            Select an image first.
        </div>
    )
}

function ImageVariationsPrimaryButton({
    object,
    isProcessing,
    setIsProcessing,
    onRenderStart,
    editImageProcessType,
    isOtherProcessRunning,
}: {
    object: EditorActiveObject,
    isProcessing: boolean,
    setIsProcessing: (value: boolean) => void,
    editImageProcessType?: EditImageProcessType,
    isOtherProcessRunning: boolean,
    onRenderStart: () => void,
}) {

    if (!isStaticImageObject(object)) {
        return <ObjectInvalidButton />
    }

    return (
        isOtherProcessRunning ?
            <OtherProcessRunningButton
                type={editImageProcessType}
            /> :
            isProcessing ?
                <ProcessingButton
                    object={object as ProcessingButtonObject}
                    onFinish={() => setIsProcessing(false)}
                /> :
                <RenderButton
                    onClick={onRenderStart}
                />
    )
}

const variantStrengthOptions: Record<string, DropdownOptionItem<number>> = {
    'none': {
        name: 'None',
        value: 0.0,
    },
    'extra-weak': {
        name: 'Extra Weak',
        value: 0.02,
    },
    'weak': {
        name: 'Weak',
        value: 0.05,
    },
    'default': {
        name: 'Default',
        value: 0.1,
    },
    'strong': {
        name: 'Strong',
        value: 0.15,
    },
    'extra-strong': {
        name: 'Extra Strong',
        value: 0.2,
    }
};

export function ImageVariations() {
    const [strength, setStrength] = React.useState<number>(variantStrengthOptions['default'].value);
    const [isProcessing, setIsProcessing] = React.useState(false);
    const [editImageProcessType, setEditImageProcessType] = React.useState<EditImageProcessType>();
    const isOtherProcessRunning = React.useMemo(() => editImageProcessType != null && editImageProcessType !== 'image-variations', [editImageProcessType]);
    const [selectedOption, setSelectedOption] = React.useState(variantStrengthOptions['default'].name);

    const activeObject = editorContextStore(state => state.activeObject);

    return (
        <div className='flex flex-col'>
            <Navigate />
            <LeftPanelSectionContainer>
                <div className='h-4' />
                <div className="w-full flex flex-row items-center justify-center">
                    <div className='flex-1 flex flex-row items-center justify-center gap-2'>
                        <span className='truncate font-semibold select-none'>
                            Strength
                        </span>
                        <Tooltip
                            triggerProps={{
                                className: 'flex-1 mr-4 truncate font-semibold select-none',
                            }}
                            triggerChildren={
                                <QuestionMarkCircledIcon
                                    className='text-zinc-500 hover:text-zinc-300 transition-colors'
                                />
                            }
                            contentChildren='How close to the orginial image it is. The stronger the value, the more similar the variantion is.'
                        />
                    </div>
                    <DropdownOptionsWithTrigger
                        value={selectedOption}
                        options={variantStrengthOptions}
                        onSelectItem={(options) => {
                            if (!options || options.disabled === true) {
                                return;
                            }
                            setStrength(options.value);
                            setSelectedOption(options.name);
                        }}
                    />
                </div>
                <div className='h-4' />
                <ImageVariationsPrimaryButton
                    object={activeObject}
                    isProcessing={isProcessing}
                    setIsProcessing={setIsProcessing}
                    isOtherProcessRunning={isOtherProcessRunning}
                    editImageProcessType={editImageProcessType}
                    onRenderStart={() => {
                        if (!isStaticImageObject(activeObject)) {
                            return;
                        }

                        setEditImageProcessType('image-variations');

                        setIsProcessing(true);

                        createObjectEditImageProgressController({
                            type: 'image-variations',
                            object: activeObject as ProcessingButtonObject,
                            strength,
                            onRenderError: () => {
                                setIsProcessing(false);
                            },
                        });
                    }}
                />
            </LeftPanelSectionContainer>
        </div>
    );
}
