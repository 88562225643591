import React from "react"
import { CANVAS_CONTAINER_ID } from "components/constants/ids";
import { SimpleSpinner } from "components/icons/simple-spinner";

import { editorContextStore } from "contexts/editor-context";
import { hideObjectControls, showObjectControls } from "core/utils/fabric";
import { isFabricObject } from "core/utils/type-guards";
import { createPortal } from "react-dom";
import { EditorActiveObject } from "core/common/interfaces";
import objectLoadingCoverStyles from './object-loading-cover.module.css';
import { classNames } from "core/utils/classname-utils";
// import { debugLog } from "core/utils/print-utilts";

export interface ObjectLoadingCoverProps {
    object: EditorActiveObject,
    message?: string,
    className?: string,
}

export function ObjectLoadingCover({
    object,
    message,
    className = "",
}: ObjectLoadingCoverProps) {
    const container = React.useMemo(() => document.getElementById(CANVAS_CONTAINER_ID), []);
    const editor = editorContextStore(state => state.editor);
    const coverRef = React.useRef<HTMLDivElement | null>(null);
    const [coverSize, setCoverSize] = React.useState([0, 0]);

    React.useEffect(() => {
        if (isFabricObject(object)) {
            object.set('selectable', false);
            hideObjectControls(object);
            if (object.oCoords) {
                const {tl, tr, bl} = object.oCoords;
                const displayWidth = tl.distanceFrom(tr);
                const displayHeight = tl.distanceFrom(bl);
                setCoverSize([displayWidth, displayHeight]);
            }
        }
        return () => {
            if (isFabricObject(object)) {
                object?.set('selectable', true);
                showObjectControls(object);
                editor?.canvas.requestRenderAll();
            }
        }
    }, [editor, object]);

    const onBeforeRender = React.useCallback(() => {
        if (object && typeof(object.setCoords) === 'function' && coverRef.current) {
            object.setCoords();
            const tl = object.oCoords?.tl;
            const tr = object.oCoords?.tr;
            const bl = object.oCoords?.bl;
            const br = object.oCoords?.br;
            const angle = object.angle ?? 0;
            if (tl && tr && bl && br) {
                const width = tl.distanceFrom(tr);
                const height = tl.distanceFrom(bl);
                const left = tl.x;
                const top = tl.y;

                if (width > 0 && height > 0) {
                    coverRef.current.style.left = `${left}px`;
                    coverRef.current.style.top = `${top}px`;
                    coverRef.current.style.width = `${width}px`;
                    coverRef.current.style.height = `${height}px`;
                    coverRef.current.style.transform = `rotate(${angle}deg)`;
                    coverRef.current.style.transformOrigin = '0 0';
                }
            }
        }
    }, [object]);

    React.useEffect(() => {
        onBeforeRender?.();
        editor?.canvas.canvas.on('before:render', onBeforeRender);
        return () => {
            editor?.canvas.canvas.off('before:render', onBeforeRender);
        }
    }, [editor, onBeforeRender]);

    return container && createPortal(
        <div
            ref={coverRef}
            className={classNames(
                "absolute bg-zinc-800/50 cursor-wait select-none pointer-events-none flex flex-col justify-end",
                objectLoadingCoverStyles.OpacityPulse,
                className,
            )}
            style={{
                width: `${coverSize[0]}px`,
                height: `${coverSize[1]}px`,
            }}
        >
            <div className="flex flex-row justify-center items-center mb-2 gap-2">
                <SimpleSpinner
                    width={28}
                    height={28}
                    pathClassName="fill-lime-500"
                />
                {message && <span
                    className="min-w-0 truncate"
                >
                    {message}
                </span>}
            </div>
        </div>,
        container,
    );
}