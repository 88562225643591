import React from "react";
import { editorContextStore } from "contexts/editor-context";
import { getApiDashboardModelUrl } from "core/common/types/api";

/**
 * Replaces the current URL while preserving existing search parameters.
 * @param newPath - The new path to navigate to (e.g., '/videos').
 */
function replaceUrl(newPath: string) {
    const currentUrl = new URL(window.location.href);
    const newUrl = new URL(newPath, window.location.origin);

    // Merge existing search params into the new URL
    currentUrl.searchParams.forEach((value, key) => {
        // Avoid overwriting if the new URL already has the same key
        if (!newUrl.searchParams.has(key)) {
            newUrl.searchParams.append(key, value);
        }
    });

    // Replace the current history state with the new URL
    window.history.replaceState(null, '', newUrl.toString());
}

/**
 * Custom hook to navigate based on the dashboard type while preserving URL search params.
 */
export function useDashboardModelNavigateEffect() {
    const dashboardType = editorContextStore((state) => state.dashboardType);
    const apiDashboardModelType = editorContextStore(
        (state) => state.apiDashboardModelType
    );

    React.useEffect(() => {
        let targetUrl: string = window.location.pathname; // Default to current path

        switch (dashboardType) {
            case "manage-api":
                targetUrl = getApiDashboardModelUrl(apiDashboardModelType);
                break;
            case "projects":
                targetUrl = "/projects";
                break;
            case "models":
                targetUrl = "/models";
                break;
            case "videos":
                targetUrl = "/videos";
                break;
            case "workflows":
                targetUrl = "/workflows";
                break;
            default:
                // Optionally handle unknown dashboard types
                console.warn(`Unknown dashboard type: ${dashboardType}`);
                break;
        }

        replaceUrl(targetUrl);
    }, [dashboardType, apiDashboardModelType]);
}
