import React from "react";
import { Navigate } from "../components/navigate";
import { editorContextStore } from "contexts/editor-context";
import { GenerateVideoDialog } from "components/popup/message-dialog/generate-video-dialog";
import { getObjectEntries } from "core/utils/type-utils";
import { GenerateVideoEditorSection, GenerateVideoLeftPanelTab, GenerateVideoLeftPanelTabLabels, isVideoGenerationStatusActive, VideoGenerationDoc, VideoGenerationStatus } from "core/common/types/video";
import { ImageComponent } from "components/utils/image";
import { Backend } from "backend/base";
import { classNames } from "core/utils/classname-utils";
import { frostedGlassClassName, SecondaryButtonClassNameInactive } from "components/constants/class-names";
import { Info, Play } from "lucide-react";
import { getVideoThumbnailUrlFromGeneration, useVideoGenerationProgress, useVideoGenerationThumbnail, useVideoPastGenerationsItems, useVideoPastGenerationsUpdateEffect, VideoPastGenerationProvider } from "components/video/video-past-generations";
import dashboardStyles from 'components/video/dashboard-video.module.css';
import { VideoComponent } from "components/video/video-component";
import { capitalizeFirstLetter } from "core/utils/string-utils";
import { MasonryGrid } from "components/utils/masonry-grid";

const frostedGlassClassNameSmall = classNames(
    frostedGlassClassName,
    "px-2 py-1 text-xs",
)

function VideoGenerationLoading({
    generation,
    thumbnailUrl: initThumbnailUrl,
}: {
    generation: VideoGenerationDoc,
    thumbnailUrl: string,
}) {
    // const [progressInternal, setProgressInternal] = React.useState(0);

    const backend = editorContextStore(state => state.backend);

    const [thumbnailUrl, setThumbnailUrl] = React.useState(initThumbnailUrl);

    const progressInternal = useVideoGenerationProgress({
        generation,
        onGenerationUpdate: (newGeneration) => {
            getVideoThumbnailUrlFromGeneration({
                backend,
                generation: newGeneration,
            }).then((url) => {
                setThumbnailUrl((prevUrl) => url || prevUrl);
            });
        },
    });

    return (
        <div
            className={classNames(
                SecondaryButtonClassNameInactive,
                "group relative p-0 overflow-hidden cursor-wait",
            )}
        >
            <ImageComponent
                src={thumbnailUrl}
                className='object-cover'
            />
            <div
                className={classNames(
                    'absolute m-2 left-0 bottom-0',
                    frostedGlassClassName,
                )}
            >
                Progress {Math.round(progressInternal * 100)} %
            </div>
            <div
                className={classNames(
                    'absolute left-0 bottom-0 w-full h-px bg-zinc-800/10',
                )}
            >
                <div
                    className={`${dashboardStyles.TransitionWidth} rounded-full h-full bg-lime-500`}
                    style={{
                        width: `${progressInternal * 100}%`,
                    }}
                />
            </div>
        </div>
    )
}

function VideoGenerationFinished({
    generation,
    thumbnailUrl,
}: {
    generation: VideoGenerationDoc,
    thumbnailUrl: string,
}) {
    const backend = editorContextStore(state => state.backend);

    return (
        <div
            className={classNames(
                SecondaryButtonClassNameInactive,
                "group relative p-0 overflow-hidden",
            )}
        >
            <VideoComponent
                backend={backend}
                src={generation?.outputVideoStoragePath ?? ""}
                thumbnailUrl={thumbnailUrl}
                className='w-full h-full'
            />
            <div className='absolute m-2 left-0 bottom-0 flex flex-row items-stretch justify-stretch gap-2'>
                <div
                    className={classNames(
                        'group-hover:text-white group-hover:backdrop-blur-md text-xs',
                        frostedGlassClassNameSmall,
                    )}
                >
                    {capitalizeFirstLetter(generation.status)}
                </div>
            </div>
        </div>
    );
}

function VideoGeneration({
    backend,
    generation,
}: {
    backend?: Backend,
    generation: VideoGenerationDoc,
}) {
    const thumbnailUrl = useVideoGenerationThumbnail({
        backend,
        generation,
    });

    if (isVideoGenerationStatusActive(generation.status)) {
        return (
            <VideoGenerationLoading
                generation={generation}
                thumbnailUrl={thumbnailUrl}
            />
        );
    }

    return (
        <VideoGenerationFinished
            generation={generation}
            thumbnailUrl={thumbnailUrl}
        />
    );
}


function PastGenerations() {
    const backend = editorContextStore(state => state.backend);

    useVideoPastGenerationsUpdateEffect();

    const generateVideoPastGenerations = useVideoPastGenerationsItems();

    return (
        <MasonryGrid
            className={classNames(
                "gap-2",
            )}
            columnClassName={classNames(
                "gap-2",
            )}
            items={generateVideoPastGenerations}
            breakpoints={{
                1024: 4, // 4 columns for widths >= 1200px
                512: 3, // 3 columns for widths >= 1024px
                256: 2,  // 2 columns for widths >= 768px
                0: 1,    // 1 column for widths < 768px
            }}
            renderItem={([generationId, generation]) => (
                <VideoGeneration
                    key={generationId}
                    backend={backend}
                    generation={generation}
                />
            )}
        />
    );
}


function TipsContainer({
    children,
    className="",
    isActive=false,
    ...props
}: React.HTMLAttributes<HTMLDivElement> & {
    isActive?: boolean,
}) {
    return (
        <div
            {...props}
            className={classNames(
                'mt-2 flex flex-col items-stretch gap-1 border p-2 rounded-lg text-sm transition-colors',
                className,
                isActive ? "bg-lime-900/20 border-lime-900/30 text-lime-400 shadow-md" : "bg-transparent border-transparent text-zinc-500 hover:border-lime-900/10 hover:text-zinc-300",
            )}
        >
            {children}
        </div>
    )
}

function TipsTitle({
    children,
    className="",
    ...props
}: React.HTMLAttributes<HTMLDivElement>) {
    return (
        <div
            {...props}
            className={classNames('flex flex-row items-center justify-start', className)}
        >
            {children}
        </div>
    )
}

interface GuideConfig {
    title: React.ReactNode,
    content: React.ReactNode,
}

const guideConfigs: Record<GenerateVideoEditorSection, GuideConfig> = {
    [GenerateVideoEditorSection.Keyframe]: {
        title: (
            <div className="flex flex-col md:flex-col gap-2">
                <TipsTitle>
                    <Info size={16} className='mr-2' />
                    <div className='flex-1 truncate font-semibold '>
                        How to select keyframes
                    </div>
                </TipsTitle>
                <ul className="ml-4 list-disc list-outside font-normal text-sm gap-2 [&>li]:pl-2 [&>li]:pb-2">
                    <li>
                        Click the "Select from canvas" button to select an image from the current canvas.
                    </li>
                    <li>
                        Click the "Upload file" button to upload an image from your device.
                    </li>
                    <li>
                        Click and drag the images to arrange the start and end frame of the output video.
                    </li>
                </ul>
            </div>
        ),
        content: (
            <div></div>
        ),
    },
    [GenerateVideoEditorSection.Prompt]: {
        title: (
            <div className="flex flex-col md:flex-col gap-2">
                <TipsTitle>
                    <Info size={16} className='mr-2' />
                    <div className='flex-1 truncate font-semibold '>
                        How to write prompt
                    </div>
                </TipsTitle>
                <ul className="ml-4 list-disc list-outside font-normal text-sm gap-2 [&>li]:pl-2 [&>li]:pb-2">
                    <li>
                        Describe the subject, background, and any key element you want in the video.
                    </li>
                    <li>
                        Click the "Autocaption" text button on the top right of the prompt box to automatically generate prompt.
                    </li>
                </ul>
            </div>
        ),
        content: (
            <div></div>
        ),
    },
    [GenerateVideoEditorSection.CameraMotion]: {
        title: (
            <div className="flex flex-col md:flex-col gap-2">
                <TipsTitle>
                    <Info size={16} className='mr-2' />
                    <div className='flex-1 truncate font-semibold '>
                        Select camera motion
                    </div>
                </TipsTitle>
                <ul className="ml-4 list-disc list-outside font-normal text-sm gap-2 [&>li]:pl-2 [&>li]:pb-2">
                    <li>
                        In general, selecting a camera motion would improve the generation quality.
                    </li>
                    <li>
                        Alternatively, describe the camera motion in the prompt. For example, dynamic, quick cuts, slow-motion, timelapse, flythrough, fpv drone flythrough, etc.
                    </li>
                </ul>
            </div>
        ),
        content: (
            <div></div>
        ),
    },
}

function Guide() {
    const generateVideoEditorSection = editorContextStore(state => state.generateVideoEditorSection);
    return (
        <div
            className="flex flex-col items-stretch gap-4"
        >
            {getObjectEntries(guideConfigs).map(([type, config]) => {
                return (
                    <TipsContainer
                        key={type}
                        isActive={generateVideoEditorSection === type}
                    >
                        {config.title}
                        {config.content}
                    </TipsContainer>
                );
            })}
        </div>
    )
}


function GenerateVideoTabTrigger({
    value,
}: {
    value: GenerateVideoLeftPanelTab,
}) {
    const activeTab = editorContextStore(state => state.generateVideoLeftPanelTab);
    const setActiveTab = editorContextStore(state => state.setGenerateVideoLeftPanelTab);

    return (
        <button
            className={classNames(
                'py-2 text-center hover:text-lime-500 active:text-lime-600 focus:outline-none active:outline-none focus-visible:outline-none border-solid border-0 border-b transition-colors font-semibold cursor-pointer',
                value === activeTab ?
                    'border-lime-500 hover:border-lime-500 text-zinc-300' : 'border-zinc-800 text-zinc-400'
            )}
            onClick={() => setActiveTab(value)}
        >
            {GenerateVideoLeftPanelTabLabels[value]}
        </button>
    );
}

function GenerateVideoTabList() {
    return (
        <div
            className="grid grid-cols-2 gaps-2 shadow-md bg-zinc-900"
        >
            <GenerateVideoTabTrigger
                value={GenerateVideoLeftPanelTab.Guide}
            />
            <GenerateVideoTabTrigger
                value={GenerateVideoLeftPanelTab.PastGenerations}
            />
        </div>
    )
}

const generateVideoTabs: Record<GenerateVideoLeftPanelTab, () => JSX.Element> = {
    [GenerateVideoLeftPanelTab.Guide]: Guide,
    [GenerateVideoLeftPanelTab.PastGenerations]: PastGenerations,
};

function GenerateVideoTabContent() {
    const activeTab = editorContextStore(state => state.generateVideoLeftPanelTab);

    const Tab = generateVideoTabs[activeTab];

    return (
        <Tab/>
    );
}

function GenerateVideoInner() {
    return (
        <div
            className='flex flex-col gap-2'
        >
            <Navigate/>
            <GenerateVideoTabList/>
            <GenerateVideoTabContent/>
            <GenerateVideoDialog/>
        </div>
    );
}

export function GenerateVideo() {
    return (
        <VideoPastGenerationProvider>
            <GenerateVideoInner/>
        </VideoPastGenerationProvider>
    );
}