import { clamp } from 'lodash';

import { CustomModelType } from 'core/common/types';
import { getObjectEntries } from 'core/utils/type-utils';
import { debugLog } from 'core/utils/print-utilts';

export function getCustomModelTrainingInputFromSliderValue({
    percent,
    sliderOptions,
}: {
    percent: number,
    sliderOptions: CustomModelTrainingInputSliderOptions,
}) {
    return Object.fromEntries(
        getObjectEntries(sliderOptions)
        .map(([key, options]) => {
            const index = clamp(
                Math.round(percent * options.length - 1),
                0,
                options.length - 1,
            );

            debugLog(`Get training input ${key} from ${percent} at ${index}: ${options[index]}`);

            return [
                key,
                options[index],
            ];
        })
    ) as Record<keyof CustomModelTrainingInputSliderOptions, number>;
}

export const defaultCustomModelTrainingInputSliderOptions: CustomModelTrainingInputSliderConfigs = {
    defaultValue: 5,
    maxNumSteps: 10,
    options: {
        learningRate: [
            2e-4,
            1e-4,
            8e-5,
        ],
        steps: [1000, 1500, 2000, 2500, 3000, 4000],
        iterMultipler: [1.0, 1.2, 1.5, 1.8, 2.0, 2.2],
    },
};

export interface CustomModelTrainingInputSliderOptions {
    learningRate: number[],
    steps: number[],
    iterMultipler: number[],
}

export interface CustomModelTrainingInputSliderConfigs {
    defaultValue: number,
    maxNumSteps: number,
    options: CustomModelTrainingInputSliderOptions,
};

export type CustomModelWorkflow = {
    label?: string;
    subline?: string;
    imageUrl: string;
    hoverText: string;
    dataTabLabel?: string;
    trainingSliderConfigs: CustomModelTrainingInputSliderConfigs;
    moodboardExampleUrls?: string[];
    moodboardInfoboxText?: string | string[];
    playgroundPrompt?: string;
    playgroundInfoboxModelDescription?: string;
};

export const customModelWorkflowData: {
    [type in CustomModelType]?: CustomModelWorkflow
} = {
    [CustomModelType.Product]: {
        label: 'Product',
        dataTabLabel: 'Product photos',
        subline: 'Train model based on your product',
        imageUrl: 'https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/348762d6-a9b9-44dc-2986-e4aa74209c00/1024',
        hoverText: 'Create a custom model that preserves the details of your product.',
        trainingSliderConfigs: {
            defaultValue: 8,
            maxNumSteps: 10,
            options: {
                learningRate: [
                    1e-4,
                    9e-5,
                    8e-5,
                    7e-5,
                ],
                steps: [2000, 2500, 3000, 3500, 4000, 4500],
                iterMultipler: [1.0, 1.2, 1.5, 1.8, 2.0, 2.2, 2.5],
            },
        },
        moodboardInfoboxText: [
            'Upload 3 or more photos of your product. You can take these photos yourself or find them online',
            'Results get better with more photos from different angles',
        ],
        moodboardExampleUrls: [
            'https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/17bab463-c909-4f2e-9edd-9e1d5d8b7900/1024',
            'https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/d80c93e8-8ccd-4ace-9970-18ac45afdf00/1024',
            'https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/bce0a7d5-a4cd-44f7-cbc5-aa45a703e700/1024',
            'https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/54842d7f-ac67-4ee0-bf4a-706dc2db3000/1024',
            'https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/77ee8f8b-f73d-47a2-78bb-b3bc22b4c600/1024',
        ],
        playgroundPrompt: 'Photo of @MENTION_MODEL in front of a light brown background, 35mm lens',
    },
    [CustomModelType.Fashion]: {
        label: 'Fashion & Wearables',
        dataTabLabel: 'Clothing photos',
        subline: 'Train model based on your fashion item',
        imageUrl: 'https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/6ec46ec0-14d8-4930-f06f-8df49c0fa300/1024',
        hoverText: 'Create a custom model that preserves the details of your clothing.', // TODO Update text
        trainingSliderConfigs: {
            defaultValue: 9,
            maxNumSteps: 10,
            options: {
                learningRate: [
                    1e-4,
                    9e-5,
                    8e-5,
                    7e-5,
                ],
                steps: [2000, 2500, 3000, 3500, 4000, 4500],
                iterMultipler: [1.0, 1.2, 1.5, 1.8, 2.0, 2.2, 2.5],
            },
        },
        moodboardInfoboxText: [
            'Upload 3 or more photos of your clothing. You can take these photos yourself or find them online',
            'Results get better with more photos from different angles',
        ],
        moodboardExampleUrls: [
            'https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/345d39d0-6ba5-47b5-ba5a-d8f31ed04a00/1024',
            'https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/cced9c26-897b-4dcc-a3ee-99d3bf2eaa00/1024',
            'https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/613e9b50-c5e3-4f88-33f9-00a3d2aab500/1024',
            'https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/26c9b3c5-5695-4b02-1d74-1fd122384700/1024',
            'https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/6a832c02-b5a0-449c-4729-12394cc6da00/1024',
        ],
        playgroundPrompt: 'Tall model wearing @MENTION_MODEL walking on the street in the city towards the camera, 80mm lens',
        playgroundInfoboxModelDescription: 'a checkered beige scarf',
    },
    [CustomModelType.Style]: {
        label: 'Aesthetic',
        subline: 'Create artistic model based on your style',
        imageUrl: 'https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/c5cd42e6-6332-4774-6676-0a665d3b6400/1024',
        hoverText: 'Create a model that learns a style based on your moodboard.',
        trainingSliderConfigs: {
            defaultValue: 5,
            maxNumSteps: 10,
            options: {
                learningRate: [
                    2e-4,
                    1e-4,
                    8e-5,
                ],
                steps: [500, 1000, 1500, 2000, 2500],
                iterMultipler: [1.0, 1.2, 1.5, 1.8, 2.0],
            },
        },
        moodboardInfoboxText: 'Upload 3 photos with a consistent style aesthetic - results get better with more photos.',
        moodboardExampleUrls: [
            // TODO add moodboard examples
            // 'https://picsum.photos/seed/CustomModelWorkflowAestheticMoodboardExample1/500/500',
            // 'https://picsum.photos/seed/CustomModelWorkflowAestheticMoodboardExample2/500/500',
        ],
    },
    [CustomModelType.Furniture]: {
        label: 'Furniture',
        subline: 'Train model based on your furniture item',
        imageUrl: 'https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/881cb760-9035-4df0-40c4-6e1819cd3e00/1024',
        hoverText: 'Create a custom model that preserves the details of your furniture item.',
        trainingSliderConfigs: {
            defaultValue: 5,
            maxNumSteps: 10,
            options: {
                learningRate: [
                    1e-4,
                    9e-5,
                    8e-5,
                    7e-5,
                ],
                steps: [2000, 2500, 3000, 3500, 4000, 4500],
                iterMultipler: [1.0, 1.2, 1.5, 1.8, 2.0, 2.2, 2.5],
            },
        },
        moodboardInfoboxText: [
            'Upload 3 or more photos of your furniture item. You can take these photos yourself or find them online',
            'Results get better with more photos from different angles',
        ],
        moodboardExampleUrls: [
            'https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/62861f02-b5e0-49fa-4d52-9997bb6f8f00/1024',
            'https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/e8b325d5-0172-4118-3d3c-413b934eb300/1024',
            'https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/b2d95928-fdcb-40da-e2eb-2fbc35f09100/1024',
            'https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/f65ba150-9a6e-4605-0276-906fe9372300/1024',
            'https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/0ad9806a-3b23-4ab9-7db1-b8969aa9cb00/1024',
            'https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/1dffa114-e53e-4ebc-c2fd-9f29ae5e9500/1024',
            'https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/33fe72a0-9117-48f3-58ff-25970aa54e00/1024',
            'https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/a77dd2cd-9052-458b-dbda-762b20395f00/1024',

        ],
        playgroundPrompt: 'a photo of @MENTION_MODEL [describe furniture] sitting in a large spacious modern room with natural lighting, 60 mm lens, professional product photography with contrasting highlight and shadows on the background',
        playgroundInfoboxModelDescription: 'an upholstered lounge armchair',
    },
    [CustomModelType.Tech]: {
        label: 'Tech',
        subline: 'Train model based on your tech item',
        imageUrl: 'https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/02fe7e7b-bc83-4309-521f-e8624a47e500/1024',
        hoverText: 'Create a custom model that preserves the details of your furniture item.',
        trainingSliderConfigs: {
            defaultValue: 5,
            maxNumSteps: 10,
            options: {
                learningRate: [
                    1e-4,
                    9e-5,
                    8e-5,
                    7e-5,
                ],
                steps: [2000, 2500, 3000, 3500, 4000, 4500],
                iterMultipler: [1.0, 1.2, 1.5, 1.8, 2.0, 2.2, 2.5],
            },
        },
        moodboardInfoboxText: [
            'Upload 3 or more photos of your furniture item. You can take these photos yourself or find them online',
            'Results get better with more photos from different angles',
        ],
        moodboardExampleUrls: [
            'https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/5c40a7e7-2040-4128-7fb4-818538531200/1024',
            'https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/52e11097-f7c7-46a0-175c-2bb228fd8b00/1024',
            'https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/4382334d-e3a1-43b9-c3a1-908e5407fc00/1024',
            'https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/d85f8e0f-e85d-4a56-d730-d6490f2e6500/1024',
            'https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/b0dae43f-b265-4778-e9aa-40d3c4263000/1024',
        ],
        playgroundPrompt: 'photo of a @MENTION_MODEL [describe tech product model] the product is up in the air surrounded in air with different colored big and small shiny and matte balls flying around in front of a soft dark background, 60 mm lens, professional product photography with contrasting highlight and shadows on the background',
        playgroundInfoboxModelDescription: 'a high tech mixed reality headset',
    },
    [CustomModelType.Food]: {
        label: 'Food',
        subline: 'Train model based on your Food item',
        imageUrl: 'https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/1cf4fae7-736a-4f7b-30b4-bf4157bb3000/1024',
        hoverText: 'Create a custom model that preserves the details of your furniture item.',
        trainingSliderConfigs: {
            defaultValue: 5,
            maxNumSteps: 10,
            options: {
                learningRate: [
                    1e-4,
                    9e-5,
                    8e-5,
                    7e-5,
                ],
                steps: [2000, 2500, 3000, 3500, 4000, 4500],
                iterMultipler: [1.0, 1.2, 1.5, 1.8, 2.0, 2.2, 2.5],
            },
        },
        moodboardInfoboxText: [
            'Upload 3 or more photos of your furniture item. You can take these photos yourself or find them online',
            'Results get better with more photos from different angles',
        ],
        moodboardExampleUrls: [
            'https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/4841fcb4-b8b3-4915-2c7f-11ad7cee8900/1024',
            'https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/828dbd1a-7093-4fc7-76e8-8e52c3cfc500/1024',
            'https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/3a481908-1321-433a-cefa-1514d776fb00/1024',
            'https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/36627059-6e84-4c39-c64c-c19a30ba8000/1024',
            'https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/fb1d99b3-429c-4f57-a7fc-e276a25c3c00/1024',
        ],
        playgroundPrompt: 'photo of a @MENTION_MODEL [describe food] 60 mm lens, professional food photography with contrasting highlight and shadows on the background',
        playgroundInfoboxModelDescription: 'a delicious looking cakepop',
    },
    [CustomModelType.Vase]: {
        label: 'Vase',
        subline: 'Train model based on your Food item',
        imageUrl: 'https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/73c51e30-5c43-4f8f-6ed2-fd1b11953f00/1024',
        hoverText: 'Create a custom model that preserves the details of your furniture item.',
        trainingSliderConfigs: {
            defaultValue: 5,
            maxNumSteps: 10,
            options: {
                learningRate: [
                    1e-4,
                    9e-5,
                    8e-5,
                    7e-5,
                ],
                steps: [2000, 2500, 3000, 3500, 4000, 4500],
                iterMultipler: [1.0, 1.2, 1.5, 1.8, 2.0, 2.2, 2.5],
            },
        },
        moodboardInfoboxText: [
            'Upload 3 or more photos of your furniture item. You can take these photos yourself or find them online',
            'Results get better with more photos from different angles',
        ],
        moodboardExampleUrls: [
            'https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/b8ccef74-1737-484a-4773-ae5cd79c7c00/1024',
            'https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/14950c39-dd88-47c5-5dd3-1cc2347a5200/1024',
            'https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/733b8a4a-d763-47a1-a521-13597ad05900/1024',
            'https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/6c56d55b-92d7-4b78-8017-be8f0060ff00/1024',
        ],
        playgroundPrompt: 'a photo of a [describe product] @MENTION_MODEL posing on beige fabric against a beige vogue photoshoot in overexposed light and shadows, motion photography, old money 35mm lens, professional fashion photography',
        playgroundInfoboxModelDescription: 'a handcrafted ceramic vase',
    },
    [CustomModelType.VirtualModel]: {
        label: 'Virtual Model',
        subline: 'Train model based on your model',
        imageUrl: 'https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/e1693755-2fb2-4b53-37d3-61061c3b2d00/1024',
        hoverText: 'Create a custom model that preserves the details of your model.',
        trainingSliderConfigs: {
            defaultValue: 5,
            maxNumSteps: 10,
            options: {
                learningRate: [
                    1e-4,
                    9e-5,
                    8e-5,
                    7e-5,
                ],
                steps: [2000, 2500, 3000, 3500, 4000, 4500],
                iterMultipler: [1.0, 1.2, 1.5, 1.8, 2.0, 2.2, 2.5],
            },
        },
        moodboardInfoboxText: [
            'Upload 3 or more photos of your model. You can take these photos yourself or find them online',
            'Results get better with more photos from different angles',
        ],
        moodboardExampleUrls: [
            'https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/90d0e30d-e575-4705-528a-e1dbd458f700/1024',
            'https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/6042e274-71fc-48dd-d645-5fd8f80a2c00/1024',
            'https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/233a6fd9-12da-4f5e-afaa-9a0a3ccc1700/1024',
            'https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/34dda41c-aaad-4163-4f35-3b89e416e600/1024',
        ],
        playgroundPrompt: 'a photo of a woman @MENTION_MODEL [describe model] posing on beige fabric against a beige vogue photoshoot in overexposed light and shadows, motion photography, old money 35mm lens, professional fashion photography',
    },
    [CustomModelType.Footwear]: {
        label: 'Footwear',
        subline: 'Train model based on your shoes',
        imageUrl: 'https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/1fb13f64-30af-4c7d-3950-3fa18730ce00/1024',
        hoverText: 'Create a custom model that preserves the details of your shoes.',
        trainingSliderConfigs: {
            defaultValue: 5,
            maxNumSteps: 10,
            options: {
                learningRate: [
                    1e-4,
                    9e-5,
                    8e-5,
                    7e-5,
                ],
                steps: [2000, 2500, 3000, 3500, 4000, 4500],
                iterMultipler: [1.0, 1.2, 1.5, 1.8, 2.0, 2.2, 2.5],
            },
        },
        moodboardInfoboxText: [
            'Upload 3 or more photos of your model. You can take these photos yourself or find them online',
            'Results get better with more photos from different angles',
        ],
        moodboardExampleUrls: [
            'https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/aa81d199-14bf-4af1-ddaa-d14ca2971b00/1024',
            'https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/301dc9ab-824a-4a0a-9287-3b96d504c600/1024',
            'https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/ab4bae9d-e22c-4a90-bb47-ecdbe405b400/1024',
            'https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/301dc9ab-824a-4a0a-9287-3b96d504c600/1024',
            'https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/dc021344-1d9c-4b39-ca4d-5eb2a1346500/1024',
            'https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/f8ce7d1d-237b-4ac9-5b88-00805b22a600/1024',
            'https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/1dba4ecd-6d5f-4cd1-cdf1-1df6a37dff00/1024',
            'https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/78ca2794-65ee-4144-778c-238a477af600/1024',

        ],
        playgroundPrompt: 'a pair @MENTION_MODEL [describe shoes] shoes floating on a cloud front of a  light blue gradient  background with shadows on it. 60mm lens, professional studio photography',
        playgroundInfoboxModelDescription: 'a futuristic looking running shoe',
    },

    [CustomModelType.Jewelry]: {
        label: 'Jewelry & Accessories',
        subline: 'Train model based on your jewelry',
        imageUrl: 'https://flair.ai/cdn-cgi/imagedelivery//i1XPW6iC_chU01_6tBPo8Q/ab8afae4-f9ec-4f10-a8a1-4a0c024f8b00/1024',
        hoverText: 'Create a custom model that preserves the details of your jewelry.',
        trainingSliderConfigs: {
            defaultValue: 5,
            maxNumSteps: 10,
            options: {
                learningRate: [
                    1e-4,
                    9e-5,
                    8e-5,
                    7e-5,
                ],
                steps: [2000, 2500, 3000, 3500, 4000, 4500],
                iterMultipler: [1.0, 1.2, 1.5, 1.8, 2.0, 2.2, 2.5],
            },
        },
        moodboardInfoboxText: [
            'Upload 3 or more photos of your model. You can take these photos yourself or find them online',
            'Results get better with more photos from different angles',
        ],
        moodboardExampleUrls: [
            'https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/dbef0885-c8c7-44fd-47e2-b7da6f154c00/1024',
            'https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/a902fe7a-c21b-49c6-3308-a7419bf08700/1024',
            'https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/a0525d9c-723b-4ebc-0ff7-f89db14de700/1024',
            'https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/005f31ad-6168-4efc-c93d-5e4ee64a6200/1024',
            'https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/0aa69fca-2fcc-442b-d0f4-76dbafd34d00/1024',
            'https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/59c736e6-e71c-4aa1-d00c-6bb5c0dd0200/1024',
            'https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/5119411c-72ec-4ebe-f195-8c2080e28300/1024',
        ],
        playgroundPrompt: 'photo of a @MENTION_MODEL sitting on a piece of white concrete slab surrounded by dry white branches and flowers in front of a soft white background, 60 mm lens, professional studio photography with contrasting highlight and shadows on the background',
        playgroundInfoboxModelDescription: 'a diamond engagement ring',
    },
    [CustomModelType.Bags]: {
        label: 'Bags & Purses',
        subline: 'Train model based on your shoes',
        imageUrl: 'https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/5fbbd38b-f680-4901-634a-d4f73f6e2e00/1024',
        hoverText: 'Create a custom model that preserves the details of your shoes.',
        trainingSliderConfigs: {
            defaultValue: 5,
            maxNumSteps: 10,
            options: {
                learningRate: [
                    1e-4,
                    9e-5,
                    8e-5,
                    7e-5,
                ],
                steps: [2000, 2500, 3000, 3500, 4000, 4500],
                iterMultipler: [1.0, 1.2, 1.5, 1.8, 2.0, 2.2, 2.5],
            },
        },
        moodboardInfoboxText: [
            'Upload 3 or more photos of your model. You can take these photos yourself or find them online',
            'Results get better with more photos from different angles',
        ],
        moodboardExampleUrls: [
            'https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/2557731c-852a-47bd-b808-3b8eb00a8100/1024',
            'https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/236e0054-6456-424a-cec6-5d0427e25200/1024',
            'https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/bee96321-c0ab-46b3-f359-37c8371dd700/1024',
            'https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/343240a3-5c27-4f14-5c8d-66d08f041600/1024',
            'https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/9a512ad3-7dc2-4e3e-d3ee-c22739ac8d00/1024',
        ],
        playgroundPrompt: 'photo of an [describe model] wearing a @MENTION_MODEL [describe bag] shoulder bag posing in front of a soft rust colored background, professional fashion photography',
        playgroundInfoboxModelDescription: 'a cognac color leather handbag',
    },
};
