import { editorContextStore } from 'contexts/editor-context';
import React from 'react';

import {
    Routes,
    Route,
    Navigate,
} from 'react-router-dom';
import { FinishEmailLogin } from './auth/finish-email-login';
import { FullScreenInviteCodeInput } from './auth/invite-code';
import { LoginFullscreen } from './auth/login-fullscreen';
import { MobileRedirectErrorFullscreen, MobileRedirectFullscreen } from './auth/require-desktop';
import { EMAIL_LINK_SIGNIN, NEW_PROJECT, NEW_TRYON_PROJECT } from './constants/routes';
import { CreateNewProject } from './dashboard/create-new-project';
import { Dashboard } from './dashboard/dashboard';
import { EditorInternal } from './editor/canvas-editor';
import { EditorRouter } from './editor/editor-router';
import { CreateNewTryOnProject } from './dashboard/create-new-tryon-project';
import { ApiPlaygroundRouter } from './dashboard/api';
import { CreateCustomModel } from './custom-model/create-custom-model';
import { CustomModelEditor } from './custom-model/custom-model-editor';
import { CustomModelDataEditorPage } from './custom-model/data-editor';
import { GenerateVideoEditorDebug } from './video/generate-video-editor';

function Projects() {
    React.useEffect(() => {
        editorContextStore.getState().setDashboardType('projects');
    }, []);
    return (
        <Navigate to="/" />
    );
}

export function AppRoutes() {
    return (
        <Routes>
            <Route path="/" element={<Dashboard />} />
            <Route path="/workflows" element={<Dashboard dashboardType='workflows' />} />
            <Route path="/login" element={<LoginFullscreen />} />
            <Route path="/mobile" element={<MobileRedirectFullscreen />} />
            <Route path="/mobileError" element={<MobileRedirectErrorFullscreen />} />
            <Route path={`/${EMAIL_LINK_SIGNIN}`} element={<FinishEmailLogin />} />
            <Route path="inviteCode" element={<FullScreenInviteCodeInput />} />
            <Route path="/projects" element={<Projects />} />
            <Route path="/projects/:projectId" element={<EditorRouter />} />
            <Route path="/api" element={<Dashboard dashboardType='manage-api' />} />
            <Route path="/api/:apiId" element={<ApiPlaygroundRouter />} />
            <Route path="/editorDebug" element={<EditorInternal />} />
            <Route path={`/${NEW_PROJECT}`} element={<CreateNewProject />} />
            <Route path={`/${NEW_PROJECT}/:projectType`} element={<CreateNewProject />} />
            <Route path={`/${NEW_TRYON_PROJECT}`} element={<CreateNewTryOnProject />} />
            <Route path="/newModel" element={<CreateCustomModel />} />
            <Route path="/newModel/:workflow" element={<CreateCustomModel />} />
            <Route path="/models" element={<Dashboard dashboardType='models' />} />
            <Route path="/models/:modelId" element={<CustomModelEditor />} />
            <Route path="/models/:modelId/:dataId" element={<CustomModelDataEditorPage />} />
            <Route path="/videos" element={<Dashboard dashboardType='videos'/>} />
            <Route path="/generateVideoDebug" element={<GenerateVideoEditorDebug />} />
        </Routes>
    );
}