import * as HoverCard from '@radix-ui/react-hover-card';
import { QuestionMarkCircledIcon } from '@radix-ui/react-icons';
import { DropdownClassName } from 'components/constants/class-names';
import { LEFT_PANEL_WIDTH } from 'components/constants/left-panels';
import { EditorLeftPanelTooltipZIndex } from 'components/constants/zIndex';
import { classNames } from 'core/utils/classname-utils';

export type TooltipProps = HoverCard.HoverCardProps & {
    openDelay?: number,
    closeDelay?: number,
    triggerProps?: HoverCard.HoverCardTriggerProps,
    triggerChildren?: React.ReactNode,
    contentClassName?: string,
    contentChildren?: React.ReactNode,
    contentStyle?: React.CSSProperties,
    contentProps?: HoverCard.HoverCardContentProps,
}

export function Tooltip({
    openDelay = 200,
    closeDelay = 100,
    triggerProps,
    triggerChildren,
    contentChildren,
    contentClassName,
    contentStyle = {
        maxWidth: LEFT_PANEL_WIDTH,
    },
    contentProps = {
        sideOffset: 8,
    },
    ...props
}: TooltipProps) {
    return (
        <HoverCard.Root
            openDelay={openDelay}
            closeDelay={closeDelay}
            {...props}
        >
            <HoverCard.Trigger
                {...triggerProps}
            >
                {triggerChildren || <QuestionMarkCircledIcon />}
            </HoverCard.Trigger>
            <HoverCard.Content
                {...contentProps}
                className={classNames(
                    DropdownClassName,
                    'bg-zinc-950 text-sm',
                    contentClassName || '',
                )}
                style={{
                    zIndex: EditorLeftPanelTooltipZIndex,
                    ...contentStyle
                }}
            >
                {contentChildren}
            </HoverCard.Content>
        </HoverCard.Root>
    )
}