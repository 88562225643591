import { UserProjectType } from 'core/common/types';
import { customModelWorkflowData } from 'components/custom-model/custom-model-workflows-data'
import { CustomModelType, UiDisplayMessageDialogEventHandler } from 'core/common/types';

export type SpecialProjectTypes =
  | UserProjectType.Accessories
  | UserProjectType.Beauty
  | UserProjectType.CPG
  | UserProjectType.CustomModels
  | UserProjectType.Fashion
  | UserProjectType.Food
  | UserProjectType.Furniture
  | UserProjectType.Homegoods
  | UserProjectType.Staging
  | UserProjectType.Humans
;

export function isSpecialProjectType(projectType: UserProjectType): projectType is SpecialProjectTypes {
  return (
    projectType === UserProjectType.Beauty ||
    projectType === UserProjectType.Accessories ||
    projectType === UserProjectType.CPG ||
    projectType === UserProjectType.Fashion ||
    projectType === UserProjectType.Food ||
    projectType === UserProjectType.Furniture ||
    projectType === UserProjectType.Homegoods ||
    projectType === UserProjectType.Staging ||
    projectType === UserProjectType.Humans
  );
}

export const SpecialProjectTypeOrder: SpecialProjectTypes[] = [
  UserProjectType.Beauty,
  UserProjectType.CustomModels,
  UserProjectType.Humans,
  UserProjectType.Food,
  UserProjectType.Furniture,
  UserProjectType.CPG,
  UserProjectType.Staging,
  UserProjectType.Accessories,
  UserProjectType.Fashion,
  UserProjectType.Homegoods,
];

export function getOrderedProjectTypes(): SpecialProjectTypes[] {
  return SpecialProjectTypeOrder;
}

interface ProjectTypeInfo {
  carouselName: string;
  workflowName: string;
  image: string;
  tooltipText: string;
  untitledName: string;
}

export const UserProjectTypeInfo: Record<SpecialProjectTypes, ProjectTypeInfo> = {
  [UserProjectType.Accessories]: {
    carouselName: 'Accessories',
    workflowName: 'Shoes, Handbags, & Accessories',
    image: 'https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/c631412d-3ffb-49b6-7fc2-bbd6c2c0dc00/1024',
    tooltipText: 'Create shoes, handbags, & accessories photography project',
    untitledName: 'Untitled Accessories Project',
  },
  [UserProjectType.Beauty]: {
    carouselName: 'Beauty',
    workflowName: 'Skincare, Beauty & Cosmetics',
    image: 'https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/982ea4ba-2ce1-4d01-bd95-d19f8835a000/1024',
    tooltipText: 'Create skincare, beauty & cosmetics photography project',
    untitledName: 'Untitled Beauty Project',
  },
  [UserProjectType.CPG]: {
    carouselName: 'CPG',
    workflowName: 'Consumer Packaged Goods',
    image: 'https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/f4ec02d4-4f1f-45ff-ca9e-06209d8ba100/1024',
    tooltipText: 'Create consumer packaged goods photography project',
    untitledName: 'Untitled CPG Project',
  },
  // Will only link to models
  [UserProjectType.CustomModels]: {
    carouselName: 'Fashion Model',
    workflowName: 'Fashion Model',
    image: 'https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/1cd3b773-9ea4-4d19-f892-c432571f7800/1024',
    tooltipText: 'Create your own custom model for fashion model photography project',
    untitledName: 'NOT NEEDED SINCE WE LINK TO MODELS',
  },
  [UserProjectType.Fashion]: {
    carouselName: 'Graphic T-shirts ',
    workflowName: 'Graphic T-shirts and Sweaters',
    image: 'https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/70a08ba7-8e07-45f0-cc28-f4634a295a00/1024',
    tooltipText: 'Create fashion project',
    untitledName: 'Untitled graphic T-shirts Project',
  },
  [UserProjectType.Food]: {
    carouselName: 'Food',
    workflowName: 'Food Photography',
    image: 'https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/a470a4fc-049f-4717-b2d9-2b06ef4c7200/1024',
    tooltipText: 'Create food photography project',
    untitledName: 'Untitled Food Project',
  },
  [UserProjectType.Furniture]: {
    carouselName: 'Furniture',
    workflowName: 'Furniture Photography',
    image: 'https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/d6b94ca4-d0d2-499c-6023-7e0d48e7a700/1024',
    tooltipText: 'Create furniture photography project',
    untitledName: 'Untitled Furniture Project',
  },
  [UserProjectType.Homegoods]: {
    carouselName: 'Homegoods',
    workflowName: 'Vases, Pottery & Homegoods',
    image: 'https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/f1be62c3-2173-472f-1bd3-ee0959911300/1024',
    tooltipText: 'Create Vases, Pottery & Homegoods project',
    untitledName: 'Untitled Homegoods Project',
  },
  [UserProjectType.Staging]: {
    carouselName: 'Staging',
    workflowName: 'Virtual Staging',
    image: 'https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/ac792bab-96d0-4544-df39-44d35a896400/1024',
    tooltipText: 'Create virtual staging project',
    untitledName: 'Untitled Staging Project',
  },
  [UserProjectType.Humans]: {
    carouselName: 'Humans',
    workflowName: 'Human shots',
    image: 'https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/50271d4f-14e7-4d3c-43f7-9301f591e100/1024',
    tooltipText: 'Create human model project',
    untitledName: 'Untitled Human Project',
  }
};

export function getProjectTypeInfo(projectType: SpecialProjectTypes): ProjectTypeInfo {
  return UserProjectTypeInfo[projectType];
}

export const SpecialProjectCarouselNames: Record<SpecialProjectTypes, string> = Object.fromEntries(
  Object.entries(UserProjectTypeInfo).map(([key, value]) => [key, value.carouselName])
) as Record<SpecialProjectTypes, string>;

export const SpecialProjectWorkflowNames: Record<SpecialProjectTypes, string> = Object.fromEntries(
  Object.entries(UserProjectTypeInfo).map(([key, value]) => [key, value.workflowName])
) as Record<SpecialProjectTypes, string>;

export const SpecialProjectTypeImages: Record<SpecialProjectTypes, string> = Object.fromEntries(
  Object.entries(UserProjectTypeInfo).map(([key, value]) => [key, value.image])
) as Record<SpecialProjectTypes, string>;

export const SpecialProjectTooltipTexts: Record<SpecialProjectTypes, string> = Object.fromEntries(
  Object.entries(UserProjectTypeInfo).map(([key, value]) => [key, value.tooltipText])
) as Record<SpecialProjectTypes, string>;

export const SpecialProjectUntitledNames: Record<SpecialProjectTypes, string> = Object.fromEntries(
  Object.entries(UserProjectTypeInfo).map(([key, value]) => [key, value.untitledName])
) as Record<SpecialProjectTypes, string>;


export enum WorkflowCategories {
  Product = 'Product',
  Fashion = 'Fashion',
  // Brand = 'Brand',
};
export const WorkflowCategoryNamesAndOrder: Record<WorkflowCategories, string> = {
  [WorkflowCategories.Product]: 'Product',
  [WorkflowCategories.Fashion]: 'Fashion & Models',
  // [WorkflowCategories.Brand]: 'Brand Design',
}

export enum WorkflowType {
  Project = 'Project',
  ProjectWorkflow = 'ProjectWorkflow',
  CustomModel = 'CustomModel',
  CustomModelWorkflow = 'CustomModelWorkflow',
  CustomModelExample = 'CustomModelExample',
  CustomModelPlayground = 'CustomModelPlayground',
  Video = 'Video'
}

export type WorkflowTemplate = {
  name: string,
  image: string,
  type: WorkflowType,
  category: WorkflowCategories,
  data?: {
    type?: SpecialProjectTypes,
    modelId?: string,
    projectWorkflow?: UserProjectType,
    customModelWorkflow?: CustomModelType,
    customModelExampleId?: string,
  }
};

export const WorkflowTemplates: WorkflowTemplate[] = [
  // PRODUCT
  {
    // 2d workflow
    name: 'Beauty',
    image: 'https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/1c7d93a1-34d8-400e-0949-f84f8d9d6800/1024 ',
    type: WorkflowType.ProjectWorkflow,
    category: WorkflowCategories.Product,
    data: {
      projectWorkflow: UserProjectType.Beauty,
    }
  },
  {
    // 2d workflow
    name: 'CPG',
    image: 'https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/d2c01f55-75b1-449f-a4dc-d212eeb11f00/1024',
    type: WorkflowType.ProjectWorkflow,
    category: WorkflowCategories.Product,
    data: {
      projectWorkflow: UserProjectType.CPG,
    }
  },
  {
    // custom model workflow
    name: 'Furniture',
    image: 'https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/881cb760-9035-4df0-40c4-6e1819cd3e00/1024',
    type: WorkflowType.CustomModelWorkflow,
    category: WorkflowCategories.Product,
    data: {
      customModelWorkflow: CustomModelType.Furniture,
    }
  },
  {
    // custom model workflow
    name: 'Tech',
    image: 'https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/02fe7e7b-bc83-4309-521f-e8624a47e500/1024',
    type: WorkflowType.CustomModelWorkflow,
    category: WorkflowCategories.Product,
    data: {
      customModelWorkflow: CustomModelType.Tech,
    }
  },
  {
    // custom model workflow
    name: 'Food',
    image: 'https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/1cf4fae7-736a-4f7b-30b4-bf4157bb3000/1024',
    type: WorkflowType.CustomModelWorkflow,
    category: WorkflowCategories.Product,
    data: {
      customModelWorkflow: CustomModelType.Food,
    }
  },
  {
    // custom model workflow
    name: 'Vase',
    image: 'https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/73c51e30-5c43-4f8f-6ed2-fd1b11953f00/1024',
    type: WorkflowType.CustomModelWorkflow,
    category: WorkflowCategories.Product,
    data: {
      customModelWorkflow: CustomModelType.Vase,
    }
  },
  // FASHION & MODELS
  {
    // 2d workflow
    name: 'Fashion',
    image: 'https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/9bae9484-ba6e-4049-9d4a-191d07165e00/1024',
    type: WorkflowType.CustomModelWorkflow,
    category: WorkflowCategories.Fashion,
    data: {
      customModelWorkflow: CustomModelType.Fashion,
    }
  },
  {
    // custom model workflow
    name: 'Virtual Model',
    image: 'https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/e1693755-2fb2-4b53-37d3-61061c3b2d00/1024',
    type: WorkflowType.CustomModelWorkflow,
    category: WorkflowCategories.Fashion,
    data: {
      customModelWorkflow: CustomModelType.VirtualModel,
    }
  },
  {
    // custom model workflow
    name: 'Footwear',
    image: 'https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/1fb13f64-30af-4c7d-3950-3fa18730ce00/1024',
    type: WorkflowType.CustomModelWorkflow,
    category: WorkflowCategories.Fashion,
    data: {
      customModelWorkflow: CustomModelType.Footwear,
    }
  },
  {
    // custom model workflow
    name: 'Jewelry & Accessories',
    image: 'https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/ab8afae4-f9ec-4f10-a8a1-4a0c024f8b00/1024',
    type: WorkflowType.CustomModelWorkflow,
    category: WorkflowCategories.Fashion,
    data: {
      customModelWorkflow: CustomModelType.Jewelry,
    }
  },
  {
    // custom model workflow
    name: 'Bags & Purses',
    image: 'https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/5fbbd38b-f680-4901-634a-d4f73f6e2e00/1024',
    type: WorkflowType.CustomModelWorkflow,
    category: WorkflowCategories.Fashion,
    data: {
      customModelWorkflow: CustomModelType.Bags,
    }
  },
];
