import { SecondaryButtonClassNameInactive } from 'components/constants/class-names';
import { editorContextStore } from 'contexts/editor-context';
import { CustomModelPredictionItem, CustomModelSetPromptEditorStateEventHandler, getCustomModelPredictionInputSelfHostFromCustomModelPredictionInput } from 'core/common/types';
import { Assets } from 'core/controllers/assets';
import { classNames } from 'core/utils/classname-utils';
import { debugError, debugLog } from 'core/utils/print-utilts';
import { sortByTimeModified } from 'core/utils/time-utils';
import { formatDistanceToNow } from 'date-fns';
import React from 'react';
import { getCustomModelPlaygroundPromptEditorStateFromSerializedEditorState } from './custom-model-mention-plugin';
import { useCustomModelPlayground } from './custom-model-playground-context';
import { getPromptStateFromText } from 'core/utils/text-utils';
import * as AspectRatio from '@radix-ui/react-aspect-ratio';
import { preprocessImageUrl } from 'core/utils/url-utils';
import { useComponentSize } from 'hooks/use-component-size';
import { Download, Sparkles } from 'lucide-react';
import { downloadUrl } from 'components/utils/data';
import { openAnimateVideoWindow } from 'components/video/open-animate-image-window';

/* @tw */
const frostedGlassClassName = "text-sm text-zinc-300 backdrop-blur-sm hover:backdrop-blur-md bg-zinc-800/50 hover:bg-lime-500 active:bg-lime-700 border border-zinc-100/10 hover:border-lime-500 hover:text-zinc-900 truncate transition-all"

function CustomModelPrediction({
    prediction,
}: {
    prediction: CustomModelPredictionItem,
}) {
    const backend = editorContextStore(state => state.backend);
    const eventEmitter = editorContextStore(state => state.eventEmitter);
    const [predictionImageUrls, setPredictionImageUrls] = React.useState<string[]>([]);
    const {
        setApiState,
    } = useCustomModelPlayground();

    const input = React.useMemo(() => getCustomModelPredictionInputSelfHostFromCustomModelPredictionInput(
        prediction.input,
    ), [
        prediction,
    ]);

    React.useEffect(() => {
        setPredictionImageUrls(Array(input?.num_outputs ?? 0).map(() => ""));

        if (!backend) {
            return;
        }

        const output = prediction?.output;

        if (!output) {
            return;
        }

        Promise.all(
            output.map((path) => Assets.loadAssetFromPath({
                path,
                backend,
            }))
        ).then((imageUrls) => {
            const validImageUrls = imageUrls.filter(Boolean) as string[];

            setPredictionImageUrls(validImageUrls);
        });

    }, [backend, input, prediction?.output]);


    const timeAgo = React.useMemo(() => {

        const time = prediction.timeModified || prediction.timeCreated;

        if (!time) {
            return;
        }

        return formatDistanceToNow(
            time.toDate(),
            {
                addSuffix: true,
            },
        )

    }, [prediction]);

    const [width, height] = React.useMemo(() => [input.width, input.height], [input]);

    const prompt = React.useMemo(() => {

        const promptJson = prediction.input.promptJson;

        try {
            if (!promptJson) {
                return prediction.input.prompt;
            }

            const {
                text,
            } = getCustomModelPlaygroundPromptEditorStateFromSerializedEditorState({
                promptEditorState: JSON.parse(promptJson),
                replaceWithText: true,
            });

            return text;
        } catch (error) {
            debugError(`Cannot load prediction ${prediction.id} prompt json: `, promptJson);
        }

        return prediction.input.prompt;
    }, [prediction]);

    return (
        <div
            className={classNames(
                SecondaryButtonClassNameInactive,
                'group h-auto max-w-full p-2 flex flex-col items-stretch gap-4 rounded-lg border border-zinc-800 shadow-xl active:border-lime-700 hover:shadow-lime-500/5',
            )}
            onClick={() => {
                const promptJson = prediction.input.promptJson;

                const promptEditorStateJson = promptJson || getPromptStateFromText(prediction.input.prompt);

                const promptEditorState = getCustomModelPlaygroundPromptEditorStateFromSerializedEditorState({
                    promptEditorState: JSON.parse(promptJson || getPromptStateFromText(prediction.input.prompt)),
                });

                eventEmitter.emit<CustomModelSetPromptEditorStateEventHandler>(
                    'custom-model:set-prompt-editor-state',
                    {
                        promptEditorStateJson,
                    },
                );

                debugLog('Prompt editor state: ', promptEditorState);

                setApiState((prevApiState) => ({
                    ...prevApiState,
                    promptEditorState,
                }));
            }}
        >
            {predictionImageUrls.filter(Boolean).length > 0 && <div className='h-fit flex flex-col gap-2'>
                {predictionImageUrls.map((imageUrl, index) => (
                    <AspectRatio.Root
                        key={index}
                        ratio={width / height}
                        className={classNames(
                            "group/prediction relative rounded bg-contain",
                        )}
                        style={{
                            backgroundImage: imageUrl ? `url(${preprocessImageUrl(imageUrl)})` : undefined,
                        }}
                    >
                        <div
                            className={classNames(
                                'absolute bottom-0 p-2 w-full flex flex-row items-center',
                                'opacity-0 group-hover:opacity-100 transition-opacity',
                                'pointer-events-none group-hover:pointer-events-auto',
                            )}
                        >
                            <button
                                className={classNames(
                                    frostedGlassClassName,
                                    "px-2.5 py-1 flex items-center justify-center gap-2 rounded-full",
                                )}
                                onClick={() => {
                                    // Serialize the parameters
                                    openAnimateVideoWindow({
                                        width,
                                        height,
                                        imageUrl,
                                    });
                                }}
                            >
                                <Sparkles size={15} />
                                <span className='truncate font-semibold'>
                                    Animate
                                </span>
                            </button>
                            <div className='flex-1 min-w-0'/>
                            <button
                                className={classNames(
                                    frostedGlassClassName,
                                    "w-7 h-7 flex items-center justify-center gap-2 rounded-full",
                                )}
                                onClick={() => {
                                    debugLog('Download image from data url: ', imageUrl);

                                    const processedImageUrl = preprocessImageUrl(imageUrl);

                                    if (!processedImageUrl) {
                                        return;
                                    }

                                    downloadUrl(
                                        processedImageUrl,
                                        `output-${prediction.id}-${index}`,
                                    );
                                }}
                            >
                                <Download size={15}/>
                            </button>
                        </div>
                    </AspectRatio.Root>
                ))}
            </div>}
            <div className='flex flex-row truncate text-sm text-zinc-300 group-hover:text-lime-500'>
                <span className='flex-1 truncate'>
                    Generated result
                </span>
                <span>
                    {timeAgo}
                </span>
            </div>
            <div
                className={classNames(
                    "text-sm px-2 py-1 bg-zinc-800/50 text-zinc-500 group-hover:text-zinc-300 rounded border border-zinc-800 transition-colors"
                )}
            >
                {prompt}
            </div>
        </div>
    );
}

export function CustomModelPredictions() {
    const customModelPredictions = editorContextStore(state => state.customModelPredictions);

    const predictions = React.useMemo(() => {
        return (
            Object.values(
                customModelPredictions
            ).sort(sortByTimeModified)
        );
    }, [customModelPredictions]);


    const [containerSize, containerRef] = useComponentSize<HTMLDivElement>();

    const {
        columns,
        numColumns,
    } = React.useMemo(() => {
        const width = containerSize.width;

        const numColumns = width > 1024 ? 3 : width > 640 ? 2 : 1;

        const columns = Array.from(Array(numColumns).keys())
        .map((columnIndex) => {
            return predictions.filter((_, index) => index % numColumns === columnIndex)
        });

        return {
            columns,
            numColumns,
        }
    }, [
        containerSize.width,
        predictions,
    ]);

    return (
        <div
            ref={containerRef}
            className='grid grid-cols-2 gap-4'
            style={{
                gridTemplateColumns: `repeat(${numColumns}, minmax(0, 1fr))`,
            }}
        >
            {
                columns.map((predictions, index) => (
                    <div key={index}>
                        <div className='grid gap-4'>
                            {predictions.map((prediction) => (
                                <CustomModelPrediction
                                    key={prediction.id}
                                    prediction={prediction}
                                />
                            ))}
                        </div>
                    </div>
                ))
            }
        </div>
    );
}