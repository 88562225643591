import { CustomModelPricingConfig, PricingConfigBase, VideoGenerationPricingConfig } from "core/common/types/pricing-config";
import { QuotaOneTimePayment } from "core/common/types/quota-one-time-payment";

export function getCustomModelOneTimePaymentDollarAmount({
    numCustomModelTrainings,
    numCustomModelPredictions,
    dollarPerCustomModelPredictionCredit,
    dollarPerCustomModelTrainingCredit,
    estimatedCustomModelPredictionCreditsPerRun,
    estimatedCustomModelTrainingCreditsPerRun,
}: CustomModelPricingConfig & {
    numCustomModelTrainings: number,
    numCustomModelPredictions: number,
}) {
    const trainingDollar = numCustomModelTrainings * estimatedCustomModelTrainingCreditsPerRun * dollarPerCustomModelTrainingCredit;

    const predictionDollar = numCustomModelPredictions * estimatedCustomModelPredictionCreditsPerRun * dollarPerCustomModelPredictionCredit;

    return trainingDollar + predictionDollar;
}


export function getVideoGenerationOneTimePaymentDollarAmount({
    numVideoGenerations,
    creditsPerVideoGenerationRun,
    dollarPerVideoGenerationCredit,
}: VideoGenerationPricingConfig & {
    numVideoGenerations: number,
}) {
    return numVideoGenerations * creditsPerVideoGenerationRun * dollarPerVideoGenerationCredit;
}

export function getOneTimePaymentDollarAmount({
    numCustomModelTrainings = 0,
    numCustomModelPredictions = 0,
    numVideoGenerations = 0,
    ...config
}: PricingConfigBase & QuotaOneTimePayment) {
    const customModelDollarAmount = getCustomModelOneTimePaymentDollarAmount({
        numCustomModelPredictions,
        numCustomModelTrainings,
        ...config,
    });

    const videoDollarAmount = getVideoGenerationOneTimePaymentDollarAmount({
        numVideoGenerations,
        ...config,
    });

    const totalDollarAmount = customModelDollarAmount + videoDollarAmount;

    return {
        customModelDollarAmount,
        videoDollarAmount,
        totalDollarAmount,
    };
}
