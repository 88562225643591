import React, { memo } from 'react';
import { useNavigate, NavigateFunction } from 'react-router-dom';

import { Tooltip } from 'components/utils/tooltip';
import { classNames } from 'core/utils/classname-utils';

import { Lightbulb, } from 'lucide-react';
import {
    WorkflowCategoryNamesAndOrder,
    WorkflowTemplates,
    WorkflowCategories,
    WorkflowTemplate,
    WorkflowType
} from 'components/constants/UserProjectTypeStyles';
import { NEW_PROJECT, } from 'components/constants/routes';


import { GridCarousel } from 'components/utils/grid-carousel';

/*
  ## Workflow Types ##
  export enum WorkflowType {
    Project = 'Project',
    ProjectWorkflow = 'ProjectWorkflow',
    CustomModel = 'CustomModel',
    CustomModelWorkflow = 'CustomModelWorkflow',
    CustomModelExample = 'CustomModelExample',
    CustomModelPlayground = 'CustomModelPlayground',
    Video = 'Video'
  }
*/

function startWorkflow(
    type: WorkflowType,
    navigate: NavigateFunction,
    data?: {}
): void;

function startWorkflow(type: WorkflowType, navigate: NavigateFunction, data: any = {}) {

    switch (type) {
        case WorkflowType.Project:
            navigate(`/${NEW_PROJECT}`, { replace: true });
            break;

        case WorkflowType.ProjectWorkflow:
            const { projectWorkflow } = data;
            navigate(`/${NEW_PROJECT}/${projectWorkflow}`, { replace: true });
            break;

        case WorkflowType.CustomModel:
            navigate(`/newModel`, { replace: true });
            break;

        case WorkflowType.CustomModelWorkflow:
            const { customModelWorkflow } = data;
            navigate(`/newModel/${customModelWorkflow}`, {
                replace: true,
            });
            break;

        case WorkflowType.CustomModelExample:
            const { customModelExampleId } = data;
            navigate(`/models/${customModelExampleId}`, { replace: true });
            break;

        case WorkflowType.CustomModelPlayground:
            navigate(`/newModel/${'PlayGround'}`, { replace: true });
            break;

        case WorkflowType.Video:
            navigate(`/videos`, { replace: true });
            break;
    }

}

function TemplateItem({ template, navigate, type = 'grid' }: { template: WorkflowTemplate, navigate: NavigateFunction, type?: 'grid' | 'carousel' }) {
    return <div
        className={classNames(
            type === 'grid' ? 'flex-none' : '',
            'flex items-center justify-start group transition-colors rounded-md',
            'cursor-pointer group-hover:border-neutral-700 group-hover:shadow-neutral-100',
            'pb-[75%] relative overflow-hidden'
        )}
        style={type === 'grid' ? { aspectRatio: '4 / 3' } : {}}
        onClick={() => {
            // depending on type do stuff
            startWorkflow(template.type, navigate, { ...(template.data || {}) })
        }}
    >
        <div
            className="absolute pointer-events-none select-none inset-0 transition-all duration-300 bg-cover bg-center group-hover:scale-110"
            style={{
                backgroundImage: `url(${template.image})`,
            }}
        ></div>
        <div className="absolute inset-0 bg-black/30 transition-all duration-300 group-hover:bg-transparent"></div>
        <div
            className="absolute bottom-0 bg-gradient-to-t from-black to-transparent w-full text-center text-zinc-300 text-sm font-medium p-2"
            style={{
                lineClamp: 2,
                WebkitLineClamp: 2,
            }}
        >
            {template.name}
        </div>
    </div>
}

function TemplatesCarousel({
    templates,
    navigate,
}: {
    templates: WorkflowTemplate[],
    navigate: NavigateFunction,
}) {
    return (
        <GridCarousel columnsPerPage={5} className='mb-5' >
            {templates.map((template, index) => {
                return <TemplateItem key={index} template={template} navigate={navigate} type='carousel' />;
            })}
        </GridCarousel>
    );
}

export const DashboardWorkflows = memo(function UpdatedDashboardWorkflows({ visible = true }: { visible?: boolean }) {
    const navigate = useNavigate();

    return (
        <div className={classNames(visible ? 'flex flex-col gap-4' : 'hidden', 'p-4')}>
            <div
                className={classNames(
                    'flex flex-row items-center text-base xl:text-lg text-zinc-0 font-medium text-zinc-300',
                )}
            >
                <span>
                    Workflow Templates
                </span>
                <Tooltip
                    triggerProps={{
                        asChild: true,
                    }}
                    triggerChildren={
                        <span className="flex-shrink-0 self-center p-2 cursor-pointer text-zinc-500 hover:text-zinc-300">
                            <Lightbulb size={16} />
                        </span>
                    }
                    contentProps={{
                        align: 'start',
                        sideOffset: 8,
                    }}
                    contentChildren={
                        'Templates are categorized according to different subjects, select the flows according to the category you want.'
                    }
                />
            </div>
            <div className={``}>
                {Object.entries(WorkflowCategoryNamesAndOrder).map(([category, name], index) => {
                    const templates = WorkflowTemplates.filter(template => template.category === (category as WorkflowCategories));

                    return <div key={index} className='flex flex-col'>
                        <div className={`flex flex-row text-base md:text-base 2xl:text-lg text-zinc-500 font-medium mb-2 `} >
                            {name}
                        </div>
                        <TemplatesCarousel
                            templates={templates}
                            navigate={navigate}
                        />
                    </div>
                })}
            </div>
        </div>
    );
});
