import { debugError } from "core/utils/print-utilts";
import { httpsCallable, Functions, HttpsCallable } from "firebase/functions";
import { debounce } from "lodash";


// Enums for image length options
export enum ChatWithImagesRequestImageLengthType {
    ExtraSmall = "ExtraSmall",
    Small = "Small",
    Default = "Default",
    Large = "Large",
}


// Corresponding request and response types
export interface ChatWithImagesRequest {
    imageUrls: string[];
    llmPrompt: string;
    imageLength?: ChatWithImagesRequestImageLengthType;
}

export interface ChatWithImagesResponse {
    responseText?: string;
}

export type ChatWithImagesFunction = HttpsCallable<ChatWithImagesRequest, ChatWithImagesResponse>;

export function createChatWithImagesFunction({
    firebaseFunctions,
}: {
    firebaseFunctions: Functions;
}): ChatWithImagesFunction {
    return httpsCallable<ChatWithImagesRequest, ChatWithImagesResponse>(
        firebaseFunctions,
        "chatWithImagesColabJuly24",
    );
}

export const chatWithImages = debounce(async function({
    firebaseFunctions,
    request,
}: {
    firebaseFunctions: Functions;
    request: ChatWithImagesRequest;
}): Promise<string | undefined> {
    const chatWithImagesFunction = createChatWithImagesFunction({ firebaseFunctions });

    try {
        const result = await chatWithImagesFunction(request);
        if (!result?.data?.responseText) {
            throw new Error("No valid response text returned from chatWithImages.");
        }
        return result.data?.responseText;
    } catch (error) {
        debugError("Error calling chatWithImages request:", error);
        return undefined;
    }
}, 500);