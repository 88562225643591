import React from "react";
import { fabric } from "fabric";
import { AlignGuidelines } from "core/controllers/snap/align-guidelines";
import { LayerType } from "core/common/layers";
import { OUTPAINT_CANVAS_ID } from "components/constants/ids";
import { ObjectBounds2d, RedoOutpaintImageEventHandler, ResetOutpaintImageEventHandler, SetOutpaintImageAspectRatioEventHandler, StartOutpaintImageJobEventHandler, UiDisplayMessageEventHandler, UndoOutpaintImageEventHandler } from "core/common/types";
import { getCenterFromBounds, getObjectsBounds } from "core/utils/bbox-utils";
import Zoom from "core/controllers/zoom";
import { DEFAULT_VIEWPORT_TRANSFORM, DEFAULT_ZOOM_SENSITIVITY, MAX_CANVAS_LENGTH, RENDER_CANVAS_LEGNTH, defaultControllerOptions } from "core/common/constants";
import Events from "core/common/events";
import { OutpaintContextProvider, useOutpaintContext } from "contexts/outpaint-context";
import { debugError, debugLog } from "core/utils/print-utilts";
import { BACKGROUND_DARK, colors } from "components/constants/colors";
import { OBJECT_ROTATION_SNAP_THRESHOLD } from "components/constants/objects";
import { getDataUrlFromImageElementResized, resizeImageCanvasElement } from "core/utils/image-utils";
import { InputBoxClassName, PrimaryButtonClassName } from "components/constants/class-names";
import { SimpleSpinner } from "components/icons/simple-spinner";
import EventEmitter from "events";
import { OutpaintStatus } from "core/common/types/outpaint";
import { classNames } from "core/utils/classname-utils";
import { editorContextStore } from "contexts/editor-context";
import { getDataUrlFromString } from "core/utils/asset-utils";

const MAX_RENDER_CANVAS_LEGNTH = Math.floor(2 * RENDER_CANVAS_LEGNTH);

const defaultEditorConfig: fabric.ICanvasOptions = {
    backgroundColor: undefined,
    height: 980,
    width: 1024,
}

enum OutpaintCanvasTransformMode {
    ScaleLeft = "ScaleLeft",
    ScaleRight = "ScaleRight",
    ScaleTop = "ScaleTop",
    ScaleBottom = "ScaleBottom",
    None = "None",
}


type OutpaintFrameResizeEvent = {
    type: 'outpaint-frame:resize',
    handler: (params: {
        width: number,
        height: number,
    }) => void,
};

export type OutpaintManagerHandler = OutpaintFrameResizeEvent;

class CanvasZoomController {
    private canvas: fabric.Canvas;

    constructor({
        canvas,
    }: {
        canvas: fabric.Canvas,
    }) {
        this.canvas = canvas;
    }

    static minZoom = 10;
    static maxZoom = 200;

    private static getZoomRatio(zoom: number) {
        const minZoom = CanvasZoomController.minZoom;
        const maxZoom = CanvasZoomController.maxZoom;
        let zoomRatio = zoom
        if (zoom <= minZoom / 100) {
            zoomRatio = minZoom / 100
        } else if (zoom >= maxZoom / 100) {
            zoomRatio = maxZoom / 100
        }
        return zoomRatio;
    }

    private zoomInternal(
        center: { x: number, y: number },
        zoomFitRatio: number,
    ) {
        const zoomRatio = CanvasZoomController.getZoomRatio(zoomFitRatio);
        const defaultTransform = [1, 0, 0, 1, 0, 0];
        defaultTransform[0] = zoomRatio;
        defaultTransform[3] = zoomRatio;
        defaultTransform[4] = ((this.canvas.getWidth() / zoomRatio / 2) - center.x) * zoomRatio;
        defaultTransform[5] = ((this.canvas.getHeight() / zoomRatio / 2) - center.y) * zoomRatio;
        this.canvas.setViewportTransform(defaultTransform);
    }

    zoomToPoint(point: fabric.Point, zoom: number) {
        const zoomRatio = CanvasZoomController.getZoomRatio(zoom);
        this.canvas.zoomToPoint(point, zoomRatio)
    }

    zoomToFit(
        object: fabric.Object | fabric.Object[],
        frameMargin = 180
    ) {
        let bounds: ObjectBounds2d = {
            left: 0,
            top: 0,
            width: 0,
            height: 0,
        };

        if (Array.isArray(object) && object.length <= 0) {
            return;
        }

        if (Array.isArray(object)) {
            bounds = getObjectsBounds(object) || bounds;
        } else {
            bounds = {
                left: object.left || 0,
                top: object.top || 0,
                width: object.width || 0,
                height: object.height || 0,
            }
        }

        const zoomFitRatio = Zoom.getBBoxZoomFitRatio({
            ...bounds,
            canvas: this.canvas,
            frameMargin,
        });

        const center = getCenterFromBounds(bounds);
        this.zoomInternal(center, zoomFitRatio);
    }
}

class OutpaintRenderCanvasManager {
    private canvas: fabric.Canvas;

    private zoomController: CanvasZoomController;

    private outpaintFrameObject: fabric.Rect | undefined;

    constructor({
        canvasId,
        options,
    }: {
        canvasId: string,
        options: fabric.ICanvasOptions,
    }) {
        this.canvas = new fabric.Canvas(
            `${canvasId}_render_canvas`,
            options,
        );

        this.zoomController = new CanvasZoomController({
            canvas: this.canvas,
        });
    }

    destroy() {
        this.canvas.dispose();
    }

    private async centerToOutpaintFrame(
        callback: () => Promise<void>,
    ) {
        const prevBackgroundColor = this.canvas.backgroundColor ?? BACKGROUND_DARK;

        const {
            outpaintFrameObject,
        } = this;

        if (!outpaintFrameObject) {
            return;
        }

        const prevActiveObjects = this.canvas.getActiveObject();

        const prevOutpaintFrameVisible = outpaintFrameObject.visible;

        await new Promise<void>((resolve) => {
            this.canvas.setBackgroundColor(
                'transparent',
                async () => {
                    const prevViewportTransform = this.canvas.viewportTransform?.slice() || DEFAULT_VIEWPORT_TRANSFORM;

                    this.zoomController.zoomToFit(outpaintFrameObject);

                    outpaintFrameObject.visible = false;

                    outpaintFrameObject.setCoords();

                    this.canvas.discardActiveObject();

                    await callback();

                    if (prevActiveObjects) {
                        this.canvas.setActiveObject(prevActiveObjects);
                    }

                    outpaintFrameObject.visible = prevOutpaintFrameVisible;

                    this.canvas.setViewportTransform(prevViewportTransform);
                    this.canvas.setBackgroundColor(prevBackgroundColor, () => {
                        resolve();
                    });
                },
            )
        });

    }

    static getCanvasSize(
        outpaintFrameObject: fabric.Rect,
        targetLength: number,
    ) {
        const { width, height } = outpaintFrameObject;
        if (!width || !height) {
            return {
                width: targetLength,
                height: targetLength,
            }
        }

        targetLength = Math.min(targetLength, MAX_CANVAS_LENGTH);

        const scale = targetLength / Math.max(width, height);

        debugLog(`Width: ${width}; Height: ${height}; Target: ${targetLength}; Scale: ${scale}`);

        return {
            width: scale * width,
            height: scale * height,
        }

    }

    private renderCanvasElement(options: fabric.IDataURLOptions = {}) {
        const multiplier = (options.multiplier || 1);
        return this.canvas.toCanvasElement(multiplier, options);
    }

    private static async resizeCanvas(
        from: HTMLCanvasElement,
        to: HTMLCanvasElement,
    ) {
        if (from.width === to.width || from.height === to.height) {
            return from;
        }
        return await resizeImageCanvasElement({
            from,
            to,
        });
    }

    private async getCanvasImageInternal({
        ctx1,
        outputCanvas,
        renderCanvasOptions,
        dx, dy,
        dw, dh,
    }: {
        ctx1: CanvasRenderingContext2D,
        outputCanvas: HTMLCanvasElement,
        renderCanvasOptions: fabric.IDataURLOptions,
        dx: number,
        dy: number,
        dw: number,
        dh: number,
    }) {
        const tmpCanvas0 = this.renderCanvasElement(renderCanvasOptions);

        // Crop image

        ctx1.globalCompositeOperation = 'source-over';
        ctx1.drawImage(
            tmpCanvas0,
            0, 0,
            tmpCanvas0.width, tmpCanvas0.height,
            dx, dy,
            dw, dh,
        );

        const imageElement = await OutpaintRenderCanvasManager.resizeCanvas(ctx1.canvas, outputCanvas);

        const imageDataUrl = imageElement?.toDataURL('image/png');

        return imageDataUrl;
    }

    getCanvasDataUrl = async ({
        options,
        canvasJSON,
    }: {
        options?: fabric.IDataURLOptions,
        canvasJSON?: string,
    }) => {
        await new Promise((resolve) => this.canvas.loadFromJSON(
            canvasJSON,
            resolve,
        ));

        const outpaintFrameObject = this.canvas.getObjects(
            LayerType.RECTANGLE,
        )[0];

        this.outpaintFrameObject = outpaintFrameObject;

        if (!outpaintFrameObject) {
            return "";
        }

        const imageDataUrlRef = { current: "" };

        await this.centerToOutpaintFrame(
            async () => {
                // Load the center outpaint frame
                const oCoords = outpaintFrameObject.oCoords;
                const tl = oCoords?.tl;
                const br = oCoords?.br;
                if (!tl || !br) {
                    return;
                }

                const sx = tl.x;
                const sy = tl.y;
                const sw = br.x - tl.x;
                const sh = br.y - tl.y;

                if (sw <= 0 || sh <= 0) {
                    return;
                }

                debugLog('Outpaint frame ', oCoords);

                const width = sw;
                const height = sh;

                const dx = 0;
                const dy = 0;
                const dw = width;
                const dh = height;

                const tmpCanvas1 = fabric.util.createCanvasElement();
                const tmpCanvas2 = fabric.util.createCanvasElement();
                tmpCanvas1.width = width;
                tmpCanvas1.height = height;
                tmpCanvas2.width = tmpCanvas1.width;
                tmpCanvas2.height = tmpCanvas1.height;
                const ctx1 = tmpCanvas1.getContext('2d');
                const ctx2 = tmpCanvas2.getContext('2d');

                if (!ctx1 || !ctx2) {
                    return;
                }

                // debugLog(`Outpaint frame: [${outpaintFrameObject.width ?? MAX_RENDER_CANVAS_LEGNTH}, ${outpaintFrameObject.height ?? MAX_RENDER_CANVAS_LEGNTH}]; Max render length: ${MAX_RENDER_CANVAS_LEGNTH}`);

                // const { width: outputWidth, height: outputHeight } = OutpaintRenderCanvasManager.getCanvasSize(
                //     outpaintFrameObject,
                //     // MAX_RENDER_CANVAS_LEGNTH,
                //     Math.min(
                //         Math.max(
                //             outpaintFrameObject.width ?? MAX_RENDER_CANVAS_LEGNTH,
                //             outpaintFrameObject.height ?? MAX_RENDER_CANVAS_LEGNTH,
                //         ),
                //         MAX_RENDER_CANVAS_LEGNTH,
                //     ),
                // );

                const outputWidth = outpaintFrameObject.width ?? MAX_RENDER_CANVAS_LEGNTH;
                const outputHeight = outpaintFrameObject.height ?? MAX_RENDER_CANVAS_LEGNTH;

                debugLog(`Canvas output size: [${outputWidth}, ${outputHeight}]; tmp canvas 1 size: [${tmpCanvas1.width}, ${tmpCanvas1.height}]; tmp canvas 2 size: [${tmpCanvas2.width}, ${tmpCanvas2.height}]`);

                const tmpCanvas3 = fabric.util.createCanvasElement();
                tmpCanvas3.width = outputWidth;
                tmpCanvas3.height = outputHeight;
                const outputCanvas = tmpCanvas3;

                const multiplier = Math.max(width / sw, height / sh);

                const renderCanvasOptions: fabric.IDataURLOptions = {
                    ...options,
                    multiplier,
                    left: sx,
                    top: sy,
                    width: sw,
                    height: sh,
                    enableRetinaScaling: true,
                }

                imageDataUrlRef.current = await this.getCanvasImageInternal({
                    ctx1,
                    outputCanvas,
                    renderCanvasOptions,
                    dx, dy,
                    dw, dh,
                }) ?? "";
            },
        );

        return imageDataUrlRef.current;
    }
}

class OutpaintManager extends EventEmitter {
    static InputImageObjectId = 'input-image-object';

    private canvas: fabric.Canvas;

    private zoomController: CanvasZoomController;

    private renderCanvasManager: OutpaintRenderCanvasManager;

    private options: fabric.ICanvasOptions;

    private guidelines: AlignGuidelines;

    private unsubscribeEvents = () => { };

    private undos: string[] = [];

    private redos: string[] = [];

    private currentState: string | undefined;

    private locked = false;

    private maxCount = 100;

    private outpaintFrameObject: fabric.Rect | undefined;

    private inputImageObject: fabric.StaticImage | undefined;

    private outpaintFrameElementRef: { current: HTMLDivElement | null };

    private frameMarginInternal = 20;

    constructor({
        canvasId,
        options,
        outpaintFrameElementRef,
    }: {
        canvasId: string,
        options?: fabric.ICanvasOptions,
        outpaintFrameElementRef: { current: HTMLDivElement | null },
    }) {
        super();
        this.options = {
            ...defaultEditorConfig,
            ...options,
            preserveObjectStacking: true,
            selection: false,
        }
        this.canvas = new fabric.Canvas(
            canvasId,
            this.options,
        );
        this.renderCanvasManager = new OutpaintRenderCanvasManager({
            canvasId,
            options: this.options,
        });
        this.zoomController = new CanvasZoomController({
            canvas: this.canvas,
        });

        this.guidelines = new AlignGuidelines({
            canvas: this.canvas,
            ignoreObjTypes: [],
            pickObjTypes: [
                {
                    key: "type",
                    value: LayerType.STATIC_IMAGE,
                },
                {
                    key: "type",
                    value: LayerType.RECTANGLE,
                },
            ],
        });
        this.guidelines.init();

        this.outpaintFrameElementRef = outpaintFrameElementRef;

        this.canvas.on(
            'mouse:wheel',
            this.onMouseWheel,
        );

        this.canvas.on(
            'object:modified',
            this.onObjectModified,
        );

        this.canvas.on(
            'before:render',
            this.onBeforeRender,
        );


        this.unsubscribeEvents = () => {

            this.canvas.off(
                'mouse:wheel',
                this.onMouseWheel,
            );

            this.canvas.off(
                'object:modified',
                this.onObjectModified,
            );

            this.canvas.off(
                'before:render',
                this.onBeforeRender,
            );
        };

        this.initOutpaintFrameObject();
    }


    public emit<T extends OutpaintManagerHandler>(name: T['type'], ...args: Parameters<T['handler']>) {
        return super.emit(name, ...args);
    }

    public on<T extends OutpaintManagerHandler>(name: T['type'], handler: T['handler']) {
        return super.on(name, handler);
    }

    public once<T extends OutpaintManagerHandler>(name: T['type'], handler: T['handler']) {
        return super.once(name, handler);
    }

    public off<T extends OutpaintManagerHandler>(name: T['type'], handler: T['handler']) {
        return super.off(name, handler);
    }

    destroy() {
        this.removeAllListeners();
        this.unsubscribeEvents();
        this.canvas.dispose();
        this.renderCanvasManager.destroy();
        this.undos.length = 0;
        this.redos.length = 0;
        this.currentState = undefined;
    }

    resize({
        width,
        height,
    }: {
        width: number,
        height: number,
    }) {
        this.canvas?.setWidth(width).setHeight(height)
        this.canvas?.renderAll()
        const diffWidth = width / 2 - (this.options?.width || width) / 2
        const diffHeight = height / 2 - (this.options?.height || height) / 2

        this.options.width = width
        this.options.height = height

        const deltaPoint = new fabric.Point(diffWidth, diffHeight)
        this.canvas?.relativePan(deltaPoint);
    }

    zoomToPoint(point: fabric.Point, zoom: number) {
        return this.zoomController.zoomToPoint(point, zoom);
    }

    zoomToFit(
        object: fabric.Object | fabric.Object[],
        frameMargin: number,
    ) {
        return this.zoomController.zoomToFit(object, frameMargin);
    }

    handleZoom = (event: fabric.IEvent<any>) => {
        const delta = event.e.deltaY
        let zoomRatio = this.canvas.getZoom()
        if (delta > 0) {
            zoomRatio -= DEFAULT_ZOOM_SENSITIVITY;
        } else {
            zoomRatio += DEFAULT_ZOOM_SENSITIVITY;
        }
        this.zoomToPoint(new fabric.Point(this.canvas.getWidth() / 2, this.canvas.getHeight() / 2), zoomRatio)
        event.e.preventDefault()
        event.e.stopPropagation()
    }

    onMouseWheel = (event: fabric.IEvent<any>) => {
        const isCtrlKey = event.e.ctrlKey;
        if (isCtrlKey) {
            this.handleZoom(event);
        } else {
            const isTrackpad = Events.detectTrackpadUtil(event.e);

            if (isTrackpad) {
                const viewportTransform = this.canvas.viewportTransform;
                const deltaX = event.e?.deltaX;
                const deltaY = event.e?.deltaY;
                if (viewportTransform && deltaX != null && deltaY != null) {
                    viewportTransform[4] -= deltaX;
                    viewportTransform[5] -= deltaY;
                    this.canvas.setViewportTransform(viewportTransform);
                    this.canvas.requestRenderAll();
                }
            }
        }
    }

    private emitOutpaintFrameResizeEvent() {
        const { outpaintFrameObject } = this;
        if (!outpaintFrameObject) {
            return;
        }
        const width = outpaintFrameObject.getScaledWidth();
        const height = outpaintFrameObject.getScaledHeight();
        this.emit<OutpaintFrameResizeEvent>(
            'outpaint-frame:resize',
            {
                width,
                height,
            },
        );

    }

    private onObjectModified = (e: fabric.IEvent<Event>) => {
        const activeObject = e.target;

        if (activeObject && activeObject === this.outpaintFrameObject) {
            this.emitOutpaintFrameResizeEvent();
        }

        this.saveState();
    }

    private getCanvasJSON() {
        return this.canvas.toDatalessJSON([
            'id',
            'type',
            'hasControls',
            'selectable',
            'moveCursor',
            'hoverCursor',
            'lockMovementX',
            'lockMovementY',
            'lockRotation',
            'lockScalingX',
            'lockScalingY',
            'lockSkewingX',
            'lockSkewingY',
            'transparentCorners',
        ]) as any as string;
    }

    saveState() {
        if (this.locked) {
            return;
        }

        if (this.undos.length === this.maxCount) {
            //Drop the oldest element
            this.undos.shift();
        }

        //Add the current state
        if (this.currentState) {
            this.undos.push(
                this.currentState
            );
        }

        //Make the state of the canvas the current state
        this.currentState = this.getCanvasJSON();

        //Reset the redo stack.
        //We can only redo things that were just undone.
        this.redos.length = 0;

        return this.currentState;
    }

    undo() {
        if (this.undos.length > 0) {
            return this.applyState(this.redos, this.undos.pop());
        }
        return Promise.resolve();
    }

    //Pop the most recent redo state. Use the specified callback method.
    redo() {
        if (this.redos.length > 0) {
            return this.applyState(this.undos, this.redos.pop());
        }
        return Promise.resolve();
    }

    async applySavedState(newState: string) {
        this.redos.length = 0;
        await this.applyState(this.undos, newState);
    }

    private applyState(stack: string[], newState: string | undefined) {
        return new Promise<void>((resolve) => {
            if (this.locked) {
                return resolve();
            }

            //Lock the stacks for the incoming change
            this.locked = true;

            if (this.currentState) {
                //Push the current state
                stack.push(this.currentState);
            }

            //Make the new state the current state
            this.currentState = newState;

            //Update canvas with the new current state
            this.canvas.loadFromJSON(this.currentState, () => {

                //Unlock the stacks
                this.locked = false;

                const frames = this.canvas.getObjects(LayerType.RECTANGLE);

                this.outpaintFrameObject = frames[0];

                if (this.outpaintFrameObject) {
                    this.setOutpaintFrameObjectProps(this.outpaintFrameObject);
                }

                this.canvas.requestRenderAll();

                this.emitOutpaintFrameResizeEvent();

                resolve();
            });
        });
    }

    get frameMargin() {
        return this.frameMarginInternal;
    }

    set frameMargin(value: number) {

        const {
            inputImageObject
        } = this;

        if (inputImageObject) {
            this.zoomToFit([inputImageObject as any as fabric.Object], value);
        }

        this.frameMarginInternal = value;
    }

    static getBoundingBoxOfAspectRatio({
        left,
        top,
        width,
        height,
        aspectRatio,
    }: {
        left: number,
        top: number,
        width: number,
        height: number,
        aspectRatio: number
    }): { left: number; top: number; width: number; height: number } {
        if (aspectRatio <= 0 || width <= 0 || height <= 0) {
            return {
                left,
                top,
                width,
                height,
            };
        }

        const centerX = left + width / 2;
        const centerY = top + height / 2;

        let finalWidth = height * aspectRatio;
        let finalHeight = height;

        if (finalWidth < width) {
            finalWidth = width;
            finalHeight = width / aspectRatio;
        }

        return {
            left: centerX - finalWidth / 2,
            top: centerY - finalHeight / 2,
            width: finalWidth,
            height: finalHeight,
        };
    }

    setOutpaintFrameFromAspectRatio({
        inputImage,
        aspectRatio,
    }: {
        aspectRatio: number,
        inputImage?: fabric.StaticImage,
    }) {

        inputImage = inputImage ?? this.inputImageObject;

        if (!inputImage || !this.outpaintFrameObject || !aspectRatio) {
            return;
        }

        // Set the default aspect ratio

        const left = inputImage.left ?? 0;
        const top = inputImage.top ?? 0;
        const width = inputImage.getScaledWidth();
        const height = inputImage.getScaledHeight();

        const frameBBox = OutpaintManager.getBoundingBoxOfAspectRatio({
            left,
            top,
            width,
            height,
            aspectRatio,
        });

        this.outpaintFrameObject.set('left', frameBBox.left);
        this.outpaintFrameObject.set('top', frameBBox.top);
        this.outpaintFrameObject.set('width', frameBBox.width);
        this.outpaintFrameObject.set('height', frameBBox.height);

        this.emitOutpaintFrameResizeEvent();
    }

    static MIN_OBJECT_LENGTH = 256;
    static MAX_OBJECT_LENGTH = 1024;

    async setInputImage({
        imageUrl,
        isOutputImage = false,
        aspectRatio = 9 / 16,
    }: {
        imageUrl: string,
        isOutputImage?: boolean,
        aspectRatio?: number,
    }) {
        if (!imageUrl) {
            debugError("Input image url is invalid.");
            return;
        }

        // Delete all of the current images on the canvas

        this.canvas.getObjects(LayerType.STATIC_IMAGE).forEach((object) => {
            this.canvas.remove(object as any as fabric.Object);
        });

        const inputImage = await fabric.StaticImage.fromURL(
            imageUrl,
            {
                ...defaultControllerOptions,
                hasRotatingPoint: false,
                snapAngle: OBJECT_ROTATION_SNAP_THRESHOLD,
                id: OutpaintManager.InputImageObjectId,
                asset: {
                    type: 'image-url',
                    path: imageUrl,
                },
            },
        );

        if (isOutputImage && this.outpaintFrameObject) {
            // Set the image to be the same size as the frame
            const left = this.outpaintFrameObject.left ?? inputImage.left ?? 0;
            const top = this.outpaintFrameObject.top ?? inputImage.top ?? 0;
            const width = this.outpaintFrameObject.width ?? inputImage.width ?? 1;
            const height = this.outpaintFrameObject.height ?? inputImage.height ?? 1;

            const scaleX = width / (inputImage.width || width);
            const scaleY = height / (inputImage.height || height);

            inputImage.set('left', left);
            inputImage.set('top', top);
            inputImage.set('scaleX', scaleX);
            inputImage.set('scaleY', scaleY);
        } else {
            const currentWidth = inputImage.width ?? 1;
            const currentHeight = inputImage.height ?? 1;
            const currentLength = Math.max(currentWidth, currentHeight);

            if (currentLength < OutpaintManager.MIN_OBJECT_LENGTH) {
                const scale = Math.min(
                    OutpaintManager.MIN_OBJECT_LENGTH / currentWidth,
                    OutpaintManager.MIN_OBJECT_LENGTH / currentHeight,
                );
                inputImage.set('scaleX', scale);
                inputImage.set('scaleY', scale);
            } else if (currentLength > OutpaintManager.MAX_OBJECT_LENGTH) {
                const scale = Math.min(
                    OutpaintManager.MAX_OBJECT_LENGTH / currentWidth,
                    OutpaintManager.MAX_OBJECT_LENGTH / currentHeight,
                );
                inputImage.set('scaleX', scale);
                inputImage.set('scaleY', scale);
            }

            // Update the outpaint frame object

            this.setOutpaintFrameFromAspectRatio({
                aspectRatio,
                inputImage,
            });
        }

        inputImage.setControlVisible('ml', false);
        inputImage.setControlVisible('mr', false);
        inputImage.setControlVisible('mt', false);
        inputImage.setControlVisible('mb', false);

        this.inputImageObject = inputImage;

        this.canvas.add(inputImage as any as fabric.Object);
        this.canvas.bringToFront(inputImage as any as fabric.Object);

        this.zoomToFit(
            [
                inputImage as any as fabric.Object,
                this.outpaintFrameObject as any as fabric.Object,
            ].filter(Boolean),
            this.frameMarginInternal,
        );

        this.canvas.requestRenderAll();

        this.saveState();
    }

    private setOutpaintFrameObjectProps(
        outpaintFrameObject: fabric.Rect,
    ) {
        outpaintFrameObject.setControlVisible('mtr', false);
        outpaintFrameObject.setControlVisible('tl', false);
        outpaintFrameObject.setControlVisible('tr', false);
        outpaintFrameObject.setControlVisible('ml', false);
        outpaintFrameObject.setControlVisible('mr', false);
        outpaintFrameObject.setControlVisible('mt', false);
        outpaintFrameObject.setControlVisible('mb', false);
        outpaintFrameObject.setControlVisible('bl', false);
        outpaintFrameObject.setControlVisible('br', false);

        outpaintFrameObject.set('objectCaching', false);
        outpaintFrameObject.set('lockRotation', true);
        outpaintFrameObject.set('transparentCorners', false);

        return outpaintFrameObject;
    }

    initOutpaintFrameObject() {
        {
            const { outpaintFrameObject } = this;

            if (outpaintFrameObject) {
                this.canvas.remove(outpaintFrameObject);
            }
        }

        const outpaintFrameObject = new fabric.Rect({
            type: LayerType.RECTANGLE,
            left: 0,
            top: 0,
            fill: colors.zinc[800],
            borderColor: colors.zinc[500],
            width: 1024,
            height: 1024,
            objectCaching: false,
            lockRotation: true,
            transparentCorners: false,
        });

        this.setOutpaintFrameObjectProps(outpaintFrameObject);

        this.canvas.add(outpaintFrameObject);

        this.outpaintFrameObject = outpaintFrameObject;
    }

    updateOutpaintFrameElement = (
        outpaintFrameElement: HTMLDivElement,
    ) => {
        const {
            outpaintFrameObject,
        } = this;

        if (!outpaintFrameObject) {
            outpaintFrameElement.style.display = 'none';
            return;
        }

        outpaintFrameObject.setCoords();

        const oCoords = outpaintFrameObject.oCoords;

        if (!oCoords) {
            outpaintFrameElement.style.display = 'none';
            return;
        }

        const tl = oCoords.tl;
        const br = oCoords.br;

        outpaintFrameElement.style.left = `${tl?.x || 0}px`;
        outpaintFrameElement.style.top = `${tl?.y || 0}px`;

        const displayWidth = br.x - tl.x;
        const displayHeight = br.y - tl.y;

        outpaintFrameElement.style.width = `${displayWidth}px`;
        outpaintFrameElement.style.height = `${displayHeight}px`;
        outpaintFrameElement.style.display = 'block';
    }

    onBeforeRender = () => {
        const {
            outpaintFrameElementRef,
        } = this;

        const outpaintFrameElement = outpaintFrameElementRef.current;

        if (!outpaintFrameElement) {
            return;
        }

        this.updateOutpaintFrameElement(outpaintFrameElement);
    };

    private transformMode = OutpaintCanvasTransformMode.None;

    private snapTreshold = 20;

    handlePointerDownOnControl = (mode: OutpaintCanvasTransformMode) => {
        const {
            outpaintFrameObject,
        } = this;

        if (!outpaintFrameObject) {
            return;
        }

        this.canvas.discardActiveObject();

        this.transformMode = mode;
    }

    getXSnapValues() {
        const {
            inputImageObject,
        } = this;
        if (!inputImageObject) {
            return [];
        }

        const left = inputImageObject.left ?? 0;
        const width = inputImageObject.getScaledWidth() ?? 0;

        return [
            left,
            left + 0.5 * width,
            left + width,
        ];
    }

    getTargetScaleX(targetX: number) {
        const snapValues = this.getXSnapValues();

        if (snapValues.length > 0) {
            const targetValue = snapValues.sort((a, b) => Math.abs(a - targetX) - Math.abs(b - targetX))[0];

            targetX = Math.abs(targetValue - targetX) < this.snapTreshold ?
                targetValue :
                targetX;
        }

        return targetX;
    }

    getYSnapValues() {
        const {
            inputImageObject,
        } = this;
        if (!inputImageObject) {
            return [];
        }

        const top = inputImageObject.top ?? 0;
        const height = inputImageObject.getScaledHeight() ?? 0;

        return [
            top,
            top + 0.5 * height,
            top + height,
        ];
    }

    getTargetScaleY(targetY: number) {
        const snapValues = this.getYSnapValues();

        if (snapValues.length > 0) {
            const targetValue = snapValues.sort((a, b) => Math.abs(a - targetY) - Math.abs(b - targetY))[0];

            targetY = Math.abs(targetValue - targetY) < this.snapTreshold ?
                targetValue :
                targetY;
        }

        return targetY;
    }

    handlePointerMove = (e: PointerEvent) => {
        if (!this.transformMode || this.transformMode === OutpaintCanvasTransformMode.None) {
            return;
        }

        const {
            transformMode,
            outpaintFrameObject,
            inputImageObject,
        } = this;

        if (!outpaintFrameObject || !inputImageObject) {
            return;
        }

        const currentPointer = this.canvas.getPointer(e);

        if (!currentPointer) {
            return;
        }

        this.canvas.discardActiveObject();

        if (transformMode === OutpaintCanvasTransformMode.ScaleLeft) {
            const {
                left = 0,
                width = 0,
            } = outpaintFrameObject;

            const targetLeft = this.getTargetScaleX(currentPointer.x);

            outpaintFrameObject.left = targetLeft;
            outpaintFrameObject.width = (left + width) - targetLeft;
            outpaintFrameObject.setCoords();

            this.canvas.requestRenderAll();
        } else if (transformMode === OutpaintCanvasTransformMode.ScaleRight) {
            const {
                left = 0,
            } = outpaintFrameObject;

            const targetRight = this.getTargetScaleX(currentPointer.x);

            outpaintFrameObject.width = targetRight - left;
            outpaintFrameObject.setCoords();

            this.canvas.requestRenderAll();
        } else if (transformMode === OutpaintCanvasTransformMode.ScaleTop) {
            const {
                top = 0,
                height = 0,
            } = outpaintFrameObject;

            const targetTop = this.getTargetScaleY(currentPointer.y);

            outpaintFrameObject.top = targetTop;
            outpaintFrameObject.height = (top + height) - targetTop;
            outpaintFrameObject.setCoords();

            this.canvas.requestRenderAll();
        } else if (transformMode === OutpaintCanvasTransformMode.ScaleBottom) {
            const {
                top = 0,
            } = outpaintFrameObject;

            const targetBottom = this.getTargetScaleY(currentPointer.y);

            outpaintFrameObject.height = targetBottom - top;
            outpaintFrameObject.setCoords();

            this.canvas.requestRenderAll();
        }
    }

    handlePointerUp = () => {
        if (this.transformMode !== OutpaintCanvasTransformMode.None) {

            this.emitOutpaintFrameResizeEvent();

            this.saveState();
        }

        this.transformMode = OutpaintCanvasTransformMode.None;
    }

    static adjustBoundingBox({
        left,
        top,
        width,
        height,
        targetWidth,
        targetHeight,
    }: {
        left: number,
        top: number,
        width: number,
        height: number,
        targetWidth: number,
        targetHeight: number
      }): { left: number; top: number; width: number; height: number } {
        if (targetWidth <= 0 || targetHeight <= 0) {
            return {
                left,
                top,
                width,
                height,
            };
        }

        const centerX = left + width / 2;
        const centerY = top + height / 2;

        return {
          left: centerX - targetWidth / 2,
          top: centerY - targetHeight / 2,
          width: targetWidth,
          height: targetHeight,
        };
    }

    setOutpaintFrameSize({
        width: targetWidth,
        height: targetHeight,
    }: {
        width: number,
        height: number,
    }) {
        const outpaintFrameObject = this.outpaintFrameObject;

        if (!outpaintFrameObject) {
            return;
        }

        const left = outpaintFrameObject.left ?? 0;
        const top = outpaintFrameObject.top ?? 0;
        const width = outpaintFrameObject.width ?? 0;
        const height = outpaintFrameObject.height ?? 0;

        if (width === targetWidth && height === targetHeight) {
            return;
        }

        const frameBBox = OutpaintManager.adjustBoundingBox({
            left,
            top,
            width,
            height,
            targetWidth,
            targetHeight,
        });

        outpaintFrameObject.set('left', frameBBox.left);
        outpaintFrameObject.set('top', frameBBox.top);
        outpaintFrameObject.set('width', frameBBox.width);
        outpaintFrameObject.set('height', frameBBox.height);

        outpaintFrameObject.setCoords();

        this.canvas.requestRenderAll();
    }

    getCanvasDataUrl = async (
        options?: fabric.IDataURLOptions,
    ) => {
        return await this.renderCanvasManager.getCanvasDataUrl({
            canvasJSON: this.getCanvasJSON(),
            options,
        });
    }
}

function LoadingCover() {
    const { status } = useOutpaintContext();

    if (status !== OutpaintStatus.Rendering) {
        return null;
    }

    return (
        <div
            className="absolute left-0 top-0 w-full h-full flex flex-row items-center justify-center gap-2 pointer-events-auto cursor-wait bg-zinc-900 z-100"
        >
            <SimpleSpinner
                width={23}
                height={23}
                pathClassName="fill-lime-500"
            />
            <span className="text-zinc-300">
                Loading ...
            </span>
        </div>
    )
}

export type OutpaintInputImage = HTMLImageElement | string;

async function getDataUrlFromInputImage({
    image,
    maxLength = MAX_RENDER_CANVAS_LEGNTH,
}: {
    image?: OutpaintInputImage,
    maxLength?: number,
}) {
    try {
        if (!image) {
            return undefined;
        }

        if (typeof(image) === 'string') {

            return await getDataUrlFromString(image);

        } else {
            const targetLength = Math.min(
                Math.max(image.width, image.height),
                maxLength,
            );

            return await getDataUrlFromImageElementResized({
                image,
                targetLength,
            });
        }
    } catch (error) {
        debugError('Error loading image from input image ', image);
    }
    return undefined;
}

export function OutpaintEditor({
    canvasId = OUTPAINT_CANVAS_ID,
    frameMargin = 20,
    className = "",
    image: inputImage,
    ...props
}: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
    image?: OutpaintInputImage,
    canvasId: string,
    frameMargin?: number,
}) {
    const outpaintManagerRef = React.useRef<OutpaintManager | undefined>();
    const containerRef = React.useRef<HTMLDivElement | null>(null);
    const outpaintFrameElementRef = React.useRef<HTMLDivElement | null>(null);
    const outpaintFrameLeftControlRef = React.useRef<HTMLDivElement | null>(null);
    const backend = editorContextStore(state => state.backend);
    const eventEmitter = editorContextStore(state => state.eventEmitter);


    const {
        status,
        setStatus,
        outpaintWidth,
        outpaintHeight,
        setOutpaintWidth,
        setOutpaintHeight,
        setOutputImageUrls,
    } = useOutpaintContext();

    React.useEffect(() => {
        outpaintManagerRef.current?.setOutpaintFrameSize({
            width: outpaintWidth,
            height: outpaintHeight,
        });
    }, [outpaintWidth, outpaintHeight]);


    React.useEffect(() => {
        const container = containerRef.current;
        if (!container) {
            return;
        }

        const outpaintManager = new OutpaintManager({
            canvasId,
            outpaintFrameElementRef,
        });

        outpaintManagerRef.current = outpaintManager;

        const resizeObserver = new ResizeObserver((entries) => {
            const { width, height } = (entries[0] && entries[0].contentRect) || {}
            outpaintManager.resize({
                width,
                height,
            });
        });

        resizeObserver.observe(container);

        const outpaintFrameResizeHandler: OutpaintFrameResizeEvent['handler'] = ({ width, height }) => {
            setOutpaintWidth(Math.round(width));
            setOutpaintHeight(Math.round(height));
        };

        outpaintManager.on<OutpaintFrameResizeEvent>(
            'outpaint-frame:resize',
            outpaintFrameResizeHandler,
        );

        return () => {
            outpaintManager.off<OutpaintFrameResizeEvent>(
                'outpaint-frame:resize',
                outpaintFrameResizeHandler,
            );

            outpaintManager.destroy();
            resizeObserver.unobserve(container);
        };
    }, [
        canvasId,
        setOutpaintWidth,
        setOutpaintHeight,
    ]);

    React.useEffect(() => {
        const imageUrlRef: {current?: string} = {};

        getDataUrlFromInputImage({
            image: inputImage,
        }).then((imageUrl) => {
            imageUrlRef.current = imageUrl ?? undefined;

            if (!imageUrl) {

                eventEmitter?.emit<UiDisplayMessageEventHandler>(
                    'ui:display-message',
                    'error',
                    'Cannot load image, please choose another object.',
                );

                return;
            }

            outpaintManagerRef.current?.setInputImage({
                imageUrl,
            });
        });

        const handleReset = () => {
            const imageUrl = imageUrlRef.current;

            if (!imageUrl) {
                return;
            }

            outpaintManagerRef.current?.setInputImage({
                imageUrl,
            });
        };

        eventEmitter.on<ResetOutpaintImageEventHandler>(
            'outpaint-image:reset',
            handleReset,
        );

        return () => {
            eventEmitter.off<ResetOutpaintImageEventHandler>(
                'outpaint-image:reset',
                handleReset,
            );
        };
    }, [inputImage, eventEmitter]);

    React.useEffect(() => {

        const outpaintManager = outpaintManagerRef.current;

        if (outpaintManager) {
            outpaintManager.frameMargin = frameMargin;
        }

    }, [frameMargin]);


    React.useEffect(() => {
        const handleStartJob: StartOutpaintImageJobEventHandler['handler'] = async () => {
            if (!backend) {
                eventEmitter.emit<UiDisplayMessageEventHandler>(
                    'ui:display-message',
                    'error',
                    'Backend is not initialized yet.',
                );
                return;
            }

            if (status !== OutpaintStatus.Idle) {
                eventEmitter.emit<UiDisplayMessageEventHandler>(
                    'ui:display-message',
                    'info',
                    'Please wait until the current job finishes.',
                );
                return;
            }

            setStatus(OutpaintStatus.Rendering);

            try {

                const imageDataUrl = await outpaintManagerRef.current?.getCanvasDataUrl();

                if (!imageDataUrl) {
                    eventEmitter.emit<UiDisplayMessageEventHandler>(
                        'ui:display-message',
                        'error',
                        'Cannot process this image. Please try another one.',
                    );
                    return;
                }

                const response = await backend.outpaintImage({
                    imageDataURL: imageDataUrl,
                });

                if (!response.ok) {
                    eventEmitter.emit<UiDisplayMessageEventHandler>(
                        'ui:display-message',
                        'error',
                        response.message,
                    );
                    return;
                }

                const outputImageUrls = response.imageDataUrls.filter(Boolean) as string[];

                setOutputImageUrls(outputImageUrls);

                if (outputImageUrls.length > 0) {
                    await outpaintManagerRef.current?.setInputImage({
                        imageUrl: outputImageUrls[0],
                        isOutputImage: true,
                    });
                }


            } catch (error) {

                debugError('Error extending image: ', error);

                eventEmitter.emit<UiDisplayMessageEventHandler>(
                    'ui:display-message',
                    'error',
                    'Cannot process this image. Please try another one.',
                );

            } finally {

                setStatus(OutpaintStatus.Idle);

            }

        };

        eventEmitter.on<StartOutpaintImageJobEventHandler>(
            'outpaint-image:start-job',
            handleStartJob,
        );

        return () => {
            eventEmitter.off<StartOutpaintImageJobEventHandler>(
                'outpaint-image:start-job',
                handleStartJob,
            );
        }
    }, [
        status,
        backend,
        eventEmitter,
        setStatus,
        setOutputImageUrls,
    ]);

    React.useEffect(() => {
        const handleUndo = () => {
            outpaintManagerRef.current?.undo();
        };

        const handleRedo = () => {
            outpaintManagerRef.current?.redo();
        };

        const handleReset = () => {
        };

        const handleSetAspectRatio = (aspectRatio: number) => {
            outpaintManagerRef.current?.setOutpaintFrameFromAspectRatio({
                aspectRatio,
            });
        }

        eventEmitter.on<UndoOutpaintImageEventHandler>(
            'outpaint-image:undo',
            handleUndo,
        );

        eventEmitter.on<RedoOutpaintImageEventHandler>(
            'outpaint-image:redo',
            handleRedo,
        );

        eventEmitter.on<SetOutpaintImageAspectRatioEventHandler>(
            'outpaint-image:set-aspect-ratio',
            handleSetAspectRatio,
        );

        eventEmitter.on<ResetOutpaintImageEventHandler>(
            'outpaint-image:reset',
            handleReset,
        );

        return () => {
            eventEmitter.off<UndoOutpaintImageEventHandler>(
                'outpaint-image:undo',
                handleUndo,
            );

            eventEmitter.off<RedoOutpaintImageEventHandler>(
                'outpaint-image:redo',
                handleRedo,
            );

            eventEmitter.off<SetOutpaintImageAspectRatioEventHandler>(
                'outpaint-image:set-aspect-ratio',
                handleSetAspectRatio,
            );

            eventEmitter.off<ResetOutpaintImageEventHandler>(
                'outpaint-image:reset',
                handleReset,
            );
        };
    }, [eventEmitter]);

    return (
        <div
            ref={containerRef}
            {...props}
            className={classNames(
                "relative bg-zinc-900",
                className,
            )}
            onPointerMove={(e) => {
                outpaintManagerRef.current?.handlePointerMove(e.nativeEvent);
            }}
            onPointerUp={() => {
                outpaintManagerRef.current?.handlePointerUp();
            }}
        >
            <LoadingCover />
            <div
                ref={outpaintFrameElementRef}
                className="absolute border border-lime-500 z-10 pointer-events-none"
                style={{
                    display: 'none',
                    boxShadow: "0px 0px 0px 9999px rgba(0, 0, 0, 0.5)",
                }}
            >
                <div
                    ref={outpaintFrameLeftControlRef}
                    className="absolute left-[-4px] top-[35%] w-[7px] h-[30%] rounded-full cursor-w-resize bg-lime-500 pointer-events-auto"
                    onPointerDown={() => {
                        outpaintManagerRef.current?.handlePointerDownOnControl(
                            OutpaintCanvasTransformMode.ScaleLeft,
                        );
                    }}
                />
                <div
                    ref={outpaintFrameLeftControlRef}
                    className="absolute right-[-4px] top-[35%] w-[7px] h-[30%] rounded-full cursor-e-resize bg-lime-500 pointer-events-auto"
                    onPointerDown={() => {
                        outpaintManagerRef.current?.handlePointerDownOnControl(
                            OutpaintCanvasTransformMode.ScaleRight,
                        );
                    }}
                />
                <div
                    ref={outpaintFrameLeftControlRef}
                    className="absolute left-[35%] top-[-4px] h-[7px] w-[30%] rounded-full cursor-n-resize bg-lime-500 pointer-events-auto"
                    onPointerDown={() => {
                        outpaintManagerRef.current?.handlePointerDownOnControl(
                            OutpaintCanvasTransformMode.ScaleTop,
                        );
                    }}
                />
                <div
                    ref={outpaintFrameLeftControlRef}
                    className="absolute left-[35%] bottom-[-4px] h-[7px] w-[30%] rounded-full cursor-s-resize bg-lime-500 pointer-events-auto"
                    onPointerDown={() => {
                        outpaintManagerRef.current?.handlePointerDownOnControl(
                            OutpaintCanvasTransformMode.ScaleBottom,
                        );
                    }}
                />
            </div>
            <canvas
                id={canvasId}
            />
        </div>
    );
}

function TestOutpaintEditorInner() {
    const {
        setStatus,
        outpaintWidth,
        outpaintHeight,
        setOutpaintWidth,
        setOutpaintHeight,
    } = useOutpaintContext();

    const [widthValue, setWidthValue] = React.useState('');
    const [heightValue, setHeightValue] = React.useState('');

    React.useEffect(() => {
        setWidthValue(outpaintWidth.toString());
    }, [outpaintWidth]);

    React.useEffect(() => {
        setHeightValue(outpaintHeight.toString());
    }, [outpaintHeight]);

    return (
        <div className="w-screen flex flex-row">
            <OutpaintEditor
                canvasId={OUTPAINT_CANVAS_ID}
                image="https://imagedelivery.net/i1XPW6iC_chU01_6tBPo8Q/156af620-362a-4eb1-f27e-18b7496a3d00/public"
            />
            <div className="flex-1 relative p-4 flex flex-col items-center gap-4 z-20 bg-zinc-900 border-l border-zinc-800">
                <div
                    className="flex flex-row gap-2"
                >
                    <input
                        className={InputBoxClassName}
                        value={widthValue}
                        onChange={(e) => setWidthValue(e.currentTarget.value)}
                        onBlur={(e) => {
                            const value = parseInt(widthValue);

                            if (isNaN(value)) {
                                setWidthValue(outpaintWidth.toString());
                                return;
                            }

                            setOutpaintWidth(value);
                        }}
                    />
                    <input
                        className={InputBoxClassName}
                        value={heightValue}
                        onChange={(e) => setHeightValue(e.currentTarget.value)}
                        onBlur={(e) => {
                            const value = parseInt(heightValue);

                            if (isNaN(value)) {
                                setHeightValue(outpaintHeight.toString());
                                return;
                            }

                            setOutpaintHeight(value);
                        }}
                    />
                    <button
                        className={PrimaryButtonClassName}
                        onClick={() => {
                            setStatus(OutpaintStatus.Rendering);
                        }}
                    >
                        Generate
                    </button>
                </div>
            </div>
        </div>
    );
}

export function TestOutpaintEditor() {
    return (
        <OutpaintContextProvider>
            <TestOutpaintEditorInner />
        </OutpaintContextProvider>
    );
}