import { MAX_CANVAS_LENGTH, RENDER_CANVAS_LEGNTH } from './common/constants';
import { fabric } from "fabric"
import { FabricCanvas } from "core/common/interfaces"
import { EditorConfig, ExportGenerationFrameTemplateEventHandler, ObjectBounds2d } from "core/common/types"
import type { Editor } from "core/editor"
import { isPointInBounds } from './utils/bbox-utils';
import { editorContextStore } from 'contexts/editor-context';
import { GenerationFrameDataOutput, GetDataUrlsProps, RenderCanvasController } from './controllers/render-canvas-controller';
import { debugError, debugLog } from './utils/print-utilts';
import { downloadImageDataUrl, downloadJson } from 'components/utils/data';
import { generateUUID } from './utils/uuid-utils';
import { isStaticImageObject } from './utils/type-guards';
import { getRenderPipelineArgs, onRenderImageResultAdded } from 'components/utils/render';
import { noop } from 'lodash';
import { EditorCanvasRenderMode } from './common/types/editor-canvas-render-mode';


const imageFilterColors = new Set([
    '#000000',
    '#ffffff',
    '#0000ff',
    '#00ff00',
    '#00ffff',
])


export interface GenerationFrameDataProps {
    readCanvasData?: boolean,
}

class Canvas {
    private _isDestroyed = false;
    private editor: Editor
    public container?: HTMLDivElement
    public canvasContainer?: HTMLDivElement
    public canvasElement?: HTMLCanvasElement
    public canvas: FabricCanvas
    public canvasId: string

    private unsubscribeEventUpdates = noop;

    private renderCanvasController: RenderCanvasController;

    private options = {
        width: 0,
        height: 0,
    };
    private config: EditorConfig;

    private imageFilters = Array.from(imageFilterColors).reduce<Record<string, fabric.IBlendImageFilter>>((result, color) => {
        result[color] = new fabric.Image.filters.BlendColor({
            color,
            mode: 'tint',
            alpha: 1.0,
        });
        return result;
    }, {});

    get blackFilter() {
        return this.imageFilters['#000000'];
    }

    get whiteFilter() {
        return this.imageFilters['#ffffff'];
    }

    get blueFilter() {
        return this.imageFilters['#0000ff'];
    }

    constructor({ id, config, editor }: { id: string; config: EditorConfig; editor: Editor }) {
        this.config = config
        this.editor = editor
        this.canvasId = id

        const canvas = new fabric.Canvas(this.canvasId, {
            backgroundColor: this.config.background,
            preserveObjectStacking: true,
            fireRightClick: true,
            height: this.config.size.height,
            width: this.config.size.width,
        })
        this.canvas = canvas as FabricCanvas

        this.canvas.disableEvents = function () {
            if (this.__fire === undefined) {
                this.__fire = this.fire
                // @ts-ignore
                this.fire = function () { }
            }
        }

        this.canvas.enableEvents = function () {
            if (this.__fire !== undefined) {
                this.fire = this.__fire
                this.__fire = undefined
            }
        }

        this.renderCanvasController = new RenderCanvasController({
            editor: this.editor,
            config: this.config,
        });

        this.editor.on<ExportGenerationFrameTemplateEventHandler>(
            'generation-frame:export-template',
            this.downloadGenerationFrameAsTemplate,
        );

        this.startRenderLoop();
    }

    private renderLoopInternal = () => {
        if (this._isDestroyed) {
            return;
        }

        if (this.editor.state.editorCanvasRenderMode === EditorCanvasRenderMode.Loop) {
            this.canvas.renderAll();
        }

        fabric.util.requestAnimFrame(this.renderLoopInternal);
    }

    private startRenderLoop() {
        fabric.util.requestAnimFrame(this.renderLoopInternal);
    }

    public initialize = () => {
        if (!this.canvas) {
            const canvas = new fabric.Canvas(this.canvasId, {
                backgroundColor: this.config.background,
                preserveObjectStacking: true,
                fireRightClick: true,
                height: this.config.size.height,
                width: this.config.size.width,
            })
            this.canvas = canvas as FabricCanvas

            this.canvas.disableEvents = function () {
                if (this.__fire === undefined) {
                    this.__fire = this.fire
                    // @ts-ignore
                    this.fire = function () { }
                }
            }

            this.canvas.enableEvents = function () {
                if (this.__fire !== undefined) {
                    this.fire = this.__fire
                    this.__fire = undefined
                }
            }
        }
    }

    public destroy = () => {
        this._isDestroyed = true;
        this.canvas.dispose();
        this.editor.off<ExportGenerationFrameTemplateEventHandler>(
            'generation-frame:export-template',
            this.downloadGenerationFrameAsTemplate,
        );
        this.unsubscribeEventUpdates?.();
    }

    public resize({ width, height }: any) {
        this.canvas?.setWidth(width).setHeight(height)
        this.canvas?.renderAll()
        const diffWidth = width / 2 - this.options.width / 2
        const diffHeight = height / 2 - this.options.height / 2

        this.options.width = width
        this.options.height = height

        const deltaPoint = new fabric.Point(diffWidth, diffHeight)
        this.canvas?.relativePan(deltaPoint)
    }

    public getBoundingClientRect() {
        const canvasEl = document.getElementById("canvas")
        const position = {
            left: canvasEl?.getBoundingClientRect().left,
            top: canvasEl?.getBoundingClientRect().top,
        }
        return position
    }

    public requestRenderAll() {
        this.canvas?.requestRenderAll();
    }

    public get backgroundColor() {
        return this.canvas?.backgroundColor
    }

    public setBackgroundColor(color: string) {
        this.canvas?.setBackgroundColor(color, () => {
            this.canvas?.requestRenderAll()
            this.editor.emit("canvas:updated")
        })
    }

    static getCanvasSize(
        generationFrame: fabric.GenerationFrame,
        targetLength = RENDER_CANVAS_LEGNTH,
    ) {
        const { width, height } = generationFrame;
        if (!width || !height) {
            return {
                width: targetLength,
                height: targetLength,
            }
        }

        targetLength = Math.min(targetLength, MAX_CANVAS_LENGTH);

        const scale = targetLength / Math.max(width, height);
        return {
            width: scale * width,
            height: scale * height,
        }
    }

    private static alwaysUseShapeControl() {
        const {
            generateToolReferenceImage,
        } = editorContextStore.getState();

        return !Boolean(generateToolReferenceImage);
    }

    private getGenerationFrameArgs() {
        const generationFramesController = this.editor.generationFrames;

        const generationFrame = generationFramesController.generationFrame;

        if (!generationFrame) {
            return null;
        }

        const generationFrameBounds: ObjectBounds2d = {
            left: generationFrame.left ?? 0,
            top: generationFrame.top ?? 0,
            width: generationFrame.width ?? 0,
            height: generationFrame.height ?? 0,
        };

        const sceneObjects = generationFramesController.updateImagesIntersectingGenerationFrame();

        const alwaysUseShapeControl = Canvas.alwaysUseShapeControl();

        return {
            alwaysUseShapeControl,
            sceneObjects,
            generationFrameBounds,
        };
    }

    /**
     * Generate the image data urls from Scene JSON
     * @param props
     * @param options
     * @returns
     */
    public async getDataURLsFromScene(
        props: GetDataUrlsProps,
        options?: fabric.IDataURLOptions,
    ) {
        return this.renderCanvasController.getDataURLs(
            props,
            options,
        );
    }

    /**
     * Generate teh image data urls from the generation frame
     * @param props
     * @param options
     * @returns
     */
    public async getGenerationFrameDataURLs(
        props: GenerationFrameDataProps = {},
        options?: fabric.IDataURLOptions,
    ): Promise<GenerationFrameDataOutput> {
        const generationFrameArgs = this.getGenerationFrameArgs();

        if (!generationFrameArgs) {
            return {};
        }

        const {
            alwaysUseShapeControl,
            generationFrameBounds,
            sceneObjects,
        } = generationFrameArgs;

        const newOutput = await this.renderCanvasController.getDataURLs({
            alwaysUseShapeControl,
            generationFrameBounds,
            readCanvasData: props.readCanvasData,
            sceneObjects,
        }, options);

        // const sceneJson = this.renderCanvasController.exportToJSON({
        //     objects: sceneObjects,
        // });

        return newOutput;
    }

    private downloadGenerationFrameAsTemplate = async () => {
        try {
            const {
                generateToolPromptTemplate,
                generateToolReferenceImage,
            } = this.editor.state;

            const {
                renderPipelineArgs,
                sceneJSON,
            } = await getRenderPipelineArgs({
                editor: this.editor,
                readCanvasData: true,
            });

            const generationId = generateUUID();

            debugLog(`Start uploading generation frame to ${generationId}`);

            const pastGeneration = await onRenderImageResultAdded({
                outputImage: {
                    id: generateUUID(),
                    generationId,
                    asset: {
                        type: 'image-url',
                        path: "data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==",
                    },
                } as any as fabric.StaticImage,
                prompt: renderPipelineArgs?.prompt ?? '',
                promptTemplate: generateToolPromptTemplate,
                sceneJSON,
                referenceImage: generateToolReferenceImage,
            });

            debugLog(`Finish uploading generation frame to ${generationId}`);

            downloadJson(
                JSON.stringify({
                    pastGeneration,
                    sceneJSON,
                }),
                `pastgen-${generationId}.json`,
            );

        } catch (error) {

            debugError(error);

        }
    }

    get width() {
        return this.canvas.width || this.config.size.width;
    }

    get height() {
        return this.canvas.height || this.config.size.height;
    }

    getCenterPoint() {
        return new fabric.Point(this.width / 2, this.height / 2);
    }

    getViewportCenter() {
        return fabric.util.transformPoint(
            this.getCenterPoint(),
            fabric.util.invertTransform(
                this.canvas.viewportTransform as any[]
            )
        );
    }

    pointInViewport(point: fabric.Point) {
        const {
            tl,
            br,
        } = this.canvas.calcViewportBoundaries();

        return isPointInBounds(
            point,
            tl,
            br,
            false,
        );
    }
}

declare module "fabric" {
    namespace fabric {
        interface Canvas {
            __fire: any
            enableEvents: () => void
            disableEvents: () => void
        }
        interface Object {
            id: string
            name: string
            locked: boolean
            duration?: {
                start?: number
                stop?: number
            }
            _objects?: fabric.Object[]
            metadata?: Record<string, any>
            clipPath?: undefined | null | fabric.Object
        }
    }
}

export default Canvas
