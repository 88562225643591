import { UserAssetType } from "core/common/types";
import { isDataURL, isValidHttpsUrl } from "core/utils/string-utils";
import { Functions, HttpsCallable, httpsCallable } from "firebase/functions";

import * as dJSON from 'dirty-json';


export type UserAssetStorageExtensionHeaders = {
    "x-goog-meta-owner_id": string,
    "x-goog-meta-asset_id"?: string,
    "x-goog-meta-project_id"?: string,
    "x-goog-meta-asset_type": UserAssetType,
}

export type GenerateUserAssetUploadUrlArgs = {
    contentType: string,
    extensionHeaders?: Record<string, string>,
    assetType: UserAssetType,
};

export type GenerateUserAssetUploadUrlResponse = {
    signedUrl?: string,
    assetId?: string,
    extensionHeaders?: Partial<UserAssetStorageExtensionHeaders>,
};

export type GenerateUserAssetUploadUrlFunction = HttpsCallable<GenerateUserAssetUploadUrlArgs, GenerateUserAssetUploadUrlResponse>;

export function createGenerateUserAssetUploadUrlFunction({
    firebaseFunctions,
}: {
    firebaseFunctions: Functions,
}) {
    return httpsCallable<GenerateUserAssetUploadUrlArgs, GenerateUserAssetUploadUrlResponse>(
        firebaseFunctions,
        "generateUserAssetUploadUrlColabJuly24",
    );
}


export function getStoragePathFromSignedUrl(signedUrl: string): string {
    try {
        const url = new URL(signedUrl);

        url.search = "";

        // Assuming the file path is the pathname component of the URL

        const prefix = '/flair-ai.appspot.com/';

        const pathname = url.pathname;

        if (pathname.startsWith(prefix)) {
            return pathname.slice(prefix.length);
        }

        return pathname;
    } catch (error) {
        console.error('Invalid URL:', error);
        return ''; // Return an empty string in case of an invalid URL
    }
}

export async function getUploadDataFromString(body: string) {
    if (isDataURL(body) || isValidHttpsUrl(body)) {
        const response = await fetch(body);
        if (!response.ok) {
            throw new Error(`Failed to fetch data from URI. Status: ${response.status}`);
        }
        return await response.blob();
    }
    return body;
}

export interface UploadAssetDataWithMetadataArgs {
    signedUrl: string,
    data: string | File | Blob,
    contentType: string,
    metadata: Record<string, string>,
}

export async function uploadAssetDataWithMetadata({
    signedUrl,
    data,
    contentType,
    metadata,
}: UploadAssetDataWithMetadataArgs) {
    // Convert data URI to Blob if the body is a string
    data = typeof (data) === 'string' ?
        await getUploadDataFromString(data) :
        data;

    const headers = new Headers({
        'Content-Type': contentType,
    });

    // Add metadata headers
    Object.entries(metadata).forEach(([key, value]) => {
        headers.append(key, value);
    });


    try {
        const response = await fetch(signedUrl, {
            method: 'PUT', // PUT is used for uploads to signed URLs
            headers,
            body: data, // The actual content to upload
        });

        if (!response.ok) {
            throw new Error(`Failed to upload content. Status: ${response.status}`);
        }

        return response;
    } catch (error) {
        console.error('Error uploading string with metadata:', error);
        throw error;
    }
}


function cleanupJsonTicks(input: string): string {
    return input
        .replace(/```json\s*|```/g, '') // Remove ```json and ```
        .trim(); // Trim any surrounding whitespace and newlines
}

export function parseDirtyJSON(jsonString: string) {
    try {

        jsonString = cleanupJsonTicks(jsonString);

        return dJSON.parse(jsonString);

    } catch (error) {
        console.error('Error parsing dirty json string: ', error);
    }
    return null;
}