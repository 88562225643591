import { noop } from "lodash";
import { StateUpdater } from "core/common/types";
import { classNames } from "core/utils/classname-utils";
import { getObjectEntries } from "core/utils/type-utils";
import React, { createContext, useContext } from "react";
import { SecondaryButtonClassNameInactive } from "components-mobile/constants/class-names";
import { PrimaryButtonClassName, PrimaryButtonClassNameDisabled, PrimaryButtonClassNameLoading, SecondaryButtonClassName } from "components/constants/class-names";
import { CheckCircledIcon, CircleIcon } from "@radix-ui/react-icons";
import { roundToNearestNumber } from "core/utils/number-utils";
import { QuotaOneTimePayment } from "core/common/types/quota-one-time-payment";
import { subscribeToOneTimePayment } from "components/subscription/checkout-portal";
import { editorContextStore } from "contexts/editor-context";
import { getOneTimePaymentDollarAmount } from "core/utils/one-time-payment-utils";
import { SimpleSpinner } from "components/icons/simple-spinner";


function areObjectsEqual<T extends object>(
    objA: T,
    objB: T
): boolean {
    const keysToCompare = (Object.keys(objA) as (keyof T)[]).filter(key => objA[key] != null && objB[key] != null);
    return keysToCompare.every((key) => {
        const valueA = objA[key] ?? 0;
        const valueB = objB[key] ?? 0;
        return Math.round(Number(valueA)) === Math.round(Number(valueB));
    });
}

function areQuotaPaymentsEqual(
    paymentA: QuotaOneTimePayment,
    paymentB: QuotaOneTimePayment
): boolean {
    return areObjectsEqual(paymentA, paymentB);
}

enum QuotaOneTimePaymentStatus {
    Idle = "Idle",
    Loading = "Loading",
}

interface QuotaOneTimePaymentContextProps {
    oneTimePaymentStatus: QuotaOneTimePaymentStatus,
    setOneTimePaymentStatus: (value: StateUpdater<QuotaOneTimePaymentStatus>) => void,
    oneTimePayment: QuotaOneTimePayment,
    setOneTimePayment: (value: StateUpdater<QuotaOneTimePayment>) => void,
}

const QuotaOneTimePaymentContext = createContext<QuotaOneTimePaymentContextProps>({
    oneTimePayment: {},
    setOneTimePayment: noop,
    oneTimePaymentStatus: QuotaOneTimePaymentStatus.Idle,
    setOneTimePaymentStatus: noop,
});

const useQuotaOneTimePaymentContext = () => useContext(QuotaOneTimePaymentContext);


function getQuotaOneTimePaymentQuantity(dollarAmount: number) {
    return dollarAmount;
}

interface QuotaOneTimePaymentOption {
    payment: QuotaOneTimePayment,
    title: React.ReactNode,
    description: React.ReactNode,
}

type QuotaOneTimePaymentOptions = Record<string, QuotaOneTimePaymentOption>;

const onetimePaymentOptions: Record<string, QuotaOneTimePaymentOptions> = {
    "Get more custom models": {
        '+5 custom models': {
            payment: {
                numCustomModelTrainings: 5,
            },
            title: '+5 custom models',
            description: "",
        },
        '+10 custom models': {
            payment: {
                numCustomModelTrainings: 10,
            },
            title: '+10 custom models',
            description: "",
        },
        '+25 custom models': {
            payment: {
                numCustomModelTrainings: 25,
            },
            title: '+25 custom models',
            description: '',
        },
        '+50 custom models': {
            payment: {
                numCustomModelTrainings: 50,
            },
            title: '+50 custom models',
            description: '',
        },
    },
    "Get more video generations": {
        '+5 videos': {
            payment: {
                numVideoGenerations: 5,
            },
            title: '+5 videos',
            description: "",
        },
        '+10 videos': {
            payment: {
                numVideoGenerations: 10,
            },
            title: '+10 videos',
            description: "",
        },
        '+20 videos': {
            payment: {
                numVideoGenerations: 20,
            },
            title: '+20 videos',
            description: "",
        },
        '+35 videos': {
            payment: {
                numVideoGenerations: 35,
            },
            title: '+35 videos',
            description: '',
        },
    },
}

const iconSize = 16;

const QuotaOption = React.forwardRef(function QuotaOption({
    name,
    option,
    className = "",
    ...props
}: React.HTMLAttributes<HTMLButtonElement> & {
    name: string,
    option: QuotaOneTimePaymentOption,
}, forwardedRef: React.ForwardedRef<HTMLButtonElement>) {
    const {
        oneTimePayment,
        setOneTimePayment,
    } = useQuotaOneTimePaymentContext();

    const isActive = React.useMemo(() => {
        return areQuotaPaymentsEqual(option.payment, oneTimePayment);
    }, [option.payment, oneTimePayment]);

    return (
        <button
            {...props}
            ref={forwardedRef}
            className={classNames(
                isActive ? SecondaryButtonClassName : SecondaryButtonClassNameInactive,
                "group flex flex-col items-stretch gap-2",
                className,
            )}
            onClick={() => {
                if (isActive) {
                    setOneTimePayment((payment) => ({
                        ...payment,
                        ...Object.fromEntries(
                            Object.keys(option.payment).map((key) => [key, 0]),
                        ),
                    }));
                } else {
                    setOneTimePayment((payment) => ({
                        ...payment,
                        ...option.payment,
                    }));
                }
            }}
        >
            <div className="flex flex-row items-center">
                <div className="flex-1">
                    {option.title}
                </div>
                <div
                    className={classNames(
                        !isActive && "text-zinc-800 group-hover:text-lime-800 transition-colors",
                    )}
                >
                    {isActive ?
                        <CheckCircledIcon width={iconSize} height={iconSize} /> :
                        <CircleIcon width={iconSize} height={iconSize} />}
                </div>
            </div>
            {option.description}
        </button>
    )
});

const QuotaOptions = React.forwardRef(function QuotaOptions({
    name,
    className = "",
    options,
    ...props
}: React.HTMLAttributes<HTMLDivElement> & {
    name: string,
    options: QuotaOneTimePaymentOptions,
}, forwardedRef: React.ForwardedRef<HTMLDivElement>) {
    return (
        <div
            {...props}
            ref={forwardedRef}
            className={classNames(
                "flex flex-col items-stretch gap-4",
                className,
            )}
        >
            <div className="font-semibold">
                {name}
            </div>
            <div
                className={classNames(
                    "grid gap-2 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4",
                )}
            >
                {getObjectEntries(options).map(([name, option]) => (
                    <QuotaOption
                        key={name}
                        name={name}
                        option={option}
                    />
                ))}
            </div>
        </div>
    )
});

const QuotaOneTimePaymentOptions = React.forwardRef(function QuotaOneTimePaymnt({
    className = "",
    ...props
}: React.HTMLAttributes<HTMLDivElement>, forwardedRef: React.ForwardedRef<HTMLDivElement>) {
    return (
        <div
            ref={forwardedRef}
            {...props}
            className={classNames(
                "flex flex-col items-stretch gap-8",
                className,
            )}
        >
            {getObjectEntries(onetimePaymentOptions).map(([name, options]) => (
                <QuotaOptions
                    key={name}
                    name={name}
                    options={options}
                    className="w-full"
                />
            ))}
        </div>
    );
});

function useQuotaOneTimePaymentTotalCost() {
    const pricingConfig = editorContextStore(state => state.pricingConfig);
    const quotaContext = useQuotaOneTimePaymentContext();
    const {
        totalDollarAmount,
    } = React.useMemo(() => getOneTimePaymentDollarAmount({
        ...quotaContext.oneTimePayment,
        ...pricingConfig,
    }), [quotaContext.oneTimePayment, pricingConfig]);
    return totalDollarAmount;
}

function QuotaOneTimePaymentTotal() {
    const totalCost = useQuotaOneTimePaymentTotalCost();

    return (
        <div className="flex flex-row items-center gap-8 text-xl font-semibold">
            <span className="flex-1">
                Total
            </span>
            <div>
                ${roundToNearestNumber(totalCost, 2)}
            </div>
        </div>
    );
}

function QuotaPurchaseButton() {
    const {
        oneTimePayment,
        oneTimePaymentStatus,
        setOneTimePaymentStatus,
    } = useQuotaOneTimePaymentContext();

    const totalCost = useQuotaOneTimePaymentTotalCost();

    return (
        <button
            className={classNames(
                totalCost > 0 ?
                    (oneTimePaymentStatus === QuotaOneTimePaymentStatus.Idle ?
                        PrimaryButtonClassName :
                        PrimaryButtonClassNameLoading) :
                    PrimaryButtonClassNameDisabled,
                "flex flex-row items-center justify-center gap-2",
            )}
            onClick={() => {
                if (oneTimePaymentStatus !== QuotaOneTimePaymentStatus.Idle) {
                    return;
                }

                if (totalCost <= 0) {
                    return;
                }

                setOneTimePaymentStatus(QuotaOneTimePaymentStatus.Loading);

                const quantity = getQuotaOneTimePaymentQuantity(totalCost);

                subscribeToOneTimePayment({
                    ...oneTimePayment,
                    quantity,
                }).finally(() => {
                    setOneTimePaymentStatus(QuotaOneTimePaymentStatus.Idle);
                });
            }}
        >
            {oneTimePaymentStatus === QuotaOneTimePaymentStatus.Loading && <SimpleSpinner width={18} height={18} pathClassName="fill-lime-500" />}
            <span>
                {totalCost > 0 ? "Purchase extra credits" : "Select credits first"}
            </span>
        </button>
    );
}

export const QuotaOneTimePaymentDashboard = React.forwardRef(function QuotaOneTimePaymnt({
    className = "",
    ...props
}: React.HTMLAttributes<HTMLDivElement>, forwardedRef: React.ForwardedRef<HTMLDivElement>) {
    return (
        <div
            ref={forwardedRef}
            {...props}
            className={classNames(
                "flex flex-col items-stretch gap-8",
                className,
            )}
        >
            <QuotaOneTimePaymentOptions />
            <div className="py-8 flex flex-col sm:flex-row gap-4 sm:gap-8 items-stretch border-t border-zinc-800">
                <div className="flex-1 min-w-0 hidden sm:block" />
                <QuotaOneTimePaymentTotal />
                <QuotaPurchaseButton />
            </div>
        </div>
    );
});





export const QuotaOneTimePaymentProvider: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
    const [oneTimePaymentStatus, setOneTimePaymentStatus] = React.useState(QuotaOneTimePaymentStatus.Idle);
    const [oneTimePayment, setOneTimePayment] = React.useState<QuotaOneTimePayment>({
        numCustomModelTrainings: 5,
        numVideoGenerations: 10,
    });

    return (
        <QuotaOneTimePaymentContext.Provider
            value={{
                oneTimePayment,
                setOneTimePayment,
                oneTimePaymentStatus,
                setOneTimePaymentStatus,
            }}
        >
            {children}
        </QuotaOneTimePaymentContext.Provider>
    );
};