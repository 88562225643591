import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { editorContextStore } from 'contexts/editor-context';

interface RequireAuthProps {
    children: React.ReactNode;
}

export function RequireAuth({ children }: RequireAuthProps) {
    const user = editorContextStore(state => state.user);
    const location = useLocation();

    if (user?.uid) {
        return <>{children}</>;
    } else {
        // Preserve the current search parameters
        const searchParams = location.search;
        const loginPath = `/login${searchParams}`;

        return <Navigate replace to={loginPath} />;
    }
}
