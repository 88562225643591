import { StaticImageObjectVideoGenerationResult } from "core/common/types/elements";
import { debugError, debugLog } from "./print-utilts";
import { Editor } from "core/editor";
import { Backend } from "backend/base";
import { UserVideoGenerationQuotas } from "core/common/types/video";
import { clamp } from "lodash";

export function loadVideoElementFromURL(url: string): Promise<HTMLVideoElement> {
    return new Promise<HTMLVideoElement>((resolve, reject) => {
        const video = document.createElement('video');
        video.crossOrigin = 'anonymous';
        video.src = url;

        debugLog(`Loading video from ${url}`);

        video.onloadeddata = () => {
            debugLog(`Loaded video from ${url}`);
            resolve(video);
        };

        video.onerror = (e) => {
            debugLog(`Error loading video from ${url}`);
            reject(e);
        };
    });
}

export async function loadVideoElementFromVideoGenerationId({
    editor,
    backend,
    generationId,
}: {
    editor: Editor,
    backend: Backend,
    generationId: string,
}) {
    try {

        const videoGenerationDoc = await backend.getVideoGenerationDoc(
            generationId
        )

        const outputVideoStoragePath = videoGenerationDoc?.outputVideoStoragePath;

        if (!outputVideoStoragePath) {
            return undefined;
        }

        return await editor.assets.loadAsset({
            path: outputVideoStoragePath,
            saveToMemory: true,
        });

    } catch (error) {
        debugError(`Error loading generated video ${generationId}: `, error);
    }

    return undefined;
}


type SetStaticImageObjectVideoGenerationResultVideoElementResponse = {
    isLoaded: false,
} | {
    isLoaded: true,
    videoElement: HTMLVideoElement,
}

export async function setStaticImageObjectVideoGenerationResultVideoElement({
    object,
    editor,
    backend,
}: {
    editor: Editor,
    backend: Backend,
    object: StaticImageObjectVideoGenerationResult,
}): Promise<SetStaticImageObjectVideoGenerationResultVideoElementResponse> {
    try {

        const generationId = object.metadata.videoGenerationId;

        const videoUrl = await loadVideoElementFromVideoGenerationId({
            editor,
            backend,
            generationId,
        });

        if (!videoUrl) {
            return {
                isLoaded: false,
            };
        }

        const videoElement = await loadVideoElementFromURL(videoUrl);

        if (!videoElement) {
            return {
                isLoaded: false,
            };
        }

        const videoWidth = videoElement.videoWidth || 1;
        const videoHeight = videoElement.videoHeight || 1;

        videoElement.width = videoWidth;
        videoElement.height = videoHeight;

        const width = object.getScaledWidth() || 1;
        const height = object.getScaledHeight() || 1;

        const scale = clamp(
            Math.min(width / videoWidth, height / videoHeight),
            1e-3, 1e3,
        );

        object.setElement(videoElement);

        object.scale(scale);

        return {
            isLoaded: true,
            videoElement,
        };
    } catch (error) {
        debugError('Error loading image object');
    }
    return {
        isLoaded: false,
    };
}

/**
 * Waits for a specific event to occur on an EventTarget.
 * @param element - The EventTarget to listen to.
 * @param event - The name of the event to wait for.
 * @returns A promise that resolves when the event occurs.
 */
function waitForEvent(element: EventTarget, event: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
        const onEvent = () => {
            element.removeEventListener(event, onEvent);
            element.removeEventListener('error', onError);
            resolve();
        };
        const onError = () => {
            element.removeEventListener(event, onEvent);
            element.removeEventListener('error', onError);
            reject(new Error(`Error waiting for event: ${event}`));
        };
        element.addEventListener(event, onEvent);
        element.addEventListener('error', onError);
    });
}

/**
 * Ensures that the video's metadata is loaded.
 * @param video - The HTMLVideoElement to check.
 */
async function ensureVideoMetadataLoaded(video: HTMLVideoElement): Promise<void> {
    if (video.readyState >= HTMLMediaElement.HAVE_METADATA) {
        return;
    }
    await waitForEvent(video, 'loadedmetadata');
}

/**
 * Ensures that the video has enough data to provide a current frame.
 * @param video - The HTMLVideoElement to check.
 */
async function ensureVideoHasCurrentData(video: HTMLVideoElement): Promise<void> {
    if (video.readyState >= HTMLMediaElement.HAVE_CURRENT_DATA) {
        return;
    }
    await waitForEvent(video, 'canplay');
}

/**
 * Captures the current frame of the video onto a canvas.
 * @param video - The HTMLVideoElement to capture from.
 * @returns A canvas element with the current video frame drawn on it.
 */
function captureFrameToCanvas(video: HTMLVideoElement): HTMLCanvasElement {
    const canvas = document.createElement('canvas');
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;

    const context = canvas.getContext('2d');
    if (!context) {
        throw new Error('Could not get canvas 2D context');
    }

    context.drawImage(video, 0, 0, canvas.width, canvas.height);
    return canvas;
}

/**
 * Converts a canvas to a data URL.
 * @param canvas - The canvas element to convert.
 * @param type - The image format (default is 'image/png').
 * @returns A data URL representing the image.
 */
function canvasToDataURL(canvas: HTMLCanvasElement, type: string = 'image/png'): string {
    return canvas.toDataURL(type);
}

/**
 * Main function to capture the current frame of a video as a data URL.
 * @param video - The HTMLVideoElement to capture from.
 * @returns A promise that resolves to a data URL of the current video frame.
 */
export async function getVideoFrameAsDataURL(video: HTMLVideoElement): Promise<string> {
    await ensureVideoMetadataLoaded(video);
    await ensureVideoHasCurrentData(video);

    const canvas = captureFrameToCanvas(video);
    const dataURL = canvasToDataURL(canvas);

    // Optionally, clean up the canvas
    canvas.width = 0;
    canvas.height = 0;

    return dataURL;
}

export function canUserStartVideoGeneration({
    userQuotas,
}: {
    userQuotas?: UserVideoGenerationQuotas | null,
}) {
    if (!userQuotas) {

        debugLog('User quotas is not defined yet.');

        return false;
    }

    const {
        numVideoGenerationCredits = 1,
        maxNumVideoGenerationCredits = 0,
    } = userQuotas;

    return numVideoGenerationCredits < maxNumVideoGenerationCredits;
}