import React from 'react';
import * as ToggleGroup from '@radix-ui/react-toggle-group';
import { classNames } from 'core/utils/classname-utils';

export type OptionItem<Value = string> = {
    name: React.ReactNode;
    value: Value;
};

interface ToggleGroupInputPropsBase<Value = string> {
    options: OptionItem<Value>[];
    rootClassName?: string,
    itemClassName?: string,
}

interface ToggleGroupInputSingleProps<Value = string>
    extends ToggleGroupInputPropsBase<Value> {
    type: 'single';
    value: Value;
    onValueChange: (value: Value) => void;
}

interface ToggleGroupInputMultipleProps<Value = string>
    extends ToggleGroupInputPropsBase<Value> {
    type: 'multiple';
    value: Value[];
    onValueChange: (value: Value[]) => void;
}

export type ToggleGroupInputProps<Value = string> =
    | ToggleGroupInputSingleProps<Value>
    | ToggleGroupInputMultipleProps<Value>;


export function ToggleGroupInput<Value = string>(
    props: ToggleGroupInputProps<Value>
) {
    const {
        options,
        rootClassName = "",
        itemClassName = "",
    } = props;

    const valueToOptionMap = React.useMemo(
        () => new Map(options.map((option) => [String(option.value), option])),
        [options]
    );

    const toggleGroupProps =
        props.type === 'multiple'
            ? {
                type: 'multiple' as const,
                value: props.value.map(String),
                onValueChange: (value: string[] | undefined) => {
                    const newValue = (value ?? []).map(
                        (v) => valueToOptionMap.get(v)!.value
                    );
                    props.onValueChange(newValue);
                },
            }
            : {
                type: 'single' as const,
                value: props.value !== undefined ? String(props.value) : undefined,
                onValueChange: (value: string | null) => {
                    if (value !== null) {
                        const newValue = valueToOptionMap.get(value)?.value;
                        if (newValue !== undefined) {
                            props.onValueChange(newValue);
                        }
                    }
                },
            };

    return (
        <ToggleGroup.Root
            {...toggleGroupProps}
            className={classNames(
                "w-fit px-1 py-1 text-sm flex flex-row items-center gap-1 rounded-lg bg-zinc-950/30 border border-zinc-800",
                rootClassName,
            )}
        >
            {options.map((option) => (
                <ToggleGroup.Item
                    key={String(option.value)}
                    value={String(option.value)}
                    className={classNames(
                        'data-[state=on]:bg-zinc-800 data-[state=on]:text-zinc-300 transition-colors',
                        'hover:bg-zinc-800/50 hover:text-zinc-300',
                        'text-zinc-500 px-3 py-2 flex items-center justify-center',
                        'rounded focus:outline-none focus-visible:ring-1 focus-visible:ring-lime-500',
                        itemClassName,
                    )}
                    aria-label={String(option.name)}
                >
                    {option.name}
                </ToggleGroup.Item>
            ))}
        </ToggleGroup.Root>
    );
}
